import * as React from "react";

const Icon7 = (props) => (
    <svg
        width={78}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M56.815 78H1.135C.508 78 0 77.49 0 76.859V1.14C0 .51.507 0 1.136 0h46.647c.628 0 1.136.51 1.136 1.141v7.927h7.889c.628 0 1.136.51 1.136 1.141v11.26c0 .63-.508 1.14-1.136 1.14H15.892v41.104h40.916c.628 0 1.136.51 1.136 1.141v11.997c0 .632-.508 1.141-1.136 1.141l.007.008ZM2.271 75.718H55.68v-9.715H14.757c-.629 0-1.136-.51-1.136-1.141V21.476c0-.631.507-1.141 1.136-1.141h40.915V11.35h-7.89c-.628 0-1.135-.51-1.135-1.14V2.281H2.271v73.436Z" />
            <path d="M56.815 11.35H47.79c-.628 0-1.136-.51-1.136-1.14V1.14c0-.464.28-.875.705-1.057a1.126 1.126 0 0 1 1.234.25l9.025 9.069a1.139 1.139 0 0 1-.803 1.947Zm-7.89-2.282h5.15l-5.15-5.173v5.173ZM76.864 66.003H14.757c-.629 0-1.136-.51-1.136-1.141V21.476c0-.631.507-1.141 1.136-1.141h52.151c.628 0 1.136.51 1.136 1.141v8.855h8.813c.628 0 1.135.51 1.135 1.141v33.39c0 .631-.507 1.14-1.135 1.14h.007ZM15.9 63.72h59.829V32.614h-8.814c-.628 0-1.135-.51-1.135-1.142v-8.855H15.892v41.104h.008Z" />
            <path d="M76.864 32.614h-9.949c-.628 0-1.135-.51-1.135-1.142v-9.996c0-.464.28-.875.704-1.057a1.127 1.127 0 0 1 1.234.25l9.949 9.997a1.14 1.14 0 0 1-.803 1.948Zm-8.813-2.283h6.072l-6.072-6.101v6.101ZM31.285 40.328h-9.518c-.628 0-1.135-.51-1.135-1.142v-9.562c0-.632.507-1.141 1.135-1.141h9.518c.628 0 1.135.51 1.135 1.14v9.563c0 .632-.507 1.142-1.135 1.142Zm-8.382-2.283h7.246v-7.28h-7.246v7.28ZM31.285 59.225h-9.518c-.628 0-1.135-.51-1.135-1.142v-9.562c0-.632.507-1.141 1.135-1.141h9.518c.628 0 1.135.51 1.135 1.14v9.563c0 .632-.507 1.142-1.135 1.142Zm-8.382-2.283h7.246v-7.28h-7.246v7.28ZM61.32 31.092H36.607c-.628 0-1.135-.51-1.135-1.141s.507-1.141 1.135-1.141H61.32c.629 0 1.136.51 1.136 1.14 0 .632-.507 1.142-1.136 1.142ZM61.32 35.778H36.607c-.628 0-1.135-.51-1.135-1.14 0-.632.507-1.142 1.135-1.142H61.32c.629 0 1.136.51 1.136 1.141 0 .632-.507 1.141-1.136 1.141ZM61.32 40.457H36.607c-.628 0-1.135-.51-1.135-1.141 0-.632.507-1.141 1.135-1.141H61.32c.629 0 1.136.51 1.136 1.14 0 .632-.507 1.142-1.136 1.142ZM60.578 49.753H35.865c-.628 0-1.135-.51-1.135-1.14 0-.632.507-1.142 1.135-1.142h24.713c.629 0 1.136.51 1.136 1.141s-.507 1.141-1.136 1.141ZM60.578 54.44H35.865c-.628 0-1.135-.51-1.135-1.142 0-.631.507-1.14 1.135-1.14h24.713c.629 0 1.136.509 1.136 1.14 0 .632-.507 1.142-1.136 1.142ZM60.578 59.126H35.865c-.628 0-1.135-.51-1.135-1.141 0-.632.507-1.142 1.135-1.142h24.713c.629 0 1.136.51 1.136 1.142 0 .631-.507 1.14-1.136 1.14Z" />
        </g>
    </svg>
);

export default Icon7;
