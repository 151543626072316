import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import "../base.scss";
import "./Products.scss";
import { FadeInSection } from "../Components/FadeInSection/FadeInSection";
import "../Components/FadeInSection/FadeInSection.scss";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Navbar from "../NavMenu/Navbar";
import Footer from "../Components/Footer/Footer";
import FeaturesVT from "./assets/features-vt.png";
import FeaturesInvoice from "./assets/invoice.jpg";
import FeaturesSimplePay from "./assets/features-simple-pay.png";
import FeaturesShoppingCart from "./assets/cart.png";
import FeaturesReporting from "./assets/features-reporting.jpg";
import FeaturesIQProtect from "./assets/features-iq-protect.png";
import FeaturesCustomFields from "./assets/custom-fields.png";
import mixpanel from "mixpanel-browser";

export const ProductsTitle = styled(Typography)`
    // width: 85%;
`;

const LearnMore = styled(Button)`
    background-color: #1b9ad2;
    color: white;
    border-radius: 2em;
    width: 10rem;
    position: relative;
    will-change: transform;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
        background-color: #1b9ad2;
    }
`;

const Products = () => {
    {
        return (
            <>
                <Navbar />
                {/* Header */}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ display: "flex" }}
                >
                    <Grid item xs={6} lg={7}></Grid>
                    <Grid item lg>
                        <Typography
                            variant="h2"
                            fontWeight="500"
                            className="features-title"
                        >
                            iQ Pro Features
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        mt: 23
                    }}
                ></Grid>
                <Grid className="features-wave">
                    {/* Virtual Terminal */}
                    <FadeInSection className="fade-in-from-bottom">
                        <Grid container>
                            <Grid item xs lg={2}></Grid>
                            <Grid
                                item
                                xs={10}
                                lg={7.5}
                                className="glass-gradient-bg"
                                sx={{ mt: 22 }}
                            >
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Grid item lg={6}>
                                        <Grid
                                            container
                                            justifyContent={{
                                                xs: "center",
                                                lg: "flex-start"
                                            }}
                                        >
                                            <Grid item sx={{ pb: 2 }}>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight="500"
                                                >
                                                    Virtual Terminal
                                                </Typography>
                                            </Grid>
                                            <Grid item className="block-list">
                                                <List>
                                                    <ListItem>
                                                        Accept card and ACH
                                                        payments
                                                    </ListItem>
                                                    <ListItem>
                                                        Use stored and tokenized
                                                        payment information
                                                    </ListItem>
                                                    <ListItem>
                                                        Options for
                                                        card-not-present (CNP)
                                                        and physical terminal
                                                        transactions
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item>
                                                <LearnMore
                                                    href="features/virtual-terminal"
                                                    onClick={() =>
                                                        mixpanel.track(
                                                            "User taken to Virtual Terminal page"
                                                        )
                                                    }
                                                >
                                                    Learn more
                                                </LearnMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs lg>
                                        <img
                                            src={FeaturesVT}
                                            className="asset-placeholder-right"
                                            alt="Virtual Terminal page"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs lg></Grid>
                            </Grid>
                            <Grid item xs lg></Grid>
                        </Grid>
                    </FadeInSection>
                    {/* Invoices */}
                    <FadeInSection className="fade-in-from-bottom">
                        <Grid container sx={{ mb: 5 }}>
                            <Grid item xs lg={2}></Grid>
                            <Grid
                                item
                                xs={10}
                                lg={7.5}
                                className="glass-gradient-bg"
                                sx={{ mt: 3, mb: 10 }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Grid item lg={6} sx={{}}>
                                        <Grid
                                            container
                                            justifyContent={{
                                                xs: "center",
                                                lg: "flex-start"
                                            }}
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight="500"
                                                    sx={{ pb: 2 }}
                                                >
                                                    Invoices
                                                </Typography>
                                            </Grid>
                                            <Grid item className="block-list">
                                                <List>
                                                    <ListItem>
                                                        Send invoices without
                                                        development or
                                                        customization
                                                    </ListItem>
                                                    <ListItem>
                                                        Reduce paperwork and the
                                                        time to get paid
                                                    </ListItem>
                                                    <ListItem>
                                                        Track and display prior
                                                        payments, tax
                                                        calculation, shipping
                                                        amounts, discounts, and
                                                        more
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item>
                                                <LearnMore
                                                    href="features/invoicing"
                                                    onClick={mixpanel.track(
                                                        "User taken to Invoices page"
                                                    )}
                                                >
                                                    Learn more
                                                </LearnMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg>
                                        <img
                                            src={FeaturesInvoice}
                                            className="asset-placeholder-right"
                                            alt="Example of creating an invoice"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs lg></Grid>
                            </Grid>
                            <Grid item xs lg></Grid>
                        </Grid>
                    </FadeInSection>
                </Grid>
                {/* Empowered E-commerce */}
                <FadeInSection className="fade-in-from-left">
                    <Grid container direction="row" justifyContent="center">
                        <Typography variant="h2" className="features-h2">
                            Empowered E-commerce
                        </Typography>
                        <Grid container>
                            <Grid item lg={3}></Grid>
                            <Grid
                                item
                                lg={7}
                                className="glass-gradient-bg-large"
                                sx={{ mt: 4, px: 2, py: 3, mb: 10 }}
                            >
                                {/* Simple Pay */}
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ mb: 7 }}
                                >
                                    <Grid
                                        item
                                        lg
                                        sx={{
                                            display: { xs: "none", lg: "flex" }
                                        }}
                                    >
                                        <img
                                            src={FeaturesSimplePay}
                                            className="asset-placeholder-left"
                                            alt="Example of a simple pay page"
                                        />
                                    </Grid>
                                    <Grid item lg={7}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight="500"
                                                    sx={{ pb: 2 }}
                                                >
                                                    Simple Pay
                                                </Typography>
                                            </Grid>
                                            <Grid item className="block-list">
                                                <Typography
                                                    paragraph
                                                    variant="body"
                                                >
                                                    Simple Pay is a custom built
                                                    payment page that can be
                                                    provided to your customers.
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        Secure hosted payment
                                                        page
                                                    </ListItem>
                                                    <ListItem>
                                                        Brand the page with your
                                                        business name, logo,
                                                        colors, and more
                                                    </ListItem>
                                                    <ListItem>
                                                        Optional &quot;Pay
                                                        Now&quot; link to add to
                                                        your website, or share
                                                        with your customers via
                                                        email or text
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <LearnMore
                                                href="features/simple-pay"
                                                sx={{ mb: { xs: 4, lg: 0 } }}
                                                onClick={() =>
                                                    mixpanel.track(
                                                        "User taken to Simple Pay page"
                                                    )
                                                }
                                            >
                                                Learn more
                                            </LearnMore>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg
                                        sx={{
                                            display: { xs: "flex", lg: "none" }
                                        }}
                                    >
                                        <img
                                            src={FeaturesSimplePay}
                                            className="asset-placeholder-left"
                                            alt="Example of a simple pay page"
                                        />
                                    </Grid>
                                </Grid>
                                {/* Shopping Cart */}
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid
                                        item
                                        lg
                                        sx={{
                                            display: { xs: "none", lg: "flex" }
                                        }}
                                    >
                                        <img
                                            src={FeaturesShoppingCart}
                                            className="asset-placeholder-left"
                                            alt="Example of Shopping Cart items on the Virtual Terminal page"
                                        />
                                    </Grid>
                                    <Grid item lg={7}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight="500"
                                                    sx={{ pb: 2 }}
                                                >
                                                    Shopping Cart
                                                </Typography>
                                            </Grid>
                                            <Grid item className="block-list">
                                                <Typography
                                                    paragraph
                                                    variant="body"
                                                >
                                                    No web developer? No
                                                    problem.
                                                </Typography>
                                                <Typography
                                                    paragraph
                                                    variant="body"
                                                >
                                                    Shopping Cart offers:
                                                </Typography>
                                                <List>
                                                    <ListItem>
                                                        Embed code to host on
                                                        your website
                                                    </ListItem>
                                                    <ListItem>
                                                        Shareable URLs for
                                                        product landing pages
                                                    </ListItem>
                                                    <ListItem>
                                                        Full customization for
                                                        your business
                                                    </ListItem>
                                                </List>
                                                <LearnMore
                                                    href="features/shopping-cart"
                                                    sx={{
                                                        mb: { xs: 4, lg: 0 }
                                                    }}
                                                    onClick={() =>
                                                        mixpanel.track(
                                                            "User taken to Shopping Cart page"
                                                        )
                                                    }
                                                >
                                                    Learn more
                                                </LearnMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg
                                        sx={{
                                            display: { xs: "flex", lg: "none" }
                                        }}
                                    >
                                        <img
                                            src={FeaturesShoppingCart}
                                            className="asset-placeholder-left"
                                            alt="Example of Shopping Cart items on the Virtual Terminal page"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FadeInSection>
                {/* Simplified Workflows */}
                <FadeInSection className="fade-in">
                    <Grid container justifyContent="center">
                        <Typography
                            variant="h2"
                            className="features-h2"
                            sx={{ mb: 3 }}
                        >
                            Simplified Workflows
                        </Typography>
                    </Grid>
                </FadeInSection>
                <Grid
                    container
                    columnSpacing={10}
                    spacing={3}
                    justifyContent="center"
                >
                    {/* Account Updater */}
                    <Grid item xs={10} lg={3}>
                        <FadeInSection className="fade-in-from-bottom">
                            <div className="feature-cards">
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    spacing={5}
                                >
                                    <Grid item xs lg>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight={500}
                                                >
                                                    Account Updater
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Once per month Account
                                                    Updater will automatically
                                                    search for available valid
                                                    payment info for expired,
                                                    replaced, or canceled cards
                                                    saved in your vault.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Reduces administrative
                                                    expenses and helps avoid
                                                    lost sales revenue.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs lg>
                                        <LearnMore
                                            href="features/account-updater"
                                            onClick={() =>
                                                mixpanel.track(
                                                    "User taken to Account Updater page"
                                                )
                                            }
                                        >
                                            Learn More
                                        </LearnMore>
                                    </Grid>
                                </Grid>
                            </div>
                        </FadeInSection>
                    </Grid>
                    {/* Recurring Billing */}
                    <Grid item xs={10} lg={3}>
                        <FadeInSection className="fade-in-from-bottom">
                            <div className="feature-cards">
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    spacing={5}
                                >
                                    <Grid item lg>
                                        <Grid container spacing={3}>
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight={500}
                                                >
                                                    Recurring Billing
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Simplify the payment process
                                                    with Recurring Billing. You
                                                    can increase cash flow while
                                                    reducing overall costs to
                                                    capture payments.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Automatically bill customers
                                                    daily, monthly, or
                                                    bi-weekly.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <LearnMore
                                            href="features/recurring-billing"
                                            onClick={() =>
                                                mixpanel.track(
                                                    "User taken to Recurring Billing page"
                                                )
                                            }
                                        >
                                            Learn More
                                        </LearnMore>
                                    </Grid>
                                </Grid>
                            </div>
                        </FadeInSection>
                    </Grid>
                    {/* Customer Vault */}
                    <Grid item xs={10} lg={3}>
                        <FadeInSection className="fade-in-from-bottom">
                            <div className="feature-cards">
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    spacing={5}
                                >
                                    <Grid item lg>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Typography
                                                    variant="h3"
                                                    className="features-h3"
                                                    fontWeight={500}
                                                >
                                                    Customer Vault
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Store customer data and
                                                    payment information in the
                                                    secure Customer Vault to
                                                    limit the amount of
                                                    sensitive data on your
                                                    system.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body">
                                                    Save multiple addresses and
                                                    payment methods, and set
                                                    defaults to be more
                                                    efficient.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg>
                                        <LearnMore
                                            href="features/customer-vault"
                                            onClick={() =>
                                                mixpanel.track(
                                                    "User taken to Customer Vault page"
                                                )
                                            }
                                        >
                                            Learn More
                                        </LearnMore>
                                    </Grid>
                                </Grid>
                            </div>
                        </FadeInSection>
                    </Grid>
                </Grid>
                {/* Personalized Experience */}
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    className="glass-gradient-bg-large"
                    sx={{ mt: 5 }}
                >
                    <FadeInSection className="fade-in">
                        <Typography
                            variant="h2"
                            align="center"
                            className="features-h2"
                            sx={{ mb: 3 }}
                        >
                            Personalized Experience
                        </Typography>
                    </FadeInSection>
                    {/* Reporting */}
                    <FadeInSection className="fade-in-from-right">
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                            sx={{ mb: 5 }}
                        >
                            <Grid item lg={2}></Grid>
                            <Grid
                                container
                                item
                                lg={5}
                                direction="column"
                                sx={{ width: "90%" }}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        className="features-h3"
                                        fontWeight="500"
                                    >
                                        Reporting
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography paragraph variant="body1">
                                        iQ Pro allows you to customize how you
                                        view your reporting. Using Custom
                                        Outputs, you can select and create your
                                        custom reporting view for:
                                    </Typography>
                                </Grid>
                                <Grid item className="block-list">
                                    <List>
                                        <ListItem>transactions</ListItem>
                                        <ListItem>settlements</ListItem>
                                        <ListItem>customer vault</ListItem>
                                    </List>
                                </Grid>

                                <Grid item>
                                    <LearnMore
                                        href="features/reporting"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Reporting page"
                                            )
                                        }
                                    >
                                        Learn More
                                    </LearnMore>
                                </Grid>
                            </Grid>
                            <Grid item lg>
                                <img
                                    src={FeaturesReporting}
                                    className="asset-placeholder"
                                    alt="Example of Reporting page with several transactions"
                                />
                            </Grid>
                            <Grid item lg={2}></Grid>
                        </Grid>
                    </FadeInSection>
                    {/* Custom Fields */}
                    <FadeInSection className="fade-in-from-left">
                        <Grid container direction="row" spacing={3}>
                            <Grid item lg={1.5}></Grid>
                            <Grid
                                item
                                lg={5}
                                sx={{ display: { xs: "none", lg: "block" } }}
                            >
                                <img
                                    src={FeaturesCustomFields}
                                    className="asset-placeholder"
                                    alt="Example of a Custom Field on the Virtual Terminal"
                                />
                            </Grid>
                            <Grid container item lg={5} direction="column">
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        className="features-h3"
                                        fontWeight="500"
                                    >
                                        Custom Fields
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography paragraph variant="body1">
                                        Custom fields are available in all
                                        reporting components and receipts to
                                        keep you and your customers informed.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography paragraph variant="body1">
                                        You have full control of the style and
                                        validation of each field, as well as
                                        where it’s available.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LearnMore href="features/custom-fields">
                                        Learn More
                                    </LearnMore>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                sx={{ display: { xs: "block", lg: "none" } }}
                            >
                                <img
                                    src={FeaturesCustomFields}
                                    className="asset-placeholder"
                                    alt="Example of a Custom Field on the Virtual Terminal"
                                />
                            </Grid>
                            <Grid item lg={2}></Grid>
                        </Grid>
                    </FadeInSection>
                </Grid>
                {/* Security */}
                <Grid container justifyContent="center" sx={{ mb: 5 }}>
                    <FadeInSection className="fade-in">
                        <Typography
                            variant="h2"
                            className="features-h2"
                            sx={{ my: 3 }}
                            align="center"
                        >
                            Security
                        </Typography>
                    </FadeInSection>
                    <Grid container direction="row">
                        <Grid item xs lg={2.5}></Grid>
                        <Grid item xs={10} lg={8}>
                            <Grid item>
                                <FadeInSection className="fade-in-from-left">
                                    <Typography
                                        variant="h3"
                                        className="features-h3"
                                        fontWeight={500}
                                        sx={{ mb: 3 }}
                                    >
                                        iQ Protect
                                    </Typography>
                                </FadeInSection>
                            </Grid>
                            <FadeInSection className="fade-in-from-left">
                                <Grid
                                    container
                                    item
                                    lg
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Grid item lg className="block-list">
                                        <Typography variant="body">
                                            iQ Protect is a suite of 35+ fraud
                                            protection rules that can be
                                            customized to build the best
                                            protection for your business.
                                        </Typography>
                                        <List sx={{ mt: 2 }}>
                                            <ListItem>
                                                included in the base iQ Pro
                                                package (no additional fees)
                                            </ListItem>
                                            <ListItem>
                                                automatically accept or decline
                                                transactions based on
                                                customizable triggers
                                            </ListItem>
                                            <ListItem>
                                                set automatic notifications for
                                                each trigger individually
                                            </ListItem>
                                        </List>
                                        <Grid item sx={{ mt: 1 }}>
                                            <LearnMore
                                                href="features/iq-protect"
                                                sx={{ mb: { xs: 4, lg: 0 } }}
                                                onClick={() =>
                                                    mixpanel.track(
                                                        "User taken to iQ Protect page"
                                                    )
                                                }
                                            >
                                                Learn More
                                            </LearnMore>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs lg={6}>
                                        <img
                                            src={FeaturesIQProtect}
                                            className="features-security"
                                            alt="Example of a fraud protection rule that can be activated through iQ Protect"
                                        />
                                    </Grid>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item xs lg></Grid>
                    </Grid>
                </Grid>
                <Footer />
            </>
        );
    }
};

Products.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    description: PropTypes.string,
    bgColor: PropTypes.any
};

export default Products;
