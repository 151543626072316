import * as React from "react";

const Icon5 = ({ ...props }) => (
    <svg
        width={77}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M38.316 76.629C17.47 76.824.148 59.6.002 38.609-.194 17.321 16.786.098 38.267 0 59.259-.097 76.58 16.98 76.63 38.217c.049 21.334-17.224 38.51-38.363 38.46l.049-.048Zm.049-73.35C18.938 3.426 3.525 18.693 3.329 38.02c-.147 19.475 15.61 35.231 34.938 35.231 19.328 0 35.084-15.805 35.035-35.084 0-19.23-15.756-34.938-34.937-34.938v.049Z"
            fill="#1B9AD2"
        />
        <path
            d="M38.806 56.664c-9.394 0-16.93-6.312-18.545-15.12-1.37-7.34 2.153-15.071 8.612-18.937 1.126-.685 2.104-.49 2.643.391.538.881.195 1.762-.881 2.447-4.894 3.083-7.389 7.585-7.242 13.31.147 7.193 5.53 13.162 12.673 14.337 8.368 1.321 16.344-4.795 17.127-13.163.587-6.263-1.909-11.059-7.145-14.484-.685-.44-1.174-.979-1.125-1.81.147-1.273 1.468-1.86 2.593-1.175a17.828 17.828 0 0 1 6.606 6.606c6.46 11.01-.146 25.25-12.722 27.353-1.028.147-2.055.196-2.593.245Z"
            fill="#1B9AD2"
        />
        <path
            d="M40.028 30.045v8.025c0 1.517-1.321 2.398-2.545 1.713-.685-.392-.88-1.028-.88-1.81V21.823c0-1.125.733-1.859 1.712-1.81.979 0 1.664.734 1.664 1.86v8.171h.049Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon5;
