import * as React from "react";

const Icon6 = (props) => (
    <svg
        width={88}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0 13.432c.608-1.774 1.622-3.142 3.549-3.598a5.414 5.414 0 0 1 1.267-.152h47.146c.76 0 1.368.152 1.571.963.203.71-.253 1.47-1.014 1.57H5.374c-2.13 0-2.84.71-2.84 2.89v6.64H53.433c.76.05 1.166.507 1.166 1.217 0 .71-.406 1.166-1.065 1.318-.304.05-.608 0-.912 0H2.535v8.465c0 .71 0 1.368-.811 1.723-.76.304-1.268-.152-1.724-.659V13.432ZM73.406 0c1.521.456 3.143.76 4.613 1.369 5.982 2.635 9.734 9.174 8.973 15.46-.861 7.045-5.88 12.418-12.673 13.584-8.568 1.47-16.73-4.46-17.845-13.078C55.41 8.972 61.138 1.42 69.503.152c.152 0 .253-.101.355-.152h3.548Zm-1.723 28.081c7.046 0 12.775-5.677 12.775-12.672 0-6.995-5.78-12.824-12.775-12.824-6.996 0-12.725 5.728-12.725 12.723 0 7.096 5.627 12.773 12.725 12.773ZM0 39.233c.406-.609.913-1.065 1.673-.76.76.253.862.912.862 1.672v34.67c0 2.383.659 3.042 2.99 3.042H33.763c.71.152 1.116.608 1.065 1.318 0 .659-.405 1.115-1.115 1.216h-.913c-9.023 0-17.996-.05-27.02 0-2.89 0-4.867-.963-5.779-3.801V39.233ZM61.644 69.19l4.512 4.51c1.672 1.673 1.672 3.092 0 4.765l-7.25 7.248c-1.723 1.724-3.092 1.724-4.866 0l-4.461-4.46c-.254.253-.507.405-.71.659-1.369 1.318-2.687 2.686-4.005 4.004-.811.811-1.723 1.267-2.89.913-1.216-.355-1.824-1.217-2.078-2.433a5288.967 5288.967 0 0 0-6.033-27.372c-.557-2.433 1.116-4.106 3.549-3.548 9.125 1.977 18.25 4.004 27.375 5.981 1.166.254 2.078.811 2.433 1.977.355 1.216 0 2.18-.912 3.041-1.369 1.318-2.687 2.687-4.005 4.005-.203.202-.355.405-.608.658l-.051.051Zm3.143 6.842s-.406-.406-.558-.608l-4.005-4.005c-1.52-1.52-1.47-2.99 0-4.51l4.005-4.005c.203-.203.355-.406.66-.71L36.245 55.91l6.286 28.639c.355-.355.558-.507.76-.71 1.37-1.369 2.738-2.788 4.157-4.156 1.268-1.268 2.84-1.268 4.157 0a67.127 67.127 0 0 1 2.687 2.686c.71.71 1.369 1.47 2.079 2.18l8.415-8.415v-.1Z"
            fill="#1B9AD2"
        />
        <path
            d="M21.241 55.047c0-7.806 6.387-14.192 14.194-14.243 6.388 0 12.066 4.308 13.739 10.492.05.254.152.507.152.76 0 .66-.304 1.116-.913 1.268-.659.152-1.216 0-1.47-.66-.203-.455-.304-.912-.456-1.368-1.825-5.22-6.844-8.363-12.522-7.806-5.12.507-9.378 4.816-10.088 10.087-.76 5.728 2.89 11.152 8.415 12.672.152 0 .254.102.406.152.913.305 1.267.862 1.065 1.622-.203.76-.913 1.166-1.724.862-1.42-.507-2.89-1.014-4.208-1.774-4.207-2.534-6.691-7.096-6.691-12.064h.101ZM70.364 54.794V35.178c.05-.76.608-1.318 1.267-1.318.66 0 1.217.507 1.319 1.318V73.65c0 .355 0 .659-.051 1.014-.102.659-.558 1.064-1.217 1.064-.71 0-1.115-.355-1.267-1.014-.05-.304-.05-.608-.05-.912V54.794Z"
            fill="#1B9AD2"
        />
        <path
            d="M26.818 55.757c0-4.46 2.382-7.705 5.982-8.87 3.498-1.116 7.35.1 9.53 3.04.609.812.609 1.623 0 2.08-.659.506-1.318.303-2.027-.558-1.673-2.079-4.157-2.89-6.59-2.18-2.383.71-4.056 2.737-4.31 5.322-.152 1.825.457 3.447 1.775 4.765.202.203.405.355.557.507.66.608.76 1.318.254 1.875-.457.558-1.268.608-1.977.05-2.18-1.722-3.194-4.003-3.296-6.082l.102.051ZM14.7 16.068h.61c.76.05 1.317.608 1.317 1.267 0 .66-.507 1.217-1.318 1.318-.456 0-.912.051-1.369 0-.71-.101-1.166-.506-1.216-1.267 0-.71.507-1.216 1.267-1.318H14.701ZM20.989 18.704c-.355 0-.66-.05-1.014-.152-.608-.203-.912-.66-.912-1.267 0-.609.405-1.065 1.014-1.166.506-.05 1.013-.102 1.52 0 .811.05 1.268.557 1.268 1.267 0 .71-.457 1.217-1.268 1.318h-.659.051ZM8.315 18.704c-.355-.05-.71-.05-1.065-.152-.608-.203-.862-.66-.811-1.318 0-.608.406-1.014.963-1.115.507-.05 1.014-.102 1.521-.05.811 0 1.318.506 1.318 1.266s-.507 1.267-1.318 1.369h-.608ZM67.272 19.008c0-1.216.456-1.774 1.166-1.825.76-.05 1.267.457 1.369 1.369.152 1.216 1.115 1.926 2.281 1.673.963-.203 1.572-1.268 1.318-2.281-.203-.76-.862-1.217-1.774-1.318-2.738-.152-4.563-2.18-4.36-4.917.152-1.571.963-2.788 2.383-3.447.557-.253.76-.557.659-1.166v-.912c.101-.71.659-1.216 1.267-1.216.66 0 1.318.506 1.217 1.216-.102 1.217.253 1.926 1.368 2.484 1.116.608 1.623 1.774 1.775 3.041.101.862-.304 1.571-1.014 1.673-.811.152-1.369-.304-1.572-1.268-.202-1.115-.76-1.672-1.622-1.824-.76-.102-1.52.355-1.875 1.014-.507 1.064.05 2.331 1.267 2.585.507.101 1.064.101 1.571.253a4.507 4.507 0 0 1 3.245 3.852c.203 1.724-.811 3.549-2.434 4.309-.557.253-.76.557-.71 1.166v.912c-.05.71-.456 1.166-1.165 1.217-.71.05-1.42-.406-1.318-1.116.152-1.368-.456-2.078-1.572-2.737-1.064-.659-1.52-1.825-1.571-2.788l.101.051Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon6;
