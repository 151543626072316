import React from "react";
import PropTypes from "prop-types";
import "./Landing.scss";
import "../Components/FadeInSection/FadeInSection.scss";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FadeInSection } from "../Components/FadeInSection/FadeInSection";
import RequestDemoButton from "../Components/RequestDemoButton/RequestDemoButton";
import demo from "../Media/hero-vt.png";
import customer from "../Media/lp-customer.png";
import LPIcon1 from "../Media/lp-icon1.jsx";
import LPIcon2 from "../Media/lp-icon2.jsx";
import LPIcon3 from "../Media/lp-icon3.jsx";
import LPIcon4 from "../Media/lp-icon4.jsx";
import LPIcon5 from "../Media/lp-icon5.jsx";
import bg from "../Media/logo-bg.svg";
import Navbar from "../NavMenu/Navbar";
import Footer from "../Components/Footer/Footer";
import mixpanel from "mixpanel-browser";

export const Paragraph = styled(Typography)`
    @media (min-width: 800px) {
        width: 85%;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
`;

const Landing = () => {
    return (
        <>
            <Navbar />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: "primary.main",
                    px: { xs: 1, sm: 5, md: 13 },
                    mt: 1,
                    overflowX: "hidden",
                    overflowY: "hidden"
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}
                >
                    <Grid item lg sx={{ animation: "left-fade-in 3s" }}>
                        <Typography variant="h1" className="landing-title">
                            Accept Payments.{"   "}
                            <strong className="titleBlue">
                                Quickly. Simply. Securely.
                            </strong>
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                mt: 3,
                                mb: 0
                            }}
                            paragraph={true}
                        >
                            Our iQ Pro Virtual Terminal is ideal for accepting
                            payments over the phone or for B2B organizations
                            seeking Level II and Level III rates. Additional
                            features include recurring billing, emailing
                            invoices, and more.
                        </Typography>
                    </Grid>
                    <Grid item lg>
                        <img
                            src={demo}
                            alt="Virtual Terminal Demo"
                            className="heroImage"
                        />
                    </Grid>
                </Grid>
                <Grid item sx={{ my: 4 }}>
                    <RequestDemoButton />
                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: "secondary.light",
                    px: { xs: 3, md: 10 },
                    py: { xs: 3, md: 10 }
                }}
            >
                <Grid item>
                    <Typography variant="h3">Better is Better</Typography>
                </Grid>
                <FadeInSection className="fade-in-from-bottom">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={3}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            <LPIcon1 className="lpIcons" />
                        </Grid>
                        <Grid item xs md lg>
                            <Box
                                sx={{
                                    py: 1
                                }}
                            >
                                <LPIcon1 className="mobile-lpIcons" />
                                <Typography variant="h2" className="landing-h2">
                                    <Link
                                        color="inherit"
                                        href="features/customer-vault"
                                        className="landing-links"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Customer Vault page"
                                            )
                                        }
                                    >
                                        Securely store customer data
                                    </Link>
                                </Typography>
                            </Box>
                            <Grid item>
                                <Paragraph variant="body2" paragraph={true}>
                                    Collecting customer data should be both
                                    <strong> simple and secure</strong>. iQ Pro
                                    manages any customer data you need to run
                                    your business such as their preferred
                                    payment details and contact information.
                                    Everything is stored securely with the click
                                    of a button through advanced tokenization.
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                </FadeInSection>

                <FadeInSection className="fade-in-from-bottom">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs md lg={8}>
                            <Box
                                sx={{
                                    py: 1
                                }}
                            >
                                <LPIcon2 className="mobile-lpIcons" />
                                <Typography variant="h2" className="landing-h2">
                                    <Link
                                        color="inherit"
                                        href="features/virtual-terminal"
                                        className="landing-links"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Virtual Terminal page"
                                            )
                                        }
                                    >
                                        Level II and Level III Data entered
                                        automatically
                                    </Link>
                                </Typography>
                            </Box>
                            <Paragraph variant="body2" paragraph={true}>
                                iQ Pro is designed to help businesses
                                <strong>
                                    {" "}
                                    save money on their credit card processing.
                                </strong>{" "}
                                If you are accepting Business to Business (B2B)
                                transactions, your organization can qualify for
                                the lowest possible rates on card not present
                                (CNP) transactions. On most gateways, this would
                                require manually submitting between 5 and 19
                                additional pieces of data with each transaction-
                                if those items are available to submit at all!
                                iQ Pro <strong>automatically</strong> captures
                                and submits this information with each
                                transaction, saving B2B organizations valuable
                                time and money.
                            </Paragraph>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            <LPIcon2 className="lpIcons" />
                        </Grid>
                    </Grid>
                </FadeInSection>

                <FadeInSection className="fade-in-from-bottom">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={3}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            <LPIcon3 className="lpIcons" />
                        </Grid>
                        <Grid item xs md lg>
                            <Box
                                sx={{
                                    py: 1
                                }}
                            >
                                <LPIcon3 className="mobile-lpIcons" />
                                <Typography variant="h2" className="landing-h2">
                                    <Link
                                        color="inherit"
                                        href="features/virtual-terminal"
                                        className="landing-links"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Virtual Terminal page"
                                            )
                                        }
                                    >
                                        A new way to process B2B and CNP
                                        transactions
                                    </Link>
                                </Typography>
                            </Box>
                            <Paragraph variant="body2" paragraph={true}>
                                Process payments quickly with our modern and
                                user-friendly Virtual Terminal. We know that
                                every business is unique- customize your Virtual
                                Terminal to only include the fields you need to{" "}
                                <strong>streamline your payments</strong>.
                                Collecting specialized data? Create exactly what
                                you need through flexible custom fields.
                            </Paragraph>
                        </Grid>
                    </Grid>
                </FadeInSection>
                <FadeInSection className="fade-in-from-bottom">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs md lg={8}>
                            <Box
                                sx={{
                                    py: 1
                                }}
                            >
                                <LPIcon4 className="mobile-lpIcons" />
                                <Typography variant="h2" className="landing-h2">
                                    <Link
                                        color="inherit"
                                        href="features/shopping-cart"
                                        className="landing-links"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Shopping Cart page"
                                            )
                                        }
                                    >
                                        E-commerce
                                    </Link>
                                </Typography>
                            </Box>
                            <Paragraph variant="body2" paragraph={true}>
                                Growing your business into accepting E-Commerce
                                sales has never been easier. iQ Pro offers
                                out-of-the-box Shopping Cart solutions that can
                                be built in minutes- no IT, web developer, or
                                even hosted site needed to get started.
                                Maintaining your Card-Not-Present and E-Commerce
                                transactions in one system consolidates
                                reporting, and makes your E-Commerce goals
                                <strong> attainable</strong>.
                            </Paragraph>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            <LPIcon4 className="lpIcons" />
                        </Grid>
                    </Grid>
                </FadeInSection>
                <FadeInSection className="fade-in-from-bottom">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={3}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "block" } }}
                        >
                            <LPIcon5 className="lpIcons" />
                        </Grid>
                        <Grid item xs md lg>
                            <Box
                                sx={{
                                    py: 1
                                }}
                            >
                                <LPIcon5 className="mobile-lpIcons" />
                                <Typography variant="h2" className="landing-h2">
                                    <Link
                                        color="inherit"
                                        href="features/virtual-terminal"
                                        className="landing-links"
                                        onClick={() =>
                                            mixpanel.track(
                                                "User taken to Virtual Terminal page"
                                            )
                                        }
                                    >
                                        Your computer is the hardware
                                    </Link>
                                </Typography>
                            </Box>
                            <Paragraph variant="body2" paragraph={true}>
                                iQ Pro is ready to process when and where you
                                are! This Cloud-Based payment gateway can be{" "}
                                <strong>accessed from anywhere </strong>
                                using a computer, tablet, or smartphone. This
                                eliminates the need to download or maintain
                                softwares or haul even additional equipment.
                            </Paragraph>
                        </Grid>
                    </Grid>
                </FadeInSection>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: "primary.main",
                    backgroundImage: `url(${bg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                    backgroundSize: { xs: "50%", sm: "35%", md: "28%" },
                    p: { xs: 3 }
                }}
            >
                <Grid
                    item
                    md={4}
                    sx={{ display: { xs: "none", md: "inherit" } }}
                >
                    <FadeInSection className="fade-in-from-left">
                        <img
                            src={customer}
                            className="customerDemo"
                            alt="Customer Demo"
                        />
                    </FadeInSection>
                </Grid>
                <Grid item xs={12} md>
                    <FadeInSection className="fade-in-from-right">
                        <Grid container direction="column" spacing={8}>
                            <Grid item>
                                <Paragraph
                                    variant="h5"
                                    paragraph={true}
                                    sx={{ width: { xs: "auto", md: "45%" } }}
                                >
                                    In a world of unprecedented consumer choice,
                                    you know what makes your business special.
                                    Your payment gateway should too. iQ Pro was
                                    built to be remarkably customizable to
                                    streamline and enhance individual business
                                    workflows.
                                </Paragraph>
                            </Grid>
                            <Grid item>
                                <RequestDemoButton />
                            </Grid>
                        </Grid>
                    </FadeInSection>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

Landing.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string
};

export default Landing;
