import React from "react";
import "../AccountUpdater/AccountUpdater.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";
import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import { ListText } from "../Reporting/Reporting";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import auMockup from "../AccountUpdater/assets/au-mockup.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import IconCheck from "./assets/icon-check";

export default function AccountUpdater() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="au-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 5 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Account Updater
                                        </strong>{" "}
                                        - a new automatic credit card update
                                        feature now available on iQ Pro!
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ px: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Account Updater</strong> - a
                    new automatic credit card update feature now available on iQ
                    Pro!
                </BannerTitle>
            </FadeInSection>
            {/* CONTENT */}
            <Grid
                container
                direction={{ md: "row" }}
                alignItems={{ md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item lg={6}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: {
                                    xs: 3,
                                    md: 0,
                                    fontSize: "1.25rem !important"
                                }
                            }}
                        >
                            The Account Updater feature reviews credit card
                            payment methods within an iQ Pro customer vault,
                            looks for expired card information, and
                            automatically contacts the card issuer and updates
                            the customer&apos;s card account details within the
                            vault.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FadeInSection className="fade-in-from-right">
                        <img
                            src={auMockup}
                            className="au-mockup"
                            alt="Account Updater Mockup"
                        ></img>
                    </FadeInSection>
                </Grid>

                <FadeInSection className="fade-in-from-bottom">
                    <Grid item md={12} sx={{ mt: 5 }}>
                        <Typography
                            variant="h2"
                            fontWeight={450}
                            className="products-title"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            Account Updater
                        </Typography>
                    </Grid>
                </FadeInSection>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 } }}
            >
                <Grid item lg={1}></Grid>
                <Grid item lg={10}>
                    <List>
                        <FadeInSection className="fade-in-from-left">
                            <Grid>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon1 className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        <Typography
                                            variant="h2"
                                            className="list-title"
                                        >
                                            33% of all payment credentials
                                            change each year!
                                        </Typography>
                                    </ListText>
                                </ListItem>
                                <Grid container direction="row">
                                    <Grid item md={1}></Grid>
                                    <Grid
                                        item
                                        md={11}
                                        sx={{ pl: { xs: 3, md: 0 } }}
                                    >
                                        <Typography
                                            sx={{
                                                width: { xs: "100%", md: "80%" }
                                            }}
                                        >
                                            Automatically update your customers’
                                            credit card account details avoiding
                                            disruption to revenue streams and
                                            additional expense of managing
                                            failed payments.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FadeInSection>
                        <FadeInSection className="fade-in-from-left">
                            <ListItem>
                                <ListItemIcon>
                                    <Icon2 className="listIcon" />
                                </ListItemIcon>
                                <ListText>
                                    <Typography
                                        variant="h2"
                                        className="list-title"
                                    >
                                        Pay-Per-Update
                                    </Typography>
                                </ListText>
                            </ListItem>
                        </FadeInSection>
                        <Grid container direction="row">
                            <Grid item md={1}></Grid>
                            <Grid item md={11} sx={{ pl: { xs: 3, md: 0 } }}>
                                <FadeInSection className="fade-in-from-left">
                                    <Typography
                                        sx={{
                                            width: { xs: "100%", md: "80%" }
                                        }}
                                    >
                                        Unlike some account updates, you only
                                        pay for the payment data that gets
                                        updated. Big Picture: what you save in
                                        admin costs, efficiency and protection
                                        of your revenue will far outweigh your
                                        Account Updater transactional fees.
                                    </Typography>
                                </FadeInSection>
                                <FadeInSection className="fade-in-from-left">
                                    <ListItem>
                                        <ListItemIcon>
                                            <IconCheck className="listIcon" />
                                        </ListItemIcon>
                                        <ListText>
                                            Avoid lost sales revenue
                                        </ListText>
                                    </ListItem>
                                </FadeInSection>
                                <FadeInSection className="fade-in-from-left">
                                    <ListItem>
                                        <ListItemIcon>
                                            <IconCheck className="listIcon" />
                                        </ListItemIcon>
                                        <ListText>
                                            Keep your customers happy
                                        </ListText>
                                    </ListItem>
                                </FadeInSection>
                                <FadeInSection className="fade-in-from-left">
                                    <ListItem>
                                        <ListItemIcon>
                                            <IconCheck className="listIcon" />
                                        </ListItemIcon>
                                        <ListText>
                                            Reduce PCI scope exposure
                                        </ListText>
                                    </ListItem>
                                </FadeInSection>
                                <FadeInSection className="fade-in-from-left">
                                    <ListItem>
                                        <ListItemIcon>
                                            <IconCheck className="listIcon" />
                                        </ListItemIcon>
                                        <ListText>
                                            Strengthen merchant relationships
                                        </ListText>
                                    </ListItem>
                                </FadeInSection>
                                <FadeInSection className="fade-in-from-left">
                                    <ListItem>
                                        <ListItemIcon>
                                            <IconCheck className="listIcon" />
                                        </ListItemIcon>
                                        <ListText>Cut admin expenses</ListText>
                                    </ListItem>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                    </List>
                </Grid>
                <Grid item lg={1}></Grid>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
