import * as React from "react";

const Icon4 = ({ ...props }) => (
    <svg
        width={77}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M76.527 52.753c-.363 1.29-.892 2.446-1.95 3.339-1.09.892-2.347 1.322-3.768 1.322H43.937c-.893 0-1.389-.397-1.389-1.09 0-.728.496-1.158 1.422-1.158 8.825 0 17.65-.033 26.508 0 2.347 0 3.9-1.586 3.868-3.933-.033-15.04-.033-30.079 0-45.118 0-2.248-1.422-3.9-3.9-3.9-21.287.066-42.54.033-63.827 0-2.347 0-3.9 1.586-3.867 3.933.033 15.04.033 30.079 0 45.118 0 2.248 1.454 3.9 3.9 3.9 10.379-.066 20.79 0 31.17 0h.66c.695.066 1.124.496 1.091 1.157 0 .628-.396 1.025-1.09 1.09H6.586c-2.909 0-5.024-1.42-5.784-3.9-.166-.495-.232-1.057-.232-1.586V5.553C.57 2.644 2.851.264 5.76.099h.892C27.872 0 49.16 0 70.445 0c2.281 0 4.099.76 5.322 2.743.364.595.529 1.256.793 1.918v48.092h-.033Z"
            fill="#1B9AD2"
        />
        <path
            d="M7.25 6.776v43.829h62.57V25.517c.067-.628.43-.991 1.058-.991.628 0 .992.33 1.124.958.033.199 0 .397 0 .595v24.559c0 1.62-.661 2.28-2.314 2.28H7.218c-1.62 0-2.281-.66-2.281-2.28V6.776c0-1.587.66-2.28 2.214-2.28H13.3c.893 0 1.388.396 1.388 1.09 0 .727-.495 1.157-1.421 1.157H7.911c-.231 0-.495 0-.76.033h.1Z"
            fill="#1B9AD2"
        />
        <path
            d="M69.81 6.71H19.007c-.728-.033-1.19-.529-1.19-1.157s.462-1.025 1.19-1.058h51.166c1.157.033 1.917.76 1.917 1.917V20.23c0 .793-.463 1.289-1.124 1.289-.66 0-1.09-.496-1.09-1.29V6.777l-.066-.066Z"
            fill="#1B9AD2"
        />
        <path
            d="M23.504 15.006V25.32c0 .297 0 .595-.066.892-.132.562-.529.86-1.09.827-.53 0-.926-.298-1.058-.86-.066-.231-.066-.496-.066-.727V11.37c0-.595.165-1.124.826-1.355.661-.198 1.058.132 1.421.661 1.355 1.95 2.71 3.9 4.033 5.85 1.19 1.72 2.38 3.471 3.57 5.19.132.165.264.33.528.496v-.76c0-3.405-.066-6.843-.066-10.247 0-1.025.827-1.62 1.587-1.157.297.198.595.661.628.992.1 4.76.132 9.552.165 14.312 0 .66-.264 1.223-.958 1.421-.694.232-1.19-.132-1.554-.66a2873.523 2873.523 0 0 0-7.239-10.479l-.495-.694-.166.066ZM38.688 18.444c0-4.694 3.934-8.594 8.627-8.561 4.694.033 8.528 3.9 8.528 8.56 0 4.694-3.9 8.595-8.594 8.595-4.693 0-8.56-3.9-8.56-8.594Zm8.66-6.28c-3.47-.066-6.313 2.71-6.412 6.214-.099 3.437 2.71 6.313 6.247 6.412 3.438.1 6.313-2.71 6.38-6.148.066-3.57-2.645-6.412-6.215-6.478ZM36.2 32.657v5.156h4.397c.66 0 1.19.198 1.322.925.165.76-.364 1.356-1.256 1.356h-4.496v5.156h4.562c.298 0 .595 0 .892.1.53.131.76.495.794 1.024 0 .529-.232.892-.76 1.057-.232.067-.496.1-.728.1h-5.486c-1.124 0-1.488-.397-1.488-1.52V31.83c0-1.058.397-1.455 1.421-1.455h5.653c.925 0 1.42.396 1.42 1.157 0 .727-.462 1.123-1.42 1.123H36.2ZM49.518 32.657v5.156h4.628c.694.066 1.157.496 1.157 1.124 0 .628-.43 1.09-1.124 1.124-.562.033-1.124 0-1.72 0h-2.908v5.156h4.529c.297 0 .595 0 .892.1.496.132.76.495.76 1.024s-.264.892-.76 1.058c-.231.066-.496.099-.727.099h-5.487c-1.124 0-1.488-.364-1.488-1.52v-14.18c0-1.091.364-1.455 1.488-1.455 1.917 0 3.801-.033 5.718 0 .397 0 .926.364 1.157.694.165.232.066.86-.132 1.058-.298.297-.826.463-1.223.463-1.554.066-3.107 0-4.727 0l-.033.099ZM23.543 32.624v5.024h4.463c.628.099 1.057.43 1.057 1.124 0 .694-.396 1.057-1.057 1.123-.662.066-1.323 0-2.017 0H23.51v6.247c0 .827-.462 1.356-1.157 1.356-.66 0-1.09-.53-1.09-1.322V31.698c0-.958.396-1.355 1.355-1.355h5.553c.826 0 1.388.463 1.355 1.124 0 .628-.496 1.09-1.322 1.124h-4.727l.066.033Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon4;
