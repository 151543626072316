import * as React from "react";

const CustomerIcon = (props) => (
    <svg
        width={52}
        height={35}
        viewBox="0 0 65 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M41.7186 33.263C40.6941 32.8593 37.5106 31.6208 34.7124 30.5352C35.9877 29.2936 36.7828 27.5596 36.7828 25.6391C36.7828 21.8654 33.7247 18.8073 29.951 18.8073C26.1773 18.8073 23.1192 21.8654 23.1192 25.6391C23.1192 27.5596 23.9143 29.2936 25.1895 30.5352C22.3913 31.6208 19.2079 32.8593 18.1834 33.263C16.2293 34.0306 15.5137 34.7125 15.5137 36.7278V47.3884H44.3913V36.7278C44.3913 34.7125 43.6758 34.0336 41.7216 33.263H41.7186Z"
            fill="#A3D3FE"
            className="iconSwing"
        />
        <path
            d="M44.9419 32.6942C44.0153 32.3303 41.3303 31.2844 38.7584 30.2844C39.7798 29.0092 40.3945 27.3945 40.3945 25.6391C40.3945 21.5352 37.055 18.1957 32.9511 18.1957C28.8471 18.1957 25.5076 21.5352 25.5076 25.6391C25.5076 27.3945 26.1193 29.0122 27.1437 30.2844C24.5719 31.2844 21.8868 32.3303 20.9602 32.6942C18.9327 33.4923 17.9021 34.3333 17.9021 36.7278V47.3884C17.9021 47.7248 18.1774 48 18.5138 48H47.3914C47.7278 48 48.0031 47.7248 48.0031 47.3884V36.7278C48.0031 34.3364 46.9725 33.4923 44.945 32.6942H44.9419ZM32.9511 19.419C36.3823 19.419 39.1712 22.2079 39.1712 25.6391C39.1712 29.0703 36.3823 31.8593 32.9511 31.8593C29.5199 31.8593 26.7309 29.0703 26.7309 25.6391C26.7309 22.2079 29.5199 19.419 32.9511 19.419ZM46.7798 46.7767H40.792V40.5841C40.792 40.2477 40.5168 39.9725 40.1804 39.9725C39.844 39.9725 39.5688 40.2477 39.5688 40.5841V46.7767H26.3394V40.5841C26.3394 40.2477 26.0642 39.9725 25.7278 39.9725C25.3914 39.9725 25.1162 40.2477 25.1162 40.5841V46.7767H19.1284V36.7278C19.1284 35.104 19.5566 34.5627 21.4128 33.8349C22.3945 33.4495 25.3578 32.2966 28.0642 31.2446C29.3731 32.3884 31.0856 33.0856 32.9572 33.0856C34.8287 33.0856 36.5413 32.3884 37.8501 31.2446C40.5566 32.2966 43.5199 33.4495 44.5015 33.8349C46.3578 34.5657 46.7859 35.107 46.7859 36.7278V46.7767H46.7798Z"
            fill="#4C5B8C"
            className="iconSwing"
        />
        <path
            d="M8.32722 11.7982C7.99083 11.7982 7.7156 12.0734 7.7156 12.4098C7.7156 12.7462 7.99083 13.0214 8.32722 13.0214H12.4067C12.7431 13.0214 13.0184 12.7462 13.0184 12.4098C13.0184 12.0734 12.7431 11.7982 12.4067 11.7982H8.32722Z"
            fill="#4C5B8C"
        />
        <path
            d="M14.318 32.3945H4.50765C2.69725 32.3945 1.22324 30.9205 1.22324 29.1101V27.0581H21.2263C21.5627 27.0581 21.8379 26.7829 21.8379 26.4465C21.8379 26.1101 21.5627 25.8349 21.2263 25.8349H1.22324V20.2691H22.8349C23.1713 20.2691 23.4465 19.9939 23.4465 19.6575C23.4465 19.3211 23.1713 19.0459 22.8349 19.0459H1.22324V4.50765C1.22324 2.69725 2.69725 1.22324 4.50765 1.22324H38.7982C40.6086 1.22324 42.0826 2.69725 42.0826 4.50765V19.0673C42.0826 19.4037 42.3578 19.6789 42.6942 19.6789C43.0306 19.6789 43.3058 19.4037 43.3058 19.0673V4.50765C43.3058 2.02141 41.2844 0 38.7982 0H4.50765C2.02141 0 0 2.02141 0 4.50765V29.1131C0 31.5994 2.02141 33.6208 4.50765 33.6208H14.318C14.6544 33.6208 14.9297 33.3456 14.9297 33.0092C14.9297 32.6728 14.6544 32.3976 14.318 32.3976V32.3945Z"
            fill="#4C5B8C"
        />
        <path
            d="M14.318 32.3945H4.50765C2.69725 32.3945 1.22324 30.9205 1.22324 29.1101V27.0581H21.2263C21.5627 27.0581 21.8379 26.7829 21.8379 26.4465C21.8379 26.1101 21.5627 25.8349 21.2263 25.8349H1.22324V20.2691H22.8349C23.1713 20.2691 23.4465 19.9939 23.4465 19.6575C23.4465 19.3211 23.1713 19.0459 22.8349 19.0459H12.0291H1.22324V4.50765C1.22324 2.69725 2.69725 1.22324 4.50765 1.22324H38.7982C40.6086 1.22324 42.0826 2.69725 42.0826 4.50765V19.0673C42.0826 19.4037 42.3578 19.6789 42.6942 19.6789C43.0306 19.6789 43.3058 19.4037 43.3058 19.0673V4.50765C43.3058 2.02141 41.2844 0 38.7982 0H4.50765C2.02141 0 0 2.02141 0 4.50765V29.1131C0 31.5994 2.02141 33.6208 4.50765 33.6208H14.318C14.6544 33.6208 14.9297 33.3456 14.9297 33.0092C14.9297 32.6728 14.6544 32.3976 14.318 32.3976V32.3945Z"
            fill="#4C5B8C"
        />
        <path
            d="M14.318 32.3945H4.50765C2.69725 32.3945 1.22324 30.9205 1.22324 29.1101V27.0581H21.2263C21.5627 27.0581 21.8379 26.7829 21.8379 26.4465C21.8379 26.1101 21.5627 25.8349 21.2263 25.8349H1.22324V20.2691H22.8349C23.1713 20.2691 23.4465 19.9939 23.4465 19.6575C23.4465 19.3211 23.1713 19.0459 22.8349 19.0459H1.22324V4.50765C1.22324 2.69725 2.69725 1.22324 4.50765 1.22324H38.7982C40.6086 1.22324 42.0826 2.69725 42.0826 4.50765V19.0673C42.0826 19.4037 42.3578 19.6789 42.6942 19.6789C43.0306 19.6789 43.3058 19.4037 43.3058 19.0673V4.50765C43.3058 2.02141 41.2844 0 38.7982 0H4.50765C2.02141 0 0 2.02141 0 4.50765V29.1131C0 31.5994 2.02141 33.6208 4.50765 33.6208H14.318C14.6544 33.6208 14.9297 33.3456 14.9297 33.0092C14.9297 32.6728 14.6544 32.3976 14.318 32.3976V32.3945Z"
            fill="#4C5B8C"
        />
    </svg>
);

export default CustomerIcon;
