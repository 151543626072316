import * as React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import "../base.scss";
import axios from "axios";
import "./CTAForm.scss";
import { useForm } from "react-hook-form";
import { FadeInSection } from "../Components/FadeInSection/FadeInSection";
import "../Components/FadeInSection/FadeInSection.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
// import logo from "../Media/logo.png";
// import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import bg from "../Media/logo-bg.svg";
import mixpanel from "mixpanel-browser";

export const StyledTextField = styled(TextField)`
    margin: 0.7em;
    width: 90%;
    @media (max-width: 900px) {
        margin: 1em;
    }
`;

export const StyledContainedButton = styled(Button)`
    color: white;
    background-color: #189ad0;
`;

const CTASubtitle = styled(Typography)`
    font-size: 24px !important;
    padding: 0 1em;
    letter-spacing: 1.3px;
`;

const CTATitle = styled(Typography)`
    font-size: 60px;
    margin: 0 1em;
    @media (max-width: 900px) {
        font-size: 50px;
    }
`;

const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
};

export default function CTAForm() {
    // react hook form
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [open, setOpen] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const handleSnackbarClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const [inputValue, setInputValue] = useState("");
    const handleInput = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setInputValue(formattedPhoneNumber);
    };

    const leadOptions = [
        {
            id: "New Merchant",
            description:
                "I’m new to BASYS – I want to learn about accepting card cards with iQ Pro"
        },
        {
            id: "Current Merchant",
            description:
                "I’m a current BASYS customer - I don’t use iQ Pro – I want to learn more"
        },
        {
            id: "Integrations Partner",
            description:
                "I want to learn about integrating iQ Pro with my company’s software"
        }
    ];

    const handleClose = () => {
        setOpen(false);
    };
    let date = new Date();
    date.toISOString().split("T")[0];
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const userDate = date.toISOString().split("T")[0];

    const onSubmit = (data, e) => {
        setDisabled(true);
        data.date = userDate;
        e.preventDefault();
        mixpanel.track("Contact form submitted", {
            "Lead Type": data.leadType,
            "First Name": data.firstName,
            "Last Name": data.lastName,
            "Company Name": data.companyName,
            // prettier-ignore
            "Email": data.email,
            "Phone Number": data.phoneNumber,
            // prettier-ignore
            "Date": userDate
        });
        axios
            .post(
                `${process.env.REACT_APP_EMAIL_FUNCTION_URL}/api/sendleademail`,
                data
            )
            .then((res) => {
                if (res.status === 200) {
                    setOpen(true);
                }
            })
            .catch(function (error) {
                setSnackbarMessage(error.response.data);
                setSnackbarOpen(true);
                console.log(error);
            });
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
        <Grid>
            <AppBar
                position="static"
                sx={{
                    color: "black",
                    boxShadow: "none",
                    pl: { xs: 0, lg: 4 }
                }}
            >
                <Toolbar>
                    <Link to="/">
                        <div alt="iQ Pro Logo" className="cta-logo"></div>
                    </Link>
                </Toolbar>
            </AppBar>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: "13px"
                    }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Great!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Thank you for reaching out. Your request will be sent to
                        one of our representatives.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button href="/">Return home</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid className="cta-bg">
                    <FadeInSection className="fade-in-from-bottom">
                        <CTASubtitle
                            variant="h4"
                            className="subtitle"
                            sx={{ m: 2, display: { xs: "block", md: "none" } }}
                        >
                            Ready to learn more about how iQ Pro can help your
                            business? Provide some basic information below and
                            we’ll be in touch soon.
                        </CTASubtitle>
                    </FadeInSection>
                    <Grid
                        className="cta-content-bg"
                        sx={{
                            backgroundImage: `url(${bg})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: {
                                xs: "right bottom",
                                md: "right"
                            },
                            backgroundSize: { xs: "85%", sm: "45%", md: "35%" }
                        }}
                    >
                        <Grid>
                            <FadeInSection className="fade-in">
                                <CTASubtitle
                                    variant="h4"
                                    className="subtitle"
                                    sx={{
                                        display: { xs: "none", md: "block" }
                                    }}
                                >
                                    Ready to learn more about how iQ Pro can
                                    help your business? Provide some basic
                                    information below and we’ll be in touch
                                    soon.
                                </CTASubtitle>
                            </FadeInSection>
                        </Grid>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} md={4.5}>
                                <FadeInSection className="fade-in-from-left">
                                    <Grid
                                        item
                                        className="cta-form"
                                        sx={{ m: 2.5 }}
                                    >
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Grid item>
                                                <FormControl fullWidth>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "13px",
                                                            pl: 1.5
                                                        }}
                                                    >
                                                        I am a(n)...
                                                    </Typography>
                                                    <StyledTextField
                                                        select
                                                        multiline
                                                        maxRows={4}
                                                        size="small"
                                                        id="Lead Type"
                                                        name={"Lead Type"}
                                                        label="Select an option"
                                                        defaultValue={""}
                                                        error={
                                                            errors?.leadType
                                                                ?.message !==
                                                            undefined
                                                        }
                                                        {...register(
                                                            "leadType",
                                                            {
                                                                required:
                                                                    "Please select option"
                                                            }
                                                        )}
                                                        helperText={
                                                            errors &&
                                                            errors?.leadType
                                                                ?.message
                                                        }
                                                        sx={{ width: "200px" }}
                                                    >
                                                        {leadOptions.map(
                                                            (option) => (
                                                                <MenuItem
                                                                    key={
                                                                        option.id
                                                                    }
                                                                    value={
                                                                        option.id
                                                                    }
                                                                >
                                                                    <strong>
                                                                        {
                                                                            option.id
                                                                        }
                                                                        {"  "}
                                                                    </strong>
                                                                    -{"  "}
                                                                    {
                                                                        option.description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </StyledTextField>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <StyledTextField
                                                    size="small"
                                                    id="First Name"
                                                    name={"First Name"}
                                                    label="First Name"
                                                    error={
                                                        errors?.firstName
                                                            ?.message !==
                                                        undefined
                                                    }
                                                    {...register("firstName", {
                                                        required:
                                                            "Please add first name",
                                                        maxLength: 100
                                                    })}
                                                    helperText={
                                                        errors &&
                                                        errors?.firstName
                                                            ?.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <StyledTextField
                                                    size="small"
                                                    id="Last Name"
                                                    name={"Last Name"}
                                                    label="Last Name"
                                                    error={
                                                        errors?.lastName
                                                            ?.message !==
                                                        undefined
                                                    }
                                                    {...register("lastName", {
                                                        required:
                                                            "Please add last name",
                                                        maxLength: 100
                                                    })}
                                                    helperText={
                                                        errors &&
                                                        errors?.lastName
                                                            ?.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <StyledTextField
                                                    size="small"
                                                    id="Company Name"
                                                    name={"Company Name"}
                                                    label="Company Name"
                                                    error={
                                                        errors?.companyName
                                                            ?.message !==
                                                        undefined
                                                    }
                                                    {...register(
                                                        "companyName",
                                                        {
                                                            required:
                                                                "Please add company name",
                                                            maxLength: 100
                                                        }
                                                    )}
                                                    helperText={
                                                        errors &&
                                                        errors?.companyName
                                                            ?.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <StyledTextField
                                                    size="small"
                                                    id="Email"
                                                    name={"Email"}
                                                    label="Email"
                                                    error={
                                                        errors?.email
                                                            ?.message !==
                                                        undefined
                                                    }
                                                    {...register("email", {
                                                        required:
                                                            "Please add email address",
                                                        pattern: {
                                                            value: /^(([^<>()[\][\].,;:\s@"]+(\.[^<>()[\][\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message:
                                                                "Invalid email address"
                                                        }
                                                    })}
                                                    helperText={
                                                        errors &&
                                                        errors?.email?.message
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <StyledTextField
                                                    size="small"
                                                    value={inputValue}
                                                    id="Phone Number"
                                                    name={"Phone Number"}
                                                    label="Phone Number"
                                                    error={
                                                        errors?.phoneNumber
                                                            ?.message !==
                                                        undefined
                                                    }
                                                    {...register(
                                                        "phoneNumber",
                                                        {
                                                            required:
                                                                "Please add phone number",
                                                            minLength: {
                                                                value: 10,
                                                                message:
                                                                    "Invalid phone number"
                                                            }
                                                        }
                                                    )}
                                                    helperText={
                                                        errors &&
                                                        errors?.phoneNumber
                                                            ?.message
                                                    }
                                                    onChange={(e) => {
                                                        handleInput(e);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid container sx={{ mt: 2 }}>
                                                <Button
                                                    href="/"
                                                    size="large"
                                                    variant="outlined"
                                                    sx={{ m: 1 }}
                                                    onClick={() =>
                                                        mixpanel.track(
                                                            "Contact form cancelled"
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <StyledContainedButton
                                                    variant="contained"
                                                    type="submit"
                                                    value="Submit"
                                                    sx={{ m: 1 }}
                                                    disabled={disabled}
                                                >
                                                    Submit
                                                </StyledContainedButton>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </FadeInSection>
                            </Grid>
                            <Grid item md sx={{ p: 2 }}>
                                <FadeInSection className="fade-in-from-right">
                                    <CTATitle
                                        sx={{
                                            color: {
                                                xs: "white",
                                                md: "#189ad0"
                                            }
                                        }}
                                    >
                                        iQ Pro, the solution that{" "}
                                        <strong>your</strong> business needs
                                    </CTATitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
