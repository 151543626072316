import * as React from "react";

const Icon5 = (props) => (
    <svg
        width={89}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M87.715 15.48H1.285A1.28 1.28 0 0 1 0 14.199V1.283A1.28 1.28 0 0 1 1.285 0h86.43A1.28 1.28 0 0 1 89 1.283v12.915a1.28 1.28 0 0 1-1.285 1.283ZM2.57 12.916h83.86V2.565H2.57v10.35Z" />
            <path d="M7.384 9.023c-.167 0-.334-.035-.493-.096a1.496 1.496 0 0 1-.413-.282 1.247 1.247 0 0 1-.379-.905c0-.342.141-.667.379-.905.3-.298.74-.439 1.153-.35a.944.944 0 0 1 .237.078c.08.026.159.07.22.114.07.053.141.106.194.167.238.237.379.571.379.905 0 .334-.141.668-.379.905a1.287 1.287 0 0 1-.906.378l.008-.009ZM16.608 7.74a1.28 1.28 0 0 1 1.285-1.282 1.28 1.28 0 0 1 1.285 1.282 1.28 1.28 0 0 1-1.285 1.283 1.28 1.28 0 0 1-1.285-1.283ZM28.411 9.023c-.343 0-.669-.132-.907-.378a1.247 1.247 0 0 1-.378-.905c0-.342.14-.667.378-.905.062-.061.124-.114.185-.166.08-.044.15-.088.22-.115.08-.035.168-.061.238-.079a1.286 1.286 0 0 1 1.54 1.257c0 .167-.035.334-.105.492a1.246 1.246 0 0 1-.273.421c-.123.123-.264.211-.423.282a1.332 1.332 0 0 1-.493.096h.018ZM44.597 64.27h-.053a1.304 1.304 0 0 1-1.162-.862l-3.16-9.172a1.288 1.288 0 0 1 .801-1.635c.67-.228 1.4.123 1.629.8l2.086 6.071 4.876-11.044a1.285 1.285 0 0 1 1.69-.659c.65.29.941 1.046.66 1.687l-6.197 14.049a1.284 1.284 0 0 1-1.179.764h.009Z" />
            <path d="M45.002 71.149c-6.346 0-12.076-4.007-14.267-9.964a15.058 15.058 0 0 1-.942-5.218c0-8.373 6.821-15.183 15.209-15.183 8.387 0 15.209 6.81 15.209 15.183 0 8.373-6.822 15.182-15.21 15.182Zm0-27.8c-6.971 0-12.64 5.659-12.64 12.618 0 1.458.265 2.916.784 4.34 1.822 4.955 6.584 8.276 11.856 8.276 6.97 0 12.639-5.658 12.639-12.616 0-6.959-5.669-12.617-12.64-12.617Z" />
            <path d="M87.715 69.4H66.908a1.285 1.285 0 0 1-1.153-1.854c1.787-3.61 2.711-7.441 2.738-11.395v-27.94H21.335V56.17c.035 3.944.959 7.775 2.746 11.377a1.286 1.286 0 0 1-1.153 1.854H1.285C.572 69.4 0 68.82 0 68.117V14.198a1.28 1.28 0 0 1 1.285-1.283h86.43A1.28 1.28 0 0 1 89 14.198v53.92a1.28 1.28 0 0 1-1.285 1.282Zm-18.818-2.565H86.43V15.48H2.57v51.354h18.36a28.452 28.452 0 0 1-2.165-10.649V26.93a1.28 1.28 0 0 1 1.285-1.283h49.737a1.28 1.28 0 0 1 1.284 1.283v29.204a28.613 28.613 0 0 1-2.165 10.702h-.009Z" />
            <path d="M44.914 88c-.23 0-.45-.061-.652-.176l-9.945-5.86c-5.519-3.25-9.858-7.846-12.551-13.284-1.963-3.963-2.966-8.162-3.001-12.503V26.93a1.28 1.28 0 0 1 1.285-1.283h49.737a1.28 1.28 0 0 1 1.284 1.283v29.204c-.035 4.376-1.047 8.584-3.01 12.547-2.693 5.438-7.023 10.033-12.55 13.284l-9.946 5.86a1.342 1.342 0 0 1-.651.176Zm-23.58-59.788v27.957c.036 3.944.96 7.775 2.747 11.377 2.464 4.99 6.46 9.208 11.547 12.204l9.294 5.474 9.295-5.474c5.087-2.996 9.083-7.222 11.547-12.204 1.787-3.61 2.711-7.441 2.737-11.395v-27.94H21.335Z" />
        </g>
    </svg>
);

export default Icon5;
