import * as React from "react";
import "../Footer/Footer.scss";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Button from "@mui/material/Button";
import logo from "../../Media/alt-footer-logo.png";
import ServiceProviderBadge from "./assets/service-provider-badge.png";
import mixpanel from "mixpanel-browser";

const FooterButton = styled(Button)`
    background-color: #23354b;
    color: white;
    transform: scale(0.8);
    margin-top: -10em;
    margin-left: 3em;
    padding: 1.3em;
    border-radius: 1.8em;
    position: absolute;
    transition: 0.3s ease-in-out;
    will-change: transform;
    &:hover {
        transform: scale(0.83);
        background-color: #23354b;
    }
    @media (max-width: 900px) {
        margin-top: -4em;
        margin-left: -4em;
        transform: scale(0.6);
    }
`;

const CSNumber = styled(Typography)`
    @media (max-width: 1200px) {
        font-size: 30px !important;
    }
    @media (max-width: 500px) {
        font-size: 20px !important;
    }
`;

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Grid>
            <Grid sx={{ backgroundColor: "primary.main" }}>
                <div className="footer-gradient" />
            </Grid>
            <Grid container direction="row" className="tele-bg">
                <Grid item></Grid>
                <Grid item>
                    <Grid
                        container
                        sx={{
                            p: 1,

                            pr: { xs: 3, md: 8 },
                            pt: { xs: 1.5, sm: 3, md: 4 }
                        }}
                        justifyContent="right"
                        direction="column"
                        className="contact"
                    >
                        <Grid item>
                            <Grid container direction="row">
                                <a
                                    href="tel:8883661325"
                                    aria-label="Call for more info at 8 8 8 3 6 6 1 3 2 5"
                                    onClick={() =>
                                        mixpanel.track(
                                            "Call for more info clicked"
                                        )
                                    }
                                >
                                    <LocalPhoneIcon
                                        fontSize="large"
                                        className="phone-icon"
                                        sx={{ color: "white" }}
                                    />
                                </a>
                                <CSNumber
                                    variant="h6"
                                    color="white"
                                    fontWeight={600}
                                >
                                    <a
                                        href="tel:8883661325"
                                        aria-label="Call for more info at 8 8 8 3 6 6 1 3 2 5"
                                        onClick={() =>
                                            mixpanel.track(
                                                "Call for more info clicked"
                                            )
                                        }
                                    >
                                        (888) 366-1325
                                    </a>
                                </CSNumber>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="white">
                                Call for more info
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FooterButton
                onClick={() => mixpanel.track("User taken to Contact Form")}
                href="/contact"
                sx={{ display: { xs: "none", md: "block" } }}
            >
                <Grid
                    container
                    direction="row"
                    spacing="20"
                    alignItems="center"
                >
                    <Grid item>
                        LEARN <br />
                        MORE
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" color="white">
                            Schedule a Demo
                        </Typography>
                    </Grid>
                </Grid>
            </FooterButton>
            {/* Footer content start */}
            <Grid
                container
                direction="row"
                sx={{
                    backgroundColor: "#23354B",
                    color: "white",
                    padding: 5
                }}
            >
                <Grid item xs sm></Grid>
                <Grid item xs={10} sm={10}>
                    <Grid container direction="column">
                        {/* Row 1 - Links */}
                        <Grid item>
                            <Grid
                                container
                                direction={{ xs: "column", md: "row" }}
                                justifyContent="space-between"
                                rowGap={5}
                            >
                                {/* Features */}

                                <Grid direction="row">
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                fontWeight="600"
                                                sx={{ pb: 1 }}
                                            >
                                                Features
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            columnGap={5}
                                        >
                                            {/* Column 1 */}
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    rowSpacing={1}
                                                >
                                                    <Grid item>
                                                        <Link to="/features/account-updater">
                                                            Account Updater
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/customer-vault">
                                                            Customer Vault
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/custom-fields">
                                                            Custom Fields
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/invoicing">
                                                            Invoicing
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/iq-protect">
                                                            iQ Protect
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* Column 2 */}
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    spacing={1}
                                                >
                                                    <Grid item>
                                                        <Link to="/features/recurring-billing">
                                                            Recurring Billing
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/reporting">
                                                            Reporting
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/shopping-cart">
                                                            Shopping Cart
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/simple-pay">
                                                            Simple Pay
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/features/virtual-terminal">
                                                            Virtual Terminal
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="line"></Grid>
                                {/* Resources */}
                                <Grid direction="row">
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                fontWeight="600"
                                                sx={{ pb: 1 }}
                                            >
                                                Resources
                                            </Typography>
                                        </Grid>
                                        <Grid container direction="row">
                                            {/* Column 1 */}
                                            <Grid item xs lg>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    spacing={1}
                                                >
                                                    <Grid item>
                                                        <Typography>
                                                            <a href="https://sandbox.basysiqpro.com/docs/api/">
                                                                Developer Docs
                                                            </a>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link to="/support">
                                                            Frequently Asked
                                                            Questions
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>
                                                            <a href="https://basyspro.com/equipment/qrg/qrg-iqpro/">
                                                                Quick Resource
                                                                Guide
                                                            </a>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="line"></Grid>
                                {/* Extra */}
                                <Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography>
                                                <a href="https://basyspro.com/privacy-policy/">
                                                    Privacy Policy
                                                </a>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                <a href="/terms-of-service">
                                                    Terms of Service
                                                </a>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <img
                                                src={ServiceProviderBadge}
                                                className="serivce-provider-badge"
                                                alt="Service Provider Badge"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Row 2 - Logo */}
                        <Grid item xs lg sx={{ pt: 6, pb: 2 }}>
                            <Grid
                                container
                                alignItems="center"
                                direction="row"
                                spacing={5}
                            >
                                <Grid item>
                                    <img
                                        src={logo}
                                        alt="iQ Pro Footer Logo"
                                        className="footer-logo"
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">
                                                © {currentYear} iQ Pro
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                iQ Pro is a registered service
                                                mark of BASYS Processing. All
                                                rights reserved.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs sm></Grid>
            </Grid>
        </Grid>
    );
}
