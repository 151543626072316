import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import getTheme from "./theme";
import React from "react";
import ScrollToTop from "./Components/ScrollToTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState, useMemo, useEffect, createContext } from "react";
// import { ColorModeContext } from "./contexts/ColorModeContext";
import mixpanel from "mixpanel-browser";
import Products from "./Products/Products";
import VirtualTerminal from "./Products/ProductPages/VirtualTerminal/VirtualTerminal";
import CustomerVault from "./Products/ProductPages/CustomerVault/CustomerVault";
import Reporting from "./Products/ProductPages/Reporting/Reporting";
import RecurringBilling from "./Products/ProductPages/RecurringBilling/RecurringBilling";
import Invoicing from "./Products/ProductPages/Invoicing/Invoicing";
import AccountUpdater from "./Products/ProductPages/AccountUpdater/AccountUpdater";
import CustomFields from "./Products/ProductPages/CustomFields/CustomFields";
import SimplePay from "./Products/ProductPages/SimplePay/SimplePay";
import ShoppingCart from "./Products/ProductPages/ShoppingCart/ShoppingCart";
import IQProtect from "./Products/ProductPages/iQProtect/iQProtect";
import TOS from "./TOS/TOS";
// import Solutions from "./Solutions/Solutions";
import Support from "./Support/Support";
import Landing from "./Landing/Landing";
import CTAForm from "./CTAForm/CTAForm";
import SignIn from "./SignIn/SignIn";
import OnboardingFeatures from "./Onboarding/Features";
import OnboardingTraining from "./Onboarding/Training";
import OnboardingConversion from "./Onboarding/Conversion";
import OnboardingSignIn from "./Onboarding/SignIn";

import BetaFeatures from "./BetaFeatures/BetaFeatures";
import { CssBaseline } from "@mui/material";

const ColorModeContext = createContext({
    toggleColorMode: () => {}
});

function App() {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [mode, setMode] = useState("light");
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === "light" ? "dark" : "light"
                );
            }
        }),
        []
    );
    let theme = useMemo(() => getTheme(mode), [mode]);
    useEffect(() => {
        localStorage.getItem("iqPro-theme")
            ? setMode(localStorage.getItem("iqPro-theme"))
            : setMode(prefersDarkMode ? "dark" : "light");
    }, [prefersDarkMode]);
    theme = responsiveFontSizes(theme);

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

    mixpanel.identify();
    mixpanel.people.set();
    return (
        <div className={mode}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <HelmetProvider>
                        <Helmet>
                            <title>
                                iQ Pro: Accept payments. Quickly. Simply.
                                Securely.{" "}
                            </title>
                            <meta
                                name="description"
                                content="iQ Pro is ideal for accepting payments over the phone or for B2B organizations seeking Level II and Level III rates."
                            />
                        </Helmet>
                        <Router>
                            <ScrollToTop />

                            <Routes>
                                <Route
                                    path="/plus-features"
                                    element={<BetaFeatures />}
                                />
                                <Route
                                    path="/features"
                                    element={<Products />}
                                />
                                <Route
                                    path="/features/virtual-terminal"
                                    element={<VirtualTerminal />}
                                />
                                <Route
                                    path="/features/customer-vault"
                                    element={<CustomerVault />}
                                />
                                <Route
                                    path="/features/reporting"
                                    element={<Reporting />}
                                />
                                <Route
                                    path="/features/recurring-billing"
                                    element={<RecurringBilling />}
                                />
                                <Route
                                    path="/features/account-updater"
                                    element={<AccountUpdater />}
                                />
                                <Route
                                    path="/features/custom-fields"
                                    element={<CustomFields />}
                                />
                                <Route
                                    path="/features/iq-protect"
                                    element={<IQProtect />}
                                />
                                <Route
                                    path="/features/shopping-cart"
                                    element={<ShoppingCart />}
                                />
                                <Route
                                    path="/features/simple-pay"
                                    element={<SimplePay />}
                                />
                                <Route
                                    path="/features/invoicing"
                                    element={<Invoicing />}
                                />
                                <Route path="/contact" element={<CTAForm />} />
                                <Route path="sign-in" element={<SignIn />} />
                                <Route
                                    path="terms-of-service"
                                    element={<TOS />}
                                />
                                {/* <Route path="/solutions" element={<Solutions />} /> */}
                                <Route path="/support" element={<Support />} />
                                <Route path="/" element={<Landing />} />
                                {/* Onboarding */}
                                <Route
                                    path="/onboarding-features"
                                    element={<OnboardingFeatures />}
                                />
                                <Route
                                    path="/onboarding-training"
                                    element={<OnboardingTraining />}
                                />
                                <Route
                                    path="/onboarding-conversion"
                                    element={<OnboardingConversion />}
                                />
                                <Route
                                    path="/onboarding-sign-in"
                                    element={<OnboardingSignIn />}
                                />
                            </Routes>
                        </Router>
                    </HelmetProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;
