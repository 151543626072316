import * as React from "react";

const Icon3 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M67.816 59.66c-.378 1.06-.795 2.043-1.742 2.725-.757.568-1.59.908-2.536.946H13.97c-2.007 0-3.522-.757-4.393-2.574a5.644 5.644 0 0 1-.492-2.233v-44.67c0-2.914 1.97-4.807 4.998-4.807h48.81c1.704 0 3.18.53 4.165 1.969.34.53.568 1.135.833 1.703v46.864l-.076.076ZM38.47 11.431H13.366c-1.023.038-1.856.833-2.008 1.855-.038.34-.038.643-.038.984v43.95c0 .454 0 .946.152 1.362.303 1.023 1.174 1.515 2.499 1.515h48.733c2.007 0 2.84-.833 2.84-2.877V14.195c0-2.006-.795-2.8-2.802-2.8h-24.31l.038.037Z"
            fill="#1B9AD2"
        />
        <path
            d="M58.806 6.852h-2.31v-1.93c0-1.704-.87-2.575-2.575-2.575H4.847c-1.742 0-2.613.909-2.613 2.612v44.404c0 1.779.871 2.65 2.65 2.65h1.818v2.233c-2.12.114-4.203.227-5.718-1.666C.303 51.748 0 50.802 0 49.703V5.035C0 2.27 1.893 0 5.036 0c16.245.038 32.49 0 48.734 0 3.142 0 5.036 1.855 5.036 4.997v1.855Z"
            fill="#1B9AD2"
        />
        <path
            d="M13.594 6.89V4.656h2.272v1.969c0 .113-.19.265-.265.265h-2.007ZM4.545 6.852V4.656H6.74v2.196H4.545ZM9.05 6.89V4.656h2.271v1.969c0 .113-.227.265-.34.303H9.05V6.89ZM63.274 18.246v2.196h-49.68v-2.196h49.68ZM13.594 26.044V23.85h9.012v2.195h-9.012ZM20.334 28.391v2.234h-6.74V28.39h6.74ZM63.276 58.827h-6.702v-2.158h6.702v2.158ZM13.594 15.899v-2.271h1.97c.113 0 .302.189.302.303v1.93h-2.272v.038ZM20.335 15.937h-2.272v-1.968c0-.114.19-.265.265-.265h2.007v2.27-.037ZM24.878 13.666v2.233h-2.272v-1.968c0-.114.19-.265.265-.265h2.007ZM32.57 52l4.216-24.172h3.005L35.574 52H32.57Zm6.225 0 4.233-24.172h3.005L41.8 52h-3.005Zm9.712-14.46H31.225v-2.806h17.282v2.806Zm-1.262 7.653H29.98v-2.789h17.265v2.79Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon3;
