import * as React from "react";

function Tab() {
    return (
        <path
            d="m29 44.361 1.917-1.214c1.917-1.214 2.777-1.595 2.777-4.05V17.261c0-2.455-.86-2.833-2.777-4.05L29 12v32.361Z"
            fill="#A3D3FE"
            stroke="#4C5B8C"
            strokeMiterlimit={10}
            className="iconPeek"
        />
    );
}

const ReportingIcon = (props) => (
    <svg
        width={55}
        height={35}
        viewBox="0 0 70 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Tab />
        <g clipPath="url(#a)">
            <path d="M29.5 43.441V12.93L28 12l-.13 32.362" fill="#A3D3FE" />
        </g>
        <rect width={35} height={48} rx={5} fill="#fff" />
        <path
            d="M9.953 31.414H6.555a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM9.953 37.034H6.555a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM9.953 42.657H6.555a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM14.703 31.414h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM19.703 31.414h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM24.703 31.414h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM14.703 37.034h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM19.703 37.034h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM24.703 37.034h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM14.703 42.657h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM19.703 42.657h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM24.703 42.657h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM29.453 31.414h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM29.453 37.034h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581ZM29.453 42.657h-3.398a.583.583 0 0 0-.581.581c0 .32.261.582.581.582h3.398c.32 0 .582-.262.582-.582a.583.583 0 0 0-.582-.581Z"
            fill="#4C5B8C"
        />
        <path
            d="m2 8.531.037 18.483 7.57-.016-.036-18.482L2 8.531Z"
            fill="#0288D1"
        />
        <path
            d="m2 8.531.037 18.483 7.57-.016-.036-18.482L2 8.531Z"
            fill="#fff"
            fillOpacity={0.6}
        />
        <path
            d="m11.735 10.707.037 16.366 7.57-.014-.036-16.365-7.571.013Z"
            fill="#0288D1"
        />
        <path
            d="m11.735 10.707.037 16.366 7.57-.014-.036-16.365-7.571.013Z"
            fill="#fff"
            fillOpacity={0.6}
        />
        <path
            d="m21.47 12.812.036 13.845 7.571-.011-.037-13.845-7.57.011Z"
            fill="#0288D1"
        />
        <path
            d="m21.47 12.812.036 13.845 7.571-.011-.037-13.845-7.57.011Z"
            fill="#fff"
            fillOpacity={0.6}
        />
        <path
            d="m3.367 8.532.037 18.482c0 .283.233.514.516.514l7.57-.015a.516.516 0 0 0 .515-.516l-.037-18.482A.516.516 0 0 0 11.452 8l-7.571.015a.516.516 0 0 0-.514.516Zm7.572.5.035 17.453-6.541.012-.035-17.453 6.541-.013ZM13.367 11.45l.037 15.643c0 .24.233.435.516.435l7.57-.013c.284 0 .515-.197.515-.436l-.037-15.643c0-.24-.233-.436-.516-.435l-7.571.013c-.283 0-.514.197-.514.436Zm7.572.423.035 14.772-6.541.01-.035-14.771 6.541-.011ZM23.372 13.375l.032 13.77c0 .211.233.383.516.383l7.57-.013c.284 0 .515-.173.515-.384l-.033-13.77c0-.212-.232-.384-.515-.383l-7.571.013c-.284 0-.515.173-.514.384Zm7.572.37.03 13.004-6.541.011-.03-13.004 6.54-.01Z"
            fill="#4C5B8C"
        />
        <g clipPath="url(#b)">
            <path
                d="M30.444 0H4.556A4.566 4.566 0 0 0 0 4.564V43.44a4.566 4.566 0 0 0 4.556 4.564h25.888A4.566 4.566 0 0 0 35 43.439V4.564A4.566 4.566 0 0 0 30.444 0Zm3.395 43.439c0 1.873-1.522 3.4-3.395 3.4H4.556a3.402 3.402 0 0 1-3.394-3.4V4.564c0-1.873 1.521-3.4 3.394-3.4h25.888a3.402 3.402 0 0 1 3.395 3.4V43.44Z"
                fill="#4C5B8C"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(28 11)"
                    d="M0 0h5.636v33.54H0z"
                />
            </clipPath>
            <clipPath id="b">
                <path fill="#fff" d="M0 0h35v48H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default ReportingIcon;
