import React from "react";
import "./BetaFeatures.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Navbar from "../NavMenu/Navbar";
import Footer from "../Components/Footer/Footer";
import LaptopMobileMockup from "./assets/LaptopMobileMockup.png";
import BetaMobileMockup from "./assets/BetaMobile-Mockup.png";
import BetaDesktopMockup from "./assets/BetaDesktop-Mockup.png";
// import SignIn from "./assets/sign-in.png";
import search from "./assets/search-icon.svg";
import customer from "./assets/customer-vt.png";
import FPCustomer from "./assets/fp-customer.png";
import BetaCustomer from "./assets/beta-customer.png";
import MobileBetaCustomer from "./assets/mobile-beta-customer.png";
import DarkMode from "./assets/dark-mode.png";
import Feedback from "./assets/feedback.png";
import SettlementTotals from "./assets/settlements-totals.png";
import BatchOverview from "./assets/batch-overview.png";
import ExportOptions from "./assets/export-options.png";
import GatewaySwitch from "./assets/gateway-switch.png";
import Blocks from "../Products/assets/blocks-teal-blue.svg";

const BetaFeatures = () => {
    {
        return (
            <Grid>
                <Navbar />
                <Grid sx={{ pt: { xs: 2, md: 10 }, width: "100%" }}>
                    {/* Hero Section */}
                    {/* Desktop header */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        <Grid item xs={6} lg={7} className="mockup-container">
                            <img
                                src={BetaDesktopMockup}
                                className="beta-desktop-mockup"
                                alt="Desktop mockup of iQ Pro+"
                            />
                            <img
                                src={BetaMobileMockup}
                                className="beta-mobile-mockup"
                                alt="Mobile mockup of iQ Pro+"
                            />
                        </Grid>
                        <Grid item lg>
                            <Typography
                                variant="h1"
                                fontWeight="500"
                                color="#1CC1E6"
                            >
                                iQ Pro+
                            </Typography>
                            <Typography variant="h4">
                                The next evolution of iQ Pro.
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Mobile header */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: "auto", md: "none" } }}
                    >
                        <Grid item md={6}></Grid>
                        <Grid item md={6} lg>
                            <Typography
                                variant="h1"
                                fontWeight="500"
                                color="#1CC1E6"
                            >
                                iQ Pro+
                            </Typography>
                            <Typography variant="h4">
                                The next evolution of iQ Pro.
                            </Typography>
                        </Grid>
                        <Grid item xs={9} lg={7} className="mockup-container">
                            <img
                                src={LaptopMobileMockup}
                                className="mockup-container"
                            />
                        </Grid>
                    </Grid>
                    {/* Cleaner Look & Feel */}
                    <Grid container className="look-and-feel">
                        <Grid sx={{ mt: { xs: 18, md: 30 }, mb: 10 }}>
                            <Grid container>
                                <Grid item xs={1} lg={1.5}></Grid>
                                <Grid item xs>
                                    <Typography
                                        variant="h3"
                                        color="white"
                                        fontWeight="500"
                                        sx={{ my: 3 }}
                                    >
                                        Cleaner Look <i className="blue">&</i>{" "}
                                        Feel
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                sx={{ mt: 5 }}
                            >
                                {/* Mobile */}
                                <Grid
                                    item
                                    xs={9}
                                    sx={{
                                        mb: 5,
                                        display: { xs: "flex", lg: "none" }
                                    }}
                                >
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                fontWeight="500"
                                                color="white"
                                            >
                                                UX Enhancements
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                color="white"
                                            >
                                                iQ Pro+ features a polished new
                                                look while maintaining the
                                                workflows you’ve come to expect.
                                                A clarified designed has led to
                                                a quicker and more intuitive
                                                experience, giving you more time
                                                to focus on your business.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} lg={1}></Grid>
                                <Grid item xs={10} lg={5}>
                                    <img
                                        src={FPCustomer}
                                        className="fp-customer"
                                        alt="iQ Pro interface with arrow pointing to new iQ Pro+ interface"
                                    />
                                </Grid>
                                {/* DESKTOP */}
                                <Grid
                                    item
                                    lg={4}
                                    sx={{ display: { xs: "none", lg: "flex" } }}
                                >
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                fontWeight="500"
                                                color="white"
                                            >
                                                UX Enhancements
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                color="white"
                                            >
                                                iQ Pro+ features a polished new
                                                look while maintaining the
                                                workflows you’ve come to expect.
                                                A clarified designed has led to
                                                a quicker and more intuitive
                                                experience, giving you more time
                                                to focus on your business.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={1}></Grid>
                                <Grid
                                    item
                                    xs={10}
                                    lg={8}
                                    sx={{
                                        display: { xs: "none", md: "block" }
                                    }}
                                >
                                    <img
                                        src={BetaCustomer}
                                        className="beta-customers"
                                        alt="iQ Pro Bet customer interface"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={10}
                                    lg={8}
                                    sx={{
                                        display: { xs: "block", md: "none" }
                                    }}
                                >
                                    <img
                                        src={MobileBetaCustomer}
                                        className="beta-customers"
                                        alt="iQ Pro Bet customer interface"
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                sx={{ mt: 10 }}
                            >
                                <Grid item xs={1} lg={0.5}></Grid>
                                <Grid item xs={10} lg={4.5} sx={{ mb: 5 }}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                fontWeight="500"
                                                color="white"
                                            >
                                                Dark Mode
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                color="white"
                                            >
                                                iQ Pro+ now employs your saved
                                                device preferences and
                                                automatically detects the use of
                                                light or dark mode and carries
                                                that theme throughout the
                                                application, making it easier on
                                                the eyes.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs lg={1}></Grid>
                                <Grid item xs={9} lg={4}>
                                    <img
                                        src={DarkMode}
                                        className="dark-mode"
                                        alt="Example of iQ Pro+ in dark mode"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Optimized Transaction Workflow */}
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        rowSpacing={5}
                        sx={{ pt: 5 }}
                    >
                        <Grid item>
                            <Typography variant="h3" align="center">
                                Optimized Transaction Workflow
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent="center">
                                <Grid item lg></Grid>
                                <Grid item xs={10} lg={9.5}>
                                    <Grid
                                        container
                                        direction="row"
                                        rowSpacing={3}
                                        alignItems="center"
                                        justifyContent="center"
                                        className="glass-gradient-bg"
                                    >
                                        <Grid item xs={11} lg={12}>
                                            <Typography
                                                variant="h6"
                                                fontWeight={400}
                                            >
                                                Saving your customers in the
                                                vault allows for secure and
                                                prompt use of saved payment
                                                information. iQ Pro+ takes that
                                                one step further with a
                                                streamlined payment process for
                                                your customers.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs sm={3} lg={1.5}>
                                            <img
                                                src={search}
                                                className="search"
                                                alt="Magnifying glass icon"
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm lg>
                                            <Typography
                                                variant="h6"
                                                fontWeight={400}
                                            >
                                                Search, locate, and select your
                                                saved customer directly from the
                                                Virtual Terminal payment screen.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11} md={8} lg={7}>
                            <img
                                src={customer}
                                className="customer-vt"
                                alt="Example of searching for a saved customer in the Virtual Terminal"
                            />
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="center">
                                <Grid item xs={11} lg={8}>
                                    <Typography
                                        variant="h4"
                                        fontWeight={300}
                                        align="center"
                                        color="#08304C"
                                    >
                                        <strong>
                                            Now all your payment methods are
                                            easily found on one screen.{" "}
                                        </strong>
                                        No need to navigate between pages to
                                        process a transaction.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Reporting Enhancements */}
                    <Grid
                        container
                        sx={{ mt: 10, mb: 4, px: 5 }}
                        className="reporting-bg"
                        rowSpacing={5}
                    >
                        <Grid item md={1.2}></Grid>
                        <Grid item md>
                            <Typography
                                variant="h3"
                                fontWeight="500"
                                sx={{ my: 3 }}
                            >
                                Reporting Enhancements
                            </Typography>
                            <Typography variant="h5" sx={{ my: 3 }}>
                                Applying merchant feedback, we have introduced
                                improved reporting workflows, functionality, and
                                formats including:
                            </Typography>
                        </Grid>
                        <Grid item md={1}></Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt: 2, mb: 10 }}
                        >
                            <Grid item lg={4.5}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            fontWeight="400"
                                        >
                                            PDF and CSV Export options
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src={ExportOptions}
                                            className="reporting-examples"
                                            alt="Example of CSV and PDF options for exporting reports"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={5}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            fontWeight="400"
                                        >
                                            Auto-calculating running totals for
                                            quick reconciliation
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src={SettlementTotals}
                                            className="reporting-examples"
                                            alt="Example of settlement batch totals being automatically calculated in final row of settlement list"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={5} sx={{ mt: 5 }}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            fontWeight="400"
                                        >
                                            Improved settlement reporting for
                                            fewer clicks and less load time
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src={BatchOverview}
                                            className="reporting-examples"
                                            alt="Example of improved settlement details screen"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Improved Multi-Merchant Experience */}
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        rowSpacing={5}
                        sx={{ backgroundColor: "#1CC1E6", pt: 5, pb: 10 }}
                    >
                        <Grid item>
                            <Typography
                                variant="h3"
                                color="white"
                                align="center"
                                fontWeight="400"
                            >
                                Improved Multi-Merchant Experience
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={10}
                                    lg={3}
                                    className="mm-glass-gradient"
                                    color={{ xs: "white", lg: "black" }}
                                >
                                    <Typography variant="h6" fontWeight={400}>
                                        iQ Pro+ introduces improved
                                        functionality and flexibility for
                                        merchants who manage multiple locations,
                                        businesses, or revenue sources through a{" "}
                                        {"   "}
                                        <strong>single sign-on</strong>.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={10}
                                    lg={4}
                                    className="sign-in"
                                ></Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ width: "75%" }}>
                            <Typography
                                variant="h5"
                                fontWeight={500}
                                color="white"
                            >
                                Users can now choose to view all merchant
                                accounts through a single gateway environment or
                                separately with a new quick-toggle action.
                            </Typography>
                        </Grid>
                        <Grid item sx={{ width: "75%" }}>
                            <Typography
                                variant="h5"
                                fontWeight={300}
                                color="white"
                            >
                                The new quick-toggle action allows each merchant
                                account to maintain individual settings,
                                preferences, reports, and more without needing
                                to maintain more than one login.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "80%",
                                    md: "65%",
                                    lg: "30%"
                                }
                            }}
                        >
                            <img
                                src={GatewaySwitch}
                                className="gateway-switch"
                                alt="Example of the improved ability to switch between gateways under a single sign on"
                            />
                        </Grid>
                    </Grid>
                    {/* Customer-Driven Development */}
                    <Grid container direction="row" justifyContent="center">
                        <Grid item lg={1.5}></Grid>
                        <Grid item xs={10} lg>
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        fontWeight="500"
                                        sx={{ my: 3 }}
                                    >
                                        Customer-Driven Development
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5">
                                        iQ Pro+ is designed with real merchants
                                        in mind. Utilizing authentic customer
                                        feedback and comprehensive user testing,
                                        iQ Pro+ is the latest in payment
                                        processing.
                                    </Typography>
                                </Grid>
                                <Grid container direction="row" sx={{ my: 5 }}>
                                    <Grid item xs={3} lg={1.3}>
                                        <img
                                            src={Blocks}
                                            className="bullet-blocks"
                                        ></img>
                                    </Grid>
                                    <Grid item xs={9} lg={9}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="500"
                                            sx={{ my: 3 }}
                                        >
                                            iQ Pro+ users will have the unique
                                            opportunity to continue driving
                                            innovation.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item></Grid>
                                    <Grid item xs={10} lg={7}>
                                        <img
                                            src={Feedback}
                                            className="feedback"
                                            alt="Example of iQ Pro+ feedback collector form"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1.5}
                                        sx={{
                                            display: { xs: "block", md: "none" }
                                        }}
                                    ></Grid>
                                    <Grid item xs={10} lg>
                                        <Typography variant="h5">
                                            Through an integrated feedback
                                            collector, users can continuously
                                            submit comments, observations, and
                                            requests on-demand,{" "}
                                            <strong>
                                                giving you back valuable time
                                            </strong>
                                            .
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2}></Grid>
                    </Grid>
                </Grid>
                <Footer />
            </Grid>
        );
    }
};

export default BetaFeatures;
