import { createTheme } from "@mui/material/styles";
import getPalette from "./palettes";

const getTheme = (mode) => {
    const palette = getPalette(mode);
    let theme = createTheme({
        ...palette,
        typography: {
            h1: { fontSize: "4rem" },
            h2: {
                fontWeight: 300,
                fontSize: "2rem",
                "@media (max-width:600px)": {
                    fontSize: 24,
                    textAlign: "left",
                    paddingLeft: 2
                }
            },
            h3: {
                color: "#189AD0",
                fontWeight: 600
            },
            h4: {
                fontWeight: 300,
                color: "#08304C",
                "@media (max-width:600px)": {
                    fontSize: 20
                }
            },
            h5: {
                "@media (max-width:600px)": {
                    fontSize: 18
                }
            },
            h6: {
                "@media (max-width: 600px)": {
                    fontSize: 15
                }
            },
            subtitle2: {
                fontSize: 23
            },
            body1: {
                fontWeight: 400,
                "@media (max-width:600px)": {
                    fontSize: 15
                }
            }
        }
    });
    theme = {
        ...theme,
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.neutrals.main
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.fontColor.main,
                        backgroundImage: "none"
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        whiteSpace: "normal"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        backgroundColor: "white"
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        textDecoration: "none",
                        color: theme.palette.secondary.main
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: theme.palette.fontColor.main,
                        "&.Mui-focused": {
                            color: theme.palette.fontColor.main
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        height: "1.8rem",
                        fontSize: "12px",
                        "@media (max-width: 600px)": {
                            height: "3rem",
                            fontSize: "18px"
                        },
                        "&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                            color: "red"
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.fontColor.main,
                                color: theme.palette.fontColor.main
                            }
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontSize: "12px",
                        "@media (max-width: 600px)": {
                            fontSize: "18px"
                        }
                    }
                }
            }
        }
    };
    return theme;
};

export default getTheme;
