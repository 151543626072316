import * as React from "react";

const Icon7 = (props) => (
    <svg
        width={75}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M61.851 28.075c-.891 0-2.025-.276-3.428-1.064a.983.983 0 0 1 .964-1.713c1.422.8 2.595 1.024 3.297.61.649-.374.832-1.195.832-1.575 0-.171-.059-.447-.124-.71-.086-.367-.184-.78-.184-1.214 0-.926.328-1.438.564-1.812.177-.27.255-.394.255-.742s-.078-.473-.255-.749c-.243-.374-.564-.88-.564-1.812s.328-1.438.564-1.812c.177-.276.255-.394.255-.748a.983.983 0 1 1 1.966 0c0 .925-.327 1.437-.563 1.812-.177.269-.256.394-.256.748 0 .355.079.473.256.749.242.374.563.88.563 1.812s-.327 1.438-.57 1.812c-.177.27-.255.394-.255.742 0 .203.065.486.13.762.086.374.178.768.178 1.175-.013 1.024-.492 2.495-1.816 3.263-.439.256-1.035.46-1.815.46l.006.006Z" />
            <path d="M49.189 62H38.473a.983.983 0 0 1-.983-.985v-5.994c-3.16.564-6.377.597-9.55.111v5.883c0 .545-.439.985-.983.985H16.235a.983.983 0 0 1-.983-.985V49.827c-5.63-4.235-9.288-10.413-10.16-17.117H3.272a3.281 3.281 0 0 1-3.277-3.283v-7.123A3.281 3.281 0 0 1 3.27 19.02h3.985a25.524 25.524 0 0 1 3.539-5.489 5.84 5.84 0 0 1-2.68-1.78C5.944 9.226 7.006 1.695 7.137.848a.987.987 0 0 1 .97-.84c.858 0 8.448.078 10.617 2.606a5.96 5.96 0 0 1 1.43 3.736 28.899 28.899 0 0 1 12.23-2.705c15.165 0 27.5 11.628 27.5 25.915 0 7.649-3.532 14.825-9.713 19.77v11.693c0 .545-.44.985-.983.985V62Zm-9.733-1.97h8.75V48.842c0-.308.144-.59.38-.781 5.931-4.57 9.333-11.32 9.333-18.509 0-13.203-11.457-23.945-25.535-23.945-4.555 0-9.032 1.143-12.944 3.31a.983.983 0 0 1-1.082-.086.984.984 0 0 1-.34-1.03c.393-1.452.104-2.877-.78-3.908-1.088-1.267-5.329-1.812-8.272-1.904-.347 2.929-.445 7.216.643 8.477.76.886 1.874 1.379 3.132 1.392.394 0 .748.243.898.604a.994.994 0 0 1-.196 1.063c-1.98 2.056-3.546 4.373-4.647 6.868a.985.985 0 0 1-.898.584h-4.62c-.722 0-1.312.591-1.312 1.314v7.123c0 .723.59 1.314 1.311 1.314h2.7c.512 0 .931.387.977.892.61 6.645 4.201 12.804 9.857 16.9a.98.98 0 0 1 .407.802v10.702h8.75V53.95a.985.985 0 0 1 1.173-.965 27.21 27.21 0 0 0 11.116-.131.959.959 0 0 1 .825.197c.23.184.367.466.367.768v6.211h.007Z" />
            <path d="M20.154 61.271a.983.983 0 0 1-.983-.985v-2.232a.983.983 0 1 1 1.966 0v2.232c0 .545-.439.985-.983.985ZM42.228 61.271a.983.983 0 0 1-.983-.985v-2.232a.983.983 0 1 1 1.966 0v2.232c0 .545-.439.985-.983.985ZM14.288 19.027c0-.544.446-.984.99-.984a.986.986 0 0 1 0 1.97.993.993 0 0 1-.99-.985ZM33.06 40.556c-6.666 0-12.093-5.436-12.093-12.113 0-6.678 5.427-12.114 12.092-12.114 6.666 0 12.093 5.436 12.093 12.114 0 6.677-5.427 12.113-12.093 12.113Zm0-22.257c-5.585 0-10.127 4.55-10.127 10.144s4.542 10.144 10.126 10.144 10.126-4.55 10.126-10.144-4.542-10.144-10.126-10.144Z" />
            <path d="M33.773 35.048h-1.435a3.022 3.022 0 0 1-3.015-3.02.983.983 0 1 1 1.966 0c0 .577.472 1.05 1.05 1.05h1.434c.577 0 1.05-.473 1.05-1.05V30.59c0-.578-.473-1.05-1.05-1.05h-1.435a3.022 3.022 0 0 1-3.015-3.021V25.08c0-1.661 1.35-3.02 3.015-3.02h1.435a3.022 3.022 0 0 1 3.015 3.02c0 .545-.439 1.018-.983 1.018s-.983-.407-.983-.952c0-.644-.472-1.11-1.049-1.11h-1.435c-.577 0-1.049.473-1.049 1.05v1.439c0 .577.472 1.05 1.05 1.05h1.434a3.022 3.022 0 0 1 3.015 3.02v1.438c0 1.661-1.35 3.02-3.014 3.02v-.006Z" />
            <path d="M33.059 23.814a.983.983 0 0 1-.983-.985v-2.173a.983.983 0 1 1 1.966 0v2.173c0 .545-.44.985-.983.985ZM33.059 37.215a.983.983 0 0 1-.983-.985v-2.174a.983.983 0 1 1 1.966 0v2.174c0 .545-.44.985-.983.985Z" />
        </g>
    </svg>
);

export default Icon7;
