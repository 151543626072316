import * as React from "react";

const Icon6 = ({ ...props }) => (
    <svg
        width={77}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M38.472.45h32.49c2.632 0 4.5 1.257 5.148 3.544.153.572.23 1.22.23 1.83v52.8c0 3.317-2.06 5.376-5.378 5.376H5.715C2.397 64 .338 61.941.338 58.587V5.863C.338 2.47 2.359.449 5.753.449h32.719ZM2.054 11.81V58.625c0 2.478 1.258 3.736 3.737 3.736h65.171c2.479 0 3.737-1.258 3.737-3.736V11.81H2.054Zm72.645-1.754V6.015c0-2.707-1.182-3.927-3.927-3.927H5.258c-1.488.038-2.899.992-3.052 2.44-.19 1.792-.038 3.66-.038 5.528H74.7Z"
            fill="#1B9AD2"
        />
        <path
            d="M46.404 20.197c-.153.381-.229.686-.381.953-4.69 10.636-9.42 21.273-14.11 31.909-.076.19-.114.457-.267.534-.305.19-.686.304-1.03.457-.152-.381-.419-.762-.38-1.106.037-.457.342-.876.533-1.296 4.576-10.37 9.19-20.739 13.766-31.108.115-.267.191-.61.42-.762.267-.191.648-.267.915-.23.229.077.382.42.534.649ZM17.652 36.742c.42.343.801.648 1.144.915 3.051 2.402 6.064 4.803 9.114 7.205.191.153.458.267.534.458.115.343.23.838.077.99-.23.23-.687.23-1.068.23-.153 0-.343-.23-.496-.343-3.546-2.822-7.131-5.643-10.677-8.464-.992-.8-1.03-1.182-.039-1.982 3.547-2.821 7.131-5.642 10.678-8.463.076-.077.19-.153.267-.23.42-.342.877-.533 1.297-.037.419.495.228.953-.268 1.334-2.173 1.715-4.309 3.431-6.482 5.146-1.335 1.068-2.67 2.097-4.08 3.241ZM59.064 36.742l-7.513-5.947c-.991-.8-1.983-1.563-2.974-2.364-.458-.343-.801-.8-.382-1.334.42-.534.916-.343 1.373 0 3.661 2.936 7.36 5.833 11.02 8.73.84.687.84 1.106-.037 1.792-3.623 2.86-7.208 5.718-10.83 8.578-.077.076-.19.152-.267.228-.42.305-.877.42-1.259-.038-.381-.495-.228-.915.267-1.258 2.632-2.058 5.263-4.155 7.856-6.214.877-.686 1.792-1.41 2.746-2.173ZM11.512 6.168c0 .648-.534 1.22-1.22 1.258-.687 0-1.22-.496-1.259-1.182 0-.686.534-1.258 1.182-1.258.649 0 1.259.533 1.259 1.22l.038-.038ZM15.972 6.244c0 .648-.61 1.22-1.259 1.182-.686 0-1.22-.572-1.182-1.258 0-.648.61-1.22 1.259-1.22.648 0 1.22.61 1.22 1.258l-.038.038ZM5.792 4.986c.648 0 1.22.572 1.258 1.22 0 .686-.496 1.22-1.182 1.258-.649 0-1.259-.534-1.259-1.182 0-.61.572-1.258 1.22-1.258l-.037-.038Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon6;
