import * as React from "react";
import "../ChipNav/ChipNav.scss";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CustomerIcon from "../../NavMenu/assets/customer-icon.jsx";
import RecurringIcon from "../../NavMenu/assets/recurring-icon.jsx";
import LockIcon from "../../NavMenu/assets/lock-icon.jsx";
import ReportingIcon from "../../NavMenu/assets/reporting-icon.jsx";
import CartIcon from "../../NavMenu/assets/cart-icon.jsx";
import UpdaterIcon from "../../NavMenu/assets/updater-icon.jsx";
import FraudIcon from "../../NavMenu/assets/fraud-icon.jsx";
import InvoicingIcon from "../../NavMenu/assets/invoicing-icon.jsx";

const StyledChip = styled(Chip)`
    margin: 0.5em;
    padding: 1.7em 0.5em;
    font-size: 0.8em;
    // background-color: #f5f5f5;
    &:hover {
        // background-color: #e4e4e4;
    }
`;

export default function ChipNav() {
    const productSubpages = [
        {
            id: "Account Updater",
            icon: <UpdaterIcon />,
            route: "/features/account-updater"
        },
        {
            id: "Customer Vault",
            icon: <CustomerIcon />,
            route: "/features/customer-vault"
        },
        {
            id: "Custom Fields",
            icon: <CustomerIcon />,
            route: "/features/custom-fields"
        },
        {
            id: "Invoicing",
            icon: <InvoicingIcon />,
            route: "/features/invoicing"
        },
        {
            id: "iQ Protect",
            icon: <FraudIcon />,
            route: "/features/iq-protect"
        },

        {
            id: "Recurring Billing",
            icon: <RecurringIcon />,
            route: "/features/recurring-billing"
        },
        {
            id: "Reporting",
            icon: <ReportingIcon />,
            route: "/features/reporting"
        },
        {
            id: "Shopping Cart",
            icon: <CartIcon />,
            route: "/features/shopping-cart"
        },
        {
            id: "Simple Pay",
            icon: <CartIcon />,
            route: "/features/simple-pay"
        },
        {
            id: "Virtual Terminal",
            icon: <LockIcon />,
            route: "/features/virtual-terminal"
        }
    ];

    return (
        <Grid container>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={11} lg={10}>
                <ul>
                    <Stack
                        direction="row"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                        {productSubpages.map(({ id, icon, route }) => (
                            <li key={id} className="chip-list">
                                <StyledChip
                                    className="chip"
                                    clickable
                                    component="a"
                                    href={route}
                                    icon={icon}
                                    label={id}
                                    sx={{
                                        "& .MuiChip-icon": { height: 30 },
                                        "& .MuiChip-label": { paddingLeft: 1 }
                                    }}
                                />
                            </li>
                        ))}
                    </Stack>
                </ul>
            </Grid>
            <Grid item xs={0.5} lg={1}></Grid>
        </Grid>
    );
}
