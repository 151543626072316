import * as React from "react";

const Icon10 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M36.298 19.744a.958.958 0 0 1-.965-.952V10.68c0-.527.431-.952.965-.952.535 0 .966.425.966.952v8.11a.958.958 0 0 1-.966.953ZM.966 65A.962.962 0 0 1 0 64.048V7.603c0-.527.431-.952.966-.952.534 0 .965.425.965.952v55.48l33.402-.501V50.384c0-.526.431-.952.965-.952.535 0 .966.425.966.952v13.137c0 .52-.425.946-.953.952L.98 65H.966Z" />
            <path d="M16.7 11.64a.958.958 0 0 1-.965-.953V7.654c0-3.173-3.097-5.75-6.902-5.75-.232 0-.457.013-.682.032-3.548.292-6.22 2.748-6.22 5.718a.958.958 0 0 1-.965.952A.958.958 0 0 1 0 7.654C0 3.687 3.432.413 7.99.038 8.267.013 8.55 0 8.833 0c4.867 0 8.833 3.433 8.833 7.654v3.033a.958.958 0 0 1-.965.952Z" />
            <path d="M42.344 11.64H16.701a.958.958 0 0 1-.966-.953V7.654c0-3.173-3.097-5.75-6.902-5.75a.958.958 0 0 1-.966-.952C7.867.425 8.3 0 8.833 0h25.644c4.873 0 8.833 3.433 8.833 7.654v3.033a.958.958 0 0 1-.966.952ZM17.666 9.734h23.712V7.654c0-3.173-3.096-5.75-6.901-5.75H14.652c1.842 1.402 3.007 3.459 3.007 5.75v2.081h.006ZM25.585 51.336c-.186 0-.373-.057-.534-.158a.95.95 0 0 1-.431-.794V18.792c0-.527.431-.952.965-.952h38.45c.534 0 .965.425.965.952v31.592a.958.958 0 0 1-.966.952c-.129 0-.29-.025-.405-.07l-4.436-1.808-4.436 1.808a.995.995 0 0 1-.74 0l-4.43-1.808-4.43 1.808a.995.995 0 0 1-.74 0l-4.43-1.808-4.429 1.808a.995.995 0 0 1-.74 0l-4.43-1.808-4.43 1.808a.922.922 0 0 1-.366.07h-.007Zm24.002-3.858c.122 0 .251.025.367.07l4.43 1.808 4.435-1.809a.995.995 0 0 1 .74 0l3.51 1.428V19.744H26.55v29.219l3.464-1.416a.995.995 0 0 1 .74 0l4.43 1.81 4.43-1.81a.995.995 0 0 1 .74 0l4.429 1.81 4.43-1.81a.92.92 0 0 1 .367-.07h.006Z" />
            <path d="M59.309 39.183h-27.55a.958.958 0 0 1-.965-.952c0-.527.431-.952.966-.952h27.549c.534 0 .965.425.965.952a.958.958 0 0 1-.965.952ZM59.309 33.35h-27.55a.958.958 0 0 1-.965-.952c0-.526.431-.952.966-.952h27.549c.534 0 .965.426.965.952a.958.958 0 0 1-.965.952ZM59.309 27.524h-27.55a.958.958 0 0 1-.965-.952c0-.526.431-.951.966-.951h27.549c.534 0 .965.425.965.951a.958.958 0 0 1-.965.952ZM18.342 54.503H8.151a.958.958 0 0 1-.966-.952c0-.527.431-.952.966-.952h10.191c.535 0 .966.425.966.952a.958.958 0 0 1-.966.952ZM18.342 39.83H8.151a.958.958 0 0 1-.966-.952c0-.527.431-.952.966-.952h10.191c.535 0 .966.425.966.952a.958.958 0 0 1-.966.952ZM18.342 25.15H8.151a.958.958 0 0 1-.966-.951c0-.527.431-.952.966-.952h10.191c.535 0 .966.425.966.952a.958.958 0 0 1-.966.952Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h65v65H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon10;
