import * as React from "react";

const LPIcon4 = (props) => (
    <svg
        width={270}
        height={233}
        viewBox="0 0 270 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            opacity={0.5}
            cx={140.934}
            cy={133.022}
            r={129.066}
            fill="#76CBE7"
        />
        <g clipPath="url(#a)">
            <path
                d="M53.426 66.798v64.889a4.22 4.22 0 0 0 4.223 4.223h104.716a4.22 4.22 0 0 0 4.223-4.223V71.021a4.22 4.22 0 0 0-4.223-4.223H93.728"
                fill="#fff"
            />
            <path
                d="M53.426 66.798v64.889a4.22 4.22 0 0 0 4.223 4.223h104.716a4.22 4.22 0 0 0 4.223-4.223V71.021a4.22 4.22 0 0 0-4.223-4.223H93.728"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M54.91 67.253h106.383a4.39 4.39 0 0 1 4.386 4.389v8.468H54.909V67.253Z"
                fill="#3A859D"
            />
            <path
                d="M93.728 66.798H53.426V52.912H83.66a7.82 7.82 0 0 1 7.487 5.529l2.581 8.367v-.01Z"
                fill="#fff"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </g>
        <circle
            cx={129.066}
            cy={129.066}
            r={128.066}
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeDasharray="10 10"
            className="dashRotate"
        />
        <path
            d="M156.865 181.744c23.931-5.538 38.841-29.428 33.303-53.358-5.538-23.931-29.427-38.842-53.358-33.303-23.931 5.538-38.841 29.427-33.303 53.358 5.538 23.931 29.427 38.841 53.358 33.303Z"
            fill="#fff"
            stroke="#344154"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            d="M160.969 186.022c26.35-7.903 41.304-35.671 33.401-62.02-7.903-26.35-35.67-41.304-62.02-33.401-26.35 7.902-41.304 35.67-33.401 62.02 7.903 26.349 35.67 41.304 62.02 33.401Z"
            stroke="#344154"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeDasharray="2 8"
        />
        <path
            d="M146.046 188.55c28.127 0 50.924-22.797 50.924-50.924"
            stroke="#344154"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            d="m186.447 169.615 23.291 22.396a5.885 5.885 0 0 1 0 8.318 5.886 5.886 0 0 1-8.317 0l-22.391-21.318"
            fill="#3A859D"
        />
        <path
            d="m186.447 169.615 23.291 22.396a5.885 5.885 0 0 1 0 8.318 5.886 5.886 0 0 1-8.317 0l-22.391-21.318 7.417-.989-5.933-1.978 5.933-6.429Z"
            stroke="#344154"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            stroke="#344154"
            strokeLinecap="round"
            strokeDasharray="2 2"
            d="m181.937 182.409 6.92-.865M88.191 67.545l2.616 6.976"
        />
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(51.942 51.428)"
                    d="M0 0h116.12v85.965H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default LPIcon4;
