import * as React from "react";

const Icon5 = (props) => (
    <svg
        width={78}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M46.387 48.91c-7.091 0-12.855-5.7-12.855-12.713 0-7.013 5.764-12.713 12.855-12.713s12.855 5.708 12.855 12.713c0 7.004-5.772 12.712-12.855 12.712Zm0-23.166c-5.827 0-10.562 4.683-10.562 10.445 0 5.762 4.735 10.445 10.562 10.445 5.827 0 10.562-4.683 10.562-10.445 0-5.762-4.735-10.445-10.562-10.445Z" />
            <path d="M46.387 42.075c-3.283 0-5.945-2.64-5.945-5.879 0-3.238 2.67-5.878 5.945-5.878 3.274 0 5.944 2.64 5.944 5.878 0 3.239-2.67 5.88-5.944 5.88Zm0-9.497c-2.018 0-3.652 1.623-3.652 3.61 0 1.989 1.641 3.612 3.652 3.612 2.01 0 3.651-1.623 3.651-3.611 0-1.988-1.64-3.611-3.651-3.611ZM6.651 8.123c-.306 0-.589-.124-.808-.342a1.03 1.03 0 0 1-.244-.365 1.135 1.135 0 0 1 .243-1.235.853.853 0 0 1 .173-.131c.063-.047.134-.078.197-.11.07-.03.149-.053.212-.069.377-.07.761.047 1.036.31.204.21.33.505.33.8a1.134 1.134 0 0 1-.707 1.049c-.141.062-.29.093-.44.093h.008ZM14.881 6.99c0-.63.518-1.135 1.147-1.135.636 0 1.146.505 1.146 1.134a1.147 1.147 0 0 1-2.293 0ZM25.404 8.123c-.306 0-.59-.124-.809-.342a1.115 1.115 0 0 1 0-1.6.853.853 0 0 1 .173-.131c.063-.047.133-.078.196-.11.07-.03.141-.053.212-.069.377-.07.762.047 1.037.31.204.21.33.505.33.8a1.135 1.135 0 0 1-.707 1.049 1.09 1.09 0 0 1-.44.093h.008ZM1.147 70.017A1.137 1.137 0 0 1 0 68.883V1.133C0 .506.51 0 1.147 0c.636 0 1.146.505 1.146 1.134v67.749c0 .629-.51 1.134-1.146 1.134ZM57.184 28.33a1.137 1.137 0 0 1-1.146-1.134V10.709c0-.629.51-1.134 1.146-1.134.636 0 1.147.505 1.147 1.134v16.487c0 .629-.51 1.134-1.147 1.134ZM57.184 66.32a1.137 1.137 0 0 1-1.146-1.134V49.26c0-.63.51-1.134 1.146-1.134.636 0 1.147.505 1.147 1.134v15.927c0 .63-.51 1.134-1.147 1.134Z" />
            <path d="M10.562 78c-.338 0-.675-.016-1.013-.047C4.107 77.503 0 73.581 0 68.821c0-.63.51-1.134 1.147-1.134.636 0 1.146.505 1.146 1.134 0 3.572 3.204 6.523 7.445 6.873.267.023.541.038.816.038 4.555 0 8.27-3.098 8.27-6.911v-3.642c0-.63.51-1.134 1.146-1.134.636 0 1.146.505 1.146 1.134v3.642c0 5.063-4.735 9.179-10.562 9.179h.008Z" />
            <path d="M55.001 78H10.562a1.137 1.137 0 0 1-1.146-1.134c0-.629.51-1.134 1.146-1.134 4.555 0 8.27-3.098 8.27-6.911v-3.642c0-.63.51-1.134 1.146-1.134h44.447c.636 0 1.146.505 1.146 1.134v3.642c0 5.063-4.735 9.179-10.562 9.179h-.008Zm-37.505-2.268h37.505c4.555 0 8.27-3.098 8.27-6.911v-2.509H21.115v2.509c0 2.749-1.405 5.226-3.62 6.911ZM57.184 12.588H1.147A1.137 1.137 0 0 1 0 11.455V1.134C0 .504.51 0 1.147 0h56.037c.637 0 1.147.505 1.147 1.134v10.32c0 .63-.51 1.134-1.147 1.134ZM2.293 10.321h53.745V2.268H2.293v8.053ZM46.387 52.963c-12.03 0-22.412-9.777-26.315-13.979-.4-.434-.4-1.102 0-1.53 3.895-4.2 14.253-13.97 26.315-13.97 12.062 0 22.412 9.777 26.315 13.978.4.435.4 1.103 0 1.53-3.895 4.202-14.245 13.971-26.315 13.971ZM22.49 38.216c4.146 4.286 13.436 12.48 23.896 12.48s19.765-8.186 23.896-12.473c-4.146-4.286-13.444-12.48-23.896-12.48S26.62 33.93 22.49 38.217Z" />
        </g>
    </svg>
);

export default Icon5;
