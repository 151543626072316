import * as React from "react";
import "../Components/Footer/Footer.scss";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import logo from "./assets/iQPro+Logo.png";
import mixpanel from "mixpanel-browser";

const CSNumber = styled(Typography)`
    @media (max-width: 1200px) {
        font-size: 30px !important;
    }
    @media (max-width: 500px) {
        font-size: 20px !important;
    }
`;

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Grid>
            <Grid sx={{ backgroundColor: "primary.main" }}>
                <div className="footer-gradient" />
            </Grid>
            <Grid container direction="row" className="tele-bg">
                <Grid item></Grid>
                <Grid item>
                    <Grid
                        container
                        sx={{
                            p: 1,

                            pr: { xs: 3, md: 8 },
                            pt: { xs: 1.5, sm: 3, md: 4 }
                        }}
                        justifyContent="right"
                        direction="column"
                        className="contact"
                    >
                        <Grid item>
                            <Grid container direction="row">
                                <a
                                    href="tel:8883661325"
                                    aria-label="Call for more info at 8 8 8 3 6 6 1 3 2 5"
                                    onClick={() =>
                                        mixpanel.track(
                                            "Call for more info clicked"
                                        )
                                    }
                                >
                                    <LocalPhoneIcon
                                        fontSize="large"
                                        className="phone-icon"
                                        sx={{ color: "white" }}
                                    />
                                </a>
                                <CSNumber
                                    variant="h6"
                                    color="white"
                                    fontWeight={600}
                                >
                                    <a
                                        href="tel:8883661325"
                                        aria-label="Call for more info at 8 8 8 3 6 6 1 3 2 5"
                                        onClick={() =>
                                            mixpanel.track(
                                                "Call for more info clicked"
                                            )
                                        }
                                    >
                                        (888) 366-1325
                                    </a>
                                </CSNumber>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="white">
                                Call for more info
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                direction={{ xs: "column", lg: "row" }}
                alignItems="center"
                sx={{
                    backgroundColor: "#23354B",
                    color: "white",
                    padding: 5
                }}
            >
                <Grid item lg={5}>
                    <img
                        src={logo}
                        alt="iQ Pro+ Footer Logo"
                        className="beta-footer-logo"
                    />
                </Grid>
                <Grid item xs lg></Grid>
                <Grid item lg={4}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className="beta-footer-links"
                            >
                                © {currentYear} iQ Pro
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className="beta-footer-links"
                            >
                                iQ Pro is a registered service mark of BASYS
                                Processing.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className="beta-footer-links"
                            >
                                All rights reserved.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className="beta-footer-links"
                            >
                                <a href="https://basyspro.com/privacy-policy/">
                                    Privacy Policy
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className="beta-footer-links"
                            >
                                <a href="/terms-of-service">Terms of Service</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
