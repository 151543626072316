import * as React from "react";

function CircleArrows() {
    return (
        <>
            <path
                d="M26.1162 33.0184C26.1162 27.3211 30.7523 22.6881 36.4465 22.6881H37.5627L35.1774 25.0734C34.9388 25.3119 34.9388 25.7003 35.1774 25.9388C35.2966 26.0581 35.4526 26.1193 35.6086 26.1193C35.7645 26.1193 35.9205 26.0581 36.0398 25.9388L39.4679 22.5107C39.5841 22.3945 39.6483 22.2416 39.6483 22.0795C39.6483 21.9174 39.5841 21.7615 39.4679 21.6483L36.0398 18.2202C35.8012 17.9817 35.4128 17.9817 35.1743 18.2202C34.9358 18.4587 34.9358 18.8471 35.1743 19.0856L37.5596 21.471H36.4434C30.0734 21.471 24.8899 26.6544 24.8899 33.0245C24.8899 36.1865 26.1437 39.1376 28.4159 41.3333C28.5352 41.4495 28.6881 41.5046 28.841 41.5046C28.9939 41.5046 29.1621 41.4434 29.2813 41.318C29.5168 41.0765 29.5107 40.6881 29.2661 40.4526C27.2324 38.4893 26.1131 35.8502 26.1131 33.0214L26.1162 33.0184Z 
        M48 33.0184C48 29.8563 46.7462 26.9052 44.474 24.7064C44.2324 24.471 43.844 24.4771 43.6086 24.7217C43.3731 24.9633 43.3792 25.3517 43.6238 25.5872C45.6575 27.5535 46.7768 30.1927 46.7768 33.0184C46.7768 38.7156 42.1407 43.3486 36.4465 43.3486H35.3303L37.7156 40.9633C37.9541 40.7248 37.9541 40.3364 37.7156 40.0979C37.4771 39.8593 37.0887 39.8593 36.8502 40.0979L33.422 43.526C33.1835 43.7645 33.1835 44.1529 33.422 44.3914L36.8502 47.8196C36.9694 47.9388 37.1254 48 37.2813 48C37.4373 48 37.5933 47.9388 37.7125 47.8196C37.9511 47.581 37.9511 47.1927 37.7125 46.9541L35.3272 44.5688H36.4434C42.8135 44.5688 47.9969 39.3853 47.9969 33.0153L48 33.0184Z"
                fill="#4C5B8C"
                className="iconSpin"
            />
        </>
    );
}

const RecurringIcon = (props) => (
    <svg
        width={48}
        height={35}
        viewBox="0 0 70 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M40.9647 40.7447C45.2378 36.4717 45.2378 29.5436 40.9647 25.2706C36.6916 20.9975 29.7636 20.9975 25.4905 25.2706C21.2174 29.5436 21.2174 36.4717 25.4905 40.7447C29.7636 45.0178 36.6916 45.0178 40.9647 40.7447Z"
            fill="#0288D1"
        />
        <path
            d="M40.9647 40.7447C45.2378 36.4717 45.2378 29.5436 40.9647 25.2706C36.6916 20.9975 29.7636 20.9975 25.4905 25.2706C21.2174 29.5436 21.2174 36.4717 25.4905 40.7447C29.7636 45.0178 36.6916 45.0178 40.9647 40.7447Z"
            fill="white"
            fillOpacity={0.6}
        />
        <CircleArrows />
        <path
            d="M5.10704 42.3639C4.49847 42.3639 4.00306 42.8593 4.00306 43.4679C4.00306 44.0765 4.49847 44.5719 5.10704 44.5719C5.7156 44.5719 6.21101 44.0765 6.21101 43.4679C6.21101 42.8593 5.7156 42.3639 5.10704 42.3639Z"
            fill="#4C5B8C"
        />
        <path
            d="M11.9174 42.3639C11.3089 42.3639 10.8135 42.8593 10.8135 43.4679C10.8135 44.0765 11.3089 44.5719 11.9174 44.5719C12.526 44.5719 13.0214 44.0765 13.0214 43.4679C13.0214 42.8593 12.526 42.3639 11.9174 42.3639Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.7248 42.3639C18.1162 42.3639 17.6208 42.8593 17.6208 43.4679C17.6208 44.0765 18.1162 44.5719 18.7248 44.5719C19.3333 44.5719 19.8287 44.0765 19.8287 43.4679C19.8287 42.8593 19.3333 42.3639 18.7248 42.3639V42.3639Z"
            fill="#4C5B8C"
        />
        <path
            d="M8.33027 11.7982C7.99388 11.7982 7.71865 12.0734 7.71865 12.4098C7.71865 12.7462 7.99388 13.0214 8.33027 13.0214H12.4098C12.7462 13.0214 13.0214 12.7462 13.0214 12.4098C13.0214 12.0734 12.7462 11.7982 12.4098 11.7982H8.33027Z"
            fill="#4C5B8C"
        />
        <path
            d="M22.0092 25.8349H1.22324V20.2691H27.9021C28.2385 20.2691 28.5138 19.9939 28.5138 19.6575C28.5138 19.3211 28.2385 19.0459 27.9021 19.0459H1.22324V4.50765C1.22324 2.69725 2.69725 1.22324 4.50765 1.22324H38.7982C40.6086 1.22324 42.0826 2.69725 42.0826 4.50765V18.7645C42.0826 19.1009 42.3578 19.3761 42.6942 19.3761C43.0306 19.3761 43.3058 19.1009 43.3058 18.7645V4.50765C43.3058 2.02141 41.2844 0 38.7982 0H4.50765C2.02141 0 0 2.02141 0 4.50765V29.1131C0 31.5994 2.02141 33.6208 4.50765 33.6208H20.581C20.9174 33.6208 21.1927 33.3456 21.1927 33.0092C21.1927 32.6728 20.9174 32.3976 20.581 32.3976H4.50765C2.69725 32.3976 1.22324 30.9235 1.22324 29.1131V27.0612H22.0092C22.3456 27.0612 22.6208 26.7859 22.6208 26.4495C22.6208 26.1131 22.3456 25.8379 22.0092 25.8379V25.8349Z"
            fill="#4C5B8C"
        />
    </svg>
);

export default RecurringIcon;
