import * as React from "react";

const Icon2 = (props) => (
    <svg
        width={100}
        height={73}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M71.945 73H1.055A1.056 1.056 0 0 1 0 71.94V1.06C0 .472.471 0 1.056 0h60.903c.584 0 1.056.473 1.056 1.06v10.005h8.93c.584 0 1.055.473 1.055 1.06V71.94c0 .586-.472 1.059-1.055 1.059ZM2.11 70.882H70.89V13.183h-8.93a1.056 1.056 0 0 1-1.055-1.059V2.118H2.11v68.764Z" />
            <path d="M71.945 13.184h-9.986a1.056 1.056 0 0 1-1.055-1.06V1.06A1.056 1.056 0 0 1 62.74.346l9.985 11.065a1.058 1.058 0 0 1-.781 1.772Zm-8.93-2.119h6.544l-6.544-7.259v7.26ZM16.867 58.757h-6.424a1.056 1.056 0 0 1-1.056-1.059V33.061c0-.586.472-1.059 1.056-1.059h6.424c.584 0 1.056.473 1.056 1.06v24.63c0 .585-.472 1.058-1.056 1.058v.007Zm-5.369-2.118h4.314V34.127h-4.314V56.64ZM27.908 58.757h-6.425a1.056 1.056 0 0 1-1.055-1.059v-27.61c0-.586.471-1.059 1.055-1.059h6.425c.584 0 1.056.473 1.056 1.06v27.61c0 .585-.472 1.058-1.056 1.058ZM22.54 56.64h4.313V31.147H22.54V56.64ZM38.942 58.757h-6.425a1.056 1.056 0 0 1-1.055-1.059V26.904c0-.586.471-1.06 1.055-1.06h6.425c.584 0 1.055.474 1.055 1.06v30.794c0 .586-.471 1.06-1.055 1.06Zm-5.37-2.118h4.314V27.963h-4.313v28.676ZM49.983 58.757h-6.425a1.056 1.056 0 0 1-1.056-1.059V22.646c0-.586.472-1.06 1.056-1.06h6.425c.584 0 1.055.474 1.055 1.06V57.69c0 .586-.471 1.06-1.055 1.06v.006Zm-5.37-2.118h4.314V23.705h-4.313v32.934ZM61.016 58.757h-6.424a1.056 1.056 0 0 1-1.056-1.059v-38.47c0-.586.472-1.06 1.056-1.06h6.424c.584 0 1.056.474 1.056 1.06v38.47c0 .586-.472 1.06-1.056 1.06Zm-5.369-2.118h4.314V20.287h-4.314V56.64Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h73v73H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon2;
