import * as React from "react";

const Icon1 = (props) => (
    <svg
        width={81}
        height={59}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M24.636 17.933h-12.65v9.095h12.65v-9.095ZM80.064 36.585H.936v10.612h79.128V36.585Z" />
            <path d="M74.232 0H6.768C3.038 0 0 3.055 0 6.808v45.384C0 55.944 3.037 59 6.768 59h67.464C77.962 59 81 55.944 81 52.192V6.808C81 3.055 77.963 0 74.232 0ZM6.768 1.883h67.464c2.7 0 4.896 2.208 4.896 4.925v28.84H1.872V6.809c0-2.717 2.195-4.925 4.896-4.925Zm72.36 44.377H1.872v-8.728h77.256v8.728Zm-4.896 10.857H6.768c-2.7 0-4.896-2.208-4.896-4.925V48.14h77.256v4.053c0 2.717-2.195 4.925-4.896 4.925Z" />
            <path d="M11.986 27.97h12.65a.942.942 0 0 0 .936-.942v-9.095a.942.942 0 0 0-.936-.942h-12.65a.942.942 0 0 0-.936.942v9.095c0 .518.421.942.936.942Zm.936-9.096H23.7v7.213H12.922v-7.213ZM42.117 27.97a.942.942 0 0 0 .936-.942V21.78a.942.942 0 0 0-.936-.942.942.942 0 0 0-.936.942v5.25c0 .517.421.94.936.94ZM49.652 27.97a.942.942 0 0 0 .936-.942V21.78a.942.942 0 0 0-.936-.942.942.942 0 0 0-.936.942v5.25c0 .517.422.94.936.94ZM57.187 27.97a.942.942 0 0 0 .936-.942V21.78a.942.942 0 0 0-.936-.942.942.942 0 0 0-.936.942v5.25c0 .517.421.94.936.94ZM64.727 27.97a.942.942 0 0 0 .936-.942V21.78a.942.942 0 0 0-.936-.942.942.942 0 0 0-.936.942v5.25c0 .517.421.94.936.94Z" />
        </g>
    </svg>
);

export default Icon1;
