import * as React from "react";

const LPIcon3 = (props) => (
    <svg
        width={270}
        height={233}
        viewBox="0 0 270 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            opacity={0.5}
            cx={129.066}
            cy={133.022}
            r={129.066}
            fill="#76CBE7"
        />
        <circle
            cx={140.197}
            cy={129.066}
            r={128.066}
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeDasharray="10 10"
            className="dashRotate"
        />
        <path
            d="m134.473 96.42 93.289 86.554s-.669 20.999-38.111 22.727c-37.441 1.727-33.122-12.45-33.122-12.45l-22.056-96.83Z"
            fill="#8DE0FC"
        />
        <path
            stroke="#000"
            strokeDasharray="6 6"
            d="m140.359 107.635 82.016 77.046M138.002 107.833l28.582 80.774"
        />
        <path
            d="M224.909 185.609c.006.549-.437 1.348-1.939 2.285-1.444.901-3.605 1.758-6.367 2.502-5.51 1.483-13.186 2.458-21.715 2.551-8.528.093-16.225-.714-21.765-2.077-2.778-.683-4.957-1.492-6.42-2.361-1.523-.905-1.983-1.694-1.989-2.243-.006-.548.437-1.347 1.939-2.285 1.444-.901 3.605-1.757 6.368-2.501 5.509-1.483 13.186-2.458 21.714-2.552 8.529-.093 16.225.715 21.765 2.077 2.778.684 4.957 1.493 6.421 2.362 1.522.905 1.982 1.694 1.988 2.242Z"
            fill="#fff"
            stroke="#344154"
            strokeWidth={2}
        />
        <g clipPath="url(#a)">
            <path
                d="M123.364 106.759a11.66 11.66 0 0 1-4.486-.895l15.111-15.11c1.765 4.2.932 9.22-2.473 12.637a11.457 11.457 0 0 1-8.152 3.381v-.013Z"
                fill="#10B2E5"
            />
            <path
                d="M118.941 66.633 94.758 90.816l-1.628-1.628c-6.673-6.674-6.673-17.497 0-24.183 6.674-6.673 17.497-6.673 24.183 0l1.628 1.628Z"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
            />
            <path
                d="M94.87 84.54c-3.591-4.983-3.144-11.98 1.33-16.465a12.64 12.64 0 0 1 9.009-3.728c2.709 0 5.294.845 7.444 2.398L94.858 84.54h.012Z"
                fill="#3A859D"
            />
            <path
                d="m117.636 67.925-10.787 10.787-10.786 10.786s-15.707 14.813-4.909 37.927l32.211-32.21 32.197-32.198c-23.126-10.799-37.926 4.908-37.926 4.908Z"
                fill="#fff"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
            />
            <path
                d="M112.131 106.461c6.201 6.201 16.266 6.201 22.467 0 6.201-6.201 6.201-16.267 0-22.468M88.942 84.876l-27.338 27.338c-10.824 10.824-10.824 28.371 0 39.194l24.704 24.705"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
            />
            <path
                d="M141.533 176.1H67.519c-7.755 0-14.03 6.288-14.03 14.03h102.073c0-7.754-6.288-14.03-14.029-14.03Z"
                fill="#fff"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
            />
            <path
                d="M137.842 176.1v-4.97a2.485 2.485 0 0 0-3.132-2.399l-7.369 1.989-7.369-1.989a2.49 2.49 0 0 0-3.131 2.399v4.97"
                stroke="#344154"
                strokeWidth={2}
                strokeMiterlimit={10}
            />
        </g>
        <path
            stroke="#344154"
            strokeLinecap="round"
            strokeDasharray="2 2"
            d="m88.567 190.476 7.594-4.865"
        />
        <path
            d="m94.498 182.873-10.236 7.456H72.424l22.074-7.456Z"
            fill="#344154"
        />
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(51.625 57.363)"
                    d="M0 0h107.143v134.631H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default LPIcon3;
