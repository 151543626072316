import React from "react";
import "./style.scss";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SignIn1 from "./assets/signin-1.png";
import SignIn2 from "./assets/signin-2.png";
import SignIn3 from "./assets/signin-3.png";
import SignIn4 from "./assets/signin-4.png";
import SignIn5 from "./assets/signin-5.png";
import SignIn6 from "./assets/signin-6.png";
import SignIn7 from "./assets/signin-7.png";
import SignIn8 from "./assets/signin-8.png";
import SignIn9 from "./assets/signin-9.png";

const OnboardingConversion = () => {
    {
        return (
            <Grid>
                <Navbar />
                {/* TITLE */}
                <Grid align="center" sx={{ mb: 5 }}>
                    <Typography
                        variant="h1"
                        fontWeight="500"
                        color="secondary"
                        className="onboarding-title"
                    >
                        Signing Into iQ Pro+ for the First Time
                    </Typography>
                    <Typography variant="h5" fontWeight={300} align="center">
                        To Login to iQ Pro+ for the First Time as a Gateway
                        user, follow the steps below:
                    </Typography>
                </Grid>
                {/* CONTENT START */}
                <Grid container direction="row">
                    <Grid item xs={1} lg></Grid>
                    <Grid item xs={10} lg={6}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Grid container direction="column">
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        1. Once you are added as a user in iQ
                                        Pro+, you will receive an email with a
                                        registration link.
                                    </Typography>
                                    <img src={SignIn1} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        2. Click on &quot;Sign In&quot;.
                                    </Typography>
                                    <img src={SignIn2} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        3. Your email address will be
                                        pre-filled.
                                    </Typography>
                                    <img src={SignIn3} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        4. Enter a password and confirm your new
                                        password.
                                    </Typography>
                                    <img src={SignIn4} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        5. Then, press &quot;Create&quot; to set
                                        your password.
                                    </Typography>
                                    <img src={SignIn5} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        6. Your password will be created and you
                                        will be directed to the sign-in screen.
                                        Enter your email address and password.
                                    </Typography>
                                    <img src={SignIn6} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        7. Press Sign In and you will be signed
                                        into iQ Pro+.
                                    </Typography>
                                    <img src={SignIn7} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        8. If you logged in using gateway user
                                        login credentials and this is your first
                                        time logging in, you will be prompted to
                                        accept Terms of Service (TOS).
                                    </Typography>
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        Once TOS is accepted, you will be
                                        directed to the Virtual Terminal.
                                    </Typography>
                                    <img src={SignIn8} className="ob-steps" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="left"
                                >
                                    <Typography
                                        fontWeight="500"
                                        color="secondary"
                                        align="left"
                                        sx={{ mb: 2 }}
                                    >
                                        9. If you have already accepted Terms of
                                        Service (TOS), you will be directed to
                                        the Virtual Terminal.
                                    </Typography>
                                    <img src={SignIn9} className="ob-steps" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs lg></Grid>
                </Grid>

                <Footer />
            </Grid>
        );
    }
};

export default OnboardingConversion;
