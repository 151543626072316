import * as React from "react";

const Icon2 = (props) => (
    <svg
        width={69}
        height={70}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M35.199 40.26c-11.097 0-20.132-9.028-20.132-20.13C15.067 9.027 24.095 0 35.199 0 46.302 0 55.33 9.027 55.33 20.13c0 11.102-9.028 20.13-20.131 20.13Zm0-38.245c-9.989 0-18.117 8.127-18.117 18.114 0 9.988 8.128 18.115 18.117 18.115 9.988 0 18.116-8.127 18.116-18.114 0-9.988-8.128-18.115-18.116-18.115ZM11.05 67.454H1.008C.45 67.454 0 67.004 0 66.447V44.712c0-.557.45-1.007 1.008-1.007H11.05c.557 0 1.007.45 1.007 1.007v21.735c0 .557-.45 1.007-1.007 1.007ZM2.015 65.44h8.027V45.72H2.015v19.72Z" />
            <path d="M39.82 70c-.067 0-.14-.007-.208-.02l-28.77-6.032c-.464-.1-.8-.51-.8-.987V46.5c0-.376.209-.726.545-.894a13.313 13.313 0 0 0 2.545-1.712c1.848-1.585 4.75-2.364 7.658-1.995 1.317.168 2.566.57 3.607 1.169 1.995 1.135 4.125 1.645 6.153 1.477l7.84-.665c.282-.026.557.074.765.262.209.188.33.457.33.74 0 4.096-3.413 7.716-7.605 8.072l-3.788.323 11.48 4.5 21.126-5.924c3.338-1.075 6.992.719 8.114 3.996a3.387 3.387 0 0 1-.181 2.633 3.52 3.52 0 0 1-2.083 1.773L40.17 69.933c-.114.04-.228.06-.349.06V70Zm-27.762-7.852 27.688 5.803 26.15-9.59c.437-.142.76-.41.941-.773.168-.336.195-.726.074-1.081-.766-2.25-3.291-3.473-5.629-2.72L39.793 59.81a.99.99 0 0 1-.638-.034l-15.819-6.199a1.007 1.007 0 0 1 .282-1.941l8.095-.685c2.787-.235 5.118-2.364 5.649-4.957l-6.637.564c-2.438.209-4.97-.39-7.322-1.733-.82-.463-1.807-.785-2.861-.92-2.345-.302-4.669.303-6.093 1.525a15.294 15.294 0 0 1-2.391 1.679v15.038ZM36.434 30.789h-2.471a4.51 4.51 0 0 1-4.501-4.5c0-.558.45-1.008 1.008-1.008.557 0 1.007.45 1.007 1.008a2.488 2.488 0 0 0 2.485 2.485h2.472a2.488 2.488 0 0 0 2.486-2.485v-2.472a2.488 2.488 0 0 0-2.486-2.485h-2.471a4.51 4.51 0 0 1-4.501-4.5V14.36c0-2.485 2.022-4.5 4.5-4.5h2.472a4.51 4.51 0 0 1 4.501 4.5v.107c0 .558-.45 1.008-1.008 1.008-.557 0-1.007-.45-1.007-1.008v-.107a2.488 2.488 0 0 0-2.486-2.485h-2.471a2.488 2.488 0 0 0-2.486 2.485v2.472a2.488 2.488 0 0 0 2.485 2.485h2.472a4.51 4.51 0 0 1 4.501 4.5v2.472a4.51 4.51 0 0 1-4.5 4.5Z" />
            <path d="M35.199 11.485c-.558 0-1.008-.45-1.008-1.007V6.75c0-.557.45-1.007 1.008-1.007.557 0 1.007.45 1.007 1.007v3.728c0 .557-.45 1.007-1.008 1.007ZM35.199 34.517c-.558 0-1.008-.45-1.008-1.008v-3.728c0-.557.45-1.007 1.008-1.007.557 0 1.007.45 1.007 1.007v3.728c0 .557-.45 1.008-1.008 1.008Z" />
        </g>
    </svg>
);

export default Icon2;
