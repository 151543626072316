import * as React from "react";

const Icon1 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M34.029 14.597c.42 0 .764-.343.764-.764a.766.766 0 0 0-.764-.764h-12.23a.766.766 0 0 0-.765.764c0 .42.344.764.764.764h12.23ZM45.11 20.535H10.708a.766.766 0 0 0-.764.764c0 .42.343.764.764.764H45.11c.42 0 .764-.344.764-.764a.766.766 0 0 0-.764-.764ZM45.875 28.76a.766.766 0 0 0-.764-.764H10.707a.766.766 0 0 0-.764.764c0 .42.343.764.764.764H45.11c.42 0 .764-.344.764-.764ZM10.707 35.457a.766.766 0 0 0-.764.764c0 .42.343.764.764.764H27.91c.42 0 .764-.344.764-.764a.766.766 0 0 0-.764-.764H10.707ZM65.588 53.516l-25.17-13.047a.763.763 0 0 0-.855.104.77.77 0 0 0-.217.832l3.757 10.484a.774.774 0 0 0 .443.457l4.816 1.848-4.816 1.848a.75.75 0 0 0-.443.457l-3.757 10.484a.77.77 0 0 0 .719 1.021c.117 0 .24-.027.352-.085l25.171-13.047a.763.763 0 0 0 0-1.356ZM41.44 65.668l2.984-8.324 6.344-2.436a.765.765 0 0 0 0-1.428l-6.344-2.436-2.984-8.324 22.137 11.474L41.44 65.668Z" />
            <path d="M19.311 64.439H4.155a2.633 2.633 0 0 1-2.631-2.63V4.153c0-1.45 1.18-2.63 2.631-2.63h38.758v10.615c0 .42.344.764.765.764h10.616V43.57c0 .42.344.764.764.764.42 0 .764-.343.764-.764V12.14a.762.762 0 0 0-.221-.538L44.215.22A.76.76 0 0 0 43.678 0H4.156A4.157 4.157 0 0 0 0 4.153V61.81a4.157 4.157 0 0 0 4.155 4.153h15.156c.42 0 .764-.344.764-.764a.766.766 0 0 0-.764-.764v.005Zm25.126-53.064V2.608l8.776 8.771h-8.776v-.004Z" />
            <path d="M37.777 53.43h-2.713a.766.766 0 0 0-.764.764c0 .42.343.764.764.764h2.713c.42 0 .764-.344.764-.764a.766.766 0 0 0-.764-.764ZM31.754 54.194a.766.766 0 0 0-.764-.764h-2.713a.766.766 0 0 0-.764.764c0 .42.344.764.764.764h2.713c.42 0 .764-.344.764-.764ZM33.364 47.325h-2.713a.766.766 0 0 0-.764.764c0 .42.344.763.764.763h2.713c.42 0 .764-.343.764-.763a.766.766 0 0 0-.764-.764ZM26.577 47.325h-2.713a.766.766 0 0 0-.764.764c0 .42.344.763.764.763h2.713c.42 0 .764-.343.764-.763a.766.766 0 0 0-.764-.764ZM26.577 59.536h-2.713a.766.766 0 0 0-.764.763c0 .42.344.764.764.764h2.713c.42 0 .764-.343.764-.764a.766.766 0 0 0-.764-.763ZM33.364 59.536h-2.713a.766.766 0 0 0-.764.763c0 .42.344.764.764.764h2.713c.42 0 .764-.343.764-.764a.766.766 0 0 0-.764-.763Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h66v68H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon1;
