import * as React from "react";

const Icon4 = (props) => (
    <svg
        width={76}
        height={90}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M36.03 36.101H14.483a.974.974 0 0 1-.969-1.08l.813-7.407a3.326 3.326 0 0 1 3.324-3.265h4.65v-1.801a.974.974 0 0 1 1.463-.845c.956.546 2.016.526 2.979-.053a.981.981 0 0 1 .982-.02c.305.177.494.502.494.853v1.873h4.65a3.33 3.33 0 0 1 3.324 3.265l.812 7.407a.966.966 0 0 1-.24.755.976.976 0 0 1-.729.325l-.006-.007Zm-20.454-1.95h19.368l-.696-6.374v-.104c0-.761-.618-1.372-1.372-1.372H27.25a.974.974 0 0 1-.976-.976v-1.398a4.684 4.684 0 0 1-2.016.026v1.372c0 .54-.435.976-.975.976h-5.626c-.76 0-1.372.617-1.372 1.372v.104l-.696 6.373h-.013Z" />
            <path d="M25.201 24.044a4.845 4.845 0 0 1-2.406-.65c-2.179-1.236-3.583-4.052-3.583-7.167 0-.248.013-.488.026-.729a9.848 9.848 0 0 1 .507-2.504c.962-2.783 3.109-4.585 5.456-4.585 2.348 0 4.494 1.802 5.457 4.585.214.611.364 1.262.449 1.932.058.468.084.89.084 1.307 0 3.03-1.352 5.814-3.453 7.082-.8.488-1.659.735-2.543.735l.006-.006Zm0-13.69c-1.508 0-2.926 1.288-3.616 3.271a8.064 8.064 0 0 0-.41 2.003c-.012.195-.019.39-.019.592 0 2.393 1.047 4.591 2.602 5.47.956.546 2.016.526 2.978-.053 1.529-.923 2.517-3.05 2.517-5.417 0-.332-.026-.676-.071-1.06a7.49 7.49 0 0 0-.358-1.541c-.69-1.99-2.107-3.272-3.616-3.272l-.007.007ZM59.06 67h-.255c-1.489-.065-2.81-.69-3.72-1.769l-17.982-20.72a.984.984 0 0 1-.234-.761.993.993 0 0 1 .416-.683 22.492 22.492 0 0 0 3.154-2.621 22.477 22.477 0 0 0 2.621-3.16.988.988 0 0 1 .683-.417.953.953 0 0 1 .761.234l20.733 17.989c1.067.904 1.698 2.224 1.763 3.714.085 1.983-.82 4.07-2.471 5.723-1.58 1.58-3.564 2.471-5.47 2.471ZM39.274 44.03l17.293 19.926c.573.677 1.373 1.047 2.322 1.093 1.437.058 3.011-.65 4.26-1.9 1.249-1.248 1.958-2.841 1.899-4.259-.04-.95-.416-1.75-1.08-2.309l-19.933-17.3a23.812 23.812 0 0 1-4.76 4.76v-.012Z" />
            <path d="M24.499 48.998a24.476 24.476 0 0 1-17.332-7.18c-9.554-9.554-9.554-25.097 0-34.651C11.784 2.543 17.943 0 24.493 0 31.041 0 37.2 2.543 41.817 7.167c8.357 8.357 9.56 21.494 2.849 31.224a24.67 24.67 0 0 1-2.849 3.427 24.67 24.67 0 0 1-3.427 2.849 24.465 24.465 0 0 1-13.892 4.331Zm0-47.047c-5.775 0-11.55 2.198-15.947 6.595-8.8 8.793-8.8 23.1-.006 31.893 7.694 7.694 19.777 8.8 28.74 2.621a22.493 22.493 0 0 0 3.153-2.62 22.469 22.469 0 0 0 2.621-3.161c6.172-8.956 5.073-21.046-2.62-28.74-4.397-4.396-10.172-6.594-15.947-6.594l.006.006Zm.033 42.527c-5.184 0-10.335-1.99-14.178-5.834-7.811-7.804-7.811-20.499-.007-28.297 7.804-7.804 20.5-7.804 28.297 0 6.647 6.647 7.746 17.183 2.628 25.059a20.29 20.29 0 0 1-2.628 3.238 20.29 20.29 0 0 1-3.238 2.628 19.914 19.914 0 0 1-10.88 3.213l.006-.007Zm-.033-38.033a18.002 18.002 0 0 0-12.766 5.281c-7.044 7.043-7.044 18.496 0 25.54 5.996 5.996 15.504 6.99 22.612 2.367a18.284 18.284 0 0 0 2.927-2.367 18.006 18.006 0 0 0 2.367-2.927c4.624-7.108 3.63-16.617-2.367-22.613a18.002 18.002 0 0 0-12.766-5.28h-.007Z" />
        </g>
    </svg>
);

export default Icon4;
