import * as React from "react";
import "../../base.scss";

const UpdaterIcon = (props) => (
    <svg
        width="48"
        height="35"
        viewBox="0 0 70 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M35.5963 35.1132H0V41.3486H35.5963V35.1132Z" fill="#0288D1" />
        <path
            d="M35.5963 35.1132H0V41.3486H35.5963V35.1132Z"
            fill="white"
            fillOpacity="0.6"
        />
        <path
            d="M8.52913 30.3333H11.9817C12.3181 30.3333 12.5934 30.0581 12.5934 29.7217C12.5934 29.3853 12.3181 29.1101 11.9817 29.1101H8.52913C8.19274 29.1101 7.91751 29.3853 7.91751 29.7217C7.91751 30.0581 8.19274 30.3333 8.52913 30.3333Z"
            fill="#4C5B8C"
        />
        <path
            d="M45.4832 0H16.4771C14.3242 0 12.5719 1.75229 12.5719 3.9052V15.0642C12.5719 15.4006 12.8472 15.6758 13.1836 15.6758C13.5199 15.6758 13.7952 15.4006 13.7952 15.0642V3.9052C13.7952 2.42508 15.0001 1.22324 16.4771 1.22324H45.4832C46.9634 1.22324 48.1652 2.42813 48.1652 3.9052V15.1254H37.5536C37.2172 15.1254 36.942 15.4006 36.942 15.737C36.942 16.0734 37.2172 16.3486 37.5536 16.3486H48.1652V21.3609H42.4129C42.0765 21.3609 41.8013 21.6361 41.8013 21.9725C41.8013 22.3089 42.0765 22.5841 42.4129 22.5841H48.1652V24.7187C48.1652 26.1988 46.9603 27.4006 45.4832 27.4006H42.5199C42.1835 27.4006 41.9083 27.6758 41.9083 28.0122C41.9083 28.3486 42.1835 28.6239 42.5199 28.6239H45.4832C47.6361 28.6239 49.3884 26.8716 49.3884 24.7187V3.9052C49.3884 1.75229 47.6361 0 45.4832 0Z"
            fill="#4C5B8C"
        />
        <path
            d="M34.2997 19.3761H5.29363C3.14072 19.3761 1.38843 21.1284 1.38843 23.2813V44.0948C1.38843 46.2477 3.14072 48 5.29363 48H34.2997C36.4526 48 38.2049 46.2477 38.2049 44.0948V23.2813C38.2049 21.1284 36.4526 19.3761 34.2997 19.3761ZM5.29363 20.5994H34.2997C35.7799 20.5994 36.9817 21.8043 36.9817 23.2813V34.5015H2.61167V23.2813C2.61167 21.8012 3.81656 20.5994 5.29363 20.5994V20.5994ZM36.9817 40.737H2.61167V35.7248H36.9848V40.737H36.9817ZM34.2997 46.7768H5.29363C3.8135 46.7768 2.61167 45.5719 2.61167 44.0948V41.9602H36.9848V44.0948C36.9848 45.5749 35.7799 46.7768 34.3028 46.7768H34.2997Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.1621 10.9572C23.4985 10.9572 23.7737 10.682 23.7737 10.3456C23.7737 10.0092 23.4985 9.73395 23.1621 9.73395H19.7095C19.3731 9.73395 19.0979 10.0092 19.0979 10.3456C19.0979 10.682 19.3731 10.9572 19.7095 10.9572H23.1621Z"
            fill="#4C5B8C"
        />
        <path
            d="M61.1981 23.6645C61.1981 20.0626 59.7699 16.7011 57.1817 14.1965C56.9065 13.9283 56.4641 13.9352 56.1959 14.2139C55.9276 14.4891 55.9346 14.9315 56.2133 15.1997C58.5298 17.4396 59.8047 20.4458 59.8047 23.6645C59.8047 30.1542 54.5238 35.4317 48.0376 35.4317H46.7661L49.4832 32.7146C49.7549 32.4428 49.7549 32.0004 49.4832 31.7287C49.2115 31.457 48.7691 31.457 48.4974 31.7287L44.5925 35.6337C44.3207 35.9054 44.3207 36.3478 44.5925 36.6195L48.4974 40.5245C48.6333 40.6603 48.8109 40.73 48.9886 40.73C49.1662 40.73 49.3439 40.6603 49.4797 40.5245C49.7515 40.2528 49.7515 39.8104 49.4797 39.5387L46.7626 36.8216H48.0341C55.2902 36.8216 61.1946 30.9171 61.1946 23.661L61.1981 23.6645Z"
            fill="#4C5B8C"
            className="iconDanceX"
        />
    </svg>
);

export default UpdaterIcon;
