import * as React from "react";

const Icon9 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M25.326 61H4.24C1.903 61 0 59.093 0 56.745V4.249A4.25 4.25 0 0 1 4.24 0h21.086a4.256 4.256 0 0 1 4.247 4.249v11.52c0 .24-.098.473-.27.644a17.501 17.501 0 0 0-5.256 12.557c0 3.489 1.016 6.861 2.944 9.755a.899.899 0 0 1 .067.877l-3.267 7.07 4.51-2.091a.9.9 0 0 1 1.278.815v11.349A4.257 4.257 0 0 1 25.332 61h-.006ZM4.24 1.803a2.453 2.453 0 0 0-2.447 2.452v52.49a2.453 2.453 0 0 0 2.447 2.452h21.086a2.453 2.453 0 0 0 2.448-2.452v-9.939l-5.458 2.532a.906.906 0 0 1-1.016-.178.9.9 0 0 1-.177-1.017l4.093-8.848A19.377 19.377 0 0 1 22.25 28.97c0-5.12 1.958-9.92 5.525-13.574V4.249a2.453 2.453 0 0 0-2.448-2.453H4.24v.007Z" />
            <path d="M21.937 49.418a.9.9 0 0 1-.814-1.281l4.093-8.848a19.377 19.377 0 0 1-2.967-10.325c0-5.248 2.056-10.16 5.794-13.844a19.242 19.242 0 0 1 13.584-5.574c10.684 0 19.38 8.713 19.38 19.418s-8.696 19.418-19.38 19.418c-3.763 0-7.397-1.08-10.536-3.121l-8.775 4.071a.924.924 0 0 1-.38.086ZM41.62 11.355a17.457 17.457 0 0 0-12.323 5.058 17.502 17.502 0 0 0-5.256 12.557c0 3.489 1.015 6.861 2.943 9.755a.899.899 0 0 1 .067.877l-3.267 7.07 7.006-3.256a.901.901 0 0 1 .887.073 17.456 17.456 0 0 0 9.95 3.09c9.692 0 17.58-7.903 17.58-17.615S51.32 11.35 41.627 11.35l-.007.006ZM28.667 9.503H.9a.902.902 0 0 1 0-1.802h27.768a.902.902 0 0 1 0 1.802ZM28.667 52.931H.9a.902.902 0 0 1 0-1.802h27.768a.902.902 0 0 1 0 1.802ZM17.415 5.616h-5.269a.902.902 0 0 1 0-1.802h5.268a.902.902 0 0 1 0 1.802ZM17.415 56.874h-5.269a.902.902 0 0 1 0-1.803h5.268a.902.902 0 0 1 0 1.803ZM14.784 41.153a4.299 4.299 0 0 1-4.29-4.298c0-2.367 1.928-4.298 4.29-4.298a4.299 4.299 0 0 1 0 8.596Zm0-6.793a2.5 2.5 0 0 0-2.49 2.495 2.5 2.5 0 0 0 2.49 2.495 2.5 2.5 0 0 0 2.49-2.495 2.5 2.5 0 0 0-2.49-2.495Z" />
            <path d="M42.068 39.467c-4.094 0-7.826-2.575-9.295-6.413a9.968 9.968 0 0 1-.667-3.569c0-5.506 4.467-9.982 9.962-9.982s9.962 4.476 9.962 9.982a9.968 9.968 0 0 1-.667 3.569c-1.469 3.832-5.202 6.413-9.295 6.413Zm0-18.16c-4.504 0-8.163 3.666-8.163 8.178 0 .994.184 1.974.55 2.925 1.206 3.145 4.266 5.254 7.619 5.254 3.353 0 6.413-2.109 7.618-5.254.361-.95.55-1.931.55-2.925 0-4.512-3.658-8.179-8.162-8.179h-.012ZM14.784 34.36a.9.9 0 0 1-.765-.423c-.667-1.073-6.505-10.607-6.505-14.286 0-4.016 3.262-7.284 7.27-7.284 4.007 0 7.269 3.268 7.269 7.284 0 3.672-5.838 13.207-6.505 14.286a.9.9 0 0 1-.764.423Zm0-20.19c-3.017 0-5.47 2.458-5.47 5.48 0 2.349 3.444 8.658 5.47 12.067 2.019-3.415 5.47-9.724 5.47-12.066 0-3.023-2.454-5.482-5.47-5.482Z" />
            <path d="M14.783 22.342a3.485 3.485 0 0 1-3.475-3.482c0-1.92 1.56-3.483 3.475-3.483a3.485 3.485 0 0 1 3.476 3.483 3.485 3.485 0 0 1-3.476 3.482Zm0-5.162a1.68 1.68 0 0 0 0 3.36 1.68 1.68 0 0 0 0-3.36ZM42.674 35.102h-1.206a2.609 2.609 0 0 1-2.6-2.606c0-.497.404-.902.9-.902.495 0 .899.405.899.902 0 .441.36.803.801.803h1.206c.44 0 .801-.362.801-.803v-1.208a.805.805 0 0 0-.801-.803h-1.206a2.609 2.609 0 0 1-2.6-2.606V26.67a2.609 2.609 0 0 1 2.6-2.606h1.206c1.431 0 2.6 1.171 2.6 2.606 0 .497-.404.926-.9.926-.495 0-.899-.374-.899-.877s-.36-.858-.801-.858h-1.206a.805.805 0 0 0-.801.803v1.208c0 .441.36.803.801.803h1.206c1.431 0 2.6 1.171 2.6 2.606v1.208a2.609 2.609 0 0 1-2.6 2.605v.007Z" />
            <path d="M42.068 25.678a.902.902 0 0 1-.9-.902v-1.82c0-.497.404-.902.9-.902s.9.405.9.902v1.82a.902.902 0 0 1-.9.902ZM42.068 37.143a.902.902 0 0 1-.9-.901V34.42c0-.497.404-.901.9-.901s.9.404.9.901v1.82a.902.902 0 0 1-.9.902Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h61v61H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon9;
