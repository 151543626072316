import * as React from "react";

const Icon3 = (props) => (
    <svg
        width={74}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.389 28.573c0-2.753-.087-5.374 0-7.995.306-8.213 4.064-14.373 11.362-18.13C29.987-3.8 44.888 3.89 47.03 17.476a25.91 25.91 0 0 1 .262 3.67v7.427h2.229c2.316 0 4.02 1.703 4.02 4.063V70.774c0 2.796-1.617 4.413-4.414 4.413H4.247c-2.534 0-4.238-1.704-4.238-4.238V32.723c0-2.534 1.66-4.194 4.239-4.194h2.054l.087.044ZM26.84 73.09h22.418c1.661 0 2.316-.656 2.316-2.316V33.03c0-1.748-.611-2.36-2.403-2.36H4.422c-1.53 0-2.185.7-2.185 2.185v38.008c0 1.573.656 2.228 2.185 2.228H26.84ZM39.6 28.529v-5.898c0-1.354 0-2.708-.218-4.019-1.31-7.208-7.997-11.664-15.164-10.223-5.244 1.049-9.526 5.767-9.876 11.14-.175 2.927-.087 5.855-.13 8.782 0 .087.043.13.087.218H39.6Zm5.682 0v-4.806c0-1.965.087-3.931-.219-5.854C43.403 6.773 32.084-.173 21.422 3.191 13.774 5.593 8.574 12.627 8.574 20.665v7.165c0 .219 0 .437.044.655H12.2c0-2.49-.088-4.893 0-7.295.044-1.53.175-3.146.612-4.588C14.91 9.263 22.252 4.851 29.812 6.25c6.38 1.18 11.406 6.771 11.799 13.28.175 2.71.087 5.418.131 8.083v.917h3.584-.044Z"
            fill="#1B9AD2"
        />
        <path
            d="M23.083 56.402v-3.146c0-.305-.175-.699-.437-.873-2.185-1.879-3.06-4.719-2.185-7.384.874-2.62 3.277-4.456 6.118-4.63 2.71-.175 5.462 1.616 6.511 4.194 1.093 2.708.35 5.766-1.923 7.689-.393.35-.524.655-.524 1.18v6.203a3.72 3.72 0 0 1-3.146 3.67c-1.923.261-3.628-.7-4.196-2.49-.174-.481-.218-1.006-.218-1.486v-2.927Zm2.097-.524v3.495c0 1.135.743 1.922 1.748 1.922.962 0 1.661-.787 1.661-1.922v-6.728c0-.787.219-1.267.874-1.704 1.617-1.005 2.404-3.058 1.967-4.937-.481-2.053-2.273-3.538-4.327-3.582-2.141-.044-4.02 1.267-4.632 3.277-.612 2.053.175 4.15 2.01 5.286.525.35.787.742.743 1.354v3.451l-.044.088Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon3;
