import React from "react";
import "../CustomerVault/CustomerVault.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";
import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { Typography } from "../Reporting/Reporting";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import cvMockup from "../CustomerVault/assets/cv-mockup.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";

export default function CustomerVault() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-right">
                <Grid
                    item
                    className="cv-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid item md lg></Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 5 } }}
                            >
                                <FadeInSection className="fade-in-from-left">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Customer Vault
                                        </strong>{" "}
                                        - Securely store your customer
                                        information and let us handle everything
                                        for you.
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Customer Vault</strong> -
                    Securely store your customer information and let us handle
                    everything for you.
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                direction={{ md: "row" }}
                alignItems={{ md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item md={4} lg={5}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: { xs: 2, md: 0 },
                                fontSize: "1.25rem !important"
                            }}
                        >
                            Mitigate your risk by using the Customer Vault and
                            avoid the huge cost and resources it takes to become
                            PCI compliant. The Vault was designed to address
                            concerns with handling sensitive data such as credit
                            card and ACH information.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FadeInSection className="fade-in-from-right">
                        <img
                            src={cvMockup}
                            className="cv-mockup"
                            alt="Customer Vault Mockup"
                        ></img>
                    </FadeInSection>
                </Grid>

                <FadeInSection className="fade-in-from-bottom">
                    <Grid item md={12} sx={{ mt: 5 }}>
                        <Typography
                            variant="h2"
                            fontWeight={450}
                            className="products-title"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            Customer Vault
                        </Typography>
                    </Grid>
                </FadeInSection>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 } }}
            >
                <Grid container>
                    <Grid item lg={1}></Grid>
                    <Grid item lg sx={{ mb: 2, px: { xs: 2, md: 0 } }}>
                        <FadeInSection className="fade-in-from-left">
                            <ProductsPageTitle
                                paragraph="true"
                                fontWeight={400}
                                sx={{ fontSize: "1.15rem" }}
                            >
                                Easily access a customer vault record at any
                                time to process a future transaction or set up
                                recurring billing.
                            </ProductsPageTitle>
                        </FadeInSection>
                    </Grid>
                </Grid>

                <List>
                    <Grid container direction="row">
                        <Grid item lg={1}></Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Lessen PCI Compliance costs
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Limit the amount of sensitive data
                                            kept on your system
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Ability to save multiple addresses
                                            and payment methods
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Easily find customers by searching
                                            and filtering vaulted customer
                                            records
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Store sensitive data including
                                            payment information in the secure
                                            customer vault
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon6 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Set default payment methods and
                                            addresses for more efficient payment
                                            processing
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Process a transaction for a customer
                                            saved in the vault with the click of
                                            a button
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={1}></Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
