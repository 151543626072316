import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

export function FadeInSection(props) {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setVisible(entry.isIntersecting);
                }
            });
        });
        observer.observe(domRef.current);
        return () => observer.disconnect;
    }, []);
    return (
        <div
            className={`${props.className} ${isVisible ? "is-visible" : ""}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

FadeInSection.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string
};
