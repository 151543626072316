import * as React from "react";

const Icon4 = (props) => (
    <svg
        width={79}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M39.315 64.198H6.87C2.829 64.198 0 61.37 0 57.289V6.828C0 3.394 2.141.728 5.455.08 5.899 0 6.344 0 6.788 0h64.973c3.96 0 6.829 2.828 6.829 6.787V57.37c0 3.515-2.142 6.14-5.495 6.787-.485.081-1.01.081-1.495.081H39.315v-.04ZM3.637 17.858V57.45c0 1.454.727 2.585 1.98 2.949.525.162 1.09.202 1.656.202H71.6c2.263 0 3.354-1.131 3.354-3.394V14.302c-.283 0-.485-.04-.688-.04H45.578c-.404 0-.848.08-1.212.202-2.99.97-6.02 2.02-9.01 2.99a7.871 7.871 0 0 1-2.344.363H3.677l-.04.04Zm0-3.677h29.536c.364 0 .768-.081 1.132-.202 3.07-1.01 6.182-2.06 9.253-3.07a4.885 4.885 0 0 1 1.535-.243h29.82v-1.09c0-1.173.04-2.344 0-3.516-.121-1.575-1.455-2.505-3.394-2.505h-61.7c-1.213 0-2.465 0-3.677.081-1.293.08-2.425 1.212-2.465 2.465-.08 2.666 0 5.333 0 8.08h-.04Z"
            fill="#1B9AD2"
        />
        <path
            d="M48.164 28.564H30.79c-1.293 0-2.061-.606-2.182-1.616-.122-1.131.687-2.02 1.94-2.02h34.87c1.616 0 2.505.646 2.505 1.818 0 1.171-.889 1.818-2.505 1.818H48.165ZM48.245 32.119h17.617c.848 0 1.454.283 1.818 1.05.323.687.242 1.415-.323 1.9-.404.322-.97.524-1.495.605-.808.081-1.657 0-2.505 0H30.264c-1.091-.12-1.738-.848-1.697-1.898.04-.97.808-1.657 1.818-1.657H48.244ZM48.244 42.825h17.698c1.01 0 1.738.606 1.9 1.536.12.767-.405 1.696-1.172 1.898-.404.122-.808.122-1.253.122H30.223c-1.05-.122-1.737-.89-1.697-1.9.04-.928.808-1.656 1.859-1.656H48.244ZM48.204 53.491H30.465c-.97 0-1.697-.525-1.858-1.373-.202-.809.202-1.697.97-2.02.404-.162.848-.203 1.293-.203H66.104c1.05.122 1.818.89 1.737 1.859-.04.97-.848 1.697-1.899 1.697H48.204v.04ZM10.708 30.26v-3.353c0-1.252.727-1.98 1.98-1.98h6.829c1.171 0 1.899.688 1.899 1.86.04 2.342.04 4.686 0 7.029 0 1.172-.687 1.818-1.819 1.858h-7.111c-1.132 0-1.778-.767-1.819-1.898V30.26h.04Zm3.677-1.656v3.434h3.435v-3.434h-3.435ZM16.123 53.491h-3.516c-1.09 0-1.858-.687-1.899-1.818 0-2.384-.04-4.727 0-7.11 0-1.091.809-1.819 1.9-1.819h6.99c1.05 0 1.778.606 1.818 1.657.04 2.424.08 4.848 0 7.272 0 1.131-.727 1.737-1.9 1.778h-3.434l.04.04Zm1.737-7.03h-3.475v3.394h3.475v-3.394ZM12.566 7.151c.97 0 1.818.889 1.778 1.818-.04.97-.89 1.737-1.9 1.697-.97 0-1.737-.848-1.737-1.778 0-.97.848-1.777 1.859-1.777v.04ZM19.678 10.706c-1.01 0-1.778-.808-1.778-1.818s.808-1.777 1.819-1.737c.97 0 1.737.768 1.737 1.778s-.768 1.818-1.778 1.818v-.04ZM28.567 8.888c0 1.01-.767 1.818-1.737 1.818-1.01 0-1.818-.767-1.818-1.777 0-.97.727-1.738 1.697-1.778 1.01 0 1.818.727 1.858 1.737Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon4;
