import * as React from "react";

const Icon2 = (props) => (
    <svg
        width={69}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M61.932 75.037H1.068C.477 75.037 0 74.561 0 73.972V2.065C0 1.475.477 1 1.068 1h46.06c.59 0 1.067.476 1.067 1.065v13.706h13.737c.591 0 1.068.475 1.068 1.065v57.136c0 .59-.477 1.065-1.068 1.065Zm-59.797-2.13h58.73V17.9H47.127c-.59 0-1.068-.476-1.068-1.065V3.13H2.136v69.777Z"
            fill="#1B9AD2"
        />
        <path
            d="M61.932 17.9H47.127c-.59 0-1.067-.475-1.067-1.064V2.066a1.064 1.064 0 0 1 1.822-.753l14.805 14.77c.306.305.398.76.235 1.157a1.07 1.07 0 0 1-.99.66Zm-13.737-2.13h11.16L48.196 4.636V15.77ZM55.37 33.154H44.686c-.59 0-1.068-.476-1.068-1.065V21.43c0-.59.477-1.065 1.068-1.065H55.37c.59 0 1.067.476 1.067 1.065v10.66c0 .588-.476 1.064-1.067 1.064Zm-9.616-2.13h8.548v-8.529h-8.548v8.529ZM55.37 50.573H44.686c-.59 0-1.068-.475-1.068-1.065V38.85c0-.589.477-1.065 1.068-1.065H55.37c.59 0 1.067.476 1.067 1.065v10.66c0 .589-.476 1.064-1.067 1.064Zm-9.616-2.13h8.548v-8.529h-8.548v8.53ZM55.37 67.787H44.686c-.59 0-1.068-.476-1.068-1.066V56.064c0-.59.477-1.066 1.068-1.066H55.37c.59 0 1.067.476 1.067 1.066v10.659c0 .589-.476 1.065-1.067 1.065Zm-9.616-2.13h8.548v-8.53h-8.548v8.53Z"
            fill="#1B9AD2"
        />
        <path
            d="M50.373 46.93c-.377 0-.726-.198-.918-.518l-5.687-9.523a1.072 1.072 0 0 1 .37-1.463 1.077 1.077 0 0 1 1.466.37l4.769 7.989 5.289-8.834a1.075 1.075 0 0 1 1.466-.37c.505.299.669.96.37 1.463l-6.207 10.368c-.192.32-.54.519-.918.519ZM34.714 22.708H8.734a1.065 1.065 0 1 1 0-2.13h25.98a1.065 1.065 0 1 1 0 2.13ZM34.714 27.821H8.734a1.065 1.065 0 1 1 0-2.13h25.98a1.065 1.065 0 1 1 0 2.13ZM34.714 40.135H8.734a1.065 1.065 0 1 1 0-2.13h25.98a1.065 1.065 0 1 1 0 2.13ZM34.714 45.248H8.734a1.065 1.065 0 1 1 0-2.13h25.98a1.065 1.065 0 1 1 0 2.13ZM34.714 57.34H8.734a1.065 1.065 0 1 1 0-2.13h25.98a1.065 1.065 0 1 1 0 2.13ZM29.596 62.454H8.734a1.065 1.065 0 1 1 0-2.13h20.87a1.065 1.065 0 1 1 0 2.13h-.008Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon2;
