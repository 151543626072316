import React from "react";
import "../Invoicing/Invoicing.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import invMockup from "../Invoicing/assets/inv-mockup.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";
import Icon8 from "./assets/icon-8";

export default function Invoicing() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER*/}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="inv-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 8 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Invoicing
                                        </strong>{" "}
                                        - hosted payment page that allows
                                        merchants to accept customer payment
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md={6}></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Invoicing</strong> - hosted
                    payment page that allows merchants to accept customer
                    payment
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                direction={{ md: "row" }}
                alignItems={{ md: "flex-start" }}
                justifyContent={{ sm: "center", lg: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item sm={12} lg={5}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: { xs: 2, md: 0 },
                                width: { xs: "100%", md: "75%", lg: "100%" },
                                fontSize: "1.25rem !important"
                            }}
                        >
                            Efficiently collect payment from customers by
                            sending an invoice. Invoices are customizable and
                            can be sent via email, SMS, or mail.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <FadeInSection className="fade-in-from-right">
                    <Grid item sm md lg>
                        <img
                            src={invMockup}
                            className="inv-mockup"
                            alt="Invoicing Mockup"
                        ></img>
                    </Grid>
                </FadeInSection>
                <Grid container>
                    <FadeInSection className="fade-in-from-bottom">
                        <Grid item md={12} sx={{ mt: 5 }}>
                            <Typography
                                variant="h2"
                                fontWeight={450}
                                className="products-title"
                                sx={{ display: { xs: "none", md: "block" } }}
                            >
                                Invoicing
                            </Typography>
                        </Grid>
                    </FadeInSection>
                </Grid>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 }, pl: { xs: 2, md: 5, lg: 0 } }}
            >
                <List>
                    <Grid container direction="row">
                        <Grid item lg={1}></Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Simple & Intuitive
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Streamline the billing process by
                                            reducing paperwork and time to get
                                            paid
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Encourage faster payments
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Real-time invoice creation &
                                            delivery
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Start sending invoices today without
                                            any development or customization
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon6 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            No need to store sensitive data
                                            eliminating the most rigorous of PCI
                                            compliance issues
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            No integration required
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon8 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Logo and color options allows for
                                            tailoring invoices to a specific
                                            brand
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={1}></Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
