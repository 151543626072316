import * as React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import AppBar from "@mui/material/AppBar";
// import Tooltip from "@mui/material/Tooltip";
// import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import MenuLinks from "./MenuLinks";

import mixpanel from "mixpanel-browser";

const drawerWidth = "60%";

const productSubpages = [
    { id: "Features Overview", route: "/features" },
    { id: "Account Updater", route: "/features/account-updater" },
    { id: "Custom Fields", route: "/features/custom-fields" },
    { id: "Customer Vault", route: "/features/customer-vault" },
    { id: "Invoicing", route: "/features/invoicing" },
    { id: "iQ Protect", route: "/features/iq-protect" },
    { id: "Recurring Billing", route: "/features/recurring-billing" },
    { id: "Reporting", route: "/features/reporting" },
    { id: "Shopping Cart", route: "/features/shopping-cart" },
    { id: "Simple Pay", route: "/features/simple-pay" },
    { id: "Virtual Terminal", route: "/features/virtual-terminal" }
];

export default function Navbar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [featuresMenuOpen, setFeaturesMenuOpen] = useState(false);
    const handleFeaturesMenuClick = () => {
        setFeaturesMenuOpen(!featuresMenuOpen);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // mobile drawer
    const drawer = (
        <Box sx={{ textAlign: "center" }}>
            <Link to="/">
                <div alt="iQ Pro Logo" className="landing-logo"></div>
            </Link>
            <Divider />
            <List sx={{ width: "90%" }}>
                <ListItemButton onClick={() => handleFeaturesMenuClick()}>
                    <ListItemText>Features</ListItemText>
                    {featuresMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={featuresMenuOpen} timeout="auto" unmountOnExit>
                    {productSubpages.map(({ id, route }) => (
                        <Link key={id} to={route}>
                            <ListItem sx={{ pl: 3, py: 0 }}>
                                <ListItemButton>{id}</ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </Collapse>

                <ListItem disablePadding>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://sandbox.basysiqpro.com/docs/api"
                    >
                        <ListItemButton sx={{ width: "100vw" }}>
                            <ListItemText>Developers</ListItemText>
                        </ListItemButton>
                    </a>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/support">
                        <ListItemButton sx={{ width: "100vw" }}>
                            <ListItemText>Support</ListItemText>
                        </ListItemButton>
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link to="/sign-in">
                        <ListItemButton sx={{ width: "100vw" }}>
                            <ListItemText>Sign In</ListItemText>
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const [isActive, setIsActive] = useState(false);
    const handleMouseOver = () => {
        setIsActive(true);
    };

    return (
        <Box sx={{ height: { xs: "100px", md: "115px" } }}>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: "none",
                    pl: { xs: 3, lg: 10 },
                    py: 1
                }}
            >
                <Grid container columns={{ md: 6, lg: 12 }} alignItems="center">
                    <Grid item xs={7}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ display: { md: "none" } }}
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={2} lg={6} xs={5}>
                        <Link
                            to="/"
                            onClick={() => {
                                mixpanel.track("User taken to Landing page");
                            }}
                        >
                            <div
                                alt="iQ Pro Logo"
                                className="landing-logo"
                            ></div>
                        </Link>
                    </Grid>
                    <Grid
                        item
                        lg={5}
                        sx={{ display: { xs: "none", md: "inherit" } }}
                        className="navigation-link-wrapper"
                    >
                        <ul>
                            <li
                                className={
                                    isActive ? "features open" : "features"
                                }
                                onMouseOver={handleMouseOver}
                                onMouseOut={() => setIsActive(false)}
                                aria-label="Press enter to go to features overview, press spacebar to open features menu"
                            >
                                <Link
                                    color="inherit"
                                    role="aria"
                                    to="/features"
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    className="nav-links"
                                    onClick={() => {
                                        mixpanel.track(
                                            "User taken to Features page"
                                        );
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.key === " ") {
                                            mixpanel.track(
                                                "Used keyboard to open/close features menu"
                                            );
                                            event.preventDefault();
                                            setIsActive(!isActive);
                                        }
                                    }}
                                >
                                    Features
                                </Link>
                                <MenuLinks />
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="http://sandbox.basysiqpro.com/docs/api"
                                    className="nav-links"
                                    onClick={() => {
                                        mixpanel.track(
                                            "User taken to API Docs page"
                                        );
                                    }}
                                >
                                    Developers
                                </a>
                            </li>
                            <li>
                                <Link
                                    color="inherit"
                                    to="/support"
                                    className="nav-links"
                                    onClick={() => {
                                        mixpanel.track(
                                            "User taken to Support page"
                                        );
                                    }}
                                >
                                    Support
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item sx={{ display: { xs: "none", md: "inherit" } }}>
                        <Link
                            to="/sign-in"
                            className="nav-sign-in"
                            onClick={() => {
                                mixpanel.track("User taken to Sign In page");
                            }}
                        >
                            Sign In
                        </Link>
                    </Grid>
                </Grid>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    disableScrollLock={true}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { xs: "block", lg: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth
                        }
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

Navbar.propTypes = {
    window: PropTypes.object
};
