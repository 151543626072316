import * as React from "react";

const Icon6 = (props) => (
    <svg height="78" width="78" xmlns="http://www.w3.org/2000/svg" {...props}>
        <defs>
            <style>{".cls-1{fill:#1B9AD2}"}</style>
        </defs>
        <path
            className="cls-1"
            d="M75.99 50.87a6.508 6.508 0 0 1-4.61 1.92H6.53c-1.74 0-3.39-.68-4.62-1.91S0 48 0 46.26V6.53c0-1.74.68-3.39 1.91-4.62S4.78 0 6.53 0h64.85c3.6 0 6.53 2.93 6.53 6.53v39.73c0 1.8-.73 3.43-1.92 4.61Zm-4.61-.63c2.19 0 3.98-1.79 3.99-3.99V6.52a3.99 3.99 0 0 0-3.98-3.98H6.52c-1.07 0-2.06.41-2.82 1.16S2.54 5.45 2.54 6.52v39.74c0 1.07.41 2.06 1.16 2.82s1.75 1.16 2.82 1.16h64.86Z"
        />
        <path
            className="cls-1"
            d="M67.41 27.62c-2.41 2.41-5.74 3.9-9.41 3.9-7.33 0-13.31-5.97-13.31-13.31S50.66 4.9 58 4.9s13.31 5.97 13.31 13.31c0 3.67-1.49 7-3.9 9.41ZM50.39 10.6a10.683 10.683 0 0 0-3.15 7.61c0 5.94 4.83 10.77 10.77 10.77 2.88 0 5.58-1.12 7.61-3.15 2.03-2.03 3.15-4.73 3.15-7.61s-1.12-5.58-3.15-7.61c-2.03-2.03-4.73-3.15-7.61-3.15s-5.66 1.21-7.61 3.15Z"
        />
        <path
            className="cls-1"
            d="M61.21 30.06c-.96.96-2.05 1.46-3.2 1.46-2.01 0-3.8-1.51-5.06-4.27-1.11-2.44-1.74-5.64-1.73-9.04 0-3.39.62-6.61 1.73-9.04 1.26-2.75 3.07-4.27 5.06-4.27s3.8 1.51 5.06 4.27c1.11 2.44 1.74 5.64 1.73 9.04 0 3.39-.62 6.61-1.73 9.04-.53 1.16-1.16 2.11-1.86 2.8ZM56.59 8.17c-.48.48-.94 1.18-1.35 2.06-.97 2.11-1.51 4.95-1.51 7.98s.53 5.87 1.51 7.98c.8 1.74 1.83 2.79 2.76 2.79s1.96-1.04 2.76-2.79c.97-2.11 1.51-4.95 1.51-7.98s-.53-5.87-1.51-7.98c-.8-1.74-1.83-2.79-2.76-2.79-.45 0-.94.26-1.41.72Z"
        />
        <path
            className="cls-1"
            d="M58.9 31.15a1.272 1.272 0 0 1-2.17-.9V6.18c0-.7.57-1.27 1.27-1.27.7 0 1.27.57 1.27 1.27v24.07c0 .36-.14.67-.37.9Z"
        />
        <path
            className="cls-1"
            d="M70.94 19.11c-.23.23-.54.37-.9.37H45.97c-.7 0-1.27-.57-1.27-1.27 0-.7.57-1.27 1.27-1.27h24.07a1.272 1.272 0 0 1 .9 2.17ZM20.7 46.53c-.23.23-.54.37-.9.37H8.47a1.263 1.263 0 0 1-1.27-1.27v-8.56c0-.7.57-1.27 1.27-1.27H19.8a1.263 1.263 0 0 1 1.27 1.27v8.56c0 .36-.14.67-.37.9ZM9.74 44.36h8.79v-6.01H9.74v6.01ZM36.76 46.53c-.23.23-.54.37-.9.37H24.53c-.7 0-1.27-.57-1.27-1.27v-8.56c0-.67.6-1.27 1.27-1.27h11.33c.7 0 1.27.58 1.27 1.28v8.56c0 .36-.14.67-.37.9ZM25.8 44.36h8.79v-6.01H25.8v6.01ZM52.84 46.53c-.24.24-.56.37-.9.37H40.61c-.7 0-1.27-.58-1.27-1.28v-8.56c0-.7.57-1.27 1.27-1.27h11.33c.7 0 1.27.57 1.27 1.27v8.56c0 .34-.13.66-.37.9Zm-10.96-2.16h8.79v-6.01h-8.79v6.01ZM68.9 46.53c-.23.23-.54.37-.9.37H56.67a1.263 1.263 0 0 1-1.27-1.27v-8.56c0-.7.57-1.27 1.27-1.27H68a1.263 1.263 0 0 1 1.27 1.27v8.56c0 .36-.14.67-.37.9Zm-10.96-2.17h8.79v-6.01h-8.79v6.01ZM35.1 22.06c-.23.23-.54.37-.9.37H8.18c-.34 0-.66-.14-.9-.38s-.37-.56-.37-.9v-9.73c0-.67.6-1.27 1.27-1.27h26.01c.7 0 1.27.57 1.27 1.27v9.73c0 .36-.14.67-.37.9ZM9.46 19.89h23.47V12.7H9.46v7.19Z"
        />
    </svg>
);

export default Icon6;
