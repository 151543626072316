import * as React from "react";

const Icon7 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M38.56 43.408c-6.322 0-11.282-9.537-11.282-21.7C27.278 9.544 32.238 0 38.56 0c.624 0 1.129.504 1.129 1.13 0 .624-.505 1.128-1.13 1.128-4.892 0-9.024 8.904-9.024 19.442s4.132 19.442 9.025 19.442c.624 0 1.129.505 1.129 1.13 0 .624-.505 1.129-1.13 1.129v.007Z" />
            <path d="M38.764 43.408a1.128 1.128 0 0 1-1.13-1.129c0-.625.505-1.129 1.13-1.129 4.892 0 9.025-8.904 9.025-19.442s-4.133-19.45-9.025-19.45a1.128 1.128 0 0 1-1.13-1.129c0-.625.505-1.129 1.13-1.129 6.323 0 11.283 9.537 11.283 21.7 0 12.164-4.953 21.7-11.283 21.7v.008Z" />
            <path d="M38.764 43.408a1.128 1.128 0 0 1-1.13-1.129V1.13c0-.625.505-1.129 1.13-1.129.625 0 1.129.504 1.129 1.13v41.15c0 .624-.504 1.128-1.13 1.128Z" />
            <path d="M59.335 22.83H18.193c-.625 0-1.13-.505-1.13-1.13 0-.624.505-1.129 1.13-1.129h41.15c.624 0 1.129.505 1.129 1.13 0 .624-.505 1.128-1.13 1.128h-.007ZM40.819 53.299c-.52 0-.986-.362-1.1-.881-.549-2.461-2.4-4.185-4.508-4.193l-6.187-.022H9.476C4.253 48.203 0 43.31 0 37.303c0-6.006 4.253-10.898 9.476-10.898h7.98c.624 0 1.128.504 1.128 1.129s-.504 1.129-1.129 1.129H9.476c-3.981 0-7.218 3.876-7.218 8.64 0 4.765 3.237 8.642 7.218 8.642l25.743.022c3.161.015 5.923 2.462 6.699 5.962a1.129 1.129 0 0 1-.858 1.347c-.083.015-.166.03-.249.03l.008-.007Z" />
            <path d="M38.764 43.408c-6.037 0-11.644-2.424-15.8-6.827a21.615 21.615 0 0 1-5.908-14.873C17.064 9.74 26.796 0 38.764 0c11.968 0 21.7 9.74 21.7 21.7a21.59 21.59 0 0 1-5.9 14.866c-4.148 4.41-9.763 6.835-15.807 6.835l.007.007Zm0-41.15c-10.718 0-19.442 8.724-19.442 19.442 0 4.968 1.881 9.703 5.291 13.33 3.719 3.945 8.747 6.12 14.151 6.12s10.44-2.175 14.158-6.127A19.364 19.364 0 0 0 58.206 21.7c0-10.726-8.724-19.442-19.442-19.442ZM73.253 76H4.275a4.199 4.199 0 0 1-4.192-4.193V55.331a4.199 4.199 0 0 1 4.192-4.193h68.978a4.199 4.199 0 0 1 4.192 4.193v16.476A4.199 4.199 0 0 1 73.253 76ZM4.275 53.404a1.934 1.934 0 0 0-1.934 1.934v16.477c0 1.069.865 1.934 1.934 1.934h68.978a1.934 1.934 0 0 0 1.934-1.934V55.338a1.934 1.934 0 0 0-1.934-1.934H4.275Z" />
            <rect
                x={7.167}
                y={62.082}
                width={61.721}
                height={4.544}
                rx={2.272}
            />
        </g>
        <defs>
            <path fill="#fff" d="M0 0h77.438v76H0z" />
        </defs>
    </svg>
);

export default Icon7;
