import React from "react";
import "../SimplePay/SimplePay.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import spUI from "../SimplePay/assets/sp-ui.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";
import Icon8 from "./assets/icon-8";

export default function SimplePay() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="sp-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, md: 4 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Simple Pay
                                        </strong>{" "}
                                        - an excellent solution for those
                                        looking for a simple payment collection
                                        resource
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Simple Pay</strong> - an
                    excellent solution for those looking for a simple payment
                    collection resource
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                direction={{ xs: "column", md: "row" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid
                    item
                    md={4}
                    lg={6}
                    sx={{ display: { xs: "block", md: "none" } }}
                >
                    <FadeInSection className="fade-in-from-right">
                        <ProductsPageTitle
                            paragraph="true"
                            color="black"
                            sx={{
                                p: { xs: 3, md: 0 },
                                fontSize: "1.25rem !important"
                            }}
                        >
                            Simple Pay allows businesses to accept customer
                            payments through a secure hosted payment page in iQ
                            Pro. Simple Pay streamlines the payment process with
                            its user-friendly page design and customization, an
                            excellent solution for those looking for a simple
                            payment collection resource.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <Grid item md lg={5} className="ui-title">
                    <FadeInSection className="fade-in-from-left">
                        <img
                            src={spUI}
                            className="sp-ui"
                            alt="Example of a Simple Pay page"
                        ></img>
                    </FadeInSection>
                </Grid>
                <Grid item md={4} lg={6}>
                    <FadeInSection className="fade-in-from-right">
                        <ProductsPageTitle
                            paragraph="true"
                            color="black"
                            sx={{
                                p: { xs: 3, md: 0 },
                                display: { xs: "none", md: "block" }
                            }}
                        >
                            Simple Pay allows businesses to accept customer
                            payments through a secure hosted payment page in iQ
                            Pro. Simple Pay streamlines the payment process with
                            its user-friendly page design and customization, an
                            excellent solution for those looking for a simple
                            payment collection resource.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <Grid container>
                    <Grid item lg={12} sx={{ mt: 5 }}>
                        <FadeInSection className="fade-in-from-bottom">
                            <Typography
                                variant="h2"
                                fontWeight={450}
                                className="products-title"
                                sx={{ display: { xs: "none", md: "block" } }}
                            >
                                Simple Pay
                            </Typography>
                        </FadeInSection>
                    </Grid>
                </Grid>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 }, pl: { xs: 2, md: 5, lg: 0 } }}
            >
                <List>
                    <Grid container direction="row">
                        <Grid item lg={1}></Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Secure hosted payment page
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Optional “Click-to-Pay” button on
                                            your website
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            A streamlined payment process for
                                            your customer
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Intuitive and user-friendly page
                                            design
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Brand the payment page with your
                                            business name, logo, colors, and
                                            select fonts
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon6 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Unique “Pay Now” link that can be
                                            added to your website or shared with
                                            your customers
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Ability to add a banner message and
                                            customize the Simple Pay page by
                                            including, requiring or hiding
                                            select fields
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon8 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Add a redirect link for when a
                                            customer completes payment
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={1}></Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
