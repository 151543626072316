import React from "react";
import "../RecurringBilling/RecurringBilling.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import rbUI from "../RecurringBilling/assets/rb-ui.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";

export default function RecurringBilling() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="rb-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 5 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Recurring Billing
                                        </strong>{" "}
                                        - used for submitting and managing
                                        recurring, or subscription based,
                                        transactions.
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Recurring Billing</strong> -
                    used for submitting and managing recurring, or subscription
                    based, transactions.
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                direction={{ xs: "column", md: "row" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{
                    pl: { xs: 0, md: 18, xl: 25 },
                    my: { xs: 0, md: 5 }
                }}
            >
                <Grid item md lg className="ui-title">
                    <FadeInSection className="fade-in-from-left">
                        <img
                            src={rbUI}
                            className="ui"
                            alt="Recurring Billing Mockup"
                        ></img>
                    </FadeInSection>
                </Grid>
                <Grid item md={4} lg={7}>
                    <FadeInSection className="fade-in-from-right">
                        <ProductsPageTitle
                            paragraph="true"
                            sx={{
                                width: { xs: "90%", md: "80%" },
                                p: { xs: 3, md: 0 },
                                fontSize: "1.25rem !important"
                            }}
                        >
                            Automated recurring billing helps simplify the
                            payment process while increasing cash flow and
                            reducing overall costs to capture payments.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <FadeInSection className="fade-in-from-bottom">
                    <Grid item md={12} sx={{ mt: 5 }}>
                        <Typography
                            variant="h2"
                            fontWeight={450}
                            className="products-title"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            Recurring Billing
                        </Typography>
                    </Grid>
                </FadeInSection>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="left"
                sx={{ mb: { xs: 2, lg: 10 }, pl: { xs: 2, md: 5, lg: 15 } }}
            >
                <List>
                    <Grid container direction="row">
                        <Grid item lg={6}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Email receipts to customers
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Save the customer to the vault
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Add a unique PO number or Order ID
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Process Sale, Authorization, Blind
                                            Credit, Refund, and Verification
                                            transactions
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={6}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Create custom fields
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon6 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Accept Card or ACH payment methods
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Swipe a card using a peripheral
                                            swiper
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
