import * as React from "react";

const Icon3 = (props) => (
    <svg
        width={76}
        height={90}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M52.402 67H.985A.987.987 0 0 1 0 66.013V.987C0 .442.442 0 .985 0h65.037c.543 0 .985.442.985.987v51.67a.987.987 0 0 1-.985.988H53.387v12.368a.987.987 0 0 1-.985.987ZM1.97 65.033h49.447V52.665c0-.545.441-.987.985-.987h12.635V1.974H1.97v63.059Z" />
            <path d="M52.402 67a.985.985 0 0 1-.985-.98V52.665c0-.545.441-.987.985-.987h13.613a.985.985 0 0 1 .686 1.688L53.088 66.72a.982.982 0 0 1-.686.279Zm.985-13.355V63.67L63.61 53.645H53.387ZM17.193 19.733a4.696 4.696 0 0 1-2.18-.538c-2.113-1.075-3.472-3.587-3.472-6.398 0-3.826 2.534-6.936 5.659-6.936s5.659 3.11 5.659 6.936c0 2.811-1.366 5.323-3.472 6.398-.7.354-1.433.538-2.18.538h-.014Zm0-11.898c-2.03 0-3.688 2.226-3.688 4.962 0 2.042.964 3.914 2.398 4.642a2.75 2.75 0 0 0 2.58 0c1.434-.735 2.399-2.6 2.399-4.642 0-2.736-1.658-4.962-3.689-4.962ZM26.717 29.903H7.67a.987.987 0 0 1-.985-.987v-3.86a4.813 4.813 0 0 1 4.81-4.812h3.967c.543 0 .985.442.985.987v.19a.747.747 0 0 0 .829.749c.373-.034.672-.408.672-.824v-.115c0-.545.442-.987.985-.987H22.9c2.65 0 4.81 2.157 4.81 4.812v3.86a.987.987 0 0 1-.985.987h-.007ZM8.654 27.929h17.078v-2.873a2.85 2.85 0 0 0-2.84-2.845h-3.117c-.333 1.028-1.23 1.81-2.317 1.92a2.704 2.704 0 0 1-2.092-.695 2.736 2.736 0 0 1-.774-1.218h-3.098a2.846 2.846 0 0 0-2.84 2.845v2.872-.006Z" />
            <path d="M17.193 24.144a2.724 2.724 0 0 1-2.717-2.723v-3.104c0-.34.177-.66.469-.837a.981.981 0 0 1 .958-.04 2.75 2.75 0 0 0 2.58 0 .964.964 0 0 1 .959.04.98.98 0 0 1 .468.837v3.104a2.724 2.724 0 0 1-2.717 2.723Zm-.754-4.479v1.75c0 .415.34.755.754.755.415 0 .754-.34.754-.756v-1.749a4.629 4.629 0 0 1-1.501 0h-.007ZM17.193 46.614a4.695 4.695 0 0 1-2.18-.538C12.9 45 11.54 42.489 11.54 39.677c0-3.825 2.534-6.936 5.659-6.936s5.659 3.11 5.659 6.936c0 2.812-1.366 5.323-3.472 6.399a4.8 4.8 0 0 1-2.18.538h-.014Zm0-11.899c-2.03 0-3.688 2.226-3.688 4.962 0 2.042.964 3.914 2.398 4.643a2.75 2.75 0 0 0 2.58 0c1.434-.736 2.399-2.6 2.399-4.643 0-2.736-1.658-4.962-3.689-4.962ZM26.717 56.783H7.67a.987.987 0 0 1-.985-.987v-3.86c0-2.654 2.154-4.819 4.81-4.819h3.967c.543 0 .985.443.985.987v.19a.747.747 0 0 0 .829.75c.373-.034.672-.409.672-.824v-.116c0-.544.442-.987.985-.987H22.9c2.65 0 4.81 2.158 4.81 4.82v3.859a.987.987 0 0 1-.985.987h-.007ZM8.654 54.809h17.078v-2.872a2.85 2.85 0 0 0-2.84-2.846h-3.117c-.333 1.028-1.23 1.81-2.317 1.92a2.704 2.704 0 0 1-2.092-.695 2.736 2.736 0 0 1-.774-1.218h-3.098a2.846 2.846 0 0 0-2.84 2.845v2.873-.007Z" />
            <path d="M17.193 51.031a2.724 2.724 0 0 1-2.717-2.723v-3.104c0-.34.177-.66.469-.837a.981.981 0 0 1 .958-.04 2.75 2.75 0 0 0 2.58 0 .963.963 0 0 1 .959.04.98.98 0 0 1 .468.837v3.104a2.724 2.724 0 0 1-2.717 2.723Zm-.754-4.479v1.75c0 .415.34.755.754.755.415 0 .754-.34.754-.756v-1.749a4.629 4.629 0 0 1-1.501 0h-.007ZM58.386 18.066h-27.05a.987.987 0 0 1-.985-.988v-6.452c0-.545.442-.987.985-.987H58.38c.543 0 .985.442.985.987v6.452a.987.987 0 0 1-.985.988h.006Zm-26.065-1.974h25.08v-4.486h-25.08v4.486ZM44.861 29.903H31.336a.987.987 0 0 1-.985-.987v-6.453c0-.545.442-.987.985-.987h13.525c.544 0 .985.442.985.987v6.453a.987.987 0 0 1-.985.987Zm-12.54-1.974h11.555v-4.486H32.321v4.486ZM58.386 44.85h-27.05a.987.987 0 0 1-.985-.986V37.41c0-.545.442-.987.985-.987H58.38c.543 0 .985.442.985.987v6.453a.987.987 0 0 1-.985.986h.006Zm-26.065-1.974h25.08v-4.485h-25.08v4.486ZM44.861 56.688H31.336a.987.987 0 0 1-.985-.987v-6.453c0-.545.442-.987.985-.987h13.525c.544 0 .985.442.985.987V55.7a.987.987 0 0 1-.985.987Zm-12.54-1.974h11.555v-4.486H32.321v4.486Z" />
        </g>
    </svg>
);

export default Icon3;
