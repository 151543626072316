import * as React from "react";

const Icon6 = (props) => (
    <svg
        width={78}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M68.373 67.373H63.8a1.064 1.064 0 0 1-1.062-1.063V22.176h-7.75a1.064 1.064 0 0 1-1.063-1.064v-4.578c0-.586.477-1.063 1.062-1.063h11.096a3.353 3.353 0 0 1 3.344 3.349v47.49c0 .586-.476 1.063-1.062 1.063h.008Zm-3.511-2.127h2.449v-46.42c0-.672-.55-1.221-1.22-1.221H56.055v2.451h7.751c.585 0 1.062.478 1.062 1.064V65.26l-.007-.014ZM10.077 67.373H5.512A1.064 1.064 0 0 1 4.45 66.31V18.827a3.353 3.353 0 0 1 3.344-3.349h14.087c.585 0 1.061.477 1.061 1.063v4.579c0 .585-.476 1.063-1.061 1.063H11.139v44.141c0 .586-.477 1.063-1.062 1.063v-.014Zm-3.51-2.127h2.448V21.112c0-.585.477-1.063 1.062-1.063h10.734v-2.452H7.794c-.672 0-1.22.55-1.22 1.223v46.434l-.008-.008Z" />
            <path d="M69.514 73H4.486A4.498 4.498 0 0 1 0 68.508V66.99c0-.962.78-1.743 1.74-1.743h70.526c.961 0 1.741.78 1.741 1.743v1.519A4.498 4.498 0 0 1 69.521 73h-.007Zm-67.39-5.627v1.135a2.37 2.37 0 0 0 2.362 2.366h65.028a2.37 2.37 0 0 0 2.362-2.365v-1.136H2.124ZM54.987 62.484H21.816c-.282 0-.55-.116-.752-.311a1.056 1.056 0 0 1-.31-.753l.014-22.002.065-27.867c0-.051 0-.109.015-.16v-4.99c0-2.343-1.806-4.253-4.038-4.282h-.058a1.058 1.058 0 0 1-1.055-1.063c0-.608.506-1.078 1.127-1.056l30.831.174c4.63.029 8.394 3.941 8.394 8.722V61.42c0 .586-.477 1.064-1.062 1.064Zm-32.11-2.127h31.048V8.903c0-3.616-2.817-6.581-6.284-6.603L21.39 2.148a6.512 6.512 0 0 1 1.567 4.253v5.142c0 .058 0 .109-.014.16l-.065 27.708-.015 20.932.015.014Z" />
            <path d="M21.895 12.614h-10.28a1.064 1.064 0 0 1-1.061-1.063V6.408C10.554 2.871 13.335 0 16.759 0s6.205 2.871 6.205 6.408l-.014 5.15c0 .586-.477 1.063-1.062 1.063l.007-.007Zm-9.217-2.127h8.155V6.402c.008-2.358-1.82-4.282-4.074-4.282-2.254 0-4.081 1.924-4.081 4.282v4.087ZM29.112 33.046a3.97 3.97 0 0 1-3.966-3.97 3.97 3.97 0 0 1 3.966-3.971 3.97 3.97 0 0 1 3.965 3.97 3.97 3.97 0 0 1-3.965 3.971Zm0-5.822a1.846 1.846 0 0 0-1.842 1.844c0 1.013.83 1.845 1.842 1.845a1.851 1.851 0 0 0 1.842-1.845 1.85 1.85 0 0 0-1.842-1.844Z" />
            <path d="M29.112 53.551a1.064 1.064 0 0 1-1.062-1.063V31.976c0-.586.477-1.063 1.062-1.063.585 0 1.062.477 1.062 1.063v20.512c0 .586-.477 1.063-1.062 1.063ZM37.585 25.698a3.97 3.97 0 0 1-3.966-3.97 3.97 3.97 0 0 1 3.966-3.972 3.97 3.97 0 0 1 3.966 3.971 3.97 3.97 0 0 1-3.966 3.97Zm0-5.823a1.846 1.846 0 0 0-1.842 1.845 1.85 1.85 0 0 0 1.842 1.844 1.851 1.851 0 0 0 1.842-1.844c0-1.013-.83-1.845-1.842-1.845Z" />
            <path d="M37.585 53.551a1.064 1.064 0 0 1-1.062-1.063V24.635c0-.586.477-1.064 1.062-1.064.585 0 1.062.478 1.062 1.064v27.853c0 .586-.477 1.063-1.062 1.063ZM46.051 18.357a3.97 3.97 0 0 1-3.966-3.971 3.97 3.97 0 0 1 3.966-3.97 3.97 3.97 0 0 1 3.966 3.97 3.97 3.97 0 0 1-3.966 3.97Zm0-5.823a1.846 1.846 0 0 0-1.842 1.845c0 1.012.831 1.844 1.842 1.844a1.85 1.85 0 0 0 1.842-1.844 1.85 1.85 0 0 0-1.842-1.845Z" />
            <path d="M45.762 53.551h-.007a1.064 1.064 0 0 1-1.055-1.07l.29-35.202c0-.586.476-1.056 1.061-1.056h.008c.585 0 1.054.485 1.054 1.07l-.289 35.202c0 .586-.477 1.056-1.062 1.056Z" />
        </g>
    </svg>
);

export default Icon6;
