import * as React from "react";

const Icon1 = (props) => (
    <svg
        width={76}
        height={90}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M32.5 65C14.576 65 0 50.413 0 32.5S14.586 0 32.5 0C50.413 0 65 14.576 65 32.5S50.413 65 32.5 65Zm0-61.891c-16.198 0-29.381 13.183-29.381 29.38 0 16.199 13.183 29.382 29.381 29.382S61.881 48.688 61.881 32.49 48.698 3.109 32.5 3.109Z"
            fill="#1B9AD2"
        />
        <path
            d="M34.563 50h-4.126C26.333 50 23 46.742 23 42.73c0-.869.718-1.57 1.607-1.57.89 0 1.608.701 1.608 1.57 0 2.274 1.897 4.128 4.222 4.128h4.126c2.325 0 4.222-1.854 4.222-4.127v-4.033c0-2.273-1.897-4.127-4.222-4.127h-4.126c-4.104 0-7.437-3.257-7.437-7.27V23.27C23 19.258 26.333 16 30.437 16h4.126C38.667 16 42 19.258 42 23.27v.177c0 .87-.718 1.572-1.607 1.572-.89 0-1.608-.702-1.608-1.572v-.178c0-2.273-1.897-4.127-4.222-4.127h-4.126c-2.325 0-4.222 1.854-4.222 4.127v4.033c0 2.273 1.897 4.127 4.222 4.127h4.126c4.104 0 7.437 3.257 7.437 7.27v4.032C42 46.742 38.667 50 34.563 50Z"
            fill="#1B9AD2"
        />
        <path
            d="M31.5 18c-.83 0-1.5-.684-1.5-1.532v-5.936C30 9.684 30.67 9 31.5 9s1.5.684 1.5 1.532v5.936c0 .848-.67 1.532-1.5 1.532ZM31.5 56c-.83 0-1.5-.684-1.5-1.532v-5.936c0-.848.67-1.532 1.5-1.532s1.5.684 1.5 1.532v5.936c0 .848-.67 1.532-1.5 1.532Z"
            fill="#1B9AD2"
        />
        <path fill="#fff" d="M47 30h25v33H47z" />
        <g clipPath="url(#a)">
            <path
                d="M57.011 35h9.119a.99.99 0 0 1 .98.998v15.695h7.91a.98.98 0 0 1 .893.587c.154.363.094.78-.168 1.074l-13.52 15.332c-.37.423-1.08.423-1.456 0l-13.52-15.332a1.026 1.026 0 0 1-.169-1.074.97.97 0 0 1 .893-.587h8.058V35.998a.99.99 0 0 1 .98-.998Zm8.139 1.99h-7.166v15.7a.99.99 0 0 1-.98.998H50.16L61.5 66.532l11.333-12.85H66.13a.99.99 0 0 1-.98-.998V36.989Z"
                fill="#1B9AD2"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="rotate(-180 38 34.5)"
                    d="M0 0h29v34H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default Icon1;
