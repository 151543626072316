import React from "react";
import "./Support.scss";
import styled from "@emotion/styled";
import { FadeInSection } from "../Components/FadeInSection/FadeInSection";
import "../Components/FadeInSection/FadeInSection.scss";
import Button from "@mui/material/Button";
import MuiAccordion from "@mui/material/Accordion";
import Link from "@mui/material/Link";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Navbar from "../NavMenu/Navbar";
import Footer from "../Components/Footer/Footer";
import mixpanel from "mixpanel-browser";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// const CustomerSupportButton = styled(Button)`
//   background-color: rgba(24, 154, 208, 1);
//   color: white;
//   border-radius: 2em;
//   padding: 0.5em 1em;
//   position: relative;
//   will-change: transform;
//   transition: 0.3s ease-in-out;
//   &:hover {
//     background-color: rgba(24, 154, 208, 1);
//   }
// `;

const QRGButton = styled(Button)`
    background-color: #23354b;
    color: white;
    border-radius: 25px;
    position: relative;
    padding: 1.5em;
    will-change: transform;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
        background-color: #23354b;
    }
`;

const FAQTitle = styled(Typography)`
    text-transform: uppercase;
    font-weight: 300;
`;

const FAQQuestion = styled(Typography)``;
const FAQBody = styled(Typography)`
    @media (min-width: 800px) {
        font-size: 15px;
        padding-bottom: 1.5em;
    }
    @media (max-width: 800px) {
        width: 100%;
        text-align: center;
    }
`;

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    transition: "0.3s ease-in-out",
    padding: ".2em 2em",

    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    },
    "&:hover": {
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 12px"
    }
}));

const AccordionSummary = styled(({ ...props }) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
        {...props}
        direction="row-reverse"
    />
))(({ theme }) => ({
    padding: "1em 0em",

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2)
}));

const Support = () => {
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : true);
        mixpanel.track("Support question toggled");
    };

    {
        return (
            <Grid>
                <Navbar />
                <Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <FadeInSection className="fade-in">
                                <FAQTitle variant="h1" className="support-h1">
                                    Contact
                                </FAQTitle>
                            </FadeInSection>
                        </Grid>
                    </Grid>
                    <Grid className="support-bg">
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            spacing={{ xs: 3, md: 10 }}
                            sx={{ pt: 8 }}
                        >
                            <Grid item>
                                <FadeInSection className="fade-in-from-left">
                                    <Grid item className="support-blocks">
                                        <Typography
                                            variant="h2"
                                            className="support-h2"
                                            sx={{ mb: 2 }}
                                        >
                                            Support
                                        </Typography>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className="support-link"
                                            sx={{ mb: 1.3 }}
                                        >
                                            <LocalPhoneIcon
                                                fontSize="medium"
                                                sx={{ mr: 1 }}
                                            />
                                            <a
                                                aria-label="Call support for more info at 8 8 8 3 6 6 1 3 2 5"
                                                href="tel:8883661325"
                                                onClick={() =>
                                                    mixpanel.track(
                                                        "Customer support number called"
                                                    )
                                                }
                                            >
                                                (888) 366-1325
                                            </a>
                                        </Box>
                                        <Box
                                            className="support-link"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <EmailIcon
                                                fontSize="medium"
                                                sx={{ mr: 1 }}
                                            />
                                            <a
                                                href="mailto:support@iqpro.com"
                                                onClick={() =>
                                                    mixpanel.track(
                                                        "Customer support email opened"
                                                    )
                                                }
                                            >
                                                support@iqpro.com
                                            </a>
                                        </Box>
                                    </Grid>
                                </FadeInSection>
                            </Grid>
                            <Grid item>
                                <FadeInSection className="fade-in-from-right">
                                    <Grid item className="support-blocks">
                                        <Typography
                                            variant="h2"
                                            className="support-h2"
                                            sx={{ mb: 2 }}
                                        >
                                            Sales
                                        </Typography>
                                        <Box
                                            className="support-link"
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <LocalPhoneIcon
                                                fontSize="medium"
                                                sx={{ mr: 1 }}
                                            />
                                            <a
                                                href="tel:8003860711"
                                                aria-label="Call sales for more info at 8 0 0 3 8 6 0 7 1 1"
                                                onClick={() =>
                                                    mixpanel.track(
                                                        "Sales number called"
                                                    )
                                                }
                                            >
                                                (800) 386-0711
                                            </a>
                                        </Box>
                                    </Grid>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" sx={{ mt: 6 }}>
                            <FadeInSection className="fade-in-from-bottom">
                                <Grid item>
                                    <QRGButton
                                        href="https://basyspro.com/equipment/qrg-iqpro"
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="contained"
                                        sx={{ mb: 6 }}
                                    >
                                        <Typography
                                            align="center"
                                            fontWeight="500"
                                            sx={{
                                                fontSize: "1.3em !important"
                                            }}
                                        >
                                            iQ Pro Training Videos and <br />
                                            Quick Resource Guide
                                        </Typography>
                                    </QRGButton>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        my: 5,
                        mx: { xs: 3, md: 15 }
                    }}
                >
                    <Grid container justifyContent="center" sx={{ mb: 5 }}>
                        <Grid item>
                            <FadeInSection className="fade-in">
                                <FAQTitle variant="h1" className="support-h1">
                                    Frequently Asked Questions
                                </FAQTitle>
                            </FadeInSection>
                        </Grid>
                    </Grid>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    How do I log in to iQ Pro/I lost my
                                    password?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    You can log in to iQ Pro{" "}
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://app.basysiqpro.com/auth"
                                    >
                                        here.
                                    </Link>{" "}
                                    If you have forgotten your login
                                    credentials, please utilize the “Forgot
                                    Username?” or “Forgot Password?” links on
                                    the
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://app.basysiqpro.com/auth"
                                    >
                                        {" "}
                                        login page
                                    </Link>
                                    .
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                                sx={{ pl: 1 }}
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    How can I access iQ Pro?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    iQ Pro is available on any device with
                                    access to a web browser, and will utilize
                                    the native connectivity of that device
                                    (Internet, WiFi, Cellular, etc.)
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    What transaction types does iQ Pro support?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    iQ Pro supports Sales, Pre-Authorizations,
                                    Card Verifications, Full and Partial
                                    Refunds, and Blind Credits.
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel4d-content"
                                id="panel4d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    Why did my transaction decline?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    Transactions are approved or declined by the
                                    issuing bank of the card. iQ Pro will
                                    display the full, detailed transaction
                                    response that includes the decline code from
                                    the issuer. Please{" "}
                                    <Link href="tel:8883661325">
                                        contact us
                                    </Link>{" "}
                                    if you need more information on a
                                    transaction status.
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel7"}
                            onChange={handleChange("panel7")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel7d-content"
                                id="panel7d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    What fields are required to process Level
                                    II/III?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    The minimum required fields to process a
                                    card transaction include the payment
                                    information (card number and expiration
                                    date) and the cardholder first and last
                                    name. All Level II and III fields are
                                    submitted automatically on qualified card
                                    types without manual entry.
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel8"}
                            onChange={handleChange("panel8")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel8d-content"
                                id="panel8d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    Can I import my customer data into iQ Pro?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    In many cases, iQ Pro can accept an import
                                    of customer data (including secured payment
                                    data) from other gateways and software.
                                    Please{" "}
                                    <Link href="tel:8883661325">
                                        contact us
                                    </Link>{" "}
                                    for more information.
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-bottom">
                        <Accordion
                            expanded={expanded === "panel9"}
                            onChange={handleChange("panel9")}
                            sx={{ pl: 1 }}
                        >
                            <AccordionSummary
                                aria-controls="panel9d-content"
                                id="panel9d-header"
                            >
                                <FAQQuestion
                                    variant="h3"
                                    className="support-h3"
                                >
                                    How can I export my transactions for
                                    reporting?
                                </FAQQuestion>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FAQBody>
                                    iQ Pro supports export of transaction
                                    Reporting through the universal format of a
                                    CSV file for easy upload into other software
                                    for reporting and reconciliation purposes.
                                </FAQBody>
                            </AccordionDetails>
                        </Accordion>
                    </FadeInSection>
                </Grid>
                <Footer />
            </Grid>
        );
    }
};

export default Support;
