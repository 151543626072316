import * as React from "react";

const Icon7 = ({ ...props }) => (
    <svg
        width={77}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M75.55 62.759c-.541 1.275-1.507 1.7-2.898 1.661-5.642-.038-11.323 0-16.965 0h-.734c-.658-.077-.967-.463-1.005-1.082 0-.618.347-1.004.966-1.12.232-.039.425 0 .657 0h17.66V17.699H25.467c-.31 0-.657 0-.966-.039-.58-.115-.928-.502-.928-1.082 0-.54.31-.927.85-1.082.31-.077.619-.077.966-.077H73.27V8.695H2.28v6.724h17.467c.31 0 .58 0 .89.077.54.155.81.542.81 1.082 0 .58-.308.928-.85 1.044-.27.038-.58.038-.888.038H2.319v44.48h47.764c1.16 0 1.74.387 1.7 1.16 0 .734-.54 1.082-1.7 1.082H2.821C.927 64.382 0 63.222 0 61.599.039 42.47.039 23.264 0 4.058 0 2.01 1.468 0 4.058 0 10.859.077 17.66 0 24.5 0c2.55 0 4.019 1.507 4.019 4.019v2.357H72.613c1.391 0 2.396.387 2.976 1.623V62.72l-.039.039ZM2.241 6.376H26.24c0-.966.038-1.893 0-2.82-.078-.812-.619-1.276-1.43-1.276H3.787c-.889 0-1.468.58-1.507 1.469v2.627h-.039Z" />
            <path d="M35.128 22.723h14.298c.503 0 .773-.116.966-.618.928-2.087 3.13-3.324 5.333-3.015a5.205 5.205 0 0 1 4.444 4.406c.348 2.164-.85 4.405-2.936 5.371-.541.232-.619.541-.619 1.044V49.736c-.116 1.468-1.082 2.395-2.55 2.395h-9.739c-.502 0-.811.116-1.12.503l-4.406 5.449c-1.314 1.623-2.86 1.623-4.212 0-1.469-1.778-2.937-3.556-4.367-5.372-.348-.425-.696-.58-1.237-.58h-8.038c-1.739 0-2.705-.966-2.705-2.743V25.505c0-1.816.966-2.782 2.783-2.782h14.143-.038Zm19.206 6.608c-2.357-.54-3.826-1.97-4.328-4.328H21.177c-.618 0-.734.232-.734.773v23.457c0 .58.154.773.734.773h8.116c1.043 0 1.816.348 2.473 1.16 1.468 1.854 3.014 3.71 4.521 5.564.116.155.193.348.31.58.347-.425.579-.657.772-.928 1.43-1.739 2.86-3.478 4.29-5.255.618-.773 1.352-1.121 2.357-1.121h10.318V29.331Zm.773-2.241a2.946 2.946 0 0 0 2.937-2.86c0-1.546-1.314-2.898-2.898-2.937a2.92 2.92 0 0 0-2.899 2.898c0 1.623 1.237 2.899 2.86 2.899ZM64.46 12.018c0 .58-.542 1.121-1.121 1.16-.58 0-1.121-.541-1.121-1.12 0-.58.463-1.083 1.082-1.083.618 0 1.16.464 1.16 1.082v-.039ZM58.7 10.936c.658 0 1.121.464 1.121 1.082 0 .58-.502 1.082-1.082 1.121-.58 0-1.159-.502-1.159-1.082 0-.618.503-1.12 1.12-1.12ZM67.899 13.178c-.619 0-1.121-.541-1.082-1.16 0-.618.463-1.043 1.082-1.082.618 0 1.159.464 1.159 1.082 0 .58-.541 1.121-1.12 1.121l-.04.039Z" />
            <path d="M37.137 33.544h11.439c.618 0 1.12.193 1.236.811.078.348-.116.85-.347 1.16-.155.193-.619.27-.928.27H25.428c-.619-.077-.966-.425-1.005-1.044 0-.618.386-1.082 1.005-1.159h11.786l-.077-.038ZM37.138 41.04H25.274c-.58-.154-.966-.502-.928-1.159 0-.618.387-1.005 1.005-1.043h23.496c.58.077.966.425 1.005 1.005.077.618-.27 1.043-.889 1.197-.232.078-.502.04-.734.04h-11.13l.039-.04ZM37.06 46.296H26.007c-.27 0-.54 0-.811-.038-.541-.078-.812-.426-.85-.967-.039-.54.154-.927.657-1.12.309-.116.657-.116 1.004-.116h22.607c.773.038 1.276.502 1.237 1.16 0 .617-.464 1.081-1.237 1.081H37.021h.039ZM28.712 30.53h-3.014c-.889 0-1.352-.426-1.352-1.122 0-.734.502-1.159 1.39-1.159h6.107c.927 0 1.43.425 1.39 1.16 0 .734-.501 1.12-1.429 1.12h-3.091Z" />
        </g>
    </svg>
);

export default Icon7;
