import * as React from "react";

const Icon5 = (props) => (
    <svg
        width={75}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M33.888 23.609c-3.912 0-7.484-2.462-8.883-6.127a9.534 9.534 0 0 1-.638-3.413c0-5.257 4.271-9.534 9.52-9.534 5.25 0 9.522 4.277 9.522 9.534a9.564 9.564 0 0 1-.638 3.413c-1.406 3.665-4.971 6.127-8.883 6.127Zm0-17.074c-4.15 0-7.525 3.379-7.525 7.534 0 .91.17 1.822.503 2.692 1.107 2.897 3.932 4.842 7.022 4.842 3.09 0 5.915-1.945 7.022-4.842a7.564 7.564 0 0 0 .503-2.692c0-4.155-3.376-7.534-7.525-7.534Z" />
            <path d="M67.002 63H.998A1 1 0 0 1 0 62V22.733c0-.306.136-.592.374-.782L15.755 9.608a.987.987 0 0 1 1.4.157.996.996 0 0 1-.157 1.4L1.997 23.208V61h64.006V23.215L50.71 10.948a.996.996 0 0 1 1.242-1.557L67.62 21.957c.237.19.373.476.373.781v39.27a1 1 0 0 1-.998.999l.007-.007Z" />
            <path d="M67.002 63a.996.996 0 0 1-.51-.143L.49 23.588a.99.99 0 0 1-.346-1.366.99.99 0 0 1 1.365-.347L67.51 61.144a.99.99 0 0 1 .346 1.366.993.993 0 0 1-.855.49Z" />
            <path d="M.998 63a.993.993 0 0 1-.855-.49.99.99 0 0 1 .346-1.366l66.003-39.27a.988.988 0 0 1 1.365.348.996.996 0 0 1-.346 1.366L1.508 62.858a.978.978 0 0 1-.51.142Z" />
            <path d="M51.334 32.965a1 1 0 0 1-.998-1V2H17.378v29.885a1 1 0 0 1-.998 1 1 1 0 0 1-.998-1V1a1 1 0 0 1 .998-1h34.954a1 1 0 0 1 .999 1v30.966a1 1 0 0 1-.999 1Z" />
            <path d="M45.616 27.37H22.757a1 1 0 0 1 0-2h22.86a1 1 0 0 1 0 2ZM45.426 31.939H22.567a1 1 0 0 1 0-2h22.86a1 1 0 0 1 0 2ZM34.472 18.672h-.924a2.306 2.306 0 0 1-2.302-2.305 1 1 0 0 1 .998-1 1 1 0 0 1 .999 1c0 .17.136.306.305.306h.924c.17 0 .306-.136.306-.306v-.925a.305.305 0 0 0-.306-.306h-.924a2.306 2.306 0 0 1-2.302-2.305v-.925a2.306 2.306 0 0 1 2.302-2.305h.924a2.306 2.306 0 0 1 2.302 2.305c0 .551-.448 1.02-.998 1.02a.983.983 0 0 1-.998-.979c0-.21-.136-.346-.306-.346h-.924a.304.304 0 0 0-.305.305v.925c0 .17.136.306.305.306h.924a2.304 2.304 0 0 1 2.302 2.298v.925a2.306 2.306 0 0 1-2.302 2.305v.007Z" />
            <path d="M34.01 11.471a1 1 0 0 1-.998-1V9.079a1 1 0 0 1 .998-1 1 1 0 0 1 .998 1v1.394a1 1 0 0 1-.998 1ZM34.01 20.066a1 1 0 0 1-.998-1v-1.393a1 1 0 0 1 .998-1 1 1 0 0 1 .998 1v1.394a1 1 0 0 1-.998 1Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h68v63H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon5;
