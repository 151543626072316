import * as React from "react";

const LockIcon = (props) => (
    <svg
        width="48"
        height="35"
        viewBox="0 0 70 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.33027 11.7982C7.99388 11.7982 7.71865 12.0734 7.71865 12.4098C7.71865 12.7462 7.99388 13.0214 8.33027 13.0214H12.4098C12.7462 13.0214 13.0214 12.7462 13.0214 12.4098C13.0214 12.0734 12.7462 11.7982 12.4098 11.7982H8.33027Z"
            fill="#4C5B8C"
        />
        <path
            d="M20.2936 25.8349H1.22324V20.2691H23.8349C24.1713 20.2691 24.4465 19.9939 24.4465 19.6575C24.4465 19.3211 24.1713 19.0459 23.8349 19.0459H1.22324V4.50765C1.22324 2.69725 2.69725 1.22324 4.50765 1.22324H38.7982C40.6086 1.22324 42.0826 2.69725 42.0826 4.50765V13.1529C42.0826 13.4893 42.3578 13.7645 42.6942 13.7645C43.0306 13.7645 43.3058 13.4893 43.3058 13.1529V4.50765C43.3058 2.02141 41.2844 0 38.7982 0H4.50765C2.02141 0 0 2.02141 0 4.50765V29.1131C0 31.5994 2.02141 33.6208 4.50765 33.6208H18.2599C18.5963 33.6208 18.8716 33.3456 18.8716 33.0092C18.8716 32.6728 18.5963 32.3976 18.2599 32.3976H4.50765C2.69725 32.3976 1.22324 30.9235 1.22324 29.1131V27.0581H20.2936C20.63 27.0581 20.9052 26.7829 20.9052 26.4465C20.9052 26.1101 20.63 25.8349 20.2936 25.8349Z"
            fill="#4C5B8C"
        />
        <path
            d="M35.5 12C31.3661 12 28 14.915 28 18.5018V25H29.0065V18.6781C29.0065 15.5685 31.9289 13.0353 35.5186 13.0353C39.1084 13.0353 42.0271 15.5648 42.0271 18.6781V25H42.9963V18.5018C43 14.915 39.6339 12 35.5 12Z"
            fill="#4C5B8C"
            className="iconDanceY"
        />
        <path
            d="M44.7798 26H25.4251C24.0857 26 23 27.0857 23 28.4251V41.8777C23 43.217 24.0857 44.3028 25.4251 44.3028H44.7798C46.1192 44.3028 47.2049 43.217 47.2049 41.8777V28.4251C47.2049 27.0857 46.1192 26 44.7798 26Z"
            fill="#FAFAFA"
        />
        <path
            d="M41.7798 27.0856H22.4251C21.0857 27.0856 20 28.1714 20 29.5107V42.9633C20 44.3026 21.0857 45.3884 22.4251 45.3884H41.7798C43.1192 45.3884 44.2049 44.3026 44.2049 42.9633V29.5107C44.2049 28.1714 43.1192 27.0856 41.7798 27.0856Z"
            fill="#0288D1"
        />
        <path
            d="M41.7798 27.0856H22.4251C21.0857 27.0856 20 28.1714 20 29.5107V42.9633C20 44.3026 21.0857 45.3884 22.4251 45.3884H41.7798C43.1192 45.3884 44.2049 44.3026 44.2049 42.9633V29.5107C44.2049 28.1714 43.1192 27.0856 41.7798 27.0856Z"
            fill="white"
            fillOpacity="0.6"
        />
        <rect
            x="22.625"
            y="25.625"
            width="24.75"
            height="18.75"
            rx="2.375"
            stroke="#4C5B8C"
            strokeWidth="1.25"
        />
        <path
            d="M35.474 33C34.6606 33 34 33.6606 34 34.474C34 35.0673 34.3547 35.578 34.8624 35.8104V37.7125C34.8624 38.0489 35.1376 38.3242 35.474 38.3242C35.8104 38.3242 36.0856 38.0489 36.0856 37.7125V35.8104C36.5933 35.578 36.948 35.0673 36.948 34.474C36.948 33.6606 36.2875 33 35.474 33V33Z"
            fill="#4C5B8C"
        />
    </svg>
);

export default LockIcon;
