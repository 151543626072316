import * as React from "react";

const Icon4 = (props) => (
    <svg
        width={67}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M34.49 41.668h-1.98c-2.106 0-3.82-1.708-3.82-3.806a1.028 1.028 0 0 1 2.057 0c0 .97.79 1.764 1.77 1.764h1.98c.973 0 1.77-.788 1.77-1.764V35.89c0-.968-.79-1.763-1.77-1.763h-1.98c-2.106 0-3.82-1.707-3.82-3.805V28.35c0-2.098 1.714-3.805 3.82-3.805h1.98c2.106 0 3.82 1.707 3.82 3.805v.083a1.028 1.028 0 0 1-2.056 0v-.083c0-.969-.791-1.763-1.77-1.763H32.51c-.973 0-1.77.787-1.77 1.763v1.972c0 .969.79 1.756 1.77 1.756h1.98c2.106 0 3.82 1.708 3.82 3.805v1.973c0 2.097-1.714 3.805-3.82 3.805v.007Z" />
            <path d="M33.504 26.28c-.567 0-1.029-.46-1.029-1.024v-2.97a1.028 1.028 0 0 1 2.057 0v2.97c0 .564-.461 1.024-1.028 1.024ZM33.504 44.636c-.567 0-1.029-.46-1.029-1.024v-2.969a1.028 1.028 0 0 1 2.057 0v2.969c0 .564-.461 1.024-1.028 1.024Z" />
            <path d="M33.504 66.728c-.567 0-1.03-.46-1.03-1.024 0-.565.463-1.025 1.03-1.025 17.339 0 31.446-14.05 31.446-31.318 0-17.27-14.114-31.312-31.447-31.312-17.332 0-31.446 14.05-31.446 31.319 0 16.342 12.813 30.064 29.165 31.235a1.024 1.024 0 1 1-.147 2.041C13.652 65.397 0 50.776 0 33.367 0 14.97 15.03 0 33.504 0c18.473 0 33.503 14.97 33.503 33.367 0 18.399-15.03 33.368-33.504 33.368v-.007Z" />
            <path d="m27.24 61.913 3.303 3.644-3.66 3.29 2.96.153 3.66-3.296-3.31-3.645-2.952-.146ZM33.504 59.94c-14.716 0-26.681-11.924-26.681-26.572 0-3.297.602-6.516 1.791-9.569.203-.53.798-.787 1.33-.585.531.202.79.794.587 1.324a24.325 24.325 0 0 0-1.651 8.83c0 13.526 11.049 24.53 24.624 24.53s24.624-11.004 24.624-24.53c0-13.527-11.05-24.531-24.624-24.531-.567 0-1.029-.46-1.029-1.025 0-.564.462-1.024 1.029-1.024 14.715 0 26.68 11.924 26.68 26.573 0 14.648-11.972 26.572-26.68 26.572v.007Z" />
            <path d="m4.772 26.559 4.577-1.826 1.826 4.558 1.169-2.711-1.834-4.558-4.576 1.826-1.162 2.71ZM33.742 18.07c-.567 0-1.03-.46-1.03-1.024V12.37a1.028 1.028 0 0 1 2.058 0v4.676c0 .565-.462 1.025-1.029 1.025ZM33.742 55.383c-.567 0-1.03-.46-1.03-1.025v-4.676a1.028 1.028 0 0 1 2.058 0v4.676c0 .565-.462 1.025-1.029 1.025ZM22.154 22.851c-.266 0-.525-.097-.728-.3l-3.317-3.303a1.027 1.027 0 0 1 0-1.45 1.038 1.038 0 0 1 1.456 0l3.316 3.304a1.027 1.027 0 0 1 0 1.45c-.203.202-.461.3-.727.3ZM48.646 49.236c-.266 0-.525-.098-.728-.3l-3.317-3.303a1.028 1.028 0 0 1 0-1.45 1.038 1.038 0 0 1 1.456 0l3.317 3.304a1.028 1.028 0 0 1 0 1.45c-.203.201-.462.299-.728.299ZM17.354 34.385h-4.695c-.567 0-1.03-.46-1.03-1.024 0-.565.463-1.025 1.03-1.025h4.695c.567 0 1.028.46 1.028 1.025 0 .564-.462 1.024-1.028 1.024ZM54.818 34.385h-4.696c-.566 0-1.028-.46-1.028-1.024 0-.565.462-1.025 1.028-1.025h4.696c.566 0 1.028.46 1.028 1.025 0 .564-.462 1.024-1.028 1.024ZM18.837 49.236c-.266 0-.525-.098-.728-.3a1.028 1.028 0 0 1 0-1.45l3.317-3.303a1.038 1.038 0 0 1 1.455 0 1.028 1.028 0 0 1 0 1.45l-3.316 3.303c-.203.202-.462.3-.728.3ZM45.322 22.851c-.266 0-.525-.097-.728-.3a1.028 1.028 0 0 1 0-1.449l3.317-3.303a1.038 1.038 0 0 1 1.456 0 1.028 1.028 0 0 1 0 1.45l-3.317 3.303c-.203.202-.462.3-.728.3Z" />
        </g>
    </svg>
);

export default Icon4;
