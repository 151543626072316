import * as React from "react";

const LPIcon2 = (props) => (
    <svg
        width={270}
        height={233}
        viewBox="0 0 270 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            opacity={0.5}
            cx={140.934}
            cy={133.022}
            r={129.066}
            fill="#76CBE7"
        />
        <circle
            cx={129.066}
            cy={129.066}
            r={128.066}
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeDasharray="10 10"
            className="dashRotate"
        />
        <path
            d="M116.277 53.901h24.725l6.923 14.836 14.121 10.384h-60.824l9.615-12.362 5.44-12.858Z"
            fill="#fff"
        />
        <path
            d="m58.255 104.66 12.031-24.056L87.1 82.088l18.117-5.863-31.47 51.852-15.492-23.417Z"
            fill="#fff"
        />
        <path
            d="M69.298 177.034 57.43 155.77l10.879-15.824 6.251-16.119 27.87 54.69-33.132-1.483ZM116.771 202.748h24.725l6.924-12.857 12.637-15.825h-60.824l16.538 28.682Z"
            fill="#fff"
        />
        <path
            d="m186.626 179.025 13.221-22.266-8.407-11.374-4.814-8.406-29.566 39.983 29.566 2.063ZM200.34 101.374l-12.624-19.286-15.563 1.484-15.093-6.745 30.657 52.534 6.195-19.58 6.428-8.407Z"
            fill="#fff"
        />
        <path
            d="M193.913 119.671c0 32.5-31.956 67.253-65.275 67.253-33.32 0-62.308-24.369-62.308-56.869s28.989-60.824 62.308-60.824c29.176 4.45 65.275 21.758 65.275 50.44Z"
            fill="#fff"
        />
        <g clipPath="url(#a)">
            <path
                d="M97.083 103.891c7.402-9.24 18.79-15.152 31.56-15.152 19.443 0 35.682 13.719 39.531 31.986M163.543 149.383c-7.008 11.968-20.013 20.01-34.9 20.01-18.35 0-33.845-12.218-38.765-28.946"
                stroke="#344154"
                strokeWidth={4.92}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="9.84 9.84"
            />
            <path
                d="M67.285 141.811a32.303 32.303 0 0 1-6.855 10.733l-2.946 3.054 12.565 21.731 4.122-1.016a32.12 32.12 0 0 1 7.751-.939c5.93 0 11.806 1.614 17.029 4.646.083.045.167.098.258.144 7.562 4.335 13.278 11.263 15.692 19.623l1.177 4.071h25.129l1.177-4.078c2.414-8.345 8.116-15.273 15.662-19.601l.114-.068.114-.068c7.524-4.359 16.383-5.829 24.833-3.737l4.123 1.023 12.564-21.731-2.938-3.054c-4.1-4.252-6.871-9.49-8.222-15.137M193.956 109.045a31.212 31.212 0 0 1 2.892-3.457l2.946-3.054-12.564-21.73-4.123 1.015c-8.427 2.084-17.271.652-24.78-3.707a2.741 2.741 0 0 0-.258-.144c-7.562-4.335-13.278-11.263-15.692-19.623l-1.177-4.07h-25.129l-1.177 4.077c-2.414 8.346-8.116 15.273-15.662 19.601l-.114.068-.114.069c-7.524 4.358-16.383 5.828-24.833 3.736l-4.123-1.023-12.564 21.731 2.945 3.054c4.1 4.253 6.871 9.49 8.223 15.137"
                stroke="#344154"
                strokeWidth={4.92}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M121.666 61.74a39.843 39.843 0 0 1-5.838 11.294h25.622a39.661 39.661 0 0 1-5.845-11.294h-13.947.008ZM135.62 196.286a39.809 39.809 0 0 1 5.838-11.294h-25.623a39.646 39.646 0 0 1 5.846 11.294h13.946-.007Z"
                fill="#17C2C9"
            />
            <circle
                cx={129.133}
                cy={129.066}
                fill="#17C2C9"
                fillOpacity={0.27}
                r={34.121}
            />
            <path
                d="M121.713 109.195c-5.911 1.489-10.972 5.158-14.128 10.44-6.523 10.916-2.899 25.259 8.03 31.778 6.416 4.067 13.95 4.262 20.493 1.717l-2.829-5.27c-4.643 1.768-10.136 1.357-14.719-1.377-8.108-4.837-10.808-15.523-6.04-23.904 2.315-3.874 6.392-6.696 10.614-7.76l3.269 12.937 10.521-17.608-17.627-10.515 2.416 9.562Zm25.708 34.204c5.471-9.156 3.754-20.689-3.515-28.13l-3.156 5.282c4.659 5.407 5.523 13.565 1.454 19.975-1.052 1.76-2.457 3.311-4.214 4.651l3.11 5.199c2.531-1.834 4.638-4.16 6.321-6.977Z"
                fill="#344154"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(50.507 52.418)"
                    d="M0 0h156.264v153.297H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default LPIcon2;
