import * as React from "react";

const Icon5 = (props) => (
    <svg
        width={78}
        height={78}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#1B9AD2">
            <path d="M59.44 75H1.118A1.112 1.112 0 0 1 0 73.895V1.105C0 .495.5 0 1.117 0H74.89c.617 0 1.118.495 1.118 1.105v57.84c0 .61-.501 1.105-1.118 1.105H60.558v13.845c0 .61-.5 1.105-1.117 1.105ZM2.236 72.798h56.088V58.953c0-.61.501-1.105 1.118-1.105h14.332V2.21H2.235v70.588Z" />
            <path d="M59.44 75a1.103 1.103 0 0 1-1.117-1.097v-14.95c0-.61.501-1.105 1.118-1.105h15.442c.454 0 .863.275 1.032.686.17.412.07.892-.254 1.204l-15.442 14.95a1.122 1.122 0 0 1-.778.312Zm1.118-14.95v11.224L72.155 60.05H60.558ZM46.23 20.223H15.545a1.112 1.112 0 0 1-1.117-1.105v-7.224c0-.61.5-1.105 1.117-1.105h30.676c.616 0 1.117.496 1.117 1.105v7.224c0 .61-.5 1.105-1.117 1.105h.007Zm-29.567-2.21h28.449V12.99H16.663v5.022ZM30.888 33.473H15.546a1.112 1.112 0 0 1-1.117-1.105v-7.223c0-.61.5-1.105 1.117-1.105h15.342c.616 0 1.117.495 1.117 1.105v7.223c0 .61-.501 1.105-1.117 1.105Zm-14.225-2.21H29.77v-5.021H16.663v5.021ZM46.23 50.206H15.545a1.112 1.112 0 0 1-1.117-1.105v-7.224c0-.61.5-1.104 1.117-1.104h30.676c.616 0 1.117.495 1.117 1.104v7.224c0 .61-.5 1.105-1.117 1.105h.007Zm-29.567-2.21h28.449v-5.021H16.663v5.021ZM30.888 63.456H15.546a1.112 1.112 0 0 1-1.117-1.105v-7.223c0-.61.5-1.105 1.117-1.105h15.342c.616 0 1.117.495 1.117 1.105v7.223c0 .61-.501 1.105-1.117 1.105Zm-14.225-2.21H29.77v-5.02H16.663v5.02Z" />
        </g>
        <defs>
            <path fill="#fff" d="M0 0h76v75H0z" />
        </defs>
    </svg>
);

export default Icon5;
