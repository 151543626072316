import React from "react";
import "../iQProtect/iQProtect.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";
import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import protectMockup from "../iQProtect/assets/protect-mockup.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
// import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";

export default function iQProtect() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="protect-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 4 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            iQ Protect
                                        </strong>{" "}
                                        - a fraud management tool used to set
                                        rules and filters to prevent and detect
                                        suspicious transactions.
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">iQ Protect</strong> - a fraud
                    management tool used to set rules and filters to prevent and
                    detect suspicious transactions.
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                direction={{ xs: "column", md: "row" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                justifyContent={{ sm: "center", md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item sm={10} lg={5}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: {
                                    xs: 2,
                                    md: 0,
                                    fontSize: "1.25rem !important"
                                }
                            }}
                        >
                            iQ Protect is a fraud management tool used to set
                            rules and filters to prevent and detect suspicious
                            transactions. Transactions can be declined/approved
                            pre- and post- authorization.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <FadeInSection className="fade-in-from-right">
                    <Grid item xs={12} md lg>
                        <img
                            src={protectMockup}
                            className="protect-mockup"
                            alt="iQ Protect Mockup"
                        ></img>
                    </Grid>
                </FadeInSection>
                <Grid container>
                    <FadeInSection className="fade-in-from-bottom">
                        <Grid item md={12} sx={{ mt: 5 }}>
                            <Typography
                                variant="h2"
                                fontWeight={450}
                                className="products-title"
                                sx={{ display: { xs: "none", md: "block" } }}
                            >
                                iQ Protect
                            </Typography>
                        </Grid>
                    </FadeInSection>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                sx={{ mb: 2, pl: { xs: 2, md: 7 } }}
            >
                <Grid item lg={1}></Grid>
                <Grid item lg>
                    <List>
                        <FadeInSection className="fade-in-from-left">
                            <Grid>
                                <Typography
                                    variant="h2"
                                    className="features-h2"
                                    sx={{ mb: 2 }}
                                >
                                    Who benefits from iQ Protect?
                                </Typography>
                                <Grid container direction="row">
                                    <Grid item>
                                        <Typography
                                            paragraph="true"
                                            sx={{
                                                width: "85%",
                                                fontSize: "1.25rem"
                                            }}
                                        >
                                            Card-not-present merchants,
                                            especially e-commerce merchants, who
                                            are looking for an automated way to
                                            deter fraudulent transactions.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FadeInSection>
                    </List>
                </Grid>
                <Grid item lg={1}></Grid>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 }, pl: { xs: 2, md: 5, lg: 0 } }}
            >
                <List>
                    <Grid container direction="row">
                        <Grid item lg={1}></Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Prevent fraud and effortlessly
                                            accept or decline transactions with
                                            iQ Protect
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Ideal for CNP merchants looking for
                                            an automated fraudulent transaction
                                            deterrent
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            All iQ Pro Merchants qualify for iQ
                                            Protect
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            iQ Protect is Included in the base
                                            iQ Pro package, so there are no
                                            additional fees
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Easily enabled and accessible at the
                                            gateway level and can be turned on
                                            or off at the user level
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Customizable notification based on
                                            user set triggers
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={1}></Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
