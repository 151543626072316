import * as React from "react";
import "./SignIn.scss";
import { FadeInSection } from "../Components/FadeInSection/FadeInSection";
import "../Components/FadeInSection/FadeInSection.scss";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Footer from "../Components/Footer/Footer";
import Navbar from "../NavMenu/Navbar";
import iQProLogo from "../Media/logo.png";

import IQLogo from "./assets/basys-iq-logo.png";
import mixpanel from "mixpanel-browser";

const Options = [
    // {
    //   id: "iQ Pro+",
    //   logo: `${iQProLogo}`,
    //   description:
    //     " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore.",
    //   color: "#189AD0",
    // },
    {
        id: "iQ Pro",
        logo: `${iQProLogo}`,
        description:
            "iQ Pro is a payment processing solution designed to help business to business (B2B) organizations qualify for the lowest possible rates on card not present (CNP) transactions. ",
        color: "#08304C",
        link: "https://app.basysiqpro.com/auth"
    },
    {
        id: "BASYS IQ",
        logo: `${IQLogo}`,
        description:
            "BASYS IQ is an online processing solution, complete with a built-in Intelligent Qualification System (IQS).",
        color: "#81312F",
        link: "https://www.basyspro.net/Default.aspx"
    }
];

const SignInButton = styled(Button)`
    color: #ffffff;
    font-size: 22px;
    padding: 0.5px;
    width: 7em;
    border-radius: 30px;
    margin-top: -1em;
    position: absolute;
    transition: 0.3s ease-in-out;
    will-change: transform;
    &:hover {
        transform: scale(1.04);
    }
    @media (max-width: 800px) {
        width: 55%;
    }
`;

const SignInCard = () => (
    <FadeInSection className="fade-in">
        <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={10}
            sx={{ mt: 0 }}
        >
            {Options.map(({ id, logo, description, color, link }) => (
                <Grid item key={id}>
                    <Card
                        sx={{
                            width: { xs: "85vw", md: "25vw" },
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                            borderRadius: "8px",
                            mb: 3,
                            pt: 2
                        }}
                    >
                        <CardContent sx={{ p: 2 }}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item>
                                    {/* {i === 0 ? (
                    <Tooltip
                      open
                      arrow
                      title="BETA"
                      placement="top"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 1200 }}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [-91, 5],
                            },
                          },
                        ],
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            borderRadius: "8px",
                            padding: "6px",
                            textAlign: "center",
                            bgcolor: "#189AD0",
                            width: "73px",
                            height: "30px",
                            fontSize: "24px",
                            animation: "beta-bounce 0.8s 3",
                            animationEasingFunction:
                              "cubic-bezier(0.5, 0.05, 1, 0.5)",
                            animationDelay: "300ms",
                            "& .MuiTooltip-arrow": {
                              color: "#189AD0",
                              marginLeft: "-1.15em",
                              marginBottom: "-.5em !important",
                            },
                          },
                        },
                      }}
                    >
                      <img src={logo} className="logo" />
                    </Tooltip>
                  ) : (
                    <img src={logo} className="logo" />
                  )} */}
                                    <img src={logo} className="logo" />
                                </Grid>
                                <Grid item sx={{ mb: 5, height: "100px" }}>
                                    <Typography align="center" variant="body2">
                                        {description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Grid container justifyContent="center">
                            <SignInButton
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                size="large"
                                onClick={() =>
                                    mixpanel.track(
                                        `User taken to ${id} sign in portal`
                                    )
                                }
                                sx={{
                                    "&.MuiButton-root": {
                                        backgroundColor: `${color}`,
                                        "&:hover": {
                                            backgroundColor: `${color}`
                                        }
                                    }
                                }}
                            >
                                Sign In
                            </SignInButton>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    </FadeInSection>
);

export default function SignIn() {
    return (
        <Grid>
            <Navbar />
            <SignInCard />
            <Footer />
        </Grid>
    );
}
