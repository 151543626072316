import * as React from "react";

const Icon3 = (props) => (
    <svg
        width={100}
        height={73}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M64.348 73h-.276c-1.623-.07-3.062-.751-4.054-1.927L40.426 48.497a1.073 1.073 0 0 1-.256-.83c.036-.297.206-.566.454-.744a24.497 24.497 0 0 0 3.437-2.855 24.479 24.479 0 0 0 2.855-3.444c.17-.248.44-.41.744-.454.305-.042.603.057.83.256l22.59 19.6c1.162.984 1.85 2.423 1.92 4.046.092 2.16-.893 4.435-2.693 6.235C68.585 72.03 66.424 73 64.348 73ZM42.792 47.972l18.842 21.712c.624.737 1.495 1.14 2.53 1.19 1.566.064 3.28-.708 4.641-2.069 1.36-1.36 2.133-3.097 2.07-4.641-.043-1.035-.454-1.906-1.177-2.516L47.979 42.8a25.944 25.944 0 0 1-5.187 5.187v-.014Z" />
            <path d="M26.693 53.386A26.667 26.667 0 0 1 7.81 45.563c-10.41-10.41-10.41-27.345 0-37.754C12.84 2.77 19.55 0 26.686 0c7.136 0 13.846 2.77 18.877 7.809 9.106 9.105 10.416 23.419 3.104 34.02a26.882 26.882 0 0 1-3.104 3.734 26.882 26.882 0 0 1-3.734 3.104 26.656 26.656 0 0 1-15.136 4.719Zm0-51.26c-6.292 0-12.585 2.395-17.375 7.185-9.587 9.58-9.587 25.17-.007 34.75 8.383 8.383 21.549 9.587 31.313 2.855a24.515 24.515 0 0 0 3.437-2.855 24.496 24.496 0 0 0 2.855-3.444c6.725-9.757 5.528-22.93-2.855-31.313-4.79-4.79-11.083-7.185-17.375-7.185l.007.007Zm.035 46.335c-5.647 0-11.26-2.168-15.447-6.356-8.51-8.503-8.51-22.335-.007-30.831 8.503-8.503 22.335-8.503 30.831 0 7.242 7.242 8.44 18.721 2.863 27.302a22.113 22.113 0 0 1-2.863 3.529 22.109 22.109 0 0 1-3.529 2.863 21.697 21.697 0 0 1-11.855 3.5l.008-.007Zm-.035-41.439a19.614 19.614 0 0 0-13.91 5.754c-7.674 7.674-7.674 20.153 0 27.827 6.534 6.533 16.893 7.617 24.638 2.58a19.922 19.922 0 0 0 3.189-2.58 19.613 19.613 0 0 0 2.58-3.189c5.038-7.745 3.953-18.105-2.58-24.638A19.614 19.614 0 0 0 26.7 7.022h-.007Z" />
            <path d="M19.893 39.224h-4.92a.813.813 0 0 1-.809-.82V19.342c0-.453.361-.82.809-.82h4.92c.447 0 .808.367.808.82v19.057c0 .453-.36.82-.808.82v.005Zm-4.112-1.64h3.304V20.168H15.78v17.418ZM28.61 39.224h-4.921a.81.81 0 0 1-.808-.815v-21.25a.81.81 0 0 1 .808-.816h4.92a.81.81 0 0 1 .809.816v21.25a.81.81 0 0 1-.808.815Zm-4.113-1.63h3.304v-19.62h-3.304v19.62ZM37.191 39.224h-5.74c-.522 0-.944-.36-.944-.807V14.971c0-.447.422-.807.944-.807h5.74c.522 0 .943.36.943.807v23.446c0 .447-.421.807-.943.807Zm-4.797-1.613h3.854V15.777h-3.854v21.834Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h73v73H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon3;
