import React from "react";
import PropTypes from "prop-types";
import "../NavMenu/assets/style.scss";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Box from "@mui/material/Box";
import CustomerIcon from "../NavMenu/assets/customer-icon.jsx";
import RecurringIcon from "../NavMenu/assets/recurring-icon.jsx";
import LockIcon from "../NavMenu/assets/lock-icon.jsx";
import ReportingIcon from "../NavMenu/assets/reporting-icon.jsx";
import CartIcon from "../NavMenu/assets/cart-icon.jsx";
import UpdaterIcon from "../NavMenu/assets/updater-icon.jsx";
import FraudIcon from "../NavMenu/assets/fraud-icon.jsx";
import Button from "@mui/material/Button";
import InvoicingIcon from "./assets/invoicing-icon";
import { Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";

export const MenuRequestDemoButton = styled(Button)`
    background-color: rgba(24, 154, 208, 1);
    color: white;
    border-radius: 2em;
    width: 10rem;
    position: relative;
    will-change: transform;
    transition: 0.3s ease-in-out;
    &:hover {
        transform: scale(1.03);
        background-color: rgba(24, 154, 208, 1);
    }
`;

const StyledListItemButton = styled(ListItemButton)`
    &:hover {
        .iconDanceX {
            animation: danceX 0.5s infinite alternate;
            transform-origin: center center;
            transform-box: fill-box;
        }
        .iconDanceY {
            animation: danceY 2s infinite alternate;
            transform-origin: center center;
            transform-box: fill-box;
        }
        .iconBounce1 {
            animation: bounce 0.3s infinite alternate;
            animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
        }
        .iconBounce2 {
            animation: bounce 0.3s infinite alternate;
            animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
            animation-delay: 100ms;
        }
        .iconBounce3 {
            animation: bounce 0.3s infinite alternate;
            animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
            animation-delay: 200ms;
        }
        .iconPeek {
            animation: peek 1s infinite alternate;
        }
        .iconSpin {
            animation-timing-function: ease;
            animation: spin 2s infinite;
            transform-origin: center center;
            transform-box: fill-box;
        }
        .iconSwing {
            animation: swing 2s infinite;
            transform-origin: center center;
        }
    }
`;

export default function MenuLinks() {
    const Products = [
        {
            id: "Account Updater",
            icon: <UpdaterIcon />,
            route: "/features/account-updater",
            description: "Automatically update customer card info"
        },
        {
            id: "Custom Fields",
            icon: <CustomerIcon />,
            route: "/features/custom-fields",
            description:
                "Track details that are specific to your business’s needs"
        },
        {
            id: "Customer Vault",
            icon: <CustomerIcon />,
            route: "/features/customer-vault",
            description: "Securely store customer payment info"
        },
        {
            id: "Invoicing",
            icon: <InvoicingIcon />,
            route: "/features/invoicing",
            description: "Send customers a click-to-pay link via email or text"
        },
        {
            id: "iQ Protect",
            icon: <FraudIcon />,
            route: "/features/iq-protect",
            description: "Fraud prevention management"
        },

        {
            id: "Recurring Billing",
            icon: <RecurringIcon />,
            route: "/features/recurring-billing",
            description:
                "Automatically bill customers daily, weekly, monthly, etc."
        },
        {
            id: "Reporting",
            icon: <ReportingIcon />,
            route: "/features/reporting",
            description: "View transactions and settlements"
        },
        {
            id: "Shopping Cart",
            icon: <CartIcon />,
            route: "/features/shopping-cart",
            description: "Simple check-out process for your customers"
        },
        {
            id: "Simple Pay",
            icon: <CartIcon />,
            route: "/features/simple-pay",
            description: "A simple and secure payments page"
        },
        {
            id: "Virtual Terminal",
            icon: <LockIcon />,
            route: "/features/virtual-terminal",
            description: "Accept payments using a computer or mobile device"
        }
    ];

    return (
        <div className="megamenu-wrapper">
            <Grid container spacing={3} sx={{ flexWrap: "nowrap" }}>
                <Grid item lg>
                    <ul>
                        <Stack>
                            {Products.slice(0, 5).map(
                                ({ id, icon, route, description }) => (
                                    <React.Fragment key={id}>
                                        <li className="list-item">
                                            <StyledListItemButton
                                                to={route}
                                                onClick={() =>
                                                    mixpanel.track(
                                                        `User taken to ${id} page`
                                                    )
                                                }
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        sx={{ mb: -1 }}
                                                    >
                                                        <Grid item>
                                                            <ListItemIcon className="menu-icons">
                                                                {icon}
                                                            </ListItemIcon>
                                                        </Grid>
                                                        <Grid>
                                                            <ListItemText>
                                                                <Typography fontWeight="500">
                                                                    {id}
                                                                </Typography>
                                                            </ListItemText>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ pl: 8 }}>
                                                        <Typography
                                                            variant="caption"
                                                            align="center"
                                                            className="desc"
                                                        >
                                                            {description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </StyledListItemButton>
                                        </li>
                                    </React.Fragment>
                                )
                            )}
                        </Stack>
                    </ul>
                </Grid>
                <Grid item lg>
                    <ul>
                        <Stack>
                            {Products.slice(5, 10).map(
                                ({ id, icon, route, description }) => (
                                    <React.Fragment key={id}>
                                        <li className="list-item">
                                            <StyledListItemButton
                                                to={route}
                                                onClick={() =>
                                                    mixpanel.track(
                                                        `User taken to ${id} page`
                                                    )
                                                }
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        direction="row"
                                                        sx={{ mb: -1 }}
                                                    >
                                                        <ListItemIcon className="menu-icons">
                                                            {icon}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography fontWeight="500">
                                                                {id}
                                                            </Typography>
                                                        </ListItemText>
                                                    </Grid>
                                                    <Grid item sx={{ pl: 8 }}>
                                                        <Typography
                                                            variant="caption"
                                                            align="center"
                                                            className="desc"
                                                        >
                                                            {description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </StyledListItemButton>
                                        </li>
                                    </React.Fragment>
                                )
                            )}
                        </Stack>
                    </ul>
                </Grid>
            </Grid>
        </div>
    );
}

MenuLinks.propTypes = {
    hovered: PropTypes.bool
};
