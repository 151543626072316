import * as React from "react";

function Stars() {
    return (
        <>
            <path
                d="M28.1315 9.12843C28.0275 8.80733 27.682 8.63302 27.3609 8.737L25.5933 9.31192V7.45259C25.5933 7.1162 25.3181 6.84097 24.9817 6.84097C24.6453 6.84097 24.37 7.1162 24.37 7.45259V9.31192L22.6025 8.737C22.2814 8.63302 21.9358 8.80733 21.8318 9.12843C21.7278 9.44954 21.9021 9.7951 22.2233 9.89908L23.9908 10.474L22.8991 11.9786C22.7003 12.2508 22.7615 12.633 23.0336 12.8318C23.1407 12.9113 23.2691 12.948 23.3914 12.948C23.581 12.948 23.7676 12.8593 23.8869 12.6972L24.9786 11.1927L26.0703 12.6972C26.1896 12.8624 26.3762 12.948 26.5658 12.948C26.6911 12.948 26.8165 12.9113 26.9236 12.8318C27.1957 12.633 27.2569 12.2508 27.0581 11.9786L25.9664 10.474L27.734 9.89908C28.0551 9.7951 28.2324 9.44954 28.1254 9.12843H28.1315Z"
                fill="#4C5B8C"
                className="iconBounce1"
            />
            <path
                d="M31.0759 12.8318C31.1829 12.9113 31.3114 12.948 31.4337 12.948C31.6233 12.948 31.8098 12.8593 31.9291 12.6972L33.0209 11.1927L34.1126 12.6972C34.2319 12.8624 34.4184 12.948 34.608 12.948C34.7334 12.948 34.8588 12.9113 34.9658 12.8318C35.238 12.633 35.2991 12.2508 35.1004 11.9786L34.0086 10.474L35.7762 9.89908C36.0973 9.7951 36.2747 9.44954 36.1676 9.12843C36.0637 8.80733 35.7181 8.63302 35.397 8.737L33.6294 9.31192V7.45259C33.6294 7.1162 33.3542 6.84097 33.0178 6.84097C32.6814 6.84097 32.4062 7.1162 32.4062 7.45259V9.31192L30.6386 8.737C30.3175 8.63302 29.9719 8.80733 29.8679 9.12843C29.764 9.44954 29.9383 9.7951 30.2594 9.89908L32.027 10.474L30.9352 11.9786C30.7364 12.2508 30.7976 12.633 31.0698 12.8318H31.0759Z"
                fill="#4C5B8C"
                className="iconBounce2"
            />
            <path
                d="M41.0647 6.84097C40.7283 6.84097 40.453 7.1162 40.453 7.45259V9.31192L38.6855 8.737C38.3644 8.63302 38.0188 8.80733 37.9148 9.12843C37.8108 9.44954 37.9852 9.7951 38.3063 9.89908L40.0738 10.474L38.9821 11.9786C38.7833 12.2508 38.8445 12.633 39.1167 12.8318C39.2237 12.9113 39.3521 12.948 39.4745 12.948C39.6641 12.948 39.8506 12.8593 39.9699 12.6972L41.0616 11.1927L42.1534 12.6972C42.2726 12.8624 42.4592 12.948 42.6488 12.948C42.7741 12.948 42.8995 12.9113 43.0066 12.8318C43.2787 12.633 43.3399 12.2508 43.1411 11.9786L42.0494 10.474L43.817 9.89908C44.1381 9.7951 44.3154 9.44954 44.2084 9.12843C44.1044 8.80733 43.7589 8.63302 43.4378 8.737L41.6702 9.31192V7.45259C41.6702 7.1162 41.3949 6.84097 41.0586 6.84097H41.0647Z"
                fill="#4C5B8C"
                className="iconBounce3"
            />
        </>
    );
}

const FraudIcon = (props) => (
    <svg
        width="48"
        height="35"
        viewBox="0 0 70 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_68_6648)">
            <path
                d="M45.4432 32.6789H29.8836C28.8077 32.6789 27.9355 33.551 27.9355 34.6269V45.4434C27.9355 46.5193 28.8077 47.3914 29.8836 47.3914H45.4432C46.5191 47.3914 47.3912 46.5193 47.3912 45.4434V34.6269C47.3912 33.551 46.5191 32.6789 45.4432 32.6789Z"
                fill="#A3D3FE"
            />
            <path
                d="M45.4406 32.0673H43.7586V27.1926C43.7586 23.8318 41.0247 21.0948 37.6607 21.0948C34.2968 21.0948 31.5629 23.8287 31.5629 27.1926V32.0673H29.8809C28.4681 32.0673 27.3213 33.2171 27.3213 34.63V45.4434C27.3213 46.8563 28.4711 48.0061 29.8809 48.0061H45.4375C46.8503 48.0061 47.9971 46.8563 47.9971 45.4434V34.63C47.9971 33.2171 46.8473 32.0673 45.4375 32.0673H45.4406ZM32.7892 27.1926C32.7892 24.5046 34.9757 22.318 37.6638 22.318C40.3519 22.318 42.5384 24.5046 42.5384 27.1926V32.0673H32.7922V27.1926H32.7892ZM46.78 45.4404C46.78 46.1774 46.1806 46.7798 45.4436 46.7798H29.887C29.15 46.7798 28.5506 46.1804 28.5506 45.4404V34.6269C28.5506 33.8899 29.15 33.2874 29.887 33.2874H45.4436C46.1806 33.2874 46.78 33.8868 46.78 34.6269V45.4404Z"
                fill="#4C5B8C"
            />
            <path
                d="M37.6638 37.8716C36.988 37.8716 36.4375 38.422 36.4375 39.0979C36.4375 39.5505 36.6883 39.9419 37.0522 40.156V41.7034C37.0522 42.0397 37.3274 42.315 37.6638 42.315C38.0002 42.315 38.2754 42.0397 38.2754 41.7034V40.156C38.6424 39.9419 38.8901 39.5505 38.8901 39.0979C38.8901 38.422 38.3396 37.8716 37.6638 37.8716Z"
                fill="#4C5B8C"
            />
            <Stars />
            <path
                d="M16.7278 46.7768H0.611621C0.275229 46.7768 0 47.052 0 47.3884C0 47.7248 0.275229 48 0.611621 48H16.7278C17.0642 48 17.3394 47.7248 17.3394 47.3884C17.3394 47.052 17.0642 46.7768 16.7278 46.7768Z"
                fill="#4C5B8C"
            />
            <path
                d="M0.611621 42.0917H11.0642C11.4006 42.0917 11.6758 41.8165 11.6758 41.4801C11.6758 41.1437 11.4006 40.8685 11.0642 40.8685H0.611621C0.275229 40.8685 0 41.1437 0 41.4801C0 41.8165 0.275229 42.0917 0.611621 42.0917Z"
                fill="#4C5B8C"
            />
            <path
                d="M0.611621 36.1835H5.40367C5.74006 36.1835 6.01529 35.9082 6.01529 35.5719C6.01529 35.2355 5.74006 34.9602 5.40367 34.9602H0.611621C0.275229 34.9602 0 35.2355 0 35.5719C0 35.9082 0.275229 36.1835 0.611621 36.1835Z"
                fill="#4C5B8C"
            />
            <path
                d="M43.2049 0H4.79816C2.15291 0 0 2.15291 0 4.79816V18.9939C0 21.6361 2.15291 23.789 4.79816 23.789H28.0581C28.3945 23.789 28.6697 23.5138 28.6697 23.1774C28.6697 22.841 28.3945 22.5657 28.0581 22.5657H4.79816C2.82875 22.5657 1.22324 20.9633 1.22324 18.9908V4.79816C1.22324 2.82875 2.82569 1.22324 4.79816 1.22324H43.2049C45.1743 1.22324 46.7798 2.82569 46.7798 4.79816V18.9939C46.7798 19.8226 46.5046 20.6024 45.9817 21.2446C45.7676 21.5076 45.8073 21.893 46.0703 22.104C46.3333 22.318 46.7187 22.2783 46.9297 22.0153C47.6208 21.1621 48.0031 20.0887 48.0031 18.9939V4.79816C48.0031 2.15291 45.8502 0 43.2049 0V0Z"
                fill="#4C5B8C"
            />
            <path
                d="M18.5191 14.0924C17.9515 13.8665 16.1877 13.1731 14.6373 12.5654C15.3439 11.8703 15.7844 10.8996 15.7844 9.82452C15.7844 7.71196 14.09 6 11.9992 6C9.90829 6 8.21392 7.71196 8.21392 9.82452C8.21392 10.8996 8.65445 11.8703 9.36101 12.5654C7.81065 13.1731 6.04681 13.8665 5.47919 14.0924C4.39648 14.5221 4 14.9039 4 16.0321V22H20V16.0321C20 14.9039 19.6035 14.5239 18.5208 14.0924H18.5191Z"
                fill="#A3D3FE"
            />
            <path
                d="M20.2712 14.2698C19.7478 14.0622 18.2314 13.4656 16.7789 12.8952C17.3558 12.1679 17.7029 11.2469 17.7029 10.2456C17.7029 7.90478 15.8169 6 13.4991 6C11.1813 6 9.29534 7.90478 9.29534 10.2456C9.29534 11.2469 9.64076 12.1696 10.2193 12.8952C8.76684 13.4656 7.25043 14.0622 6.72712 14.2698C5.58204 14.725 5 15.2047 5 16.5705V22.6511C5 22.843 5.15544 23 5.34542 23H21.6546C21.8446 23 22 22.843 22 22.6511V16.5705C22 15.2064 21.418 14.725 20.2729 14.2698H20.2712ZM13.4991 6.69772C15.437 6.69772 17.0121 8.28853 17.0121 10.2456C17.0121 12.2027 15.437 13.7936 13.4991 13.7936C11.5613 13.7936 9.98618 12.2027 9.98618 10.2456C9.98618 8.28853 11.5613 6.69772 13.4991 6.69772ZM21.3092 22.3023H17.9275V18.7701C17.9275 18.5782 17.772 18.4212 17.582 18.4212C17.3921 18.4212 17.2366 18.5782 17.2366 18.7701V22.3023H9.76511V18.7701C9.76511 18.5782 9.60967 18.4212 9.41969 18.4212C9.22971 18.4212 9.07427 18.5782 9.07427 18.7701V22.3023H5.69257V16.5705C5.69257 15.6443 5.93437 15.3355 6.98273 14.9204C7.53713 14.7006 9.21071 14.043 10.7392 13.443C11.4784 14.0953 12.4456 14.493 13.5026 14.493C14.5596 14.493 15.5268 14.0953 16.266 13.443C17.7945 14.043 19.468 14.7006 20.0225 14.9204C21.0708 15.3373 21.3126 15.646 21.3126 16.5705V22.3023H21.3092Z"
                fill="#4C5B8C"
            />
        </g>
        <defs>
            <clipPath id="clip0_68_6648">
                <rect width="48" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default FraudIcon;
