import React from "react";
import "../CustomFields/CustomFields.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import { ListText } from "../Reporting/Reporting";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import cfMockup from "../CustomFields/assets/cf-mockup.png";
import Icon1 from "./assets/icon-1";
import IconCheck from "./assets/icon-check";

export default function CustomFields() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-left">
                <Grid
                    item
                    className="cf-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={6}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 1, lg: 2 } }}
                            >
                                <FadeInSection className="fade-in-from-right">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Custom Fields
                                        </strong>{" "}
                                        - Custom Fields let you customize the
                                        product by tracking details that are
                                        specific to your business’s needs.
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Custom Fields</strong> -
                    Custom Fields let you customize the product by tracking
                    details that are specific to your business’s needs.
                </BannerTitle>
            </FadeInSection>

            {/* CONTENT */}
            <Grid
                container
                direction={{ md: "row" }}
                alignItems={{ md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item lg={6}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: {
                                    xs: 2,
                                    md: 0,
                                    fontSize: "1.25rem !important"
                                }
                            }}
                        >
                            Custom fields can be viewed in reports and on
                            transaction receipts making it easier to reconcile
                            transactions. These can be added to the areas of the
                            product that mean the most to you including Virtual
                            Terminal, Customers, Invoices, Shopping Cart, and
                            Simple Pay.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>

                <Grid item xs={21} lg={6}>
                    <FadeInSection className="fade-in-from-right">
                        <img
                            src={cfMockup}
                            className="cf-mockup"
                            alt="Custom Fields Mockup"
                        ></img>
                    </FadeInSection>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <FadeInSection className="fade-in-from-bottom">
                    <Grid item md={12} sx={{ mt: 5 }}>
                        <Typography
                            variant="h2"
                            fontWeight={450}
                            className="products-title"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            Custom Fields
                        </Typography>
                    </Grid>
                </FadeInSection>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 } }}
            >
                <List>
                    <FadeInSection className="fade-in-from-left"></FadeInSection>
                    <FadeInSection className="fade-in-from-left">
                        <ListItem>
                            <ListItemIcon>
                                <Icon1 className="listIcon" />
                            </ListItemIcon>
                            <ListText>
                                <Typography variant="h2" className="list-title">
                                    There are five types of Custom Fields:
                                </Typography>
                            </ListText>
                        </ListItem>
                    </FadeInSection>
                    <Grid container direction="row">
                        <Grid item md={1}></Grid>
                        <Grid item md={11} sx={{ pl: { xs: 3, md: 0 } }}>
                            <FadeInSection className="fade-in-from-left">
                                <ListItem>
                                    <ListItemIcon>
                                        <IconCheck className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        Text – a text field that is customizable
                                        to be an open, alpha, numeric, or
                                        alphanumeric
                                    </ListText>
                                </ListItem>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <ListItem>
                                    <ListItemIcon>
                                        <IconCheck className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        Radio – choose only one option from a
                                        set of mutually exclusive options
                                    </ListText>
                                </ListItem>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <ListItem>
                                    <ListItemIcon>
                                        <IconCheck className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        Checkbox – choose one or more option(s)
                                        from a set of 10 or less options
                                    </ListText>
                                </ListItem>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <ListItem>
                                    <ListItemIcon>
                                        <IconCheck className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        Select – choose only one option from a
                                        list of more than 10 options
                                    </ListText>
                                </ListItem>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <ListItem>
                                    <ListItemIcon>
                                        <IconCheck className="listIcon" />
                                    </ListItemIcon>
                                    <ListText>
                                        Multiselect – choose more than one
                                        option from a list of more than 10
                                        options
                                    </ListText>
                                </ListItem>
                            </FadeInSection>
                        </Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
