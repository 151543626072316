import React from "react";
import "../Reporting/Reporting.scss";
import styled from "@emotion/styled";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import {
    ProductsPageTitle,
    BannerTitle
} from "../VirtualTerminal/VirtualTerminal";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import reportMockup from "../Reporting/assets/report-mockup.png";
import reportUI from "../Reporting/assets/report-ui.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";

export const ListText = styled(Typography)`
    font-size: 25px !important;
    @media (max-width: 800px) {
        font-size: 18px !important;
    }
`;

export default function CustomerVault() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER */}
            <FadeInSection className="fade-in-from-right">
                <Grid
                    item
                    className="report-banner"
                    sx={{ display: { xs: "none", md: "block" }, mb: 10 }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 8 } }}
                            >
                                <FadeInSection className="fade-in-from-left">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Reporting
                                        </strong>{" "}
                                        - view transactions and settlements in
                                        one place.
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Reporting</strong> - view
                    transactions and settlements in one place.
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid container>
                <Grid
                    container
                    direction="row"
                    justifyContent={{ xs: "center", md: "flex-start" }}
                    alignItems={{ xs: "center", md: "flex-start" }}
                >
                    <Grid item xs={1} lg={1}></Grid>
                    <Grid item xs={10} md={5}>
                        <FadeInSection className="fade-in-from-right">
                            <ProductsPageTitle
                                paragraph="true"
                                className="products-title"
                                sx={{
                                    p: 2,
                                    fontSize: {
                                        xs: "15px !important",
                                        md: "22px !important"
                                    }
                                }}
                            >
                                Simplify payment reconciliation by customizing
                                transaction reports and viewing the information
                                that matters.
                            </ProductsPageTitle>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <FadeInSection className="fade-in-from-left">
                            <img
                                src={reportMockup}
                                className="rp-mockup"
                                alt="Custom Reporting Mockup"
                            />
                        </FadeInSection>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 5 }}
                >
                    <Grid item xs={12} md={1}></Grid>
                    <Grid
                        item
                        xs={10}
                        md={5}
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        <FadeInSection className="fade-in-from-left">
                            <ProductsPageTitle
                                paragraph="true"
                                fontWeight={400}
                                sx={{
                                    p: 2,
                                    fontSize: {
                                        xs: "15px !important",
                                        md: "22px !important"
                                    }
                                }}
                            >
                                Increase reporting efficiency by completely
                                customizing the information that displays in the
                                report.
                            </ProductsPageTitle>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={10} md={6} lg={5}>
                        <FadeInSection className="fade-in-from-left">
                            <FadeInSection className="fade-in-from-right">
                                <img
                                    src={reportUI}
                                    className="product-ui"
                                    alt="Example of Custom Reporting user interface"
                                />
                            </FadeInSection>
                        </FadeInSection>
                    </Grid>
                    <Grid
                        item
                        md={5}
                        sx={{ display: { xs: "none", md: "block" } }}
                    >
                        <FadeInSection className="fade-in-from-left">
                            <ProductsPageTitle
                                paragraph="true"
                                fontWeight={400}
                                sx={{
                                    p: 2,
                                    fontSize: {
                                        xs: "15px !important",
                                        md: "22px !important"
                                    }
                                }}
                            >
                                Increase reporting efficiency by completely
                                customizing the information that displays in the
                                report.
                            </ProductsPageTitle>
                        </FadeInSection>
                    </Grid>
                    <Grid item md={2.5}></Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={1.5}></Grid>
                <Grid item md={10} sx={{ mt: 5 }}>
                    <FadeInSection className="fade-in-from-bottom">
                        <Typography
                            variant="h2"
                            fontWeight={450}
                            className="products-title"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            Custom Reporting
                        </Typography>
                    </FadeInSection>
                </Grid>
            </Grid>
            {/* ICON LIST */}
            <Grid
                container
                justifyContent={{ xs: "flex-start", lg: "center" }}
                sx={{ mb: { xs: 2, lg: 10 } }}
            >
                <List>
                    <FadeInSection className="fade-in-from-left">
                        <ListItem>
                            <ListItemIcon>
                                <Icon1 className="listIcon" />
                            </ListItemIcon>
                            <ListText>
                                Easily know what transactions will be funded for
                                by viewing settlement reports
                            </ListText>
                        </ListItem>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-left">
                        <ListItem>
                            <ListItemIcon>
                                <Icon2 className="listIcon" />
                            </ListItemIcon>
                            <ListText>
                                Customizable reporting reduces clutter by only
                                displaying the information that is important
                            </ListText>
                        </ListItem>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-left">
                        <ListItem>
                            <ListItemIcon>
                                <Icon3 className="listIcon" />
                            </ListItemIcon>
                            <ListText>
                                Quickly locate transactions by utilizing the
                                built in search filters
                            </ListText>
                        </ListItem>
                    </FadeInSection>
                    <FadeInSection className="fade-in-from-left">
                        <ListItem>
                            <ListItemIcon>
                                <Icon4 className="listIcon" />
                            </ListItemIcon>
                            <ListText>
                                Saving frequently used search filters makes the
                                next search as simple as pressing a button
                            </ListText>
                        </ListItem>
                    </FadeInSection>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
