import * as React from "react";

function Tab() {
    return (
        <path
            d="m29 44.361 1.917-1.214c1.917-1.214 2.777-1.595 2.777-4.05V17.261c0-2.455-.86-2.833-2.777-4.05L29 12v32.361Z"
            fill="#A3D3FE"
            stroke="#4C5B8C"
            strokeMiterlimit={10}
            className="iconPeek"
        />
    );
}

const InvoicingIcon = (props) => (
    <svg
        width={45}
        height={35}
        viewBox="0 0 50 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Tab />
        <g clipPath="url(#a)">
            <path d="M29.5 43.441V12.93L28 12l-.13 32.362" fill="#A3D3FE" />
        </g>
        <rect width={35} height={48} rx={5} fill="#fff" />
        <path
            d="M28.927 12.0178C29.6834 10.207 28.8286 8.12589 27.0178 7.36952C25.207 6.61316 23.1259 7.46795 22.3695 9.27876C21.6132 11.0896 22.468 13.1707 24.2788 13.927C26.0896 14.6834 28.1707 13.8286 28.927 12.0178Z"
            fill="#0288D1"
        />
        <path
            d="M28.927 12.0178C29.6834 10.207 28.8286 8.12589 27.0178 7.36952C25.207 6.61316 23.1259 7.46795 22.3695 9.27876C21.6132 11.0896 22.468 13.1707 24.2788 13.927C26.0896 14.6834 28.1707 13.8286 28.927 12.0178Z"
            fill="white"
            fillOpacity="0.6"
        />
        <path
            d="M28.927 23.0178C29.6834 21.207 28.8286 19.1259 27.0178 18.3695C25.207 17.6132 23.1259 18.468 22.3695 20.2788C21.6132 22.0896 22.468 24.1707 24.2788 24.927C26.0896 25.6834 28.1707 24.8286 28.927 23.0178Z"
            fill="#0288D1"
        />
        <path
            d="M28.927 23.0178C29.6834 21.207 28.8286 19.1259 27.0178 18.3695C25.207 17.6132 23.1259 18.468 22.3695 20.2788C21.6132 22.0896 22.468 24.1707 24.2788 24.927C26.0896 25.6834 28.1707 24.8286 28.927 23.0178Z"
            fill="white"
            fillOpacity="0.6"
        />
        <path
            d="M8.47793 26.8849H5.08032C4.76034 26.8849 4.49854 27.1467 4.49854 27.4666C4.49854 27.7866 4.76034 28.0484 5.08032 28.0484H8.47793C8.79791 28.0484 9.05971 27.7866 9.05971 27.4666C9.05971 27.1467 8.79791 26.8849 8.47793 26.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M8.47792 32.5049H5.0803C4.76032 32.5049 4.49852 32.7667 4.49852 33.0867C4.49852 33.4066 4.76032 33.6684 5.0803 33.6684H8.47792C8.7979 33.6684 9.0597 33.4066 9.0597 33.0867C9.0597 32.7667 8.7979 32.5049 8.47792 32.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M8.47793 29.5049H5.08032C4.76034 29.5049 4.49854 29.7667 4.49854 30.0867C4.49854 30.4066 4.76034 30.6684 5.08032 30.6684H8.47793C8.79791 30.6684 9.05971 30.4066 9.05971 30.0867C9.05971 29.7667 8.79791 29.5049 8.47793 29.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M8.47793 35.5049H5.08032C4.76034 35.5049 4.49854 35.7667 4.49854 36.0867C4.49854 36.4066 4.76034 36.6684 5.08032 36.6684H8.47793C8.79791 36.6684 9.05971 36.4066 9.05971 36.0867C9.05971 35.7667 8.79791 35.5049 8.47793 35.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M8.47792 38.1278H5.0803C4.76032 38.1278 4.49852 38.3896 4.49852 38.7096C4.49852 39.0296 4.76032 39.2914 5.0803 39.2914H8.47792C8.7979 39.2914 9.0597 39.0296 9.0597 38.7096C9.0597 38.3896 8.7979 38.1278 8.47792 38.1278Z"
            fill="#4C5B8C"
        />
        <path
            d="M13.2279 26.8849H9.83032C9.51034 26.8849 9.24854 27.1467 9.24854 27.4666C9.24854 27.7866 9.51034 28.0484 9.83032 28.0484H13.2279C13.5479 28.0484 13.8097 27.7866 13.8097 27.4666C13.8097 27.1467 13.5479 26.8849 13.2279 26.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.2279 26.8849H14.8303C14.5103 26.8849 14.2485 27.1467 14.2485 27.4666C14.2485 27.7866 14.5103 28.0484 14.8303 28.0484H18.2279C18.5479 28.0484 18.8097 27.7866 18.8097 27.4666C18.8097 27.1467 18.5479 26.8849 18.2279 26.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 26.8849H19.8303C19.5103 26.8849 19.2485 27.1467 19.2485 27.4666C19.2485 27.7866 19.5103 28.0484 19.8303 28.0484H23.2279C23.5479 28.0484 23.8097 27.7866 23.8097 27.4666C23.8097 27.1467 23.5479 26.8849 23.2279 26.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 40.8849H19.8303C19.5103 40.8849 19.2485 41.1467 19.2485 41.4667C19.2485 41.7867 19.5103 42.0485 19.8303 42.0485H23.2279C23.5479 42.0485 23.8097 41.7867 23.8097 41.4667C23.8097 41.1467 23.5479 40.8849 23.2279 40.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M13.2279 32.5049H9.83032C9.51034 32.5049 9.24854 32.7667 9.24854 33.0867C9.24854 33.4066 9.51034 33.6684 9.83032 33.6684H13.2279C13.5479 33.6684 13.8097 33.4066 13.8097 33.0867C13.8097 32.7667 13.5479 32.5049 13.2279 32.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M13.2279 29.5049H9.83032C9.51034 29.5049 9.24854 29.7667 9.24854 30.0867C9.24854 30.4066 9.51034 30.6684 9.83032 30.6684H13.2279C13.5479 30.6684 13.8097 30.4066 13.8097 30.0867C13.8097 29.7667 13.5479 29.5049 13.2279 29.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M13.2279 35.5049H9.83032C9.51034 35.5049 9.24854 35.7667 9.24854 36.0867C9.24854 36.4066 9.51034 36.6684 9.83032 36.6684H13.2279C13.5479 36.6684 13.8097 36.4066 13.8097 36.0867C13.8097 35.7667 13.5479 35.5049 13.2279 35.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.2279 32.5049H14.8303C14.5103 32.5049 14.2485 32.7667 14.2485 33.0867C14.2485 33.4066 14.5103 33.6684 14.8303 33.6684H18.2279C18.5479 33.6684 18.8097 33.4066 18.8097 33.0867C18.8097 32.7667 18.5479 32.5049 18.2279 32.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.2279 29.5049H14.8303C14.5103 29.5049 14.2485 29.7667 14.2485 30.0867C14.2485 30.4066 14.5103 30.6684 14.8303 30.6684H18.2279C18.5479 30.6684 18.8097 30.4066 18.8097 30.0867C18.8097 29.7667 18.5479 29.5049 18.2279 29.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.2279 35.5049H14.8303C14.5103 35.5049 14.2485 35.7667 14.2485 36.0867C14.2485 36.4066 14.5103 36.6684 14.8303 36.6684H18.2279C18.5479 36.6684 18.8097 36.4066 18.8097 36.0867C18.8097 35.7667 18.5479 35.5049 18.2279 35.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 32.5049H19.8303C19.5103 32.5049 19.2485 32.7667 19.2485 33.0867C19.2485 33.4066 19.5103 33.6684 19.8303 33.6684H23.2279C23.5479 33.6684 23.8097 33.4066 23.8097 33.0867C23.8097 32.7667 23.5479 32.5049 23.2279 32.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 29.5049H19.8303C19.5103 29.5049 19.2485 29.7667 19.2485 30.0867C19.2485 30.4066 19.5103 30.6684 19.8303 30.6684H23.2279C23.5479 30.6684 23.8097 30.4066 23.8097 30.0867C23.8097 29.7667 23.5479 29.5049 23.2279 29.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 35.5049H19.8303C19.5103 35.5049 19.2485 35.7667 19.2485 36.0867C19.2485 36.4066 19.5103 36.6684 19.8303 36.6684H23.2279C23.5479 36.6684 23.8097 36.4066 23.8097 36.0867C23.8097 35.7667 23.5479 35.5049 23.2279 35.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M13.2279 38.1278H9.83032C9.51034 38.1278 9.24854 38.3896 9.24854 38.7096C9.24854 39.0296 9.51034 39.2914 9.83032 39.2914H13.2279C13.5479 39.2914 13.8097 39.0296 13.8097 38.7096C13.8097 38.3896 13.5479 38.1278 13.2279 38.1278Z"
            fill="#4C5B8C"
        />
        <path
            d="M18.2279 38.1278H14.8303C14.5103 38.1278 14.2485 38.3896 14.2485 38.7096C14.2485 39.0296 14.5103 39.2914 14.8303 39.2914H18.2279C18.5479 39.2914 18.8097 39.0296 18.8097 38.7096C18.8097 38.3896 18.5479 38.1278 18.2279 38.1278Z"
            fill="#4C5B8C"
        />
        <path
            d="M23.2279 38.1278H19.8303C19.5103 38.1278 19.2485 38.3896 19.2485 38.7096C19.2485 39.0296 19.5103 39.2914 19.8303 39.2914H23.2279C23.5479 39.2914 23.8097 39.0296 23.8097 38.7096C23.8097 38.3896 23.5479 38.1278 23.2279 38.1278Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 26.8849H24.5803C24.2603 26.8849 23.9985 27.1467 23.9985 27.4666C23.9985 27.7866 24.2603 28.0484 24.5803 28.0484H27.9779C28.2979 28.0484 28.5597 27.7866 28.5597 27.4666C28.5597 27.1467 28.2979 26.8849 27.9779 26.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 40.8849H24.5803C24.2603 40.8849 23.9985 41.1467 23.9985 41.4667C23.9985 41.7867 24.2603 42.0485 24.5803 42.0485H27.9779C28.2979 42.0485 28.5597 41.7867 28.5597 41.4667C28.5597 41.1467 28.2979 40.8849 27.9779 40.8849Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 32.5049H24.5803C24.2603 32.5049 23.9985 32.7667 23.9985 33.0867C23.9985 33.4066 24.2603 33.6684 24.5803 33.6684H27.9779C28.2979 33.6684 28.5597 33.4066 28.5597 33.0867C28.5597 32.7667 28.2979 32.5049 27.9779 32.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 29.5049H24.5803C24.2603 29.5049 23.9985 29.7667 23.9985 30.0867C23.9985 30.4066 24.2603 30.6684 24.5803 30.6684H27.9779C28.2979 30.6684 28.5597 30.4066 28.5597 30.0867C28.5597 29.7667 28.2979 29.5049 27.9779 29.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 35.5049H24.5803C24.2603 35.5049 23.9985 35.7667 23.9985 36.0867C23.9985 36.4066 24.2603 36.6684 24.5803 36.6684H27.9779C28.2979 36.6684 28.5597 36.4066 28.5597 36.0867C28.5597 35.7667 28.2979 35.5049 27.9779 35.5049Z"
            fill="#4C5B8C"
        />
        <path
            d="M27.9779 38.1278H24.5803C24.2603 38.1278 23.9985 38.3896 23.9985 38.7096C23.9985 39.0296 24.2603 39.2914 24.5803 39.2914H27.9779C28.2979 39.2914 28.5597 39.0296 28.5597 38.7096C28.5597 38.3896 28.2979 38.1278 27.9779 38.1278Z"
            fill="#4C5B8C"
        />
        <g clipPath="url(#clip1_28_4540)">
            <path
                d="M4.6164 24.2328H21.2036C21.5426 24.2328 21.82 23.9554 21.82 23.6164C21.82 23.2774 21.5426 23 21.2036 23H4.6164C4.27738 23 4 23.2774 4 23.6164C4 23.9554 4.27738 24.2328 4.6164 24.2328Z"
                fill="#4C5B8C"
            />
        </g>
        <g clipPath="url(#clip2_28_4540)">
            <path
                d="M4.6164 21.2328H21.2036C21.5426 21.2328 21.82 20.9554 21.82 20.6164C21.82 20.2774 21.5426 20 21.2036 20H4.6164C4.27738 20 4 20.2774 4 20.6164C4 20.9554 4.27738 21.2328 4.6164 21.2328Z"
                fill="#4C5B8C"
            />
        </g>
        <g clipPath="url(#clip3_28_4540)">
            <path
                d="M4.6164 15.2328H21.2036C21.5426 15.2328 21.82 14.9554 21.82 14.6164C21.82 14.2774 21.5426 14 21.2036 14H4.6164C4.27738 14 4 14.2774 4 14.6164C4 14.9554 4.27738 15.2328 4.6164 15.2328Z"
                fill="#4C5B8C"
            />
        </g>
        <g clipPath="url(#clip4_28_4540)">
            <path
                d="M4.6164 12.2328H21.2036C21.5426 12.2328 21.82 11.9554 21.82 11.6164C21.82 11.2774 21.5426 11 21.2036 11H4.6164C4.27738 11 4 11.2774 4 11.6164C4 11.9554 4.27738 12.2328 4.6164 12.2328Z"
                fill="#4C5B8C"
            />
        </g>
        <g clipPath="url(#clip5_28_4540)">
            <path
                d="M4.6164 9.23279H21.2036C21.5426 9.23279 21.82 8.95541 21.82 8.6164C21.82 8.27738 21.5426 8 21.2036 8H4.6164C4.27738 8 4 8.27738 4 8.6164C4 8.95541 4.27738 9.23279 4.6164 9.23279Z"
                fill="#4C5B8C"
            />
        </g>
        <circle cx="27.5" cy="10.5" r="3" stroke="#4C5B8C" />
        <circle cx="27.5" cy="21.5" r="3" stroke="#4C5B8C" />
        <g clipPath="url(#clip6_28_4540)">
            <path
                d="M30.4439 0H4.55613C2.0443 0 0 2.04788 0 4.56409V43.4388C0 45.955 2.0443 48.0029 4.55613 48.0029H30.4439C32.9557 48.0029 35 45.955 35 43.4388V4.56409C35 2.04788 32.9557 0 30.4439 0ZM33.8385 43.4388C33.8385 45.3122 32.3169 46.8394 30.4439 46.8394H4.55613C2.68605 46.8394 1.16154 45.3151 1.16154 43.4388V4.56409C1.16154 2.69075 2.68315 1.16357 4.55613 1.16357H30.4439C32.3139 1.16357 33.8385 2.68784 33.8385 4.56409V43.4388Z"
                fill="#4C5B8C"
            />
        </g>

        <g clipPath="url(#b)">
            <path
                d="M30.444 0H4.556A4.566 4.566 0 0 0 0 4.564V43.44a4.566 4.566 0 0 0 4.556 4.564h25.888A4.566 4.566 0 0 0 35 43.439V4.564A4.566 4.566 0 0 0 30.444 0Zm3.395 43.439c0 1.873-1.522 3.4-3.395 3.4H4.556a3.402 3.402 0 0 1-3.394-3.4V4.564c0-1.873 1.521-3.4 3.394-3.4h25.888a3.402 3.402 0 0 1 3.395 3.4V43.44Z"
                fill="#4C5B8C"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(28 11)"
                    d="M0 0h5.636v33.54H0z"
                />
            </clipPath>
            <clipPath id="b">
                <path fill="#fff" d="M0 0h35v48H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default InvoicingIcon;
