import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Navbar from "../NavMenu/Navbar";
import Footer from "../Components/Footer/Footer";

// table stuff
function createData(outages, credit) {
    return { outages, credit };
}

const rows = [
    createData(
        "10 minutes through 60 minutes",
        "1/30th of MRFC for Affected Service"
    ),
    createData(
        "Each full hour in excess of 60 minutes",
        "1/30th of MRFC for Affected Service"
    )
];

export const TOS = () => {
    return (
        <div>
            <Navbar />
            <Grid container sx={{ px: 5 }}>
                <Typography variant="h5">
                    PAYMENT GATEWAY MERCHANT SERVICES AGREEMENT
                </Typography>
                <Typography>
                    BASYS Processing, Inc., referred to herein as “Payment
                    Gateway”, offers various payment processing and other
                    value-added products and services directly, and through
                    Third Party Service Providers. In order for you, on behalf
                    of your company (hereinafter, “MERCHANT”) to obtain or
                    continue using these products or services, which services
                    are described at https://sandbox.basysiqpro.com (which
                    descriptions may be changed from time to time)(the “Payment
                    Gateway Services”), you must agree to and accept the terms
                    and conditions of this Payment Gateway Merchant Services
                    Agreement (the &quot;Agreement&quot;). By accepting the
                    terms of this Agreement, either by continuing to use any of
                    the Payment Gateway Services and/or by clicking on the “I
                    AGREE” button at the end of this Agreement (if in electronic
                    format), MERCHANT agrees to be bound by the terms and
                    conditions of the same. MERCHANT further agrees that
                    MERCHANT has had the time to thoroughly read and understand
                    this Agreement, has had the opportunity to have the same
                    reviewed by counsel, and has had the opportunity to ask
                    questions regarding the same. NOW THEREFORE, MERCHANT agrees
                    as follows:
                </Typography>
                {/* 1 */}
                <Typography variant="h5">1. DEFINITIONS:</Typography>
                <Typography>
                    <b>
                        “Acquirer” or “Acquiring Bank” or “Acquiring
                        Institution”
                    </b>{" "}
                    means a financial institution that supplies MERCHANT with
                    the ability to accept credit cards for payments, for which
                    the financial institution charges fees.
                    <br />
                    <b>“ACH” or “Automated Clearing House”</b> means an
                    electronic network that allows the exchange and settlement
                    of electronic payments between financial institutions.
                    <br />
                    <b>“PARTNER”</b> means any third party through whom Payment
                    Gateway may offer the Payment Gateway Services to MERCHANT,
                    including but not limited to an “ISO” or “Independent Sales
                    Organization”, Merchant Service Provider (“MSP”), Value
                    Added Reseller (“VAR”), Application Service Provider
                    (“ASP”), Acquiring Bank or Financial Institution.
                    <br />
                    <b>“API” or “Application Programming Interface”</b> means a
                    computer interface allowing integration with a programmable
                    software package or platform.
                    <br />
                    <b>“AVS” or “Address Verification Service”</b> for the
                    purposes of this agreement shall mean the response generated
                    by the Issuing Bank, which response provides the level of
                    accuracy of a given address based on the billing address of
                    the customers credit card.
                    <br />
                    <b>“Batch”</b> means any bulk processing of Transactions, or
                    a bulk settlement submitted to a Processor by Payment
                    Gateway including credit card, ACH, and other related
                    transactions.
                    <br />
                    <b>“Card Association”</b> for the purposes of this Agreement
                    means a network of Issuing Banks and Acquiring Banks that
                    process payment cards of a specific brand. The Card
                    Association includes American Express, Discover Financial
                    Services, JCB International, MasterCard International Inc.,
                    Visa Inc., and Visa International Inc., among others.
                    <br />
                    <b>“Close Batch”</b> means the process of sending a batch of
                    transactions for settlement.
                    <br />
                    <b>“Confidential Information”</b> means any information,
                    whether oral or in writing, whether tangible or electronic,
                    that includes but shall not be limited to data, trade
                    secrets, know-how derived through the inspection of samples,
                    equipment or facilities, and includes past, present and
                    future research, products, product plans, services, services
                    documentation (in whatever form provided) customers,
                    customer lists, contracts or other legal documents,
                    financial or other business information, formulas,
                    inventions, revenue, markets, software code and/or hardware
                    configuration, marketing, marketing materials, user data, or
                    the parties to or financial terms of this Agreement.
                    <br />
                    <b>“Discount Rate”</b> means the fee charged by MERCHANT
                    financial institution for services associated with
                    processing card transactions, or fees charged by Payment
                    Gateway for ACH or other payment methods.
                    <br />
                    <b>“Effective Date”</b> means the earlier of the date
                    MERCHANT agrees to the terms and conditions of the Agreement
                    by (i) clicking the “I AGREE” button associated with the
                    Agreement (if in electronic format); (ii) by using Payment
                    Gateway Services; or (iii) by acknowledging MERCHANT
                    acceptance of the Agreement by any other method, including
                    without limitation execution of a Merchant Account
                    Application that incorporates this Agreement by reference.
                    <br />
                    <b>“Fee Schedule”</b> means a list of fees and charges that
                    is accepted by MERCHANT and paid by MERCHANT to Payment
                    Gateway.
                    <br />
                    <b>“Interchange Fee”</b> means the fee set by and collected
                    by the Card Association from MERCHANT financial institution
                    (Acquiring Bank) and paid to the issuing financial
                    institution (Issuing Bank) to cover expenses incurred in
                    billing the cardholder.
                    <br />
                    <b>“IP Address”</b> means an internet address represented in
                    series of four numbers separated by commas (e.g. 127.1.1.0).
                    <br />
                    <b>“Issuing Bank”</b> means the financial institution that
                    issues Card Association branded Payment Cards directly to
                    consumers.
                    <br />
                    <b>“MERCHANT’s Marks”</b> means all common law and/or
                    registered trademark(s), service mark(s), trade name(s),
                    trade dress rights, moral rights, rights of publicity and/or
                    similar or related rights under any laws of any country or
                    jurisdiction, including but not limited to the United States
                    of America, whether existing now or hereafter adopted,
                    belonging to MERCHANT, and includes such other trademarks as
                    MERCHANT may from time to time designate as “MERCHANT’s
                    Marks”.
                    <br />
                    <b>“PCI DSS”</b> means Payment Card Industry Data Security
                    Standard.
                    <br />
                    <b>“Payment Gateway Marks”</b> means all common law and/or
                    registered trademark(s), service mark(s), trade name(s),
                    trade dress rights, moral rights, rights of publicity and/or
                    similar or related rights under any laws of any country or
                    jurisdiction, including but not limited to the United States
                    of America, whether existing now or hereafter adopted,
                    belonging to Payment Gateway, and includes trademarks that
                    Payment Gateway may from time to time as being “Payment
                    Gateway Marks”.
                    <br />
                    <b>“Privacy Policy”</b> means the then current Payment
                    Gateway Privacy Policy which can be found on the internet at
                    https://sandbox.basysiqpro.com
                    <br />
                    <b>“Return Payment Fee”</b> is a fee charged by Payment
                    Gateway on each occurrence when Payment Gateway is unable to
                    collect fees on MERCHANT Account for any reason, including
                    but not limited to insufficient funds, a closed account, or
                    other negative response.
                    <br />
                    <b>“Third Party Service Provider”</b> for purposes of this
                    Agreement means an entity that provides bank and/or merchant
                    payment services including but not limited to billing,
                    reporting, customer service, authorization, and/or
                    settlement services.
                    <br />
                    <b>“Trademark(s)”</b> means all common law and/or registered
                    trademark(s), service mark(s), trade name(s), trade dress
                    rights, moral rights, rights of publicity and/or similar or
                    related rights under any laws of any country or
                    jurisdiction, including but not limited to the United States
                    of America, whether existing now or hereafter adopted.
                    <br />
                    <b>“Transaction(s)”</b> means any billable occurrence
                    completed or submitted under MERCHANT’s Account including
                    but not limited to capture, authorization, validation,
                    updating, or a sale, void, refund, credit, offline force, or
                    settlement regardless of whether approved or declined.
                    <br />
                    <b>“VAR” or “Value Added Reseller”</b> means any third-party
                    vendor that enhances, modifies or existing hardware or
                    software, thereby adding value to the services provided by
                    the gateway processor or acquirer.
                </Typography>
                {/* 2 */}
                <Typography variant="h5">2. AUTHORITY:</Typography>
                <Typography>
                    By accepting the terms and conditions of this Agreement,
                    MERCHANT represents and warrants that (a) the person
                    executing this Agreement on MERCHANT’s behalf is 18 years of
                    age or older, (b) all information MERCHANT has provided in
                    connection with this Agreement is true and correct in all
                    respects, and (c) MERCHANT will update any information that
                    becomes outdated by notifying Payment Gateway of any changes
                    to such information. MERCHANT further represents and
                    warrants that the undersigned individual has the legal
                    authority to accept the terms and conditions of this
                    Agreement and that such acceptance will be binding on
                    MERCHANT. Payment Gateway reserves its right, in its sole
                    discretion, to refuse to provide MERCHANT with any service
                    based if MERCHANT have supplied any information which is
                    misleading, untrue, inaccurate or incomplete.
                </Typography>
                {/* 3 */}
                <Typography variant="h5">
                    <b>3. TERM: </b> This Agreement shall commence on the
                    Effective Date and shall remain in full force and effect
                    until terminated pursuant to Section 10.
                </Typography>
                {/* 4 */}
                <Typography variant="h5">
                    4. PAYMENT GATEWAY SERVICES RIGHTS OF USE:
                </Typography>
                <Typography gutterBottom={true}>
                    <b>4.1 Payment Gateway Grant of Rights</b>
                    <br />
                    (a) MERCHANT is hereby granted a non-exclusive right to use
                    the Payment Gateway Services during the Term so long as
                    MERCHANT’s account is paid and current as to any of the fees
                    or charges referenced in Sections 8 & 9 hereof, which right
                    is subject to the restrictions contained in this Agreement.
                    <br />
                    (b) In connection with the exercise of MERCHANT’s rights and
                    obligations under this Agreement including, but not limited
                    to any related to individual privacy, MERCHANT will comply,
                    at MERCHANT’s expense, with all applicable laws,
                    regulations, rules, ordinances and orders of governmental
                    authorities having authority or jurisdiction over activities
                    described in this Agreement. MERCHANT shall not use the
                    Payment Gateway Services in any manner, or in furtherance of
                    any activity, the exposes Payment Gateway to liability or
                    potential liability for any legal or regulatory action. A
                    non-exclusive list of prohibited purposes for which MERCHANT
                    may not use Payment Gateway’s Services is attached hereto as
                    Exhibit “A”.
                    <br />
                    (c) MERCHANT’s use of the Payment Gateway Services shall be
                    restricted to MERCHANT. MERCHANT shall not process orders on
                    behalf of any other entity or individual. Any attempt by
                    MERCHANT to use the Payment Gateway Services on behalf of
                    another entity or individual may result in MERCHANT’s
                    obligation to pay Payment Gateway additional fees and
                    charges. Payment Gateway may further revoke MERCHANT’s right
                    to use the Payment Gateway Services and terminate this
                    Agreement.
                    <br />
                    (d) MERCHANT shall comply with Payment Gateway’s current
                    policies, procedures, and guidelines pertaining to the
                    Payment Gateway Services. Payment Gateway reserves the right
                    to amend, modify or change its rules, policies, procedures,
                    and guidelines at any time, in its sole discretion.
                </Typography>
                {/* 5 */}
                <Typography variant="h5">
                    5. DATA SECURITY, TRANSFER, COLLECTION, RETENTION &
                    DESTRUCTION:
                </Typography>
                <Typography gutterBottom={true}>
                    <b>5.1 MERCHANT’s Obligations</b>
                    <br />
                    (a) MERCHANT agrees that it will comply at all times with
                    all applicable and then-current legal obligations and
                    security measures, including without limitation those issued
                    by the United States Government, those required by federal,
                    state and/or municipal laws and ordinances, and those
                    required by the Card Association, the Federal Trade
                    Commission (“FTC”), PCI DSS or any other governing body of
                    competent jurisdiction. MERCHANT agrees that MERCHANT will
                    comply with all Payment Gateway security protocols, notices
                    and safeguards in effect during the term of this Agreement.
                    MERCHANT warrants that MERCHANT has taken such precautions
                    as are necessary to ensure that MERCHANT’s data and
                    MERCHANT’s customer data is protected and that MERCHANT’s
                    computer systems are secure from breach, intrusion or
                    compromise by unauthorized third parties. MERCHANT shall
                    have policies and procedures in place that limits employees’
                    use of and access to MERCHANT’s data or Transaction Data. In
                    the event that MERCHANT’s system is breached, and/or an
                    unauthorized third party has access to or has accessed Data
                    or Transaction data, MERCHANT shall immediately notify
                    Payment Gateway, and such other parties as are required to
                    receive notice under any applicable law or industry
                    guideline. In the event of a breach or compromise of
                    MERCHANT’s systems MERCHANT shall take immediate action to
                    prevent an additional or continuing breach of its systems or
                    loss of its data or Transaction data.
                    <br />
                    (b) MERCHANT is solely responsible for the security of data
                    residing on MERCHANT’s server(s), or those of Third Party
                    Service Providers, or any other third party designated by
                    MERCHANT (e.g., a web hosting company, processor, or other
                    service provider), including credit card numbers and any
                    other personal data. MERCHANT shall further comply with all
                    applicable laws and regulations concerning the collection,
                    retention and use by MERCHANT of credit card and other
                    financial information. MERCHANT agrees to have Privacy
                    Policies and Terms of Use documents on its website, in a
                    clear and conspicuous manner, that informs MERCHANT’s
                    customers how their data is used, including disclosures
                    concerning uses governed by this Agreement.
                    <br />
                    (c) MERCHANT agrees that MERCHANT is solely responsible for
                    verifying the accuracy and completeness of all Transactions
                    submitted to and processed by Payment Gateway associated
                    with MERCHANT’s account, and for verifying that all
                    corresponding funds are processed accurately. MERCHANT
                    acknowledges that the fees Payment Gateway earns by and
                    through transactions processed through MERCHANT’s account
                    are earned in full and non-refundable. MERCHANT acknowledges
                    that Payment Gateway shall not be liable for any improperly
                    processed or unauthorized Transactions, or for illegal or
                    fraudulent access to MERCHANT’s account, Data or Transaction
                    data. Payment Gateway’s liability for unauthorized
                    Transactions or improperly processed Transactions solely
                    attributable to the negligence of Payment Gateway is limited
                    pursuant to Section 13.
                    <br />
                    (d) MERCHANT agrees not to use, disclose, sell or
                    disseminate any card, cardholder or ACH information obtained
                    in connection with any Transaction except for the purposes
                    of completing or settling a Transaction, and/or for the
                    purpose of resolving chargebacks, retrievals or similar
                    issues involving a Transaction, unless required to do so by
                    a subpoena, warrant, request for production, or other order
                    of any governmental body of competent jurisdiction. In the
                    event that MERCHANT is served with any such subpoena,
                    warrant, request for production, or other order, whether
                    informally or from a court or governmental body of competent
                    jurisdiction, MERCHANT shall immediately notify Gateway
                    Provider that such information is being sought, such that
                    the Gateway Provider may seek appropriate injunctive relief,
                    quash such subpoena or warrant, or otherwise take legal
                    action to protect its interests, in Gateway Provider’s sole
                    discretion.
                </Typography>
                <Typography gutterBottom={true}>
                    <b>5.2 Payment Gateway’s Obligations</b>
                    <br />
                    (a) MERCHANT understands that Payment Gateway will collect,
                    retain, use and share information and data collected from
                    MERCHANT and MERCHANT’s customers in accordance with Payment
                    Gateway’s Privacy Policies. MERCHANT hereby consents, as a
                    condition of MERCHANT’s enrollment in and use of the Payment
                    Gateway Services, to the collection, use, processing, and
                    transfer of personal data as described in this Section 5 and
                    Payment Gateway’s Privacy Policies. <br />
                    <br />
                    (b) MERCHANT further understands that Payment Gateway may
                    obtain various consumer reports regarding MERCHANT from
                    third parties, perform a credit check, or otherwise obtain
                    personal or credit information about MERCHANT in evaluating
                    MERCHANT’s eligibility for, and for the purpose of providing
                    and administering the Payment Gateway Services.
                    <br />
                    (c) MERCHANT understands that Payment Gateway may collect
                    and hold personal or non-public information about MERCHANT
                    and MERCHANT’s customers, including but not limited to:
                    MERCHANT’s name, address, telephone number, e-mail address,
                    social security number and/or tax identification number, and
                    payment data as well as MERCHANT’s customers&apos; names,
                    mailing & shipping addresses, email addresses, phone number,
                    types of purchases and descriptions of purchases (“Data”)
                    for the purpose of considering MERCHANT’s eligibility for
                    and use of the Payment Gateway Services. MERCHANT also
                    understands and agrees that Payment Gateway, its
                    subsidiaries, PARTNERs, Third Party Providers, suppliers
                    and/or their agents and/or contractors may transfer Data
                    among themselves as necessary for the purpose of
                    provisioning and managing the Payment Gateway Services.
                    MERCHANT further agrees that Payment Gateway may further
                    transfer Data (i) to and from non-affiliated entities that
                    assist Payment Gateway in providing products and services
                    that MERCHANT requested; (ii) to and from companies that
                    provide support services to Payment Gateway; (iii) to and
                    from companies that provide marketing services on behalf of
                    Payment Gateway; or (iv) where otherwise provided by law.
                    <br />
                    (d) Payment Gateway will maintain Payment Card Industry Data
                    Security Standard (PCI DSS) compliance at all times during
                    the Term of this Agreement.
                    <br />
                    (e) MERCHANT further understands that while Payment Gateway
                    uses commercially reasonable efforts to safeguard Data and
                    Transaction data transmitted by and through the Payment
                    Gateway Services, Payment Gateway provides no warranty,
                    express or implied, that Data and Transaction data will be
                    transported without unauthorized interception or
                    modification, or that Data or Transaction data will not be
                    accessed or compromised by any unauthorized third parties.
                    <br />
                    (f) MERCHANT agrees that MERCHANT is solely responsible for
                    compiling and retaining permanent records of all Data and
                    Transaction data for future reference. Except as otherwise
                    provided herein, Payment Gateway shall have no obligation to
                    store, retain, report or otherwise provide any copies of or
                    access to any records of Transactions or other Data
                    collected or processed by Payment Gateway. MERCHANT
                    acknowledges that Payment Gateway shall have no obligation
                    to provide MERCHANT with any Data or Transaction data upon
                    termination of this Agreement. MERCHANT agrees that MERCHANT
                    shall implement industry standard policies and procedures
                    that limit access to Data or Transaction data. Prior to
                    discarding data or Transaction data MERCHANT shall render
                    all such data or Transaction data unreadable or encrypted,
                    and abide by all laws or regulations imposed on MERCHANT
                    pertaining to Data or Transaction data destruction and/or
                    disposal.
                </Typography>
                <Typography gutterBottom={true}>
                    <b>5.3 MERCHANT’s Username and Password</b>
                    <br />
                    (a) In connection with MERCHANT’s rights as described
                    herein, Payment Gateway or PARTNER will issue to MERCHANT,
                    or permit MERCHANT to use, a username and password that
                    enables MERCHANT and/or MERCHANT’s employees and/or agents
                    to access MERCHANT’s gateway account and use the Payment
                    Gateway Services. MERCHANT shall restrict access to such
                    username, password, and account to only those of MERCHANT’s
                    employees and/or agents who have reasonable need for such
                    information. MERCHANT shall ensure that each such employee
                    and/or agent accessing and using the account is aware of and
                    otherwise complies with all applicable provisions of this
                    Agreement.
                    <br />
                    (b) MERCHANT is solely responsible for maintaining adequate
                    security and control of any and all usernames, passwords, or
                    any other codes that are issued by Payment Gateway or
                    PARTNER to MERCHANT, or selected by MERCHANT, for the
                    purpose of accessing the Payment Gateway Services. Payment
                    Gateway shall be entitled to rely on information it receives
                    from MERCHANT and may assume that all such information was
                    transmitted by or on behalf of MERCHANT.
                </Typography>
                {/* 6 */}
                <Typography variant="h5">
                    6. INTELLECTUAL PROPERTY AND REVERSE ENGINEERING:
                </Typography>
                <Typography gutterBottom={true}>
                    The parties hereby agree that Payment Gateway owns and
                    retains all right, title and interest in and to the Payment
                    Gateway Services, Trademarks, copyrights, technology and any
                    and all related technology or intellectual property utilized
                    under or in connection herewith. No title to or ownership of
                    any of the foregoing is granted or otherwise transferred to
                    MERCHANT or any other entity or person under this Agreement.
                    MERCHANT shall not reverse engineer, disassemble, decompile
                    or otherwise attempt to discover the source code or trade
                    secrets of any of Payment Gateway’s Services or related
                    technology.
                </Typography>
                {/* 7 */}
                <Typography variant="h5">7. TRADEMARKS:</Typography>
                <Typography gutterBottom={true}>
                    <b>7.1 Use of Trademarks</b>
                    <br />
                    (a) Payment Gateway hereby grants to MERCHANT the right to
                    use, reproduce, publish, perform and display the Payment
                    Gateway Marks as follows: (a) on MERCHANT’s web site in
                    connection with MERCHANT’s offering of Payment Gateway
                    Services to its customers; and (b) in promotional and
                    marketing materials and electronic and printed advertising,
                    publicity, email correspondence, press releases, newsletters
                    and mailings about or related to any of the Payment Gateway
                    Services.
                    <br />
                    MERCHANT hereby grants to Payment Gateway, its Third Party
                    Service Provider and its PARTNERs the right to use,
                    reproduce, publish, perform and display MERCHANT Marks as
                    follows: (a) in connection with the development, use,
                    reproduction, modification, adaptation, publication, display
                    and performance of the Payment Gateway Services offered
                    and/or accessible through MERCHANT web site; and (b) in
                    promotional and marketing materials and electronic and
                    printed advertising, publicity, email correspondence, press
                    releases, newsletters and mailings about or related to any
                    of the Payment Gateway Services.
                    <br />
                    <b>7.2 Restrictions on Use</b>
                    <br />
                    (a) Neither party shall create any derivative mark
                    consisting of one or more Trademarks of each party, or
                    otherwise use the other party’s Mark(s) as its own property.
                    All uses of the other party&apos;s Trademarks shall inure to
                    the benefit of the party owning such Trademark. Each party
                    to this Agreement hereby acknowledges and agrees that the
                    other party is the owner of the Trademarks identified such
                    in any written notice provided to the other party pursuant
                    to this Agreement. Either party may update or change the
                    list of Trademarks usable by the other party hereunder at
                    any time by written notice to the other party.
                    <br />
                    (b) A party to this Agreement must include a statement of
                    ownership when displaying or reproducing the trademark(s) of
                    the other. It is sufficient to state: “The trademark is the
                    property of its respective owner.”
                    <br />
                    (c) MERCHANT shall not use (except as expressly provided for
                    by this Agreement), register or attempt to register any
                    Payment Gateway Trademarks or domain names that are in any
                    manner confusingly similar to the Trademarks, marks or
                    Domain Name(s) belonging to Payment Gateway. MERCHANT shall
                    not take any action inconsistent with Payment Gateway’s
                    ownership of its Trademarks, including registering any
                    confusingly similar Trademarks, or in any way dispute the
                    validity thereof. MERCHANT shall not use Payment Gateway’s
                    Trademarks in any manner that would suggest that MERCHANT is
                    using Payment Gateway Trademarks other than as a licensee,
                    or assist any third party do the same.
                </Typography>
                {/* 8 */}
                <Typography variant="h5">8. PAYMENT TERMS:</Typography>
                <Typography gutterBottom={true}>
                    <b>8.1 ACH Authorization and Payment Due Dates</b>
                    <br />
                    (a) Payment Gateway’s fees shall commence on the Effective
                    Date and MERCHANT shall be billed on the first business day
                    of each month following the Effective Date for any and all
                    amounts due and owing under this Agreement. If accrued fees
                    owed by MERCHANT exceed $50.00 USD at any time in any given
                    month, Payment Gateway will bill MERCHANT the full amount
                    due more frequently than monthly, at Payment Gateway’s
                    discretion.
                    <br />
                    (b) MERCHANT must authorize Payment Gateway to initiate
                    transaction entries into MERCHANT’s depository account via
                    ACH. This authority is to remain in full force and effect
                    until (i) Payment Gateway has received written request from
                    MERCHANT to terminate this arrangement, which request must
                    be made with sufficient time to afford Payment Gateway and
                    MERCHANT’s depository institution a commercially reasonable
                    opportunity to acknowledge and respond to the same, and (ii)
                    Payment Gateway has collected all fees due and owing under
                    this Agreement. If Payment Gateway is unable to collect
                    amounts due and owing from MERCHANT’s depository account,
                    MERCHANT hereby authorizes Payment Gateway to charge
                    MERCHANT’s credit card for any and all amounts due and owing
                    under this Agreement. Any entry initiated to or from
                    MERCHANT’s depository account will conform to the rules of
                    the National Automated Clearing House Association (NACHA)
                    and/or any other regulatory body or agency having
                    jurisdiction over the subject matter hereof.
                    <br />
                    (c) MERCHANT must promptly update MERCHANT’s account
                    information with Payment Gateway or PARTNER with current and
                    accurate information. In the event MERCHANT fails to provide
                    Payment Gateway or PARTNER with current and accurate
                    depository account and/or credit card account information,
                    Payment Gateway may immediately discontinue providing
                    Payment Gateway Services to MERCHANT, without liability,
                    until such information is provided to Payment Gateway, in
                    which case Payment Gateway may also terminate this
                    Agreement. MERCHANT acknowledges that any change in account
                    information may not be effective until the month following
                    the month in which Payment Gateway receives such notice.
                    Termination of MERCHANT’s authorization shall result in
                    termination of any and all Payment Gateway Services.
                    <br />
                    <b>8.2 MERCHANT BILLS FROM PARTNER:</b>
                    If MERCHANT is billed by a PARTNER for some or all of the
                    Fees, MERCHANT shall pay PARTNER in the manner agreed upon
                    between MERCHANT and PARTNER.
                </Typography>
                {/* 9 */}
                <Typography variant="h5">9. FEES:</Typography>
                <Typography gutterBottom={true}>
                    <b>9.1 Payment Gateway Service Fees</b>
                    <br />
                    (a) MERCHANT shall pay Payment Gateway the fees set forth in
                    the Fee Schedule provided to MERCHANT by Payment Gateway
                    and/or, if applicable, to PARTNER. The Fee Schedule is
                    hereby incorporated in its entirety into the terms of this
                    Agreement by reference.
                    <br />
                    (b) Notwithstanding the foregoing, MERCHANT may continue
                    using the Payment Gateway Services if MERCHANT’s
                    relationship with the PARTNER that offered MERCHANT the
                    Payment Gateway Services expires or terminates, and such
                    PARTNER was billing MERCHANT for certain Fees. If MERCHANT
                    elect to continue using the Payment Gateway Services,
                    MERCHANT acknowledges and agrees that Payment Gateway may
                    begin to bill MERCHANT for such Fees in the amounts that the
                    PARTNER had been charging MERCHANT. Payment Gateway may
                    thereafter amend the Fee schedule after providing MERCHANT
                    with thirty (30) days notice in accordance with Section
                    15.3.
                    <br />
                    <b>9.2 Other Fees and Charges</b>
                    <br />
                    (a) MERCHANT shall incur a late fee in the amount set forth
                    in the fee schedule if any amounts due to Payment Gateway
                    under this Agreement are not paid on or before the tenth
                    (10th) day following the date when due. In addition,
                    MERCHANT shall be subject to a finance charge equal to one
                    and one-half percent (1.5%) per month, or the highest rate
                    allowable by law, whichever is less, determined and
                    compounded daily from the date due until the date on which
                    it is paid. Payment of such late fee(s) and finance
                    charge(s) will not excuse or cure any breach or default for
                    late payment. Payment Gateway may accept any check or
                    payment from MERCHANT without prejudice to its rights to
                    recover the balance due or to pursue any other right or
                    remedy. No endorsement or statement on any check or payment
                    or any correspondence accompanying any check or payment or
                    elsewhere may be construed as an accord or satisfaction.
                    <br />
                    (b) For any occurrence in which Payment Gateway is unable to
                    collect fees on MERCHANT’s Account, for any reason,
                    including but not limited to insufficient funds, closed
                    account, or any other negative response, Payment Gateway may
                    charge MERCHANT a Return Payment Fee in the amount of $25.00
                    USD, or such fee as is set forth in the fee schedule.
                    <br />
                    (c) If MERCHANT has not paid all amounts due and owing with
                    two (2) days of their due date, Payment Gateway may, in its
                    sole discretion, discontinue providing MERCHANT with Payment
                    Gateway Services. If MERCHANT has still not paid all amounts
                    due and owing after thirty (30) days following the date on
                    which such payments were due, then Payment Gateway may
                    immediately terminate this Agreement in its sole discretion.
                    Notwithstanding the foregoing, if MERCHANT subsequently pays
                    in full all fees due and owing, including but not limited to
                    late fees, finance charges and Return Payment Fees, and if
                    Payment Gateway has not already terminated this Agreement,
                    then Payment Gateway may elect to reactivate the Payment
                    Gateway Services and charge MERCHANT a Service Reactivation
                    Fee as set forth in the fee schedule.
                    <br />
                    (d) MERCHANT agrees to pay all costs and expenses of
                    whatever nature, including attorneys&apos; fees, incurred by
                    or on behalf of Payment Gateway in connection with the
                    collection of any unpaid charges and fees.
                    <br />
                    (e) MERCHANT agrees to pay an export fee of $.10 per record
                    with a minimum charge of $250 for any tokenized data that
                    resides within the customer vault.
                </Typography>
                {/* 10 */}
                <Typography variant="h5">10. TERMINATION:</Typography>
                <Typography gutterBottom={true}>
                    <b>10.1 Termination by Payment Gateway</b>
                    <br />
                    (a) Payment Gateway may terminate this Agreement and/or
                    terminate MERCHANT’s use of the Payment Gateway Services
                    immediately, or at any time, without advance notice, with or
                    without cause, for any reason, including without limitation
                    in the event MERCHANT breaches or defaults on any obligation
                    set forth in this Agreement, or if Payment Gateway
                    determines, in its sole discretion, that MERCHANT’s business
                    practices are detrimental to Payment Gateway’s business
                    objectives. In such event, Payment Gateway shall provide
                    MERCHANT with a written or electronic notice of termination.
                    <br />
                    <b>10.2 Termination by MERCHANT</b>
                    <br />
                    (a) MERCHANT may terminate this Agreement with or without
                    cause, and for any reason, by providing Payment Gateway with
                    (30) days written notice of MERCHANT’s intent to terminate
                    this Agreement.
                    <br />
                    (b) In the event MERCHANT is billed by a PARTNER in
                    furtherance of Section 8.2 of this Agreement, MERCHANT
                    hereby authorizes PARTNER to terminate this Agreement on
                    MERCHANT’s behalf.
                    <br />
                    <b>
                        10.3 Termination by PARTNER or Third-Party Service
                        Provider
                    </b>
                    <br />
                    (a) In the event MERCHANT is billed by an PARTNER according
                    to Section 8.2, and if Payment Gateway receives notice from
                    such PARTNER that it has terminated or suspended its
                    relationship with MERCHANT, Payment Gateway may suspend
                    and/or terminate MERCHANT’s right to use Payment Gateway
                    Services and/or terminate this Agreement without notice or
                    liability.
                    <br />
                    (b) In the event Payment Gateway is notified by a Third
                    Party Service Provider, court of competent jurisdiction,
                    governmental body or authority, Acquiring Bank or the Card
                    Association that MERCHANT are no longer entitled to receive
                    payment data for any reason whatsoever, Payment Gateway may
                    suspend and/or terminate the Payment Gateway Services and/or
                    this Agreement without notice or liability.
                    <b>10.3 Effect of Termination and Survival</b>
                    All rights and interests under this Agreement shall be
                    extinguished upon termination of this Agreement, which shall
                    be given no further force or effect except that: i) all
                    accrued payment obligations shall survive such expiration or
                    termination; and (ii) the rights and obligations of the
                    parties under Sections 6, 10, 12, 13, 14 and 15 shall also
                    survive termination.
                </Typography>
                {/* 11 */}
                <Typography variant="h5">
                    11. REPRESENTATIONS AND WARRANTIES:
                </Typography>
                <Typography gutterBottom={true}>
                    <b>11.1 Mutual Representations and Warranties</b>
                    <br />
                    Each party represents and warrants to the other that (i) the
                    party&apos;s obligations under this Agreement do not violate
                    any law or breach any other agreement to which either party
                    to this Agreement is bound; (ii) the undersigned has all
                    necessary rights, powers and abilities to execute this
                    Agreement and to perform its obligations therein; and (iii)
                    neither party requires any further or third party
                    authorization or approval in order to fully perform its
                    obligations under this Agreement.
                    <br />
                    <b>11.2 MERCHANT’s Representations and Warranties</b>
                    <br />
                    (a) MERCHANT represents and warrants that MERCHANT is
                    engaged in a lawful business that includes the sale of
                    products and/or services, and is duly licensed to conduct
                    such business under the laws of all jurisdictions in which
                    MERCHANT does business. MERCHANT further represents and
                    warrants that all statements made by MERCHANT in this
                    Agreement, and/or in any other, related document executed by
                    MERCHANT or on MERCHANT’s behalf, are true, accurate and
                    complete in all material respects. MERCHANT hereby
                    authorizes Payment Gateway to investigate and confirm the
                    information submitted by MERCHANT herein. For this purpose,
                    Payment Gateway may utilize credit bureaus and/or reporting
                    agencies, and/or Payment Gateway’s own agents.
                    <br />
                    (b) MERCHANT will comply with all applicable laws,
                    regulations, rules, ordinances and orders. MERCHANT shall
                    further comply with, without limitation, the Payment Card
                    Industry Data Security Standard (PCI DSS), the
                    Gramm-Leach-Bliley Act, and such regulations as may apply or
                    be promulgated from time to time by the National Automated
                    Clearing House Association (NACHA), and/or any other
                    regulatory body or agency of competent jurisdiction.
                    <br />
                    (c) MERCHANT shall abide with all material terms of the then
                    current policies, procedures, and guidelines of Payment
                    Gateway governing the Payment Gateway Services.
                    <br />
                    <b>11.3 Payment Gateway’s Representations and Warranties</b>
                    <br />
                    (a) Payment Gateway represents and warrants that the Payment
                    Gateway Services provided to MERCHANT will substantially
                    conform to the specifications set forth in the Payment
                    Gateway Service Level Agreement (SLA), a copy of which is
                    attached hereto as Appendix B, which SLA may be amended from
                    time to time at Payment Gateway’s sole discretion. In
                    addition to the exclusions contained in Appendix B, the SLA
                    will not apply if (i) MERCHANT’s equipment or software
                    creates a defect or malfunction; (ii) the Payment Gateway
                    Services are used in a manner that in any way conflicts with
                    this Agreement, or (iii) any of the Payment Gateway Services
                    are modified by any individual or entity other than Payment
                    Gateway.
                    <br />
                    (b) MERCHANT acknowledges that Payment Gateway Services are
                    designed for use with certain third-party programs,
                    including, without limitation, certain internet browser
                    software programs. MERCHANT will look solely to the
                    developers and manufacturers of such programs with respect
                    to any warranty, maintenance or support regarding the same.
                    Payment Gateway makes no warranty, express or implied, as to
                    any such third-party software.
                    <br />
                    (c) MERCHANT may not rely on any representation or warranty
                    pertaining to Payment Gateway Services made by any third
                    party in contravention of this Agreement, including the
                    representations or warranties of any Third-Party Service
                    Provider or PARTNER. In the event of a breach of the
                    foregoing warranty, Payment Gateway shall use commercially
                    reasonable efforts to repair or replace the Payment Gateway
                    Services, at its option. MERCHANT acknowledges that Payment
                    Gateway neither warrants nor guarantees that such efforts
                    will be successful. If Payment Gateway’s efforts are
                    unsuccessful, MERCHANT may terminate this Agreement in
                    accordance with Section 10.2. THE FOREGOING SHALL CONSTITUTE
                    MERCHANT’s SOLE REMEDY, AND PAYMENT GATEWAY’s SOLE
                    LIABILITY, FOR INTERRUPTIONS, OUTAGES OR OTHER DELAYS IN
                    PAYMENT GATEWAY’S SERVICES AND/OR VALUE-ADDED SERVICES,
                    WHICH VALUE-ADDED SERVICES ARE DESCRIBED AND DISCUSSED IN
                    EXHIBIT “C” HERETO. Payment Gateway neither warrants nor
                    guarantees the services of any third party, including
                    without limitation Third Party Service Providers, PARTNERs,
                    VARS or the Card Association.
                </Typography>
                {/* 12 */}
                <Typography variant="h5">
                    12. CONFIDENTIALITY AND NONDISCLOSURE:
                </Typography>
                <Typography gutterBottom={true}>
                    <b>12.1 Use of Confidential Information</b>
                    <br />
                    Each party that receives information (the “Receiving Party”)
                    from the other party (the “Disclosing Party”) agrees to use
                    its commercially reasonable, best efforts to protect all
                    non-public information and know-how of the Disclosing Party
                    that is either designated as proprietary and/or confidential
                    or that, by the nature of the circumstances surrounding
                    disclosure, reasonably ought to be treated as proprietary
                    and/or confidential (“Confidential Information”). Each party
                    shall take precautions as to the Confidential Information of
                    the other at least as great as those taken to protect its
                    own, similar information. Each party agrees that the terms
                    and conditions of this Agreement will be Confidential
                    Information, however, either party may disclose the terms
                    and conditions of this Agreement to its immediate legal and
                    financial consultants in the ordinary course of its
                    business.
                    <br />
                    <b>12.2 Exclusions</b>
                    <br />
                    The foregoing restrictions will not apply to any information
                    that: (a) the Receiving Party can document it had in its
                    possession prior to disclosure by the Disclosing Party; (b)
                    was in or entered the public domain through no fault of the
                    Receiving Party; (c) is disclosed to the Receiving Party by
                    a third party legally entitled to make such disclosure
                    without violation of any obligation of confidentiality; (d)
                    is required to be disclosed by applicable laws or
                    regulations, but in such event, only to the extent required
                    to be disclosed, and in which case the Disclosing Party
                    shall notify the other of its intention or obligation to
                    make such disclosures in prior to doing so; or (e) is
                    independently developed by the Receiving Party without
                    reference to any Confidential Information of the Disclosing
                    Party. Upon request of the other party, or in any event upon
                    any termination or expiration of the Term, each party shall
                    return to the other all materials, in any medium, whether
                    physical or electronic, that contain, embody, reflect or
                    reference part or all of the Confidential Information of the
                    other party. Each party acknowledges that its breach of this
                    provision would result in irreparable harm to the other
                    party, for which money damages would be an insufficient
                    remedy, thus for which the other party shall be entitled to
                    seek injunctive relief to enforce the provisions of this
                    Section 12.
                </Typography>
                {/* 13 */}
                <Typography variant="h5">
                    13. LIMITATION OF LIABILITY:
                </Typography>
                <Typography gutterBottom={true}>
                    <b>13.1 Payment Gateway Disclaimers</b>
                    <br />
                    (a) GATEWAY EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY OR
                    LOSS, HOWEVER OCCURRING, INCLUDING NEGLIGENCE, WHICH ARISES
                    FROM OR WHICH IS RELATED TO ANY UNAUTHORIZED ACCESS TO
                    MERCHANT’S FACILITIES OR TO MERCHANT’S DATA OR PROGRAMS DUE
                    TO ACCIDENT, ILLEGAL OR FRAUDULENT MEANS OR DEVICES USED BY
                    ANY THIRD PARTY, OR DUE TO OTHER CAUSES BEYOND PAYMENT
                    GATEWAY&apos;S REASONABLE CONTROL.
                    <br />
                    (b) PAYMENT GATEWAY EXPRESSLY DISCLAIMS ANY LIABILITY OR
                    LOSS, HOWEVER OCCURRING, INCLUDING NEGLIGENCE, ARISING FROM
                    OR RELATED TO: (I) MERCHANT’S FAILURE TO PROPERLY ACTIVATE,
                    INTEGRATE OR SECURE MERCHANT’S ACCOUNT(S); (II) FRAUDULENT
                    TRANSACTIONS PROCESSED THROUGH MERCHANT’S ACCOUNT(S); (III)
                    DISRUPTION OF PAYMENT GATEWAY’S SERVICES, SYSTEMS, SERVER OR
                    WEB SITE BY ANY MEANS, INCLUDING WITHOUT LIMITATION, DDOS
                    ATTACKS, SOFTWARE VIRUSES, TROJAN HORSES, SYBIL ATTACKS,
                    WORMS, TIME BOMBS, OR ANY OTHER MALWARE, SPYWARE OR OTHER
                    TECHNOLOGY; (IV) ACTIONS OR OMISSIONS BY ANY THIRD PARTY,
                    INCLUDING, WITHOUT LIMITATION, A THIRD PARTY SERVICE
                    PROVIDER, PARTNER, OR AQUIRING BANK; OR (V) ANY UNAUTHORIZED
                    ACCESS TO DATA OR CUSTOMER DATA, INCLUDING BUT NOT LIMITED
                    TO CREDIT CARD NUMBERS, PERSONALLY IDENTIFIABLE INFORMATION,
                    TRANSACTION DATA OR OTHER PERSONAL INFORMATION BELONGING TO
                    PAYMENT GATEWAY, MERCHANT OR ANY THIRD PARTY.
                    <br />
                    (c) PAYMENT GATEWAY EXPRESSLY DISCLAIMS ANY AND ALL
                    LIABILITY OR LOSS PERTAINING TO THE LEGITIMACY OF ORDERS
                    FORWARDED FROM MERCHANT, AND FOR ANY AND ALL CLAIMS OF LOSS
                    AND/OR FRAUD INCURRED THROUGH ASSUMPTIONS OR CONCLUSIONS
                    DRAWN FROM THE DATA PROVIDED TO MERCHANT THROUGH ANY OF
                    PAYMENT GATEWAY’S SERVICES, FROM ANY SYSTEM OR PROGRAM
                    ASSOCIATED THEREWITH, OR AS THE RESULT OF LIMITATIONS IN THE
                    FUNCTIONING OF ANY PAYMENT GATEWAY SERVICES OR SOFTWARE,
                    HARDWARE, OR ASSOCIATED EQUIPMENT, WHETHER OWNED BY PAYMENT
                    GATEWAY OR OFFERED THROUGH A THIRD PARTY SERVICE PROVIDER OR
                    OTHER ENTITY.
                    <br />
                    <b>13.2. Payment Gateway Limitation of Liability</b>
                    <br />
                    (a) UNDER NO CIRCUMSTANCES WILL PAYMENT GATEWAY, OR ANY OF
                    ITS PARENT’S OR RELATED PARTIES’ AFFILIATES OR VENDORS,
                    OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR ASSIGNS BE LIABLE
                    FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
                    EXEMPLARY DAMAGES, HOWEVER OR WHENEVER ARISING, INCLUDING,
                    WITHOUT LIMITATION, DAMAGES FOR LOST REVENUE, LOST PROFITS,
                    ANTICIPATED PROFITS, LOST BUSINESS OR INJURY TO BUSINESS
                    REPUTATION, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE
                    SERVICES, UNDER ANY THEORY OF LIABILITY OR CAUSE OF ACTION
                    WHETHER IN TORT, INCLUDING NEGLIGENCE, CONTRACT OR
                    OTHERWISE, REGARDLESS OF WHETHER OR NOT IT HAS BEEN ADVISED
                    OF THE POSSIBILITY OF SUCH DAMAGES.
                    <br />
                    (b) PAYMENT GATEWAY’S TOTAL LIABILITY TO MERCHANT, WHETHER
                    ARISING IN TORT (INCLUDING NEGLIGENCE), CONTRACT OR
                    OTHERWISE, UNDER THIS AGREEMENT OR WITH REGARD TO ANY OF
                    PAYMENT GATEWAY’S PRODUCTS OR SERVICES, SHALL NOT EXCEED THE
                    AGGREGATE COMPENSATION PAYMENT GATEWAY RECEIVED FOR
                    PROVIDING PAYMENT GATEWAY SERVICES TO MERCHANT DURING THE
                    THIRTY (30) DAYS PRECEDING THE DATE ON WHICH THE CLAIM AROSE
                    OR $1,000.00 USD, WHICHEVER IS LESS.
                    <br />
                    <b>13.3 Payment Gateway Services “As Is” Disclaimer</b>
                    <br />
                    GATEWAY PROVIDER SERVICES ARE PROVIDED ON AN “AS IS” “AS
                    AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES.
                    GATEWAY PROVIDER DOES NOT REPRESENT OR WARRANT THAT THE
                    GATEWAY PROVIDER SERVICES WILL BE AVAILABLE, ACCESSIBLE,
                    UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE, OR ERROR
                    FREE. MERCHANT MAY NOT RELY UPON ANY REPRESENTATION OR
                    WARRANTY REGARDING THE GATEWAY PROVIDER SERVICES MADE BY ANY
                    THIRD PARTY IN CONTRAVENTION OF THE FOREGOING STATEMENTS,
                    INCLUDING, BUT NOT LIMITED TO REPRESENTATIONS BY THIRD PARTY
                    SERVICE PROVIDERS OR PARTNERS, EXCEPT AS SET FORTH IN THE
                    SERVICE LEVEL AGREEMENT CONTAINED IN APPENDIX B OF THIS
                    AGREEMENT. MERCHANT UNDERSTANDS AND AGREES THAT GATEWAY
                    PROVIDER SHALL BEAR NO RISK WHATSOEVER AS TO THE SALE OF
                    PRODUCTS OR SERVICES, INCLUDING WITHOUT LIMITATION, ANY RISK
                    ASSOCIATED WITH CREDIT CARD FRAUD, ACH FRAUD, CHECK FRAUD OR
                    CHARGEBACKS. GATEWAY PROVIDER SPECIFICALLY DISCLAIMS ANY AND
                    ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, WHETHER
                    EXPRESS OR IMPLIED, ARISING BY STATUTE, OPERATION OF LAW,
                    USAGE OF TRADE, COURSE OF DEALING, OR OTHERWISE, INCLUDING
                    BUT NOT LIMITED TO WARRANTIES OR CONDITIONS OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    NON-INFRINGEMENT, OR TITLE WITH RESPECT TO THE GATEWAY
                    PROVIDER SERVICES, OR OTHER SERVICES OR GOODS PROVIDED UNDER
                    THIS AGREEMENT. MERCHANT UNDERSTANDS AND AGREES THAT PAYMENT
                    GATEWAY SHALL BEAR NO RISK WITH RESPECT TO MERCHANT’S SALE
                    OF PRODUCTS OR SERVICES, INCLUDING WITHOUT LIMITATION, ANY
                    RISK ASSOCIATED WITH CREDIT CARD FRAUD, ACH FRAUD, CHECK
                    FRAUD OR CHARGEBACKS.
                    <br />
                    <b>13.4 Disputes</b>
                    <br />
                    The parties shall promptly investigate any disputes under
                    this Agreement. If the disputed amount is less than five
                    percent (5%) of the total fees invoiced by Payment Gateway
                    for the relevant billing statement, the total amount
                    invoiced shall be due and payable on or before the due date.
                    If the disputed amount is greater than five percent (5%) of
                    the total fees invoiced by Payment Gateway for the relevant
                    billing statement, such amount may be withheld until the
                    dispute is resolved. All disputes must be made in good
                    faith, in writing, within thirty (30) days of the billing
                    statement date. Fees billed shall be deemed accepted and
                    non-refundable in the absence of such dispute.
                </Typography>
                {/* 14 */}
                <Typography variant="h5">14. INDEMNIFICATION:</Typography>
                <Typography gutterBottom={true}>
                    <b>14.1 MERCHANT Indemnification</b>
                    <br />
                    MERCHANT shall defend, indemnify, and hold Payment Gateway
                    and its PARTNERs, Third Party Service Providers, parents,
                    and/or subsidiaries, and any of their officers, directors,
                    agents and employees, harmless from and against any and all
                    claims, actions, proceedings, and suits, and all related
                    liabilities, damages, settlements, penalties, fines, costs
                    or expenses (including reasonable attorneys&apos; fees and
                    other litigation expenses) incurred by Payment Gateway,
                    arising out of or relating to (a) any breach or alleged
                    breach by MERCHANT of any representation, warranty, or
                    obligation of MERCHANT set forth in this Agreement; (b) any
                    damage or loss caused by negligence, fraud, dishonesty or
                    willful misconduct by MERCHANT or any of MERCHANT’s agents,
                    employees or customers; (c) payment card transactions
                    submitted to Payment Gateway by MERCHANT and rejected by
                    Payment Gateway or an issuing bank; (d) the reliability,
                    accuracy, or legitimacy of payment data or purchase orders
                    submitted by MERCHANT to Payment Gateway; (f) claims by
                    MERCHANT’s customers, including, without limitation, claims
                    relating to the disclosure of consumer data; (f) any alleged
                    infringement of a patent, copyright, trademark or other
                    intellectual property right resulting from any act or
                    omission on the part of MERCHANT; (g) any violation of
                    Payment Gateway’s then current policies or guidelines; or
                    (h) any alleged or actual violation by MERCHANT of any
                    applicable laws, regulations or rules of (i) the Credit Card
                    Associations; (ii) the Gramm Leach Bliley Act; (iii) or any
                    regulatory body or agency having jurisdiction over the
                    subject matter hereof. In the event MERCHANT causes fines
                    and/or penalties to be charged to Payment Gateway by the
                    Credit Card Associations or any other entity, MERCHANT
                    agrees to reimburse Payment Gateway immediately for said
                    fines and penalties.
                    <br />
                    <b>14.2 Payment Gateway Indemnification</b>
                    <br />
                    (a) Payment Gateway shall defend, indemnify and hold
                    MERCHANT and MERCHANT’s officers, directors, agents or
                    employees harmless from and against any and all third party
                    claims, actions, proceedings, and suits and all related
                    liabilities, damages, settlements, penalties, fines, costs
                    or expenses (including reasonable attorneys&apos; fees and
                    other litigation expenses) incurred by MERCHANT, arising out
                    of or relating to any alleged infringement of a U.S. patent
                    of any other entity or person by Payment Gateway.
                    <br />
                    (b) Payment Gateway’s obligations in Section 14.2(a) do not
                    apply if Payment Gateway’s Services are, in whole or in
                    part, (a) modified by persons or entities other than Payment
                    Gateway, if the alleged infringement relates to such
                    modification; (b) combined with other products, services,
                    processes or materials not supplied or recommended by
                    Payment Gateway, where the alleged infringement relates to
                    such combination, or (c) used after Payment Gateway has made
                    a non-infringing version available to MERCHANT
                    (collectively, “MERCHANT Faults”). If Payment Gateway’s
                    Services or any component thereof becomes, or in Payment
                    Gateway’s opinion is likely to become, the subject of a
                    claim of infringement, then MERCHANT shall allow Payment
                    Gateway, at Payment Gateway’s sole option and expense,
                    either to (i) procure for MERCHANT the right to continue
                    using the Payment Gateway Services as provided for in this
                    Agreement, or (ii) replace or modify the affected Payment
                    Gateway Services or infringing component so that it becomes
                    non-infringing. If, after using commercially reasonable
                    efforts, Payment Gateway is unable to cure the infringement,
                    either party may terminate this Agreement upon written
                    notice to the other, as provided in Section 10.
                    Notwithstanding the above, Payment Gateway’s total liability
                    shall not exceed the amounts described in Section 13.2(b).
                    THIS SECTION 14.2(b) PROVIDES THE ENTIRE LIABILITY OF
                    PAYMENT GATEWAY TO MERCHANT WITH RESPECT TO ANY INFRINGEMENT
                    OF ANY INTELLECTUAL PROPERTY RIGHTS BY PAYMENT GATEWAY
                    SERVICES.
                    <br />
                    <b>14.3 Indemnification Process</b>
                    <br />
                    The obligations of each party (“Indemnitor”) under this
                    Section 14.3 to defend, indemnify and hold harmless the
                    other party (“Indemnitee”) shall be subject to the
                    following: (a) Indemnitee shall provide Indemnitor with
                    prompt notice of the claim giving rise to such obligation;
                    provided, however, that any failure or delay in giving such
                    notice shall only relieve Indemnitor of its obligations
                    under this section to the extent it reasonably demonstrates
                    that its defense or settlement of the claim or suit was
                    adversely affected thereby; (b) Indemnitor shall have
                    control of the defense and of all negotiations for
                    settlement of such claim or suit; and (c) Indemnitee shall
                    cooperate with Indemnitor in the defense or settlement of
                    any such claim or suit, provided that Indemnitee shall be
                    reimbursed for all reasonable out-of-pocket expenses
                    incurred in providing any cooperation requested by
                    Indemnitor. Subject to clause (b) above, Indemnitee may
                    participate in the defense of any such claim or suit at its
                    own expense. Indemnitor shall not, without the consent of
                    the Indemnitee, enter into any settlement that reasonably
                    could be expected to require a material affirmative
                    obligation of liability, result in any ongoing material
                    liability to or materially prejudice Indemnitee in any way.
                    <br />
                    <b>14.4 Exceptions</b>
                    <br />
                    If MERCHANT is an agency or instrumentality of any state
                    within the United States, and is precluded by the law of
                    that state from entering into indemnification obligations,
                    then the obligations under this Section 14 shall apply only
                    to the extent permitted by such state’s law.
                </Typography>
                {/* 15 */}
                <Typography variant="h5">15. GENERAL PROVISIONS:</Typography>
                <Typography gutterBottom={true}>
                    <b>15.1 Non-exclusivity</b>
                    <br />
                    This Agreement shall not confer on either party any
                    exclusive rights. Each party is free to contract with
                    others, subject to the terms of this Agreement.
                    <br />
                    <b>15.2 Relationship of the Parties</b>
                    <br />
                    All notices to MERCHANT shall be sent electronically to the
                    email address posted in the MERCHANT’s Gateway Provider
                    account. All notices to Gateway Provider shall be sent to
                    iqpro@basyspro.com with a written copy to BASYS Processing,
                    Inc. Legal Department, Attention: General Counsel 15300 W
                    105th Terr , Lenexa KS 66219 US. Such written notice will be
                    deemed given upon personal delivery, or three (3) days after
                    the date of mailing if sent by certified or registered mail,
                    or by a recognized private delivery service.
                    <br />
                    <b>15.4 Non-solicitation</b>
                    <br />
                    The obligations of each party (“Indemnitor”) under this
                    Section 14.3 to defend, indemnify and hold harmless the
                    other party (“Indemnitee”) shall be subject to the
                    following: (a) Indemnitee shall provide Indemnitor with
                    prompt notice of the claim giving rise to such obligation;
                    provided, however, that any failure or delay in giving such
                    notice shall only relieve Indemnitor of its obligations
                    under this section to the extent it reasonably demonstrates
                    that its defense or settlement of the claim or suit was
                    adversely affected thereby; (b) Indemnitor shall have
                    control of the defense and of all negotiations for
                    settlement of such claim or suit; and (c) Indemnitee shall
                    cooperate with Indemnitor in the defense or settlement of
                    any such claim or suit, provided that Indemnitee shall be
                    reimbursed for all reasonable out-of-pocket expenses
                    incurred in providing any cooperation requested by
                    Indemnitor. Subject to clause (b) above, Indemnitee may
                    participate in the defense of any such claim or suit at its
                    own expense. Indemnitor shall not, without the consent of
                    the Indemnitee, enter into any settlement that reasonably
                    could be expected to require a material affirmative
                    obligation of liability, result in any ongoing material
                    liability to or materially prejudice Indemnitee in any way.
                    <br />
                    <b>15.5 Severability, Headings</b>
                    <br />
                    If MERCHANT is an agency or instrumentality of any state
                    within the United States, and is precluded by the law of
                    that state from entering into indemnification obligations,
                    then the obligations under this Section 14 shall apply only
                    to the extent permitted by such state’s law.
                    <b>15.6 Dispute Resolution</b>
                    <br />
                    Any dispute or claim arising out of or relating to this
                    Agreement, except claims involving intellectual property and
                    claims for indemnification, will be resolved by binding
                    arbitration. The arbitration of any dispute or claim shall
                    be conducted in accordance with the American Arbitration
                    Association (“AAA”) rules, as modified by this Agreement,
                    which shall take place in Chicago, Illinois, unless the
                    Parties mutually agree otherwise. This Agreement evidences a
                    transaction in interstate commerce and this arbitration
                    provision shall be interpreted and enforced in accordance
                    with the Federal Arbitration Act and federal arbitration
                    law. An arbitrator may not award relief in excess of or
                    contrary to what this Agreement provides, or order
                    consolidation or arbitration on a class wide or
                    representative basis, except that the arbitrator may award
                    damages required by statute on an individual basis and may
                    order injunctive or declaratory relief pursuant to an
                    applicable consumer protection statute. Any arbitration
                    determination or award shall be confidential, and neither
                    Party may disclose the existence, content or results of any
                    arbitration, except as may be required by law or for
                    purposes of enforcement of the same. Judgment on any
                    arbitration award may be entered in any court having proper
                    jurisdiction. All administrative fees and expenses will be
                    divided equally between the Parties, though each Party will
                    bear its own expense of counsel, experts, witnesses and
                    preparation and presentation of evidence at the arbitration.
                    IF FOR ANY REASON THIS ARBITRATION CLAUSE IS DEEMED
                    INAPPLICABLE OR INVALID, THE PARTIES WAIVE, TO THE FULLEST
                    EXTENT ALLOWED BY LAW, ANY RIGHT TO PURSUE ANY CLAIMS ON A
                    CLASS OR CONSOLIDATED BASIS OR IN A REPRESENTATIVE CAPACITY.
                    No action, regardless of form, arising out of or in
                    conjunction with the subject matter of this Agreement,
                    except for claims involving intellectual property and claims
                    for indemnification, may be brought by either Party more
                    than one (1) year after the cause of action arose.
                    <br />
                    <b>15.7 Amendment</b>
                    <br />
                    With written notice to MERCHANT, Gateway Provider may modify
                    any of the terms and conditions contained in this Agreement
                    at any time in its sole discretion. MERCHANT acknowledges
                    and agrees the transaction processing and gateway services
                    industries evolve and change over time, and therefore agrees
                    that Gateway Provider may so modify this Agreement, from
                    time to time, to comply with any additional rules or
                    policies that may be required under the laws of the United
                    States or any other governing body, or to adjust to changing
                    business circumstances. Gateway Provider will post the most
                    current version of this Agreement, including any amendments
                    or modifications thereto, via the MERCHANT Control Panel on
                    Gateway Provider’s Website, which version shall
                    automatically become effective thirty (30) days after the
                    date it is posted. MERCHANT may terminate the Agreement at
                    any time by providing Gateway Provider with written notice
                    within thirty (30) days after Gateway Provider posts an
                    amendment or modification to this Agreement if MERCHANT
                    deems such amendment or modification to be unacceptable.
                    MERCHANT&apos;s continued participation following a
                    modification or amendment shall constitute MERCHANT&apos;s
                    binding acceptance of the change. No provision of this
                    Agreement shall be modified by MERCHANT except by means of a
                    written document signed by Gateway Provider.
                    <br />
                    <b>15.8 Electronic Signatures</b>
                    <br />
                    Under the Electronic Signatures in Global and National
                    Commerce Act (E-Sign), this Agreement and all electronically
                    executed documents related hereto are legally binding in the
                    same manner as are hard copy documents executed by hand
                    signature when (1) a party’s electronic signature (which may
                    be evidenced by MERCHANT clicking the “Submit,” “Accept” or
                    other equivalent indicator on the applicable website) is
                    associated with this Agreement and related documents, (2)
                    MERCHANT consents and intends to be bound by this Agreement
                    and related documents, and/or (3) the Agreement is delivered
                    in an electronic record capable of retention by the
                    recipient at the time of receipt (i.e., print or otherwise
                    store the electronic record). This Agreement and all related
                    electronic documents shall be governed by the provisions of
                    E-Sign. By pressing Submit, Accept or other equivalent
                    indicator, such person or party agrees (i) that this
                    Agreement and related documents shall be effective by
                    electronic means, (ii) to be bound by the terms and
                    conditions of this Agreement and related documents, (iii)
                    that said person or party has the ability to print or
                    otherwise store the Agreement and related documents.
                    <br />
                    <b>
                        15.9 Governing Law, Jurisdiction, Conflicts of Law,
                        Forum
                    </b>
                    <br />
                    This Agreement and performance thereof shall be interpreted,
                    construed and enforced in all respects in accordance with
                    the laws of the State of Illinois. MERCHANT hereby
                    irrevocably consents to the personal jurisdiction of and
                    venue in the state and federal courts located in DuPage
                    County, Illinois with respect to any action, claim or
                    proceeding arising out of or related to this Agreement and
                    agrees not to commence or prosecute any such action, claim
                    or proceeding other than in such courts. The parties hereto
                    agree that Illinois law shall apply regardless of any choice
                    or conflicts of law principles. The parties further agree
                    that DuPage County, Illinois is a convenient forum, and
                    waives any objection to the same under forum non conveniens
                    principles.
                    <br />
                    <b>15.10 Waiver</b>
                    <br />
                    The failure of any party to insist on or enforce strict
                    performance of any provision of this Agreement, or to
                    exercise any right or remedy under this Agreement or
                    applicable law will not be construed as a waiver or
                    relinquishment of the right to assert or rely upon any such
                    provision, right or remedy in that or any other instance.
                    Waiver by either party of a breach of any provision
                    contained herein must be in writing, and no such waiver may
                    be construed as a waiver of any other and/or succeeding
                    breach of such or any other provision of this Agreement, or
                    a waiver of the provision itself.
                    <br />
                    <b>15.11 Assignment</b>
                    <br />
                    MERCHANT may not assign this Agreement or any rights
                    hereunder without the prior written consent of Gateway
                    Provider, except that Gateway Provider’s prior written
                    consent will not be required for an assignment as the result
                    of any MERCHANT merger, consolidation, reorganization or
                    similar transaction involving all or substantially all of
                    the assets of MERCHANT. In any such event, the assignee must
                    assume and agree in writing to perform all of the assigning
                    party&apos;s executory obligations. The assigning party must
                    further performance by the assignee throughout the Term of
                    this Agreement. Subject to the foregoing, this Agreement
                    shall be binding upon, enforceable by, and inure to the
                    benefit of the parties and their respective successors and
                    assigns.
                    <br />
                    <b>15.12 Force Majeure</b>
                    <br />
                    Gateway Provider shall not be responsible for any failure to
                    perform beyond its reasonable control, including, without
                    limitation acts of God, acts or omissions of civil or
                    military authority, civil disturbances, wars, strikes or
                    other labor disputes, fires, transportation contingencies,
                    or interruptions in telecommunications or internet services,
                    third party vendors or network provider services.
                    <br />
                    <b>15.13 Merger</b>
                    <br />
                    This Agreement, constitutes the entire agreement between the
                    parties and supersedes all prior memoranda or agreements
                    relating thereto, whether oral or in writing.
                </Typography>
                {/* APX A */}
                <Typography variant="h5">
                    Appendix A - Prohibited Activities
                </Typography>
                <Typography gutterBottom={true}>
                    MERCHANT agrees that MERCHANT will not at any time conduct
                    MERCHANT’s business in any manner that directly or
                    indirectly offers, sells, leases, licenses or displays,
                    delivers, markets, advertises, recommends, or promotes any
                    product(s), service(s), data, information, image(s), text
                    and/or any content which:
                    <br />
                    (1) is threatening, abusive, harassing, defamatory, obscene,
                    libelous, slanderous, deceptive, fraudulent, invasive of
                    another&apos;s privacy, tortuous, or otherwise violates
                    Payment Gateway’s rules or policies;
                    <br />
                    (2) infringes on any patent, trademark, trade secret,
                    copyright, right of publicity, or other proprietary right of
                    any person or party, including, but not limited to, the
                    unauthorized copying and posting of trademarks, pictures,
                    logos, software, articles, musical works and videos;
                    <br />
                    (3) offers or disseminates fraudulent goods, services,
                    schemes, or promotions (i.e., make money fast schemes, chain
                    letters, pyramid schemes) or engage in any unfair deceptive
                    act or practice;
                    <br />
                    (4) violates any U.S. export or import laws, including,
                    without limitation, the Export Administration Act and the
                    Export Administration Regulations maintained by the
                    Department of Commerce;
                    <br />
                    (5) is associated with any form of illegal gambling or
                    illegal lottery type services;
                    <br />
                    (6) is associated with the sale of (a) any controlled drug
                    that requires a prescription from a licensed practitioner
                    unless MERCHANT are authorized by the National Association
                    of Boards of Pharmacy to offer such products as a Verified
                    Internet Pharmacy Practice Site and only if such a
                    prescription has been issued by the practitioner after a
                    bona fide examination of the patient; or (b) any
                    over-the-counter drug, unless the sale of such product,
                    without a prescription, has been approved by the Food & Drug
                    Administration; or (c) nonprescription drugs that make false
                    or misleading treatment claims or treatment claims that
                    require FDA approval; or (d) any drug or controlled
                    substance that Payment Gateway believes to be or may become
                    harmful, unlawful, or prohibited. Payment Gateway requires
                    sellers of prescription drugs to abide by all laws
                    applicable to both the buyer and seller and may require
                    MERCHANT to provide evidence of compliance with these
                    requirements. In addition, due to the complexities of
                    current laws regulating the importation of controlled drugs
                    into the United States, MERCHANT may not use the Services to
                    sell prescription drugs that are imported into the United
                    States from an international location. The foregoing list is
                    a non-exhaustive list of prohibited goods and services;
                    <br />
                    (7) impersonates any person or entity;
                    <br />
                    (8) victimizes, harasses, degrades, or intimidates an
                    individual or group of individuals on the basis of religion,
                    gender, sexual orientation, race, ethnicity, age, or
                    disability;
                    <br />
                    (9) is associated with any illegal form of adult, sexually
                    oriented, or obscene materials or services, including
                    without limitation, any material associated with, linked to
                    or containing images of children less than 18 years old
                    and/or escort services;
                    <br />
                    (10) is associated with electronic wallets (i.e.,
                    “e-wallets”) or any similar payment type;
                    <br />
                    (11) violates any applicable local, state, federal, national
                    or international law, statute, ordinance, or regulation
                    including, without limitation, Credit Card Association
                    rules, consumer protection laws, unfair competition,
                    antidiscrimination or false advertising rules;
                    <br />
                    (12) is associated with illegal telecommunications or
                    illegal cable television equipment or illegal satellite
                    equipment; or
                    <br />
                    (13) contains harmful content, including, without
                    limitation, software viruses, Trojan horses, worms, time
                    bombs, cancel bots, malware, spy-ware, or any other files,
                    software programs, or technology that is designed or
                    intended to disrupt, damage, intercept or expropriate the
                    Services or any system, program, data or personal
                    information, or limit the functioning of any software,
                    hardware, or equipment, or to damage or obtain unauthorized
                    access to any data or other information of any third party.
                </Typography>
                {/* APX B */}
                <Typography variant="h5">
                    Appendix B - PAYMENT GATEWAY SERVICE LEVEL AGREEMENT
                </Typography>
                <Typography gutterBottom={true}>
                    This Service Level Agreement (“SLA Agreement”) sets forth
                    the SLA applicable to the various Payment Gateway Services
                    ordered by MERCHANT and provided by Payment Gateway. This
                    SLA provides MERCHANT’s sole and exclusive remedies for any
                    Service interruptions, deficiencies, or failures of any
                    kind. If such remedies include service credits, the parties
                    agree that such credits constitute liquidated damages. No
                    otherwise applicable SLA, including any remedies hereunder,
                    shall apply with respect to any Excluded Events.
                    <br />
                    The following Service Level Targets apply to the Services.
                    <br />
                    For transaction processing services, this SLA provides that
                    the Payment Gateway Application will have 99.5% uptime
                    (averaged over the calendar month). MERCHANT will be
                    eligible for service credits as set forth in the following
                    table if this Availability Target is not met due to Outages.
                </Typography>
                {/* TABLE */}
                <Box sx={{ p: 2 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>
                                            Cumulative Duration of Outages
                                            within a Calendar Month which exceed
                                            the Availability Target
                                        </b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>Credit</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={"outages" + index}>
                                        <TableCell component="th" scope="row">
                                            {row.outages}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.credit}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {/* DEFINITIONS */}
                <Typography variant="h5">Definitions</Typography>
                <Typography gutterBottom={true}>
                    <b>“MRFC”</b> Monthly Recurring Fixed Charge means any and
                    all fees MERCHANT is obligated to pay to Payment Gateway,
                    including MERCHANT’S monthly charge and fixed Service
                    charges.
                    <br />
                    <b>“Affected Service”</b> means only that portion of a
                    Service that is actually impacted by the relevant Service
                    Level Target.
                    <br />
                    <b>“Availability”</b> means the percentage of time during a
                    month in which the Payment Gateway application is not
                    subject to an Outage.
                    <br />
                    <b>“Billing Commencement Date”</b> means the first day of
                    MERCHANT’s monthly billing cycle for any Gateway Provider
                    Service.
                    <br />
                    <b>“Outage”</b> means any period of at least one minute
                    during which the Payment Gateway Application is completely
                    unavailable or inaccessible for reasons other than an
                    Excluded Event. An Outage begins when Payment Gateway opens
                    the relevant support ticket and ends at the earlier of the
                    restoration of the Affected Service or when the ticket is
                    closed. All Outage measurements will be rounded to the
                    nearest one-minute increment.
                    <br />
                    <b>“Payment Gateway Application”</b> means the components
                    owned and operated by Payment Gateway excluding any
                    components that are not owned and operated directly by
                    Payment Gateway.
                    <br />
                    <b>“Excluded Events”</b> means any event that adversely
                    impacts the Service that is caused by (a) the failure or
                    malfunction of equipment, applications or systems not owned
                    or controlled by Payment Gateway; (b) the acts or omissions
                    of MERCHANT, MERCHANT’s employees, customers, contractors or
                    agents; (c) Force Majeure events; (d) any suspension of
                    Service pursuant to the Merchant Service Agreement; (e)
                    scheduled maintenance, alteration or implementation; or (f)
                    the unavailability of MERCHANT’s required personnel,
                    including as a result of failure to provide Payment Gateway
                    with accurate, current contact information.
                    <br />
                    <b>Process</b> MERCHANT must request any credit due
                    hereunder within 30 days of the conclusion of the month in
                    which it accrues. MERCHANT herby waives any right to credits
                    not requested within this 30 day period. Credits will be
                    issued once confirmed by Payment Gateway and applied toward
                    the invoice which MERCHANT will receive no later than two
                    months following MERCHANT’s credit request. All performance
                    calculations and applicable service credits are based on
                    Payment Gateway’s records and data.
                    <br />
                    <b>Limitations</b> This SLA does not apply to any Excluded
                    Events, as defined in this SLA. MERCHANT will not be
                    eligible to accrue any otherwise applicable service credits:
                    (a) before the start of the first full calendar month
                    following the Billing Commencement Date for the Affected
                    Service, or (b) during any period in which MERCHANT is in
                    violation of the Payment Gateway Merchant Service Agreement
                    or is past due on any amounts owed for Payment Gateway
                    Services. MERCHANT may not carry over any service credits
                    subject to the limits or exclusions of this SLA to
                    subsequent months.
                    <br />
                    In no event will the credits accrued in a single month
                    exceed, in the aggregate, across all service levels and
                    events, thirty percent (30%) of the invoice amount for the
                    Affected Service.
                    <br />
                    MERCHANT acknowledges that Payment Gateway manages its
                    throughput in part on the basis of MERCHANT’s utilization of
                    Service and that changes in its utilization may impact
                    Payment Gateway’s ability to manage throughput. Therefore,
                    notwithstanding anything else to the contrary, if MERCHANT
                    significantly changes MERCHANT’s utilization of the Service
                    and such change creates a material and adverse impact on the
                    volume through the Payment Gateway Application, as
                    determined by Payment Gateway, Payment Gateway may either
                    modify the applicable charges or terminate the Affected
                    Services.
                </Typography>
                {/* Appendix C - VALUE-ADDED SERVICES */}
                <Typography variant="h5">
                    Appendix C - VALUE-ADDED SERVICES
                </Typography>
                <Typography gutterBottom={true}>
                    In the event MERCHANT enrolls in, and Payment Gateway
                    provides MERCHANT with, Value-Added Service(s), MERCHANT
                    agrees as follows:
                    <br />
                    <b>1. Expansion of Services</b>
                    <br />
                    The terms “Services” and “Payment Gateway Services,” as each
                    is defined in the Agreement, shall include the Value-Added
                    Services. Each Value-Added Service is or will be described
                    on the Payment Gateway Web site and/or in other Services
                    Documentation provided to MERCHANT from time to time. All
                    terms of the Agreement applicable to the Payment Gateway
                    Services shall be applicable to each Value-Added Service.
                    <br />
                    <b>2. MERCHANT’S Obligations</b>
                    <br />
                    In addition to MERCHANT’s obligations set forth in this
                    Agreement, MERCHANT hereby agrees to pay the Value-Added
                    Service Fees, in accordance with this Agreement, in the
                    amounts provided for in the Fee Schedule provided to
                    MERCHANT by Payment Gateway and/or, if applicable, PARTNER.
                    The Fee Schedule is hereby incorporated into the terms of
                    this Agreement by reference and/or in the Value-Added
                    Service documentation page accessed during enrollment in the
                    applicable Value-Added Service. By checking the “I ACCEPT”
                    button next to a Value-Added Service Fee schedule, MERCHANT
                    acknowledge MERCHANT acceptance of such fees, MERCHANT’s
                    obligation to pay same and the terms and conditions
                    applicable to the Value-Added Service.
                    <br />
                    <b>3. MERCHANT’s Warranty</b>
                    <br />
                    MERCHANT represents, warrants, and covenants to Payment
                    Gateway that MERCHANT’s use of the Value-Added Services and
                    any information gathered by MERCHANT in connection with use
                    of a Value-Added Service: (a) will be fully compliant with
                    all applicable local, state and federal laws, rules, and
                    regulations; (b) will be in accordance with all applicable
                    Services Documentation; and (c) will not be used for any
                    purpose other than in connection with the Value-Added
                    Service.
                    <br />
                    <b>4. Acknowledgement</b>
                    <br />
                    MERCHANT understands, acknowledges, and agrees that (a)
                    MERCHANT will be solely responsible for ALL transactions
                    processed through MERCHANT’s Payment Gateway account(s),
                    regardless of whether such transactions are monitored by a
                    Value-Added Service; (b) MERCHANT will be solely responsible
                    for MERCHANT’s use of the Value-Added Service including,
                    without limitation (i) with respect to each Transaction
                    processed via MERCHANT’s account(s)(regardless of any data,
                    analysis, or information generated or not generated by the
                    Value-Added Service, as applicable) determining the
                    appropriate action for each such Transaction (i.e., approve,
                    void, decline, reject); and (ii) configuring, maintaining
                    and updating, as MERCHANT may deem necessary, the applicable
                    settings for MERCHANT’s Value-Added Service account; and (c)
                    under certain circumstances, it may be necessary for Payment
                    Gateway to adjust MERCHANT’s Value-Added Service security
                    settings, with or without notice to MERCHANT, to guard
                    against fraudulent activity, and that such actions may
                    inadvertently cause legitimate transactions to expire, be
                    rejected or delayed; and (d) Payment Gateway shall not be
                    liable under any theory of law, including negligence, for
                    any loss associated with any of the foregoing.
                    <br />
                    <b>5. LIMITATIONS AND DISCLAIMER</b>
                    <br />
                    IN ADDITION TO ANY LIMITATIONS OR DISCLAIMERS SET FORTH IN
                    THE AGREEMENT, MERCHANT UNDERSTANDS, ACKNOWLEDGES AND AGREES
                    THAT THE VALUE-ADDED SERVICES ARE PROVIDED TO MERCHANT BY
                    PAYMENT GATEWAY “AS IS” AND THAT PAYMENT GATEWAY DOES NOT
                    REPRESENT OR WARRANT THAT THE VALUE ADDED SERVICES OR ANY
                    OTHER TECHNOLOGY, CONTENT, INTELLECTUAL PROPERTY, OR ANY
                    OTHER INFORMATION, DATA, PRODUCTS, OR SERVICES, WILL BE
                    AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE,
                    ACCURATE, COMPLETE, OR ERROR-FREE, AND THAT MERCHANT’S SOLE
                    REMEDY FOR ANY ISSUE RELATED TO OR ARISING FROM THE VALUE
                    ADDED SERVICES, AND PAYMENT GATEWAY&apos;S SOLE LIABILITY
                    FOR SAME, WILL BE TO TERMINATE THIS AGREEMENT AND
                    DISCONTINUE MERCHANT’S USE OF THE VALUE-ADDED SERVICES.
                    <br />
                    <b>6. Risk, Security and Disclosure</b>
                    <br />
                    The risk and security suggestions provided to MERCHANT in
                    the Services Documentation for any of the Value-Added
                    Services are solely for illustrative purposes to show best
                    industry practices. MERCHANT shall be solely responsible for
                    choosing the appropriate settings and parameters for
                    MERCHANT account.
                    <br />
                    <b>7. Termination</b>
                    <br />
                    If MERCHANT Agreement is terminated for any reason, Payment
                    Gateway shall immediately cancel access to MERCHANT’s
                    Value-Added Service account. It is MERCHANT’s responsibility
                    to download all reports prior to the effective date of any
                    such termination since such reports will not be available
                    following the termination date.
                    <br />
                    <b>8. Incorporation by Reference</b>
                    <br />
                    The Value-Added Services Fee Schedules are incorporated
                    herein by reference.
                    <br />
                    <b>9. Third Party Applications</b>
                    <br />
                    Payment Gateway makes no warranty, express or implied, with
                    respect to any third-party services or software.
                    <br />
                    <b>10. Definitions</b>
                    <br />
                    All terms and conditions of the Agreement not specifically
                    modified by this Appendix shall remain unchanged and in full
                    force and effect. Unless separately defined herein,
                    capitalized words used in this Appendix as defined terms
                    shall have the same meanings herein as in the Agreement.
                </Typography>
                {/* Card Sync */}
                <Typography variant="h5">
                    Card Sync ACCOUNT UPDATER TERMS OF USE
                </Typography>
                <Typography gutterBottom={true}>
                    BASYS Processing, Inc. (“Provider”) offers value added
                    products and services, including Card Sync Account Updater.
                    In order for your company (“Merchant”) to obtain or continue
                    using Card Sync Account Updater as made available to you by
                    Provider, Merchant must agree to and accept the terms and
                    conditions of this Account Updater Terms of Use
                    (&quot;Agreement&quot;). By: (a) clicking the “Accept” or
                    similar button; (b) checking a box captioned with acceptance
                    language; or (c) activating or continuing to use Card Sync
                    Account Updater, Merchant agrees to be bound by the terms of
                    this Agreement. The individual accepting this Agreement on
                    behalf of Merchant represents that they have the authority
                    to bind Merchant to this Agreement. THIS IS A LEGAL
                    AGREEMENT; PLEASE REVIEW THESE TERMS AND CONDITIONS
                    CAREFULLY. YOU MAY NOT USE ACCOUNT UPDATER SERVICE IF YOU DO
                    NOT AGREE TO THESE TERMS AND CONDITIONS.
                    <br />
                    NOW THEREFORE, MERCHANT agrees as follows:
                    <br />
                    <b>1. DEFINITIONS:</b>
                    <br />
                    For purposes of this Agreement, the following terms (and
                    their derivatives) shall have the meanings ascribed to them
                    below:
                    <br />
                    “Account Updater Programs” means the various programs
                    provided through the Payment Networks that enables the
                    electronic exchange of certain cardholder account
                    information as updated by the cardholder’s card issuer.
                    <br />
                    “Card Sync Account Updater” or “CAU” means access to the
                    Account Updater Programs provided to Merchant by Provider in
                    order to facilitate Merchant’s recurring payment and
                    credentials-on-file transactions by cardholders.
                    <br />
                    “Payment Networks” means Visa Inc. and Visa Account Updater
                    (VAU) program (“Visa”), Mastercard International, Inc. and
                    their Account Billing Updater (ABU) program (“Mastercard”),
                    American Express Travel Related Services Company, Inc. and
                    their Cardrefresher program (“American Express”), Discover
                    Financial Services, LLC and their Global Network Account
                    Updater program (“Discover”), debit networks and any other
                    networks and financial service card organizations by which
                    payment transactions may be routed or processed and any
                    successor organizations.
                    <br />
                    “Rules” means the rules, operating regulations, terms of
                    use, policies, by-laws, and/or procedures issued by the
                    Payment Networks, including but not limited to the Payment
                    Card Industry Data Security Standard (PCI-DSS), as amended
                    by the Payment Networks from time to time.
                    <br />
                    “Third Party Partner” means Paylani, LLC, dba Card Sync.
                </Typography>
            </Grid>
            {/* TEXT CONTAINER END */}
            <Footer />
        </div>
    );
};

export default TOS;
