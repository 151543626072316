import * as React from "react";

const Icon1 = (props) => (
    <svg
        width={74}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0 12.112c.517-1.507 1.378-2.67 3.015-3.057.345-.086.732-.13 1.077-.13h40.06c.646 0 1.163.13 1.335.819.172.603-.215 1.249-.862 1.335H4.566c-1.81 0-2.412.603-2.412 2.454v5.641H45.4c.646.044.99.431.99 1.034s-.344.99-.904 1.12c-.259.043-.517 0-.775 0H2.154V28.519c0 .603 0 1.163-.69 1.464-.646.258-1.076-.13-1.464-.56v-17.31ZM62.374.744c1.292.387 2.67.646 3.92 1.163 5.083 2.239 8.27 7.794 7.624 13.133-.732 5.986-4.997 10.55-10.769 11.541-7.28 1.249-14.214-3.79-15.162-11.11C47.082 8.366 51.95 1.95 59.057.873c.13 0 .216-.086.302-.13h3.015ZM60.909 24.6c5.988 0 10.855-4.823 10.855-10.765 0-5.943-4.91-10.895-10.855-10.895-5.944 0-10.811 4.866-10.811 10.809 0 6.028 4.78 10.851 10.811 10.851ZM0 34.074c.345-.517.775-.905 1.421-.646.647.215.733.775.733 1.42v29.455c0 2.024.56 2.584 2.541 2.584H28.688c.603.13.947.517.904 1.12 0 .56-.344.947-.947 1.033h-.776c-7.667 0-15.291-.043-22.958 0-2.456 0-4.136-.818-4.911-3.23V34.075ZM52.379 59.524l3.834 3.832c1.421 1.421 1.421 2.627 0 4.048l-6.16 6.158c-1.465 1.464-2.628 1.464-4.135 0l-3.79-3.79c-.216.216-.431.345-.604.56-1.163 1.12-2.283 2.283-3.403 3.402-.689.69-1.464 1.077-2.455.775-1.034-.301-1.55-1.033-1.766-2.067a4500.88 4500.88 0 0 0-5.126-23.253c-.474-2.067.948-3.488 3.015-3.015 7.754 1.68 15.507 3.402 23.26 5.082.991.215 1.767.689 2.068 1.68.302 1.033 0 1.85-.775 2.583-1.163 1.12-2.283 2.282-3.403 3.402-.172.172-.301.344-.517.56l-.043.043Zm2.67 5.813s-.344-.344-.473-.517l-3.403-3.402c-1.292-1.291-1.25-2.54 0-3.832l3.403-3.402c.172-.172.301-.344.56-.603l-24.337-5.34 5.34 24.33c.302-.3.475-.43.647-.602 1.163-1.163 2.326-2.369 3.532-3.531 1.077-1.077 2.412-1.077 3.532 0 .776.732 1.55 1.507 2.283 2.282.603.603 1.163 1.249 1.766 1.852l7.15-7.149v-.086Z"
            fill="#1B9AD2"
        />
        <path
            d="M18.047 47.466c0-6.631 5.427-12.057 12.061-12.1 5.427 0 10.252 3.66 11.673 8.914.043.215.13.43.13.646 0 .56-.259.947-.776 1.076-.56.13-1.034 0-1.249-.56-.172-.387-.259-.775-.388-1.162-1.55-4.436-5.815-7.105-10.64-6.632-4.35.43-7.968 4.091-8.571 8.57-.646 4.865 2.455 9.473 7.15 10.765.13 0 .216.086.345.13.775.258 1.077.731.904 1.377-.172.646-.775.99-1.464.732-1.206-.43-2.455-.861-3.575-1.507-3.575-2.153-5.686-6.029-5.686-10.249h.086ZM59.788 47.251V30.586c.043-.646.517-1.12 1.077-1.12.56 0 1.034.43 1.12 1.12V63.27c0 .301 0 .56-.043.861-.086.56-.474.905-1.034.905-.603 0-.948-.302-1.077-.862-.043-.258-.043-.516-.043-.775V47.251Z"
            fill="#1B9AD2"
        />
        <path
            d="M22.83 48.112c0-3.79 2.025-6.545 5.083-7.536 2.972-.947 6.246.087 8.098 2.584.517.69.517 1.378 0 1.766-.56.43-1.12.258-1.723-.474-1.421-1.766-3.532-2.455-5.6-1.852-2.024.603-3.445 2.326-3.66 4.522-.13 1.55.387 2.928 1.507 4.048.172.172.344.301.474.43.56.517.646 1.12.215 1.594-.388.473-1.077.516-1.68.043-1.852-1.465-2.714-3.402-2.8-5.168l.086.043ZM12.493 14.352h.517c.646.043 1.12.516 1.12 1.076s-.43 1.034-1.12 1.12c-.387 0-.775.043-1.163 0-.603-.086-.99-.43-1.034-1.077 0-.603.431-1.033 1.077-1.12H12.493ZM17.831 16.59c-.301 0-.56-.042-.861-.128-.517-.173-.776-.56-.776-1.077 0-.517.345-.904.862-.99.43-.043.861-.087 1.292 0 .69.043 1.077.473 1.077 1.076 0 .603-.388 1.034-1.077 1.12h-.56.043ZM7.066 16.634c-.302-.043-.603-.043-.905-.13-.517-.171-.732-.559-.689-1.119 0-.517.345-.861.819-.947.43-.043.861-.086 1.292-.043.689 0 1.12.43 1.12 1.076 0 .646-.431 1.077-1.12 1.163h-.517ZM57.16 16.892c0-1.033.388-1.507.991-1.55.646-.043 1.077.388 1.163 1.163.13 1.033.948 1.636 1.938 1.42.819-.171 1.336-1.076 1.12-1.937-.172-.646-.732-1.033-1.507-1.12-2.326-.129-3.877-1.851-3.705-4.177.13-1.334.819-2.368 2.025-2.928.474-.215.646-.473.56-.99v-.775c.086-.603.56-1.034 1.077-1.034.56 0 1.12.43 1.033 1.034-.086 1.033.216 1.636 1.164 2.11.947.517 1.378 1.507 1.507 2.583.086.733-.258 1.335-.861 1.421-.69.13-1.163-.258-1.336-1.076-.172-.947-.646-1.421-1.378-1.55-.646-.086-1.292.301-1.594.861-.43.904.043 1.98 1.077 2.196.43.086.905.086 1.335.216a3.83 3.83 0 0 1 2.757 3.272c.172 1.464-.689 3.015-2.067 3.66-.474.216-.647.474-.604.99v.776c-.043.603-.387.99-.99 1.034-.603.043-1.206-.345-1.12-.948.13-1.162-.388-1.765-1.335-2.325-.905-.56-1.293-1.55-1.336-2.369l.086.043Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon1;
