import * as React from "react";

const LPIcon5 = (props) => (
    <svg
        width={270}
        height={233}
        viewBox="0 0 270 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            opacity={0.5}
            cx={140.933}
            cy={133.022}
            r={129.066}
            fill="#76CBE7"
        />
        <circle
            cx={129.066}
            cy={129.066}
            r={128.066}
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeDasharray="10 10"
            className="dashRotate"
        />
        <g clipPath="url(#a)">
            <path
                d="m175.889 158.038-4.256 1.125c-2.135.57-4.338.847-6.542.847-2.204 0-4.407-.291-6.542-.847l-4.256-1.125-4.917 8.597-4.311-2.514 4.917-8.583-3.099-3.152c-3.112-3.153-5.357-7.111-6.528-11.417l-1.157-4.291h-9.848v-5.028h9.848l1.157-4.291a25.75 25.75 0 0 1 6.528-11.416l3.099-3.153-4.917-8.583 4.311-2.514 4.931 8.597 4.256-1.125a25.258 25.258 0 0 1 6.542-.847c2.217 0 4.407.292 6.542.847l4.256 1.125 4.917-8.583 4.324 2.486-4.917 8.583 3.099 3.153a26.03 26.03 0 0 1 6.529 11.416l1.157 4.292h9.847v5.027h-9.847l-1.157 4.292a25.748 25.748 0 0 1-6.529 11.416l-3.099 3.152 4.917 8.583-4.311 2.514-4.93-8.597-.014.014Zm-10.798-37.79a13.621 13.621 0 0 0-9.765 4.083 13.907 13.907 0 0 0-4.049 9.847c0 3.722 1.432 7.222 4.049 9.847 2.603 2.625 6.074 4.083 9.765 4.083 3.691 0 7.162-1.444 9.765-4.083a13.907 13.907 0 0 0 4.049-9.847c0-3.722-1.432-7.222-4.049-9.847-2.603-2.625-6.074-4.083-9.765-4.083Z"
                fill="#10B2E5"
            />
            <path
                d="m113.47 207.439 2.479 4.333a3.449 3.449 0 0 0 4.738 1.278l4.752-2.764c1.652-.958 2.217-3.097 1.267-4.778l-2.479-4.319c2.988-3.027 4.972-6.666 6.005-10.513h4.958c1.914 0 3.457-1.57 3.457-3.486v-5.528c0-1.93-1.556-3.486-3.457-3.486h-4.958a23.732 23.732 0 0 0-6.005-10.513l2.479-4.319a3.53 3.53 0 0 0-1.267-4.778l-4.752-2.764a3.467 3.467 0 0 0-4.738 1.278l-2.479 4.333a23.516 23.516 0 0 0-12.024 0l-2.479-4.333a3.46 3.46 0 0 0-4.738-1.278l-4.751 2.764c-1.653.958-2.218 3.097-1.267 4.778l2.479 4.319c-2.989 3.027-4.972 6.666-6.005 10.513h-4.959c-1.914 0-3.457 1.57-3.457 3.486v5.528c0 1.93 1.557 3.486 3.457 3.486h4.959a23.732 23.732 0 0 0 6.005 10.513l-2.48 4.319a3.528 3.528 0 0 0 1.268 4.778l4.751 2.764a3.467 3.467 0 0 0 4.738-1.278l2.479-4.333a23.516 23.516 0 0 0 12.024 0Z"
                fill="#fff"
                stroke="#344154"
                strokeWidth={3}
                strokeMiterlimit={10}
            />
            <path
                d="m173.107 164.774 3.305 5.764a4.576 4.576 0 0 0 6.294 1.694l6.322-3.68c2.204-1.278 2.961-4.125 1.68-6.347l-3.291-5.75a31.6 31.6 0 0 0 8.002-13.986h6.597c2.548 0 4.614-2.083 4.614-4.652v-7.361c0-2.569-2.066-4.653-4.614-4.653h-6.597a31.526 31.526 0 0 0-8.002-13.985l3.291-5.75c1.268-2.222.524-5.069-1.68-6.347l-6.322-3.68a4.588 4.588 0 0 0-6.294 1.694l-3.305 5.764a31.254 31.254 0 0 0-16.004 0l-3.306-5.764a4.576 4.576 0 0 0-6.294-1.694l-6.322 3.68c-2.204 1.278-2.961 4.125-1.68 6.347l3.292 5.75a31.595 31.595 0 0 0-8.003 13.985h-6.597c-2.548 0-4.614 2.084-4.614 4.653v7.361c0 2.569 2.066 4.652 4.614 4.652h6.597a31.517 31.517 0 0 0 8.003 13.986l-3.292 5.75c-1.267 2.222-.524 5.069 1.68 6.347l6.322 3.68a4.588 4.588 0 0 0 6.294-1.694l3.306-5.764a31.254 31.254 0 0 0 16.004 0Z"
                stroke="#344154"
                strokeWidth={3}
                strokeMiterlimit={10}
            />
            <path
                d="m130.92 88.874 4.959-.388c1.9-.153 3.333-1.834 3.181-3.75l-.427-5.514c-.151-1.916-1.818-3.36-3.718-3.208l-4.931.389a23.724 23.724 0 0 0-6.831-10l2.134-4.514a3.494 3.494 0 0 0-1.639-4.652l-4.944-2.375c-1.722-.833-3.788-.097-4.614 1.653l-2.135 4.514a23.33 23.33 0 0 0-12.01.944l-2.81-4.111c-1.088-1.583-3.236-2-4.82-.903l-4.517 3.125a3.518 3.518 0 0 0-.896 4.861l2.81 4.125a23.956 23.956 0 0 0-5.179 10.944l-4.958.389c-1.9.152-3.333 1.833-3.181 3.75l.426 5.513c.152 1.917 1.819 3.361 3.72 3.208l4.93-.388a23.715 23.715 0 0 0 6.831 9.999l-2.135 4.514a3.495 3.495 0 0 0 1.64 4.652l4.944 2.375c1.722.834 3.787.097 4.614-1.653l2.135-4.513a23.33 23.33 0 0 0 12.01-.945l2.809 4.111c1.088 1.584 3.237 2 4.821.903l4.517-3.125a3.517 3.517 0 0 0 .895-4.861l-2.809-4.124a23.957 23.957 0 0 0 5.178-10.945Z"
                fill="#fff"
                stroke="#344154"
                strokeWidth={3}
                strokeMiterlimit={10}
            />
            <path
                d="M107.727 93.444c4.936 0 8.938-4.036 8.938-9.014s-4.002-9.013-8.938-9.013c-4.937 0-8.939 4.035-8.939 9.013s4.002 9.014 8.939 9.014ZM116.39 184.426c0-4.986-4.008-9.014-8.939-9.014-4.93 0-8.938 4.042-8.938 9.014 0 4.972 4.008 9.013 8.938 9.013 4.931 0 8.939-4.041 8.939-9.013Z"
                fill="#BBE5F3"
                stroke="#344154"
                strokeWidth={3}
                strokeMiterlimit={10}
            />
            <path
                d="M170.793 128.428a8.001 8.001 0 0 0-11.377 0c-3.14 3.167-3.14 8.306 0 11.472a8 8 0 0 0 11.377 0c3.14-3.166 3.14-8.305 0-11.472Z"
                stroke="#344154"
                strokeWidth={3}
                strokeMiterlimit={10}
            />
            <path
                d="M118.903 90.666c7.826-12.982-6.477-21.084-11.072-19.19M94.17 181.249c-2.06 17.301 13.138 19.521 19.566 15.565M151.814 122.143c-12.857 13.352-1.978 33.132 15.462 30.28"
                stroke="#344154"
                strokeLinecap="round"
                strokeDasharray="2 2"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(74.176 52.418)"
                    d="M0 0h134.506v163.187H0z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default LPIcon5;
