import * as React from "react";

const Icon8 = (props) => (
    <svg
        width={78}
        height={76}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M69.959 24.434H57.455c-.62 0-1.124-.503-1.124-1.12v-7.282c0-.617.505-1.12 1.124-1.12h4.642V7.479H13.582v7.44h4.565c.62 0 1.125.504 1.125 1.121v7.28c0 .618-.505 1.121-1.125 1.121H6.041C2.715 24.441 0 21.743 0 18.42V6.023C0 2.699 2.707 0 6.041 0h63.917C73.285 0 76 2.699 76 6.023v12.396c0 3.316-2.707 6.022-6.041 6.022v-.007Zm-11.38-2.242h11.38a3.792 3.792 0 0 0 3.793-3.78V6.021a3.792 3.792 0 0 0-3.793-3.78H6.04a3.791 3.791 0 0 0-3.793 3.78V18.42A3.791 3.791 0 0 0 6.041 22.2h10.982v-5.04h-4.565c-.62 0-1.124-.502-1.124-1.12V6.358c0-.617.504-1.12 1.124-1.12H63.22c.62 0 1.124.503 1.124 1.12v9.682c0 .618-.504 1.12-1.124 1.12H58.58v5.04-.008Z"
            fill="#1B9AD2"
        />
        <path
            d="M63.221 17.16h-5.766c-.62 0-1.124-.502-1.124-1.12v-4.52H19.27v4.52c0 .618-.504 1.12-1.124 1.12h-5.69c-.619 0-1.123-.502-1.123-1.12V6.358c0-.617.504-1.12 1.124-1.12H63.22c.62 0 1.124.503 1.124 1.12v9.682c0 .618-.504 1.12-1.124 1.12Zm-4.642-2.24h3.518V7.478H13.582v7.44h3.441V10.4c0-.618.505-1.121 1.124-1.121h39.308c.62 0 1.124.503 1.124 1.12v4.521Z"
            fill="#1B9AD2"
        />
        <path
            d="M57.747 69.375H17.962c-.627 0-1.138-.454-1.138-1.012V10.16c0-.558.511-1.012 1.138-1.012h39.785c.627 0 1.138.454 1.138 1.012v58.203c0 .558-.51 1.012-1.138 1.012ZM19.1 67.352h37.517v-56.18H19.1v56.18Z"
            fill="#1B9AD2"
        />
        <rect
            x={32.884}
            y={19.059}
            width={39.643}
            height={1.529}
            rx={0.765}
            transform="rotate(90 32.884 19.06)"
            fill="#1B9AD2"
        />
        <rect
            x={28.295}
            y={19.059}
            width={9.148}
            height={1.529}
            rx={0.765}
            transform="rotate(90 28.295 19.06)"
            fill="#1B9AD2"
        />
        <path fill="#1B9AD2" d="M38.237 56.415h8.412v8.386h-8.412z" />
        <path
            d="M50.238 22.108a3.581 3.581 0 0 1-3.589 3.575 3.581 3.581 0 0 1-3.588-3.575 3.581 3.581 0 0 1 3.588-3.574 3.581 3.581 0 0 1 3.589 3.575Z"
            stroke="#1B9AD2"
            strokeWidth={2}
        />
    </svg>
);

export default Icon8;
