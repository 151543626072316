import * as React from "react";

const Icon2 = ({ ...props }) => (
    <svg
        width={76}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M37.958 72.062H5.572c-2.89 0-4.818-1.383-5.446-3.897C0 67.705 0 67.202 0 66.7V5.363C0 2.053 2.095 0 5.488 0h64.856c1.425 0 2.723.21 3.855 1.173C75.454 2.263 76 3.687 76 5.363v61.336c0 2.598-1.34 4.483-3.603 5.111a9.036 9.036 0 0 1-2.137.252H37.958ZM2.053 14.077v52.371c0 2.513 1.634 3.603 3.561 3.603h65.023c1.927 0 3.394-1.257 3.352-3.436-.042-16.256 0-32.47 0-48.725v-3.813H2.053Zm71.894-2.136V4.734c0-1.215-.963-2.304-2.178-2.555-.713-.126-1.425-.126-2.137-.126H5.32c-1.634 0-3.059.964-3.142 2.346-.126 2.514 0 5.028 0 7.583h71.768v-.041Z"
            fill="#1B9AD2"
        />
        <path
            d="M52.581 53.04c.713 2.598.294 4.19-1.34 5.28a4.03 4.03 0 0 1-4.65-.084c-1.51-1.131-1.928-2.807-1.174-5.153h-6.829c.67 2.555.293 4.147-1.383 5.237-1.424.963-3.226.921-4.65-.126-1.508-1.131-1.885-2.765-1.131-5.195h-4.525c-1.55 0-2.723-1.173-2.933-2.723-.167-1.383.796-2.808 2.22-3.185.336-.083.671-.083 1.006-.083h5.447c-.545-2.011-1.09-3.98-1.634-5.95-1.173-4.357-2.388-8.714-3.52-13.071-.209-.754-.502-.964-1.298-.964-1.844.042-3.73 0-5.573 0h-.586c-.629-.084-1.047-.419-1.047-1.047 0-.67.46-1.006 1.13-1.006h6.62c1.886 0 1.97-.335 2.556 1.927 1.802 6.662 3.645 13.323 5.488 20.027h17.471l4.4-14.915H33.224c-.67 0-1.215-.294-1.215-1.048s.503-1.005 1.215-1.005h24.133c1.55 0 1.885.419 1.424 1.969-1.55 5.279-3.1 10.516-4.65 15.795-.336 1.131-.545 1.299-1.76 1.299H26.94c-.587 0-.922.419-.922.921 0 .545.293.922.88.964h29.956c.67.042 1.09.377 1.09 1.09 0 .628-.462.963-1.09.963H52.58v.084Zm-17.596 3.98c1.09 0 1.969-.879 1.969-1.926 0-1.09-.922-2.011-2.011-2.011-1.047 0-1.97.921-1.927 2.01 0 1.09.922 1.97 1.969 1.97v-.042Zm12.024-2.01c0 1.09.838 1.969 1.927 2.01 1.09 0 1.97-.795 2.011-1.885 0-1.13-.838-2.052-1.927-2.052-1.047 0-1.927.88-1.969 1.969l-.042-.042Z"
            fill="#1B9AD2"
        />
        <path
            d="M17.008 31.045h4.734c.712 0 1.173.21 1.299.964.084.628-.46 1.09-1.257 1.09h-9.51c-.67 0-1.173-.252-1.215-1.006 0-.67.377-1.006 1.298-1.006h4.735l-.084-.042ZM20.53 36.03h4.315c.67 0 1.13.252 1.13.965 0 .712-.46 1.047-1.088 1.047H16.13c-.67 0-1.131-.335-1.131-1.047 0-.67.46-1.006 1.131-1.006h4.4v.042ZM23.965 43.07h-3.687c-.796 0-1.34-.462-1.257-1.048.084-.712.545-.964 1.257-.964h7.458c.712 0 1.173.252 1.256.964.084.628-.46 1.047-1.256 1.09h-3.771v-.043ZM58.95 8.044h-9.72c-.922 0-1.55-.629-1.173-1.257.21-.377.796-.712 1.215-.712 1.466-.084 2.932-.042 4.399-.042h15.376c.628.083 1.005.46.921 1.089-.041.628-.419.922-1.047.922H58.95ZM17.053 4.064c1.676 0 2.975 1.34 2.933 3.016 0 1.634-1.382 3.017-3.016 3.017-1.592 0-3.017-1.425-3.017-3.017 0-1.676 1.383-3.016 3.059-3.016h.041Zm-.377 2.01c-.167.252-.586.63-.628 1.006-.042.545.419.964 1.006.922.544 0 .88-.377.88-.964 0-.628-.378-.921-1.258-.963ZM25.01 4.064c1.677 0 3.017 1.34 3.017 2.975 0 1.675-1.424 3.058-3.058 3.016-1.634 0-2.933-1.382-2.933-3.058s1.34-2.975 3.017-2.933h-.042ZM24.006 7.29c.252.168.587.628.964.712.586.084.963-.335.963-.922 0-.544-.293-.921-.88-.963-.628 0-.963.251-1.047 1.173ZM9.007 4.064c1.676 0 3.016 1.34 3.016 2.974 0 1.634-1.424 3.059-3.058 3.059S5.99 8.714 6.032 7.038c0-1.675 1.299-2.974 2.975-2.974Zm.335 2.01c-.88.043-1.257.336-1.257.964 0 .587.335.922.88.964.587 0 1.047-.335 1.005-.922 0-.419-.46-.754-.628-1.047v.042Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon2;
