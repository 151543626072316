import React from "react";
import "../BetaFeatures/BetaFeatures.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LaptopMobileMockup from "../BetaFeatures/assets/LaptopMobileMockup.png";
import BetaMobileMockup from "../BetaFeatures/assets/BetaMobile-Mockup.png";
import BetaDesktopMockup from "../BetaFeatures/assets/BetaDesktop-Mockup.png";
import search from "../BetaFeatures/assets/search-icon.svg";
import customer from "../BetaFeatures/assets/customer-vt.png";
import FPCustomer from "../BetaFeatures/assets/fp-customer.png";
import BetaCustomer from "../BetaFeatures/assets/beta-customer.png";
import MobileBetaCustomer from "../BetaFeatures/assets/mobile-beta-customer.png";
import DarkMode from "../BetaFeatures/assets/dark-mode.png";
import Feedback from "../BetaFeatures/assets/feedback.png";
import SettlementTotals from "../BetaFeatures/assets/settlements-totals.png";
import BatchOverview from "../BetaFeatures/assets/batch-overview.png";
import ExportOptions from "../BetaFeatures/assets/export-options.png";
import GatewaySwitch from "../BetaFeatures/assets/gateway-switch.png";
import IconCheck from "../Products/ProductPages/AccountUpdater/assets/icon-check";
import IconCheckWhite from "./assets/icon-check-white";

const BetaFeatures = () => {
    {
        return (
            <Grid>
                <Navbar />
                <Grid sx={{ pt: { xs: 2, md: 0 }, width: "100%" }}>
                    {/* Hero Section */}
                    {/* Desktop header */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ px: "2em", display: { xs: "none", md: "flex" } }}
                    >
                        <Grid item xs={6} lg={6} className="mockup-container">
                            <img
                                src={BetaDesktopMockup}
                                className="beta-desktop-mockup"
                                alt="Desktop mockup of iQ Pro+"
                            />
                            <img
                                src={BetaMobileMockup}
                                className="beta-mobile-mockup"
                                alt="Mobile mockup of iQ Pro+"
                            />
                        </Grid>
                        <Grid item lg></Grid>
                        <Grid item xs lg={5}>
                            <Typography variant="h1" sx={{ display: "none" }}>
                                iQ Pro+
                            </Typography>
                            <Typography
                                variant="h2"
                                fontWeight="500"
                                className="hero-title"
                            >
                                Accept Payments.
                            </Typography>
                            <Typography
                                variant="h2"
                                fontWeight="500"
                                className="hero-title-animation"
                            >
                                Quickly. Simply. Securely.
                            </Typography>
                            <Typography paragraph className="hero-blurb">
                                iQ Pro+ is expertly designed with the user in
                                mind. Learn more about how our tools can help
                                promote your business and simplify payments.
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Mobile header */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: "auto", md: "none" } }}
                    >
                        <Grid item md={6}></Grid>
                        <Grid item md={6} lg sx={{ px: 3 }}>
                            <Typography
                                variant="h1"
                                fontWeight="500"
                                color="secondary.main"
                            >
                                iQ Pro+
                            </Typography>
                            <Typography variant="h2">
                                Accept Payments. Quickly. Simply. Securely.
                            </Typography>
                            <Typography paragraph className="hero-blurb">
                                iQ Pro+ is expertly designed with the user in
                                mind. Learn more about how our tools can help
                                promote your business and simplify payments.
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={7} className="mockup-container">
                            <img
                                src={LaptopMobileMockup}
                                className="mockup-container"
                            />
                        </Grid>
                    </Grid>

                    {/* Content Start */}
                    <Grid container direction="column" justifyContent="center">
                        <Grid item sm>
                            {/* FEATURES */}
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                rowSpacing={5}
                            >
                                <Grid className="look-and-feel">
                                    <Grid
                                        item
                                        sx={{
                                            mt: { xs: 10, md: 28 },
                                            mb: { xs: 5, md: 10 }
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            align="center"
                                            color="white"
                                        >
                                            Features
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        sx={{ mb: { xs: 2, lg: 10 } }}
                                    >
                                        <List>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                direction={{
                                                    xs: "column",
                                                    md: "row"
                                                }}
                                            >
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={8} md={5}>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Virtual
                                                                    Terminal
                                                                </b>{" "}
                                                                - Process
                                                                Transactions
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Transaction
                                                                    Reporting
                                                                </b>{" "}
                                                                - Reconcile
                                                                Transactions
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Settlement
                                                                    Reporting
                                                                </b>{" "}
                                                                - Reconcile
                                                                Transactions
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>Invoices</b>
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Software
                                                                    Partner
                                                                    Integrations
                                                                </b>
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={10} md={5}>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>Customers</b>{" "}
                                                                - Securely Store
                                                                Customer Data
                                                                including
                                                                Payment
                                                                Information
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Magtek Card
                                                                    Swiper
                                                                </b>
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>Fiserv</b>
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                    <Grid>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <IconCheckWhite className="listIcon" />
                                                            </ListItemIcon>
                                                            <Typography className="features-list">
                                                                <b>
                                                                    Mobile App
                                                                </b>
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={1}></Grid>
                                            </Grid>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                direction="row"
                                rowSpacing={5}
                                sx={{ pt: 5 }}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        align="center"
                                        sx={{ pb: 3 }}
                                    >
                                        2024 Roadmap Includes
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="center"
                                    sx={{ mb: { xs: 2, lg: 10 } }}
                                >
                                    <List>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Grid>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <IconCheck className="listIcon" />
                                                        </ListItemIcon>
                                                        <Typography className="features-list-tbr">
                                                            Recurring Billing
                                                        </Typography>
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                            <Grid item></Grid>
                                        </Grid>
                                    </List>
                                </Grid>
                            </Grid>
                            {/* Look & Feel */}
                            <Grid
                                container
                                // className="look-and-feel"
                                className="account-access"
                            >
                                <Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        sx={{ mt: 5 }}
                                    >
                                        {/* Mobile */}
                                        <Grid
                                            item
                                            xs={9}
                                            sx={{
                                                mb: 5,
                                                display: {
                                                    xs: "flex",
                                                    lg: "none"
                                                }
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="500"
                                                        color="white !important"
                                                    >
                                                        User-Focused Design
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        fontWeight="400"
                                                        color="white"
                                                    >
                                                        iQ Pro+ features a
                                                        polished look while
                                                        maintaining the
                                                        workflows you’ve come to
                                                        expect from your payment
                                                        platform. A streamlined
                                                        design creates a quicker
                                                        and more intuitive
                                                        experience, giving you
                                                        more time to focus on
                                                        your business.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={10} lg={5}>
                                            <img
                                                src={FPCustomer}
                                                className="fp-customer"
                                                alt="iQ Pro interface with arrow pointing to new iQ Pro+ interface"
                                            />
                                        </Grid>
                                        {/* DESKTOP */}
                                        <Grid
                                            item
                                            lg={4}
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    lg: "flex"
                                                }
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="500"
                                                        color="white !important"
                                                    >
                                                        User-Focused Design
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        fontWeight="400"
                                                        color="white"
                                                    >
                                                        iQ Pro+ features a
                                                        polished look while
                                                        maintaining the
                                                        workflows you’ve come to
                                                        expect from your payment
                                                        platform. A streamlined
                                                        design creates a quicker
                                                        and more intuitive
                                                        experience, giving you
                                                        more time to focus on
                                                        your business.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={1}></Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            lg={8}
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    md: "block"
                                                }
                                            }}
                                        >
                                            <img
                                                src={BetaCustomer}
                                                className="beta-customers"
                                                alt="iQ Pro Bet customer interface"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            lg={8}
                                            sx={{
                                                display: {
                                                    xs: "block",
                                                    md: "none"
                                                }
                                            }}
                                        >
                                            <img
                                                src={MobileBetaCustomer}
                                                className="beta-customers"
                                                alt="iQ Pro Bet customer interface"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        sx={{ mt: 10 }}
                                    >
                                        <Grid item xs={1} lg={0.5}></Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            lg={4.5}
                                            sx={{ mb: 5 }}
                                        >
                                            <Grid container>
                                                <Grid item>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="500"
                                                        color="white !important"
                                                    >
                                                        Dark Mode
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        fontWeight="400"
                                                        color="white"
                                                    >
                                                        iQ Pro+ employs your
                                                        saved device preferences
                                                        and automatically
                                                        detects the use of light
                                                        or dark mode and carries
                                                        that theme throughout
                                                        the application, making
                                                        it even easier on the
                                                        eyes.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs lg={1}></Grid>
                                        <Grid item xs={9} lg={4}>
                                            <img
                                                src={DarkMode}
                                                className="dark-mode"
                                                alt="Example of iQ Pro+ in dark mode"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Transaction Workflow */}
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                direction="row"
                                rowSpacing={5}
                                sx={{ pt: 5 }}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        align="center"
                                        sx={{ pb: 3 }}
                                    >
                                        Transaction Workflow
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container justifyContent="center">
                                        <Grid item lg></Grid>
                                        <Grid item xs={10} lg={9.5}>
                                            <Grid
                                                container
                                                direction="row"
                                                rowSpacing={3}
                                                alignItems="center"
                                                justifyContent="center"
                                                className="ob-glass-gradient"
                                            >
                                                <Grid item xs={11} lg={12}>
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight={400}
                                                    >
                                                        Saving your customers in
                                                        the vault allows for
                                                        secure use of saved
                                                        payment information. iQ
                                                        Pro+ takes that one step
                                                        further with a
                                                        streamlined payment
                                                        process for your
                                                        customers. Search,
                                                        locate and select your
                                                        saved customer directly
                                                        from the Virtual
                                                        Terminal payment screen.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs sm={3} lg={1.5}>
                                                    <img
                                                        src={search}
                                                        className="search"
                                                        alt="Magnifying glass icon"
                                                    />
                                                </Grid>
                                                <Grid item xs={8} sm lg>
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight={400}
                                                    >
                                                        Search, locate, and
                                                        select your saved
                                                        customer directly from
                                                        the Virtual Terminal
                                                        payment screen.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={11} md={8} lg={7}>
                                    <img
                                        src={customer}
                                        className="customer-vt"
                                        alt="Example of searching for a saved customer in the Virtual Terminal"
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={11} lg={8}>
                                            <Typography
                                                variant="h4"
                                                fontWeight={300}
                                                align="center"
                                                className="body-blue"
                                            >
                                                <strong>
                                                    All your payment methods are
                                                    easily found on one screen.{" "}
                                                </strong>
                                                No need to navigate between
                                                pages to process a transaction.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Effective Reporting Options */}
                            <Grid
                                container
                                direction="row"
                                sx={{ mt: 10, mb: 4, px: 5 }}
                                className="reporting-bg"
                            >
                                <Grid item sm={1}></Grid>
                                <Grid item sm>
                                    <Grid sx={{ my: 5 }}>
                                        <Typography
                                            variant="h3"
                                            fontWeight="500"
                                            sx={{ pb: 3 }}
                                        >
                                            Reporting Enhancements
                                        </Typography>
                                        <Typography variant="h4">
                                            We&apos;ve listened to real life
                                            businesses and have built the
                                            reporting workflows, functionality,
                                            and formats that you need,
                                            including:
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={5}
                                    >
                                        <Grid item lg={6}>
                                            <Grid item sx={{ mb: 2 }}>
                                                <img
                                                    src={ExportOptions}
                                                    className="reporting-examples"
                                                    alt="Example of CSV and PDF options for exporting reports"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    align="center"
                                                    variant="h5"
                                                    fontWeight="400"
                                                >
                                                    PDF and CSV Export options
                                                </Typography>
                                            </Grid>
                                            <Grid item></Grid>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <Grid item sx={{ mb: 2 }}>
                                                <img
                                                    src={SettlementTotals}
                                                    className="reporting-examples"
                                                    alt="Example of settlement batch totals being automatically calculated in final row of settlement list"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    align="center"
                                                    variant="h5"
                                                    fontWeight="400"
                                                >
                                                    Auto-calculating running
                                                    totals for quick
                                                    reconciliation
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} sx={{ mb: 5 }}>
                                            <Grid item sx={{ mb: 2 }}>
                                                <img
                                                    src={BatchOverview}
                                                    className="reporting-examples"
                                                    alt="Example of improved settlement details screen"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    align="center"
                                                    variant="h5"
                                                    fontWeight="400"
                                                >
                                                    Improved settlement
                                                    reporting for fewer clicks
                                                    and less load time
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={1}></Grid>
                            </Grid>
                            {/* Streamlined Account Access */}
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                direction="column"
                                rowSpacing={5}
                                className="account-access"
                                sx={{
                                    pb: 10,
                                    px: 2
                                }}
                            >
                                <Grid item>
                                    <Typography
                                        variant="h3"
                                        color="white"
                                        align="center"
                                        fontWeight="400"
                                    >
                                        Streamlined Account Access
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            lg={3}
                                            className="mm-glass-gradient"
                                        >
                                            <Typography
                                                fontWeight={300}
                                                sx={{
                                                    fontSize:
                                                        "1.2em !important",
                                                    color: {
                                                        xs: "white",
                                                        lg: "black"
                                                    }
                                                }}
                                            >
                                                iQ Pro+ provides functionality
                                                and flexibility for merchants
                                                who manage multiple locations,
                                                businesses or revenue sources
                                                through a{"   "}
                                                <strong>single sign-on</strong>.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            lg={4}
                                            className="ob-sign-in"
                                        ></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ width: "75%" }}>
                                    <Typography
                                        variant="h5"
                                        fontWeight={500}
                                        color="white"
                                    >
                                        Users can choose how they want to handle
                                        multiple locations. iQ Pro+ supports
                                        housing all merchant accounts through a
                                        single gateway environment or separately
                                        with a new quick-toggle action.
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: "75%" }}>
                                    <Typography
                                        variant="h5"
                                        fontWeight={300}
                                        color="white"
                                    >
                                        The new quick-toggle action allows each
                                        merchant account to maintain individual
                                        settings, preferences, reports, and more
                                        without needing to maintain more than
                                        one login.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={8}
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "80%",
                                            md: "65%",
                                            lg: "30%"
                                        }
                                    }}
                                >
                                    <img
                                        src={GatewaySwitch}
                                        className="gateway-switch"
                                        alt="Example of the improved ability to switch between gateways under a single sign on"
                                    />
                                </Grid>
                            </Grid>
                            {/* Customer-Driven Development */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item lg={1.5}></Grid>
                                <Grid item xs={9.5} lg>
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                variant="h3"
                                                fontWeight="500"
                                                sx={{ my: 3 }}
                                            >
                                                Customer-Driven Development
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h4">
                                                iQ Pro+ is designed with real
                                                merchants in mind. Utilizing
                                                authentic customer feedback and
                                                comprehensive user testing, iQ
                                                Pro+ is the latest in payment
                                                processing.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            sx={{ my: 5 }}
                                        >
                                            <Grid item xs={9} lg={9}>
                                                <Typography
                                                    className="body-blue"
                                                    variant="h5"
                                                    fontWeight="500"
                                                    sx={{
                                                        my: 3,
                                                        fontSize:
                                                            "2em !important"
                                                    }}
                                                >
                                                    Users will have the unique
                                                    opportunity to continue
                                                    driving innovation.
                                                </Typography>
                                                <Typography variant="h5">
                                                    Through an integrated
                                                    feedback collector, users
                                                    can continuously submit
                                                    comments, observations, and
                                                    requests on-demand,{" "}
                                                    <strong>
                                                        giving you back valuable
                                                        time
                                                    </strong>
                                                    .
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <img
                                                    src={Feedback}
                                                    className="feedback"
                                                    alt="Example of iQ Pro+ feedback collector form"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={1}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </Grid>
        );
    }
};

export default BetaFeatures;
