import * as React from "react";

const Icon2 = (props) => (
    <svg
        width={79}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M33.89 62.752A28.926 28.926 0 0 1 5.613 38.517C3.03 23.235 13.567 8.37 28.85 5.705c15.492-2.707 30.151 7.245 33.233 22.652 1.166 5.913.5 11.617-1.874 17.156-.417.916-1.041 1.207-1.791.874-.75-.333-1-1-.625-1.915 2.666-6.246 3.124-12.7.75-19.072C54.754 15.157 47.3 9.16 36.389 8.036c-13.327-1.374-25.113 7.412-27.986 20.53C5.28 42.722 14.858 57.046 29.1 59.586c5.164.916 10.161.416 14.992-1.624l.625-.25c.75-.25 1.374 0 1.665.708.292.708.042 1.416-.75 1.707-2.081.708-4.205 1.458-6.37 1.958-1.75.416-3.582.458-5.415.666h.042Z"
            fill="#1B9AD2"
        />
        <path
            d="m68.747 61.67 8.704 8.702c1.125 1.124 1.166 1.624 0 2.79l-4.456 4.456c-.958.957-1.54.957-2.499 0l-8.12-8.12c-.209-.209-.375-.458-.625-.833-.25.458-.375.708-.5.957-1.29 2.54-2.582 5.08-3.831 7.662-.292.583-.666 1.083-1.416 1.041-.791-.041-1.083-.583-1.291-1.249-2.957-9.744-5.955-19.53-8.912-29.273-.209-.624-.25-1.207.25-1.749.5-.5 1.082-.333 1.666-.166 9.661 2.956 19.323 5.87 28.943 8.827.125 0 .25.084.375.125.624.167 1.083.5 1.166 1.208.083.708-.333 1.082-.916 1.374-2.416 1.208-4.873 2.415-7.288 3.623l-1.333.666.083-.042ZM48.924 48.926l7.413 24.402s.208-.167.208-.209c1.125-2.248 2.25-4.455 3.373-6.704.75-1.457 1.541-1.582 2.708-.458l8.537 8.536c.208.209.416.375.458.417l3.373-3.331c-2.998-2.957-6.038-6.038-9.079-9.078-.999-1-.874-1.874.375-2.498l6.247-3.123c.25-.125.5-.25.875-.5l-24.488-7.454ZM33.306 0C50.006 0 64 11.41 67.164 27.066c1.374 6.787.75 13.408-1.874 19.82-.416 1-1 1.333-1.79 1-.75-.291-1-1-.625-1.957 1.707-4.164 2.457-8.453 2.373-12.95-.25-12.659-8.828-24.526-20.864-28.523C32.557.54 21.896 2.873 12.526 11.076c-.375.333-.958.666-1.375.625-.375 0-.958-.541-1.041-.916-.083-.417.125-1.124.5-1.374 2.04-1.624 4.081-3.373 6.288-4.706A32.156 32.156 0 0 1 33.348.042L33.306 0ZM33.931 67.957C17.856 67.832 3.905 56.506.74 40.766-1.26 30.98.823 21.944 6.612 13.825c.625-.875 1.5-1.041 2.124-.458.541.5.583 1.207 0 1.957-1.624 2.207-2.999 4.538-3.915 7.12C.615 34.062 2.322 44.764 10.193 54.3c5.206 6.288 12.12 9.785 20.198 10.743 5.081.625 10.037-.083 14.785-1.915l.999-.375c.791-.292 1.458 0 1.75.708.29.708 0 1.457-.792 1.707-2.457.791-4.914 1.624-7.413 2.165-1.874.417-3.873.458-5.83.667l.041-.042Z"
            fill="#1B9AD2"
        />
        <path
            d="M32.64 48.178c-1.79-.375-3.29-1.166-4.497-2.499-1.25-1.416-1.958-3.04-2-4.955 0-1 .417-1.54 1.25-1.582.833 0 1.29.5 1.374 1.457.167 2.79 2.207 4.914 4.914 5.039s4.956-1.708 5.456-4.373c.541-3.123-1.833-5.913-5.164-6.038-3.457-.124-5.955-1.79-7.246-4.996-1.75-4.206.666-9.036 5.08-10.327.292-.083.583-.167.917-.25v-3.748c0-.999.458-1.54 1.29-1.54.833 0 1.333.541 1.333 1.582v3.706c.333.083.625.167.916.25 3.207.958 5.54 3.956 5.58 7.245 0 1-.416 1.541-1.249 1.583-.832 0-1.29-.458-1.374-1.458-.166-2.998-2.457-5.122-5.372-5.08-2.79.083-5.04 2.374-5.04 5.164 0 2.914 2.208 5.163 5.206 5.246 4.165.125 7.33 2.957 7.83 6.996.458 3.789-2.082 7.37-6.039 8.453-.125 0-.25.083-.458.125v3.664c0 1.124-.458 1.666-1.333 1.666-.832 0-1.29-.542-1.29-1.624v-3.706h-.084Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon2;
