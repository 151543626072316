import React from "react";
import styled from "@emotion/styled";
import "../VirtualTerminal/VirtualTerminal.scss";
import Navbar from "../../../NavMenu/Navbar";
import Footer from "../../../Components/Footer/Footer";
import ChipNav from "../../../Components/ChipNav/ChipNav";

import { FadeInSection } from "../../../Components/FadeInSection/FadeInSection";
import { ProductsTitle } from "../../Products";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import vtMockup from "../VirtualTerminal/assets/vt-mockup.png";
import Icon1 from "./assets/icon-1";
import Icon2 from "./assets/icon-2";
import Icon3 from "./assets/icon-3";
import Icon4 from "./assets/icon-4";
import Icon5 from "./assets/icon-5";
import Icon6 from "./assets/icon-6";
import Icon7 from "./assets/icon-7";
import Icon8 from "./assets/icon-8";
import Icon9 from "./assets/icon-9";
import Icon10 from "./assets/icon-10";

export const ProductsPageTitle = styled(ProductsTitle)`
    line-height: 1.9;
    font-weight: 400;
`;

export const BannerTitle = styled(Typography)`
    font-weight: 300;
`;

export const IconContainer = styled(Grid)`
    padding: 30px;
    height: 70%;
`;

export const GridDesc = styled(Typography)`
    font-weight: 500;
`;

export default function VirtualTerminal() {
    return (
        <>
            <Navbar />
            {/* DESKTOP BANNER*/}
            <FadeInSection className="fade-in-from-right">
                <Grid
                    item
                    className="vt-banner"
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item md={6} lg={5}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ px: { xs: 2, lg: 4 } }}
                            >
                                <FadeInSection className="fade-in-from-left">
                                    <BannerTitle
                                        variant="h1"
                                        className="features-item-h1"
                                        align="left"
                                    >
                                        <strong className="bold-blue">
                                            Virtual Terminal
                                        </strong>{" "}
                                        - process card and ACH transactions
                                        using a computer or mobile device
                                    </BannerTitle>
                                </FadeInSection>
                            </Grid>
                        </Grid>
                        <Grid item md lg></Grid>
                    </Grid>
                </Grid>
            </FadeInSection>
            {/* END DESKTOP BANNER */}
            {/* MOBILE TITLE*/}
            <FadeInSection className="fade-in">
                <BannerTitle
                    sx={{ p: 2, display: { xs: "block", md: "none" } }}
                    variant="h1"
                    className="features-item-h1"
                >
                    <strong className="bold-blue">Virtual Terminal</strong> -
                    process card and ACH transactions using a computer or mobile
                    device
                </BannerTitle>
            </FadeInSection>
            {/* END MOBILE TITLE */}
            {/* CONTENT */}
            <Grid
                container
                wrap="wrap"
                direction={{ xs: "column", md: "row" }}
                justifyContent={{ xs: "center", md: "flex-start" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                rowSpacing={{ xs: 0, md: 7 }}
                sx={{ pl: { xs: 0, md: 18, xl: 25 }, my: { xs: 0, md: 5 } }}
            >
                <Grid item sm={10} lg={5}>
                    <FadeInSection className="fade-in-from-left">
                        <ProductsPageTitle
                            paragraph="true"
                            className="products-title"
                            sx={{
                                p: { xs: 3, md: 0 },
                                fontSize: "1.25rem !important"
                            }}
                        >
                            iQ Pro’s Virtual Terminal has everything you need to
                            process payments, and nothing you don’t. The Virtual
                            Terminal is fully customizable, and allows you to
                            streamline the payment process.
                        </ProductsPageTitle>
                    </FadeInSection>
                </Grid>
                <FadeInSection className="fade-in-from-right">
                    <Grid item xs={12} md lg>
                        <img
                            src={vtMockup}
                            className="vt-mockup"
                            alt="Virtual Terminal Mockup"
                        ></img>
                    </Grid>
                </FadeInSection>
                <Grid container>
                    <FadeInSection className="fade-in-from-bottom">
                        <Grid item md={12} sx={{ mt: 3 }}>
                            <Typography
                                variant="h2"
                                fontWeight={450}
                                className="products-title"
                                sx={{ display: { xs: "none", md: "block" } }}
                            >
                                Virtual Terminal
                            </Typography>
                        </Grid>
                    </FadeInSection>
                </Grid>
            </Grid>

            {/* END CONTENT */}
            {/* ICON LIST */}
            <Grid
                container
                justifyContent="center"
                sx={{ mb: { xs: 2, lg: 10 }, pl: { xs: 2, md: 5, lg: 0 } }}
            >
                <List>
                    <Grid container direction="row">
                        <Grid item lg={1}></Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon1 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Email receipts to customers
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon2 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Save the customer to the vault
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon3 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Add a unique PO number or Order ID
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon4 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Process Sale, Authorization, Blind
                                            Credit, Refund, and Verification
                                            transactions
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon5 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Create custom fields{" "}
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={5}>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon6 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Accept Card or ACH payment methods
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon7 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Swipe a card using a peripheral
                                            swiper
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon8 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Utilize a compatible physical
                                            terminal to accept card present
                                            transactions
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon9 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Access from anywhere on any mobile
                                            device
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                            <FadeInSection className="fade-in-from-left">
                                <Grid>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Icon10 className="listIcon" />
                                        </ListItemIcon>
                                        <Typography className="icon-list-text">
                                            Add advanced line items
                                        </Typography>
                                    </ListItem>
                                </Grid>
                            </FadeInSection>
                        </Grid>
                        <Grid item lg={1}></Grid>
                    </Grid>
                </List>
            </Grid>
            <ChipNav />
            <Footer />
        </>
    );
}
