import * as React from "react";

const Icon6 = (props) => (
    <svg
        width={75}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g>
            <path
                d="M56.195 57H.825A.825.825 0 0 1 0 56.173V.827C0 .369.368 0 .824 0h47.572c.456 0 .824.37.824.827V8.64h6.975c.456 0 .824.37.824.827v46.706a.825.825 0 0 1-.824.827ZM1.649 55.346H55.37V10.294h-6.974a.825.825 0 0 1-.825-.827V1.654H1.649v53.692Z"
                fill="#1B9AD2"
            />
            <path
                d="M56.195 10.294h-7.8a.825.825 0 0 1-.824-.827V.827A.824.824 0 0 1 49.005.27l7.8 8.64a.826.826 0 0 1-.61 1.384ZM49.22 8.64h5.111L49.22 2.972V8.64ZM17.874 17.423c-5.551 0-11.526-1.163-11.526-3.716 0-.033 0-.1.011-.132.187-2.823 7.327-3.584 11.52-3.584 4.194 0 11.295.755 11.52 3.59 0 .038.012.098.012.131 0 2.553-5.975 3.717-11.526 3.717l-.011-.006Zm-9.877-3.71c.143.573 3.347 2.056 9.877 2.056 6.53 0 9.718-1.472 9.877-2.057-.11-.579-3.309-2.073-9.877-2.073-6.568 0-9.767 1.5-9.877 2.068v.005Z"
                fill="#1B9AD2"
            />
            <path
                d="M17.874 42.83c-5.551 0-11.526-1.163-11.526-3.716V13.707a.825.825 0 1 1 1.649 0c.11.568 3.309 2.062 9.877 2.062 6.568 0 9.767-1.494 9.877-2.068v-.06a.82.82 0 0 1 .824-.767c.44 0 .825.375.825.827v25.407c0 2.553-5.975 3.717-11.526 3.717v.005ZM7.992 15.752v23.362c.11.568 3.308 2.062 9.877 2.062 6.568 0 9.772-1.5 9.877-2.068V15.753c-2.16 1.14-6.118 1.676-9.877 1.676-3.76 0-7.717-.535-9.877-1.676Z"
                fill="#1B9AD2"
            />
            <path
                d="M17.874 26.107c-5.551 0-11.526-1.163-11.526-3.716a.825.825 0 1 1 1.649 0c.11.568 3.309 2.062 9.877 2.062 6.568 0 9.773-1.5 9.877-2.068a.825.825 0 1 1 1.649 0c0 2.553-5.975 3.717-11.526 3.717v.005ZM17.874 35c-5.551 0-11.526-1.163-11.526-3.716a.825.825 0 1 1 1.649 0c.11.568 3.309 2.062 9.877 2.062 6.568 0 9.773-1.5 9.877-2.067a.825.825 0 1 1 1.649 0c0 2.553-5.975 3.716-11.526 3.716v.006ZM18.11 51.889a3.219 3.219 0 0 1-3.21-3.22c0-1.775 1.44-3.22 3.21-3.22s3.21 1.445 3.21 3.22c0 1.775-1.44 3.22-3.21 3.22Zm0-4.791a1.566 1.566 0 0 0 0 3.131 1.566 1.566 0 0 0 0-3.132Z"
                fill="#1B9AD2"
            />
            <path
                d="M18.044 47.098a.827.827 0 0 1-.824-.822l-.033-4.268c0-.457.363-.832.819-.832h.006c.45 0 .818.364.824.821l.033 4.268a.825.825 0 0 1-.82.833h-.005Z"
                fill="#1B9AD2"
            />
            <path d="M58.157 18.476h-4.512v26.35h4.512v-26.35Z" fill="#fff" />
            <path
                d="M51.732 36.313h-.033A.786.786 0 0 1 51 35.8l-1.638-4.477a.78.78 0 1 1 1.462-.54l.995 2.718 2.423-5.16a.776.776 0 0 1 1.04-.376c.39.182.554.65.373 1.042l-3.22 6.854a.782.782 0 0 1-.71.452h.006Z"
                fill="#1B9AD2"
            />
            <path
                d="M52.034 40.514c-2.666 0-5.117-1.235-6.722-3.39a8.357 8.357 0 0 1-1.676-5.035c0-4.648 3.765-8.424 8.398-8.424 1.583 0 3.128.452 4.474 1.306a8.387 8.387 0 0 1 3.925 7.118c0 4.648-3.765 8.425-8.399 8.425Zm0-15.284c-3.77 0-6.837 3.077-6.837 6.86 0 1.483.472 2.9 1.363 4.096 1.308 1.753 3.303 2.762 5.469 2.762 3.77 0 6.837-3.076 6.837-6.859a6.83 6.83 0 0 0-3.193-5.794 6.781 6.781 0 0 0-3.639-1.065Z"
                fill="#1B9AD2"
            />
            <path
                d="M51.55 48.89a.774.774 0 0 1-.33-.072l-.066-.033c-1.33-.673-13.053-6.81-13.053-14.656V17.91c0-.43.351-.784.78-.784h25.333c.428 0 .78.353.78.783V34.13c0 8.111-12.57 14.413-13.103 14.678a.762.762 0 0 1-.346.082h.005ZM39.662 18.69V34.13c0 6.473 9.937 12.059 11.883 13.095 1.99-1.053 11.888-6.638 11.888-13.095V18.691H39.662Z"
                fill="#1B9AD2"
            />
        </g>
    </svg>
);

export default Icon6;
