import * as React from "react";

const Icon1 = ({ ...props }) => (
    <svg
        width={76}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.38 28.326c0-2.752-.087-5.374 0-7.995.306-8.213 4.064-14.373 11.362-18.13C29.978-4.046 44.88 3.643 47.021 17.229c.175 1.224.262 2.447.262 3.67v7.427h2.229c2.316 0 4.02 1.704 4.02 4.063v38.139c0 2.796-1.617 4.412-4.413 4.412H4.239C1.704 74.94 0 73.236 0 70.702V32.476c0-2.534 1.66-4.194 4.239-4.194h2.054l.087.044Zm20.452 44.517H49.25c1.66 0 2.316-.655 2.316-2.315V32.782c0-1.747-.612-2.36-2.404-2.36H4.414c-1.53 0-2.185.7-2.185 2.185v38.008c0 1.573.655 2.228 2.185 2.228h22.418Zm12.76-44.56v-5.899c0-1.354 0-2.708-.218-4.019-1.312-7.208-7.998-11.664-15.164-10.223-5.244 1.049-9.527 5.767-9.877 11.14-.174 2.928-.087 5.855-.13 8.782 0 .087.043.13.087.218h25.302Zm5.681 0v-4.806c0-1.966.087-3.932-.219-5.855C43.395 6.526 32.076-.42 21.413 2.944 13.765 5.346 8.565 12.38 8.565 20.419v7.164c0 .219 0 .437.044.655h3.583c0-2.49-.087-4.892 0-7.295.044-1.53.175-3.146.612-4.587 2.098-7.34 9.44-11.752 17-10.354 6.38 1.18 11.405 6.771 11.798 13.28.175 2.71.088 5.418.131 8.083v.917h3.584-.044Z"
            fill="#1B9AD2"
        />
        <path
            d="M23.074 56.155V53.01c0-.306-.175-.7-.437-.874-2.185-1.879-3.059-4.718-2.185-7.383.874-2.621 3.278-4.456 6.118-4.631 2.71-.175 5.462 1.616 6.511 4.194 1.093 2.709.35 5.767-1.922 7.689-.394.35-.525.655-.525 1.18v6.203a3.72 3.72 0 0 1-3.146 3.67c-1.923.262-3.627-.7-4.195-2.49-.175-.481-.219-1.005-.219-1.486v-2.927Zm2.098-.524v3.495c0 1.136.743 1.922 1.748 1.922.961 0 1.66-.786 1.66-1.922v-6.728c0-.786.219-1.267.874-1.704 1.617-1.005 2.404-3.058 1.967-4.936-.48-2.054-2.273-3.54-4.327-3.583-2.14-.043-4.02 1.267-4.632 3.277-.611 2.053.175 4.15 2.01 5.286.525.35.787.743.743 1.354v3.452l-.043.087Z"
            fill="#1B9AD2"
        />
    </svg>
);

export default Icon1;
