import * as React from "react";

const LPIcon1 = (props) => (
    <svg
        width={270}
        height={233}
        viewBox="0 0 270 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle
            opacity={0.5}
            cx={140.933}
            cy={133.023}
            r={129.066}
            fill="#76CBE7"
        />
        <circle
            cx={129.066}
            cy={129.066}
            r={128.066}
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeDasharray="10 10"
            className="dashRotate"
        />
        <path d="M216.348 210.247H78.316V89.06h146.889v112.33" fill="#fff" />
        <path
            d="M216.348 210.247H78.316V89.06h146.889v112.33"
            stroke="#344154"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M193.857 190.859H55.825V69.671h146.889v112.331" fill="#fff" />
        <path
            d="M193.857 190.859H55.825V69.671h146.889v112.331M55.825 88.036h146.889"
            stroke="#344154"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fill="#fff"
            stroke="#000"
            strokeDasharray="5 5"
            d="M78.816 108.173H183.29v68.799H78.816z"
        />
        <path
            d="m131.539 114.725-28.682 12.748v19.121c0 17.527 12.11 34.099 28.682 38.242 16.571-4.143 28.681-20.715 28.681-38.242v-19.121l-28.681-12.748Zm12.747 47.165c0 1.913-1.912 3.825-4.143 3.825h-17.528c-1.912 0-3.824-1.912-3.824-4.143v-11.154c0-1.912 1.912-3.824 3.824-3.824v-7.967c0-4.462 4.462-7.967 8.924-7.967 4.461 0 8.923 3.505 8.923 7.967v7.967h-4.143v-7.967c0-2.55-2.231-4.143-4.78-4.143-2.55 0-4.781 1.593-4.781 4.143v7.967h13.704c1.912 0 3.824 1.912 3.824 4.143v11.153Z"
            fill="#3A859D"
        />
        <path
            stroke="#344154"
            strokeWidth={2}
            strokeDasharray="4 4"
            d="M35.885 95.265v113.23M37.988 207.495H69.01"
        />
        <path
            d="M226.135 206.944 202 182.809l15.108-15.123-51.31-13.743 13.758 51.31 15.108-15.123 24.135 24.135 7.336-7.321Z"
            fill="#fff"
            stroke="#344154"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M201.938 68.896H55.049v18.365h146.889V68.896Z"
            fill="#3A859D"
        />
    </svg>
);

export default LPIcon1;
