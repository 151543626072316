import * as React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import mixpanel from "mixpanel-browser";

const DemoButton = styled(Button)`
    background-color: #1b9ad2;
    color: white;
    height: 4rem;
    width: 30rem;
    border-radius: 2em;
    transition: 0.3s ease-in-out;
    will-change: transform;
    @media (max-width: 600px) {
        width: 17rem;
    }
    &:hover {
        transform: scale(1.03);
        transition: 0.3s ease-in-out;
        background-color: #1b9ad2;
    }
`;

export default function RequestDemoButton() {
    return (
        <DemoButton
            href="/contact"
            variant="contained"
            size="large"
            className="demo-button"
            onClick={() => mixpanel.track("User taken to Contact Form")}
        >
            <Typography variant="h5">Request A Demo</Typography>
        </DemoButton>
    );
}
