import * as React from "react";

function Arrows() {
    return (
        <>
            <path
                d="M8.09115 39.5021C7.85263 39.2636 7.46428 39.2636 7.22576 39.5021C6.98725 39.7406 6.98725 40.1289 7.22576 40.3675L10.5191 43.6608L7.22576 46.9542C6.98725 47.1927 6.98725 47.5811 7.22576 47.8196C7.34502 47.9388 7.50098 48 7.65693 48C7.81288 48 7.96884 47.9388 8.08809 47.8196L11.8157 44.092C11.9288 43.9758 11.9961 43.8229 11.9961 43.6608C11.9961 43.4988 11.9319 43.3428 11.8157 43.2297L8.08809 39.5021H8.09115Z 
        M1.04232 39.5021C0.803806 39.2636 0.415451 39.2636 0.176935 39.5021C-0.0615824 39.7406 -0.0615824 40.1289 0.176935 40.3675L3.4703 43.6608L0.179992 46.9542C-0.0585244 47.1927 -0.0585244 47.5811 0.179992 47.8196C0.299251 47.9388 0.455204 48 0.611158 48C0.767111 48 0.923064 47.9388 1.04232 47.8196L4.76991 44.092C4.88306 43.9758 4.95033 43.8229 4.95033 43.6608C4.95033 43.4988 4.88612 43.3428 4.76991 43.2297L1.04232 39.5021V39.5021Z"
                fill="#4C5B8C"
                className="iconDanceX"
            />
        </>
    );
}

const CartIcon = (props) => (
    <svg
        width="48"
        height="35"
        viewBox="0 0 70 48"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_68_6665)">
            <path
                d="M24.7467 46.3463C26.1343 44.9587 26.1343 42.7089 24.7467 41.3212C23.359 39.9336 21.1092 39.9336 19.7215 41.3212C18.3339 42.7089 18.3339 44.9587 19.7215 46.3463C21.1092 47.734 23.359 47.734 24.7467 46.3463Z"
                fill="#A3D3FE"
            />
            <path
                d="M45.0911 45.2047C45.8475 43.3939 44.9927 41.3128 43.1819 40.5564C41.3711 39.8001 39.2899 40.6549 38.5336 42.4657C37.7772 44.2765 38.632 46.3576 40.4428 47.114C42.2536 47.8703 44.3347 47.0155 45.0911 45.2047Z"
                fill="#A3D3FE"
            />
            <path
                d="M44.3632 24.6559L20.1445 20.9039V35.3555H37.4523C45.1338 35.3555 44.3632 24.6529 44.3632 24.6529V24.6559Z"
                fill="#A3D3FE"
            />
            <path
                d="M8.32643 11.7974C7.99006 11.7974 7.71484 12.0726 7.71484 12.409C7.71484 12.7454 7.99006 13.0206 8.32643 13.0206H12.4057C12.742 13.0206 13.0173 12.7454 13.0173 12.409C13.0173 12.0726 12.742 11.7974 12.4057 11.7974H8.32643Z"
                fill="#4C5B8C"
            />
            <Arrows />

            <path
                d="M43.8129 39.6703C41.7243 39.6703 39.9966 41.2176 39.7 43.2236H28.349C28.0524 41.2176 26.3217 39.6703 24.2362 39.6703C21.9397 39.6703 20.0713 41.5386 20.0713 43.8351C20.0713 46.1316 21.9397 48 24.2362 48C26.3247 48 28.0524 46.4527 28.349 44.4467H39.7C39.9966 46.4527 41.7243 48 43.8129 48C46.1094 48 47.9778 46.1316 47.9778 43.8351C47.9778 41.5386 46.1094 39.6703 43.8129 39.6703ZM24.2331 46.7738C22.6124 46.7738 21.2914 45.4558 21.2914 43.8321C21.2914 42.2083 22.6094 40.8904 24.2331 40.8904C25.8569 40.8904 27.1748 42.2083 27.1748 43.8321C27.1748 45.4558 25.8569 46.7738 24.2331 46.7738ZM43.8129 46.7738C42.1922 46.7738 40.8712 45.4558 40.8712 43.8321C40.8712 42.2083 42.1891 40.8904 43.8129 40.8904C45.4366 40.8904 46.7546 42.2083 46.7546 43.8321C46.7546 45.4558 45.4366 46.7738 43.8129 46.7738Z"
                fill="#4C5B8C"
            />
            <path
                d="M22.5368 20.9069V35.3586C22.5368 35.695 22.812 35.9702 23.1484 35.9702H40.4562C42.4713 35.9702 44.1287 35.2852 45.3855 33.9367C48.3792 30.7198 47.9939 24.8608 47.9786 24.6131C47.9572 24.3287 47.7432 24.0963 47.4618 24.0535L23.7294 20.3779C23.4664 18.0355 21.4757 16.21 19.063 16.21H17.4026C17.0662 16.21 16.791 16.4852 16.791 16.8216C16.791 17.1579 17.0662 17.4331 17.4026 17.4331H19.063C20.9804 17.4331 22.5368 18.9927 22.5368 20.9069V20.9069ZM44.4865 33.108C43.4713 34.1966 42.1166 34.7501 40.4562 34.7501H23.76V21.6225L46.7738 25.188C46.7952 26.6038 46.6607 30.7779 44.4865 33.111V33.108Z"
                fill="#4C5B8C"
            />
            <path
                d="M18.2251 27.0564C18.5615 27.0564 18.8367 26.7812 18.8367 26.4448C18.8367 26.1084 18.5615 25.8332 18.2251 25.8332H1.22316V20.2678H12.4763C12.8126 20.2678 13.0879 19.9926 13.0879 19.6562C13.0879 19.3199 12.8126 19.0447 12.4763 19.0447H1.22316V4.50736C1.22316 2.69708 2.69708 1.22316 4.50736 1.22316H38.7957C40.606 1.22316 42.0799 2.69708 42.0799 4.50736V18.9499C42.0799 19.2862 42.3551 19.5614 42.6915 19.5614C43.0278 19.5614 43.303 19.2862 43.303 18.9499V4.50736C43.303 2.02128 41.2818 0 38.7957 0H4.50736C2.02128 0 0 2.02128 0 4.50736V29.1113C0 31.5974 2.02128 33.6187 4.50736 33.6187H18.2251C18.5615 33.6187 18.8367 33.3434 18.8367 33.0071C18.8367 32.6707 18.5615 32.3955 18.2251 32.3955H4.50736C2.69708 32.3955 1.22316 30.9216 1.22316 29.1113V27.0594H18.2251V27.0564Z"
                fill="#4C5B8C"
            />
        </g>
        <defs>
            <clipPath id="clip0_68_6665">
                <rect width="48" height="47.9969" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CartIcon;
