import * as React from "react";

const Icon2 = (props) => (
    <svg
        width={67}
        height={100}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill="#1B9AD2">
            <path d="M.884 61a.913.913 0 0 1-.625-.254.89.89 0 0 1-.259-.633V7.13a.884.884 0 1 1 1.768 0v52.092l50.888-.48V10.027a.884.884 0 1 1 1.768 0v49.59c0 .485-.39.882-.879.887L.89 61H.884Z" />
            <path d="M15.402 10.912a.884.884 0 0 1-.884-.886V7.182c0-2.979-2.859-5.403-6.372-5.403-.212 0-.424.012-.63.03-3.278.266-5.748 2.577-5.748 5.367a.884.884 0 1 1-1.768 0C0 3.458 3.165.384 7.362.036 7.622.011 7.881 0 8.14 0c4.492 0 8.14 3.222 8.14 7.176v2.844c0 .49-.394.887-.884.887l.006.005Z" />
            <path d="M59.116 10.912H15.403a.884.884 0 0 1-.885-.886V7.182c0-2.979-2.859-5.403-6.372-5.403a.884.884 0 0 1-.884-.886c0-.491.395-.887.884-.887h43.708c4.492 0 8.14 3.222 8.14 7.176v2.844c0 .49-.395.886-.884.886h.006ZM16.286 9.14h41.946V7.182c0-2.979-2.859-5.403-6.372-5.403H13.493c1.71 1.319 2.788 3.252 2.788 5.403V9.14h.006ZM27.32 47.19c-7.603 0-13.792-6.206-13.792-13.832s6.189-13.833 13.793-13.833c7.604 0 13.793 6.207 13.793 13.833s-6.19 13.833-13.793 13.833Zm0-25.897c-6.63 0-12.024 5.409-12.024 12.06 0 6.65 5.394 12.058 12.025 12.058s12.025-5.408 12.025-12.059c0-6.65-5.394-12.06-12.025-12.06Z" />
            <path d="M28.158 40.777H26.49a3.252 3.252 0 0 1-3.242-3.251.884.884 0 1 1 1.768 0c0 .816.66 1.478 1.474 1.478h1.668c.814 0 1.474-.662 1.474-1.478v-1.673c0-.816-.66-1.478-1.474-1.478H26.49a3.252 3.252 0 0 1-3.242-3.251V29.45a3.252 3.252 0 0 1 3.242-3.252h1.668a3.252 3.252 0 0 1 3.242 3.252v.07a.884.884 0 1 1-1.768 0v-.07c0-.816-.66-1.478-1.474-1.478H26.49c-.813 0-1.474.662-1.474 1.478v1.673c0 .816.66 1.478 1.474 1.478h1.668a3.252 3.252 0 0 1 3.242 3.25v1.674a3.252 3.252 0 0 1-3.242 3.251Z" />
            <path d="M27.321 27.707a.884.884 0 0 1-.884-.887v-2.524a.884.884 0 1 1 1.768 0v2.524c0 .49-.395.887-.884.887ZM27.321 43.307a.884.884 0 0 1-.884-.887v-2.524a.884.884 0 1 1 1.768 0v2.524c0 .491-.395.887-.884.887Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h60v61H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default Icon2;
